import React, { MouseEventHandler } from 'react'

import AntPopconfirm, { PopconfirmProps as AntPopconfirmProps } from 'antd/es/popconfirm'

// O evento click não está na lista de props válidas do Popconfirm padrão

interface PopconfirmProps extends AntPopconfirmProps {
  onClick?: MouseEventHandler<HTMLElement>
}

function Popconfirm(props: PopconfirmProps) {
  return <AntPopconfirm {...props} />
}

export default Popconfirm
