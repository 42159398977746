import { del, get, query, post } from './client';
var endpoint = '/api/notices';
var noticeApi = {
    pageFilter: function (page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query("" + endpoint, { page: page, size: size }));
    },
    unread: function () {
        return get(endpoint + "/unread");
    },
    create: function (notice) {
        return post(endpoint, notice);
    },
    remove: function (uuid) {
        return del(query(endpoint, { uuid: uuid }));
    },
};
export default noticeApi;
