import React, { memo, ChangeEvent, useState } from 'react'

import Input from 'antd/es/input'

import { useTranslation } from '../../../i18n'
import useDebouncedFunction from '../../../hooks/useDebouncedFunction'

interface Props {
  onFilterChange: (filter: string | undefined) => void
}

function FilterCGTemplates({ onFilterChange }: Props) {
  const { t } = useTranslation()

  const [value, setValue] = useState('')

  // Para não disparar várias vezes enquanto está digitando o filtro
  const filterChangeHandler = useDebouncedFunction(onFilterChange, 250)

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    filterChangeHandler(e.target.value)
  }

  return (
    <Input.Search
      placeholder={t('words:search')}
      onChange={onInputChange}
      value={value}
      allowClear
      style={{ margin: '8px 0 4px 0' }}
    />
  )
}

export default memo(FilterCGTemplates)
