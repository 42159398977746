import { Reducer } from 'redux'

import { TwitterList } from '@anews/types'

import {
  TwitterListActionType as ActionType,
  TwitterListAction,
} from '../actions/twitterlist-actions'

export interface TwitterListsListState {
  data: TwitterList[]
  loading: boolean
}

export interface TwitterListsState {
  list: TwitterListsListState
  editing?: TwitterList
  saving: boolean
  removing: boolean
}

export const initialState: TwitterListsState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(
  list: TwitterListsListState,
  action: TwitterListAction,
): TwitterListsListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.lists,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.list, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(l => (l.id === action.list.id ? action.list : l)),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(l => l.id !== action.id) }

    default:
      return list
  }
}

const listsReducer: Reducer<TwitterListsState, TwitterListAction> = (
  state = initialState,
  action,
): TwitterListsState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT:
      return { ...state, editing: action.list }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.list, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default listsReducer
