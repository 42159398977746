import { Reducer } from 'redux'

import { Program } from '@anews/types'

import { ProgramActionType as ActionType, ProgramAction } from '../actions'

export interface ProgramsListState {
  data: Program[]
  loading: boolean
}

export interface ProgramsState {
  all: ProgramsListState
  accessible: ProgramsListState
  editing?: Program
  saving: boolean
  removing: boolean
}

export const initialState: ProgramsState = {
  all: {
    data: [],
    loading: true,
  },
  accessible: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(list: ProgramsListState, action: ProgramAction): ProgramsListState {
  switch (action.type) {
    case ActionType.LIST_ALL_REQUEST:
    case ActionType.LIST_ACCESSIBLE_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_ALL_FAILURE:
    case ActionType.LIST_ACCESSIBLE_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_ALL_SUCCESS:
    case ActionType.LIST_ACCESSIBLE_SUCCESS:
      return {
        ...list,
        data: action.programs,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.program, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(program =>
          program.id === action.program.id ? action.program : program,
        ),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(program => !action.ids.includes(program.id)) }

    default:
      return list
  }
}

const programsReducer: Reducer<ProgramsState, ProgramAction> = (
  state = initialState,
  action,
): ProgramsState => {
  switch (action.type) {
    case ActionType.LIST_ALL_REQUEST:
    case ActionType.LIST_ALL_FAILURE:
    case ActionType.LIST_ALL_SUCCESS:
      return { ...state, all: listReducer(state.all, action) }

    case ActionType.LIST_ACCESSIBLE_REQUEST:
    case ActionType.LIST_ACCESSIBLE_FAILURE:
    case ActionType.LIST_ACCESSIBLE_SUCCESS:
      return { ...state, accessible: listReducer(state.accessible, action) }

    case ActionType.NEW:
    case ActionType.EDIT_SUCCESS:
      return { ...state, editing: action.program }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.program, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        all: listReducer(state.all, action),
        // Somente REMOVE altera a lista de programas acessíveis
        // Usuário vai ter que dar reload para casos onde perdeu ou recebeu acesso a um programa
        accessible:
          action.type === ActionType.REMOVE_SUCCESS
            ? listReducer(state.accessible, action)
            : state.accessible,
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default programsReducer
