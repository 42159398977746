import { Reducer } from 'redux'

import { Device } from '@anews/types'

import { DeviceActionType, DeviceAction } from '../actions/device-actions'

export interface DevicesListState {
  data: Device[]
  loading: boolean
}

export interface DevicesState {
  list: DevicesListState
  mams: DevicesListState
  editing?: Device
  saving: boolean
  removing: boolean
}

export const initialState: DevicesState = {
  list: {
    data: [],
    loading: true,
  },
  mams: {
    loading: false,
    data: [],
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(list: DevicesListState, action: DeviceAction): DevicesListState {
  switch (action.type) {
    case DeviceActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case DeviceActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case DeviceActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.devices,
        loading: false,
      }

    case DeviceActionType.CREATE_SUCCESS:
      return { ...list, data: [action.device, ...list.data] }

    case DeviceActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(device => (device.id === action.device.id ? action.device : device)),
      }

    case DeviceActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(device => !action.ids.includes(device.id)) }

    default:
      return list
  }
}

function mamsReducer(mams: DevicesListState, action: DeviceAction): DevicesListState {
  switch (action.type) {
    case DeviceActionType.LIST_MAMS_REQUEST:
      return { ...mams, loading: true }

    case DeviceActionType.LIST_MAMS_FAILURE:
      return { ...mams, loading: false }

    case DeviceActionType.LIST_MAMS_SUCCESS:
      return {
        ...mams,
        data: action.devices,
        loading: false,
      }

    default:
      return mams
  }
}

const devicesReducer: Reducer<DevicesState, DeviceAction> = (
  state = initialState,
  action,
): DevicesState => {
  switch (action.type) {
    case DeviceActionType.LIST_REQUEST:
    case DeviceActionType.LIST_FAILURE:
    case DeviceActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case DeviceActionType.LIST_MAMS_REQUEST:
    case DeviceActionType.LIST_MAMS_FAILURE:
    case DeviceActionType.LIST_MAMS_SUCCESS:
      return { ...state, mams: mamsReducer(state.mams, action) }

    case DeviceActionType.NEW:
    case DeviceActionType.EDIT_SUCCESS:
      return { ...state, editing: action.device }

    case DeviceActionType.CREATE_REQUEST:
    case DeviceActionType.UPDATE_REQUEST:
      return { ...state, editing: action.device, saving: true }

    case DeviceActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case DeviceActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case DeviceActionType.CREATE_FAILURE:
    case DeviceActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case DeviceActionType.CREATE_SUCCESS:
    case DeviceActionType.UPDATE_SUCCESS:
    case DeviceActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default devicesReducer
