import { get, put, query } from './client';
var endpoint = '/api/index';
var indexApi = {
    loadState: function () {
        return get(endpoint + "/state");
    },
    rebuildIndex: function (targets) {
        return put(query("" + endpoint, { targets: targets }));
    },
};
export default indexApi;
