import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'

import { storyApi as api } from '@anews/api'
import { StoryConfig, Story } from '@anews/types'

import {
  EntityLockActions,
  NotificationActions,
  StoryActionType as ActionType,
  StoryActions,
  StoryActionMap,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { renewLock, takeOverLock } = EntityLockActions
const { notifyError, notifySuccess } = NotificationActions

const {
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  createConfigSuccess,
  createConfigFailure,
  patchStoryFailure,
  patchStorySuccess,
  loadStorySuccess,
  loadStoryFailure,
  updateStorySuccess,
  updateStoryFailure,
  removeStoriesSuccess,
  removeStoriesFailure,
  toggleStoriesStatusSuccess,
  toggleStoriesStatusFailure,
} = StoryActions

/* Watchers */

function* loadStorySaga(action: StoryActionMap<ActionType.LOAD_REQUEST>): Generator {
  try {
    const { edit, takeOver, clearViewing, parentTab } = action.config || {}

    if (typeof action.target === 'number') {
      const story = yield call(api.load, action.target, !!edit, !!takeOver)
      yield put(loadStorySuccess(story as Story, { edit, clearViewing, parentTab }))
    } else {
      const story = action.target
      if (takeOver) {
        yield put(takeOverLock(story.uuid))
      } else {
        yield put(renewLock(story.uuid))
      }
      yield put(loadStorySuccess(story, { edit, clearViewing, parentTab }))
    }
  } catch (error) {
    yield put(loadStoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* updateStorySaga(action: StoryActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const story = yield call(api.update, action.story)
    yield put(updateStorySuccess(story as Story))
  } catch (error) {
    yield put(updateStoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* patchStorySaga(action: StoryActionMap<ActionType.PATCH_REQUEST>): Generator {
  try {
    yield call(api.updateField, action.storyId, action.field, action.newValue)
    yield put(patchStorySuccess())
  } catch (error) {
    yield put(
      patchStoryFailure(error, action.storyId, action.field, action.newValue, action.oldValue),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* deleteStoriesSaga(action: StoryActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    const ids = yield call(api.remove, action.ids)
    yield put(removeStoriesSuccess(ids as number[]))
  } catch (error) {
    yield put(removeStoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

function* toggleStoryStatusSaga(
  action: StoryActionMap<ActionType.TOGGLE_STATUS_REQUEST>,
): Generator {
  try {
    const { ids, status, updatePrevious } = action
    yield call(api.toggleMultipleStatus, ids, status, updatePrevious)
    yield put(toggleStoriesStatusSuccess())
  } catch (error) {
    yield put(toggleStoriesStatusFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as StoryConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: StoryActionMap<ActionType.CREATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as StoryConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: StoryActionMap<ActionType.UPDATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as StoryConfig))
    yield put(
      notifySuccess({
        message: i18n.t('words:success'),
        description: i18n.t('config:updateSuccess'),
      }),
    )
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LOAD_REQUEST, loadStorySaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateStorySaga)
  },
  function* () {
    yield takeLatest(ActionType.PATCH_REQUEST, patchStorySaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, deleteStoriesSaga)
  },
  function* () {
    yield takeLeading(ActionType.TOGGLE_STATUS_REQUEST, toggleStoryStatusSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
