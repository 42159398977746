import { get, put } from './client';
var endpoint = '/api/branch';
var branchApi = {
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
    locales: function () {
        return get(endpoint + "/locales");
    },
};
export default branchApi;
