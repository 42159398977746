import { del, get, post, put, query } from './client';
var endpoint = '/api/devices';
var deviceApi = {
    protocols: function () {
        return get(endpoint + "/protocols");
    },
    types: function () {
        return get(endpoint + "/types");
    },
    vendors: function () {
        return get(endpoint + "/vendors");
    },
    list: function () {
        return get(endpoint);
    },
    cgs: function () {
        return get(endpoint + "/cgs");
    },
    mams: function () {
        return get(endpoint + "/mams");
    },
    playouts: function () {
        return get(endpoint + "/playouts");
    },
    paths: function (id) {
        return get(endpoint + "/" + id + "/paths");
    },
    load: function (id) {
        return get(endpoint + "/" + id);
    },
    create: function (device) {
        return post(endpoint, device);
    },
    update: function (device) {
        return put("" + endpoint, device);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
};
export default deviceApi;
