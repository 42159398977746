import { Story, StoryConfig, DisplayStatus, User } from '@anews/types'

export enum StoryActionType {
  UNLOAD = '@story/UNLOAD',
  STORE_TRANSIENT = '@story/STORE_TRANSIENT',
  // loadStory()
  LOAD_REQUEST = '@story/LOAD_REQUEST',
  LOAD_SUCCESS = '@story/LOAD_SUCCESS',
  LOAD_FAILURE = '@story/LOAD_FAILURE',
  // updateStory()
  UPDATE_REQUEST = '@story/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@story/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@story/UPDATE_FAILURE',
  // patchStory()
  PATCH_REQUEST = '@story/PATCH_REQUEST',
  PATCH_SUCCESS = '@story/PATCH_SUCCESS',
  PATCH_FAILURE = '@story/PATCH_FAILURE',
  // removeStories()
  REMOVE_REQUEST = '@story/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@story/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@story/REMOVE_FAILURE',
  // toggleStoriesStatus()
  TOGGLE_STATUS_REQUEST = '@story/TOGGLE_STATUS_REQUEST',
  TOGGLE_STATUS_SUCCESS = '@story/TOGGLE_STATUS_SUCCESS',
  TOGGLE_STATUS_FAILURE = '@story/TOGGLE_STATUS_FAILURE',
  // websocket
  WS_DELETE = '@story/WS_DELETE',
  WS_PATCH = '@story/WS_PATCH',
  WS_UPDATE = '@story/WS_UPDATE',
  // loadConfig()
  LOAD_CONFIG_REQUEST = '@story/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@story/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@story/LOAD_CONFIG_FAILURE',
  // createConfig()
  CREATE_CONFIG_REQUEST = '@story/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@story/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@story/CREATE_CONFIG_FAILURE',
  // updateConfig()
  UPDATE_CONFIG_REQUEST = '@story/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@story/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@story/UPDATE_CONFIG_FAILURE',
}

export type StoryActionMap<T extends StoryActionType> = { type: T } & StoryAction

export interface StoryChange {
  id: number
  rundownId: number
  changes: Partial<Story>
}

export type StoryAction = ReturnType<typeof StoryActions[keyof typeof StoryActions]>

export const StoryActions = {
  //
  //	unloadStory()
  //

  unloadStory: () => <const>{ type: StoryActionType.UNLOAD },

  //
  //	storyTransient()
  //

  storeTransient: (story: Story) => <const>{ type: StoryActionType.STORE_TRANSIENT, story },

  //
  //	loadStory()
  //

  loadStory: (
    target: number | Story,
    config?: { edit?: boolean; clearViewing?: boolean; takeOver?: boolean; parentTab?: string },
  ) => <const>{ type: StoryActionType.LOAD_REQUEST, target, config },

  loadStorySuccess: (
    story: Story,
    config?: { edit?: boolean; clearViewing?: boolean; parentTab: string | undefined },
  ) => <const>{ type: StoryActionType.LOAD_SUCCESS, story, config },

  loadStoryFailure: (error: Error) => <const>{ type: StoryActionType.LOAD_FAILURE, error },

  //
  //	updateStory()
  //

  updateStory: (story: Story) => <const>{ type: StoryActionType.UPDATE_REQUEST, story },

  updateStorySuccess: (story: Story) => <const>{ type: StoryActionType.UPDATE_SUCCESS, story },

  updateStoryFailure: (error: Error) => <const>{ type: StoryActionType.UPDATE_FAILURE, error },

  //
  //  patchStory()
  //

  patchStory: (storyId: number, field: keyof Story, newValue: any, oldValue: any) =>
    <const>{ type: StoryActionType.PATCH_REQUEST, storyId, field, newValue, oldValue },

  patchStorySuccess: () => <const>{ type: StoryActionType.PATCH_SUCCESS },

  patchStoryFailure: (
    error: Error,
    storyId: number,
    field: keyof Story,
    newValue: any,
    oldValue: any,
  ) => <const>{ type: StoryActionType.PATCH_FAILURE, error, storyId, field, newValue, oldValue },

  //
  //  removeStories()
  //

  removeStories: (ids: number[]) => <const>{ type: StoryActionType.REMOVE_REQUEST, ids },

  removeStoriesSuccess: (ids: number[]) => <const>{ type: StoryActionType.REMOVE_SUCCESS, ids },

  removeStoriesFailure: (error: Error) => <const>{ type: StoryActionType.REMOVE_FAILURE, error },

  //
  //  toggleStoriesStatus()
  //

  toggleStoriesStatus: (ids: number[], status?: DisplayStatus, updatePrevious?: boolean) =>
    <const>{ type: StoryActionType.TOGGLE_STATUS_REQUEST, ids, status, updatePrevious },

  toggleStoriesStatusSuccess: () => <const>{ type: StoryActionType.TOGGLE_STATUS_SUCCESS },

  toggleStoriesStatusFailure: (error: Error) =>
    <const>{ type: StoryActionType.TOGGLE_STATUS_FAILURE, error },

  //
  //  wsDeleteStories()
  //

  wsDeleteStories: (ids: number[]) => <const>{ type: StoryActionType.WS_DELETE, ids },

  //
  //  wsPatchStory()
  //

  wsPatchStory: (changes: StoryChange[], user: User | undefined) =>
    <const>{ type: StoryActionType.WS_PATCH, changes, user },

  //
  //  wsUpdateStory()
  //

  wsUpdateStory: (stories: Story[], user: User | undefined) =>
    <const>{ type: StoryActionType.WS_UPDATE, stories, user },

  //
  //  loadConfig()
  //

  loadConfig: () => <const>{ type: StoryActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: StoryConfig) =>
    <const>{ type: StoryActionType.LOAD_CONFIG_SUCCESS, config },

  loadConfigFailure: (error: Error) => <const>{ type: StoryActionType.LOAD_CONFIG_FAILURE, error },

  //
  //  createConfig()
  //

  createConfig: (config: StoryConfig) =>
    <const>{ type: StoryActionType.CREATE_CONFIG_REQUEST, config },

  createConfigSuccess: (config: StoryConfig) =>
    <const>{ type: StoryActionType.CREATE_CONFIG_SUCCESS, config },

  createConfigFailure: (error: Error) =>
    <const>{ type: StoryActionType.CREATE_CONFIG_FAILURE, error },

  //
  //  updateConfig()
  //

  updateConfig: (config: StoryConfig) =>
    <const>{ type: StoryActionType.UPDATE_CONFIG_REQUEST, config },

  updateConfigSuccess: (config: StoryConfig) =>
    <const>{ type: StoryActionType.UPDATE_CONFIG_SUCCESS, config },

  updateConfigFailure: (error: Error) =>
    <const>{ type: StoryActionType.UPDATE_CONFIG_FAILURE, error },
}
