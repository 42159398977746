import React, { memo, HTMLProps } from 'react'
import styled from '@emotion/styled'

interface VGroupProps extends HTMLProps<HTMLDivElement> {
  gutter?: number
}

const StyledDiv = styled.div<VGroupProps>`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: ${props => `${(props.gutter !== undefined ? props.gutter : 1) * 8}px`};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`

const VGroup = React.forwardRef<HTMLDivElement, VGroupProps>(({ as, ...props }, ref) => (
  <StyledDiv ref={ref} {...props} />
))

VGroup.displayName = 'VGroup'

export default memo(VGroup)
