import { Device } from '@anews/types'

import { Item } from '../../mos/types'
import { ItemReceivedHandler } from '../reducers/mosplugin-reducer'

export enum MosPluginActionType {
  OPEN = '@mosplugin/OPEN',
  EDIT = '@mosplugin/EDIT',
  REF = '@mosplugin/REF',
  CLOSE = '@mosplugin/CLOSE',
}

export type MosPluginActionMap<T extends MosPluginActionType> = { type: T } & MosPluginAction

export type MosPluginAction = ReturnType<typeof MosPluginActions[keyof typeof MosPluginActions]>

export const MosPluginActions = {
  //
  //  open()
  //
  open: (
    device: Device,
    roId: string,
    storyId: string,
    programUuid: string,
    handlerId: string,
    onItemReceived: ItemReceivedHandler,
  ) =>
    <const>{
      type: MosPluginActionType.OPEN,
      device,
      roId,
      storyId,
      programUuid,
      handlerId,
      onItemReceived,
    },

  //
  //  edit()
  //
  edit: (
    device: Device,
    roId: string,
    storyId: string,
    programUuid: string,
    item: Item,
    handlerId: string,
    onItemReceived?: ItemReceivedHandler,
  ) =>
    <const>{
      type: MosPluginActionType.EDIT,
      device,
      roId,
      storyId,
      programUuid,
      item,
      handlerId,
      onItemReceived,
    },

  //
  //  updateRef
  //
  updateRef: (handlerId: string, onItemReceived?: ItemReceivedHandler) =>
    <const>{ type: MosPluginActionType.REF, handlerId, onItemReceived },

  //
  //  close()
  //
  close: () => <const>{ type: MosPluginActionType.CLOSE },
}
