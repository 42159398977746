import { call, put, takeLatest } from 'redux-saga/effects'

import { facebookCategoryApi as api } from '@anews/api'
import { FacebookCategory } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  FacebookCategoryActionType as ActionType,
  FacebookCategoryActionMap as ActionMap,
  FacebookCategoryActions,
} from '../actions/facebookcategory-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

const {
  listFacebookCategoriesSuccess,
  listFacebookCategoriesFailure,
  createFacebookCategorySuccess,
  createFacebookCategoryFailure,
  updateFacebookCategorySuccess,
  updateFacebookCategoryFailure,
  removeFacebookCategorySuccess,
  removeFacebookCategoryFailure,
} = FacebookCategoryActions

/* Watchers */

function* listFacebookCategoriesSaga(action: ActionMap<ActionType.LIST_REQUEST>): Generator {
  try {
    const categories = yield call(api.list, action.pages)
    yield put(listFacebookCategoriesSuccess(categories as FacebookCategory[]))
  } catch (error) {
    yield put(listFacebookCategoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createFacebookCategorySaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const category = yield call(api.create, action.category)
    yield put(createFacebookCategorySuccess(category as FacebookCategory))
  } catch (error) {
    yield put(createFacebookCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateFacebookCategorySaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const category = yield call(api.update, action.category)
    yield put(updateFacebookCategorySuccess(category as FacebookCategory))
  } catch (error) {
    yield put(updateFacebookCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeFacebookCategorySaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeFacebookCategorySuccess(action.id))
  } catch (error) {
    yield put(removeFacebookCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listFacebookCategoriesSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createFacebookCategorySaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateFacebookCategorySaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeFacebookCategorySaga)
  },
])
