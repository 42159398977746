import { Program } from '@anews/types'

export enum ProgramActionType {
  NEW = '@program/NEW',
  LIST_ALL_REQUEST = '@program/LIST_ALL_REQUEST',
  LIST_ALL_SUCCESS = '@program/LIST_ALL_SUCCESS',
  LIST_ALL_FAILURE = '@program/LIST_ALL_FAILURE',
  LIST_ACCESSIBLE_REQUEST = '@program/LIST_ACCESSIBLE_REQUEST',
  LIST_ACCESSIBLE_SUCCESS = '@program/LIST_ACCESSIBLE_SUCCESS',
  LIST_ACCESSIBLE_FAILURE = '@program/LIST_ACCESSIBLE_FAILURE',
  EDIT_REQUEST = '@program/EDIT_REQUEST',
  EDIT_SUCCESS = '@program/EDIT_SUCCESS',
  EDIT_FAILURE = '@program/EDIT_FAILURE',
  CREATE_REQUEST = '@program/CREATE_REQUEST',
  CREATE_SUCCESS = '@program/CREATE_SUCCESS',
  CREATE_FAILURE = '@program/CREATE_FAILURE',
  UPDATE_REQUEST = '@program/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@program/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@program/UPDATE_FAILURE',
  REMOVE_REQUEST = '@program/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@program/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@program/REMOVE_FAILURE',
}

export type ProgramActionMap<T extends ProgramActionType> = { type: T } & ProgramAction
export type ProgramAction = ReturnType<typeof ProgramActions[keyof typeof ProgramActions]>

export const ProgramActions = {
  //	newProgram()

  newProgram: (program?: Program) => <const>{ type: ProgramActionType.NEW, program },

  //	listAllPrograms()

  listAllPrograms: () => <const>{ type: ProgramActionType.LIST_ALL_REQUEST },

  listAllProgramsSuccess: (programs: Program[]) =>
    <const>{ type: ProgramActionType.LIST_ALL_SUCCESS, programs },

  listAllProgramsFailure: (error: Error) =>
    <const>{ type: ProgramActionType.LIST_ALL_FAILURE, error },

  //	listAccessiblePrograms()

  listAccessiblePrograms: () => <const>{ type: ProgramActionType.LIST_ACCESSIBLE_REQUEST },

  listAccessibleProgramsSuccess: (programs: Program[]) =>
    <const>{ type: ProgramActionType.LIST_ACCESSIBLE_SUCCESS, programs },

  listAccessibleProgramsFailure: (error: Error) =>
    <const>{ type: ProgramActionType.LIST_ACCESSIBLE_FAILURE, error },

  //	editProgram()

  editProgram: (id: number, copy: boolean) =>
    <const>{ type: ProgramActionType.EDIT_REQUEST, id, copy },

  editProgramSuccess: (program: Program) =>
    <const>{ type: ProgramActionType.EDIT_SUCCESS, program },

  editProgramFailure: (error: Error) => <const>{ type: ProgramActionType.EDIT_FAILURE, error },

  //	createProgram()

  createProgram: (program: Program) => <const>{ type: ProgramActionType.CREATE_REQUEST, program },

  createProgramSuccess: (program: Program) =>
    <const>{ type: ProgramActionType.CREATE_SUCCESS, program },

  createProgramFailure: (error: Error) => <const>{ type: ProgramActionType.CREATE_FAILURE, error },

  //	updateProgram()

  updateProgram: (program: Program) => <const>{ type: ProgramActionType.UPDATE_REQUEST, program },

  updateProgramSuccess: (program: Program) =>
    <const>{ type: ProgramActionType.UPDATE_SUCCESS, program },

  updateProgramFailure: (error: Error) => <const>{ type: ProgramActionType.UPDATE_FAILURE, error },

  //	removeProgram()

  removeProgram: (ids: number[]) => <const>{ type: ProgramActionType.REMOVE_REQUEST, ids },

  removeProgramSuccess: (ids: number[]) => <const>{ type: ProgramActionType.REMOVE_SUCCESS, ids },

  removeProgramFailure: (error: Error) => <const>{ type: ProgramActionType.REMOVE_FAILURE, error },
}
