import { call, put, takeLatest } from 'redux-saga/effects'

import { programApi as api } from '@anews/api'
import { Program } from '@anews/types'
import { createCopy } from '@anews/utils'

import {
  NotificationActions,
  ProgramActions,
  ProgramActionType as ActionType,
  ProgramActionMap as ActionMap,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  listAllProgramsSuccess,
  listAllProgramsFailure,
  editProgramSuccess,
  editProgramFailure,
  createProgramSuccess,
  createProgramFailure,
  updateProgramSuccess,
  updateProgramFailure,
  removeProgramSuccess,
  removeProgramFailure,
  listAccessibleProgramsSuccess,
  listAccessibleProgramsFailure,
} = ProgramActions

const { notifyError } = NotificationActions

/* Watchers */

function* listAllProgramsSaga(): Generator {
  try {
    const programs = yield call(api.list, true)
    yield put(listAllProgramsSuccess(programs as Program[]))
  } catch (error) {
    yield put(listAllProgramsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listAccessibleProgramsSaga(): Generator {
  try {
    const programs = yield call(api.list, false)
    yield put(listAccessibleProgramsSuccess(programs as Program[]))
  } catch (error) {
    yield put(listAccessibleProgramsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* editProgramSaga(action: ActionMap<ActionType.EDIT_REQUEST>): Generator {
  try {
    const program = yield call(api.load, action.id)
    yield put(editProgramSuccess(action.copy ? createCopy(program) : (program as Program)))
  } catch (error) {
    yield put(editProgramFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createProgramSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const program = yield call(api.create, action.program)
    yield put(createProgramSuccess(program as Program))
  } catch (error) {
    yield put(createProgramFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateProgramSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const program = yield call(api.update, action.program)
    yield put(updateProgramSuccess(program as Program))
  } catch (error) {
    yield put(updateProgramFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeProgramSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.ids)
    yield put(removeProgramSuccess(action.ids))
  } catch (error) {
    yield put(removeProgramFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_ALL_REQUEST, listAllProgramsSaga)
  },
  function* () {
    yield takeLatest(ActionType.LIST_ACCESSIBLE_REQUEST, listAccessibleProgramsSaga)
  },
  function* () {
    yield takeLatest(ActionType.EDIT_REQUEST, editProgramSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createProgramSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateProgramSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeProgramSaga)
  },
])
