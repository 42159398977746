export var Indexes;
(function (Indexes) {
    Indexes["ASSIGNMENTS"] = "assignments";
    Indexes["CONTACTS"] = "contacts";
    Indexes["DOCUMENTS"] = "documents";
    Indexes["FACEBOOK"] = "facebook";
    Indexes["LOGS"] = "logs";
    Indexes["MEDIA"] = "media";
    Indexes["NEWS_REPORTS"] = "news_reports";
    Indexes["POSTS"] = "posts";
    Indexes["PRODUCTIONS"] = "productions";
    Indexes["REPORTS"] = "reports";
    Indexes["RSS"] = "rss";
    Indexes["STORIES"] = "stories";
    Indexes["TWITTER"] = "twitter";
})(Indexes || (Indexes = {}));
