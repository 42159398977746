import { get, post, put, del, query } from './client';
var endpoint = '/api/calendars/categories';
var calendarCategoryApi = {
    list: function (calendars) {
        return get(query("" + endpoint, { calendars: calendars }));
    },
    find: function (id) {
        return get(endpoint + "/" + id);
    },
    create: function (calendar) {
        return post(endpoint, calendar);
    },
    update: function (calendar) {
        return put(endpoint, calendar);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default calendarCategoryApi;
