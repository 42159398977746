export var DeviceProtocol;
(function (DeviceProtocol) {
    DeviceProtocol["II"] = "II";
    DeviceProtocol["MOS"] = "MOS";
})(DeviceProtocol || (DeviceProtocol = {}));
export var DeviceVendor;
(function (DeviceVendor) {
    DeviceVendor["AVID"] = "AVID";
    DeviceVendor["AXLE_AI"] = "AXLE_AI";
    DeviceVendor["BRAINSTORM"] = "BRAINSTORM";
    DeviceVendor["CHYRON"] = "CHYRON";
    DeviceVendor["CUESCRIPT"] = "CUESCRIPT";
    DeviceVendor["EDITSHARE"] = "EDITSHARE";
    DeviceVendor["EITV"] = "EITV";
    DeviceVendor["EMPRESS"] = "EMPRESS";
    DeviceVendor["FOR_A"] = "FOR_A";
    DeviceVendor["FOUR_S"] = "FOUR_S";
    DeviceVendor["GRASS_VALLEY"] = "GRASS_VALLEY";
    DeviceVendor["HARRIS"] = "HARRIS";
    DeviceVendor["IGSON"] = "IGSON";
    DeviceVendor["MEDIA_PORTAL"] = "MEDIA_PORTAL";
    DeviceVendor["METUS"] = "METUS";
    DeviceVendor["NEWSMAKER"] = "NEWSMAKER";
    DeviceVendor["ORAD"] = "ORAD";
    DeviceVendor["OTHER"] = "OTHER";
    DeviceVendor["PLAYBOX_NEO"] = "PLAYBOX_NEO";
    DeviceVendor["ROSS"] = "ROSS";
    DeviceVendor["SNEWS"] = "SNEWS";
    DeviceVendor["SOUV"] = "SOUV";
    DeviceVendor["SQUAREBOX"] = "SQUAREBOX";
    DeviceVendor["VIZRT"] = "VIZRT";
    DeviceVendor["VMIX"] = "VMIX";
    DeviceVendor["VOICE_INTERACTION"] = "VOICE_INTERACTION";
    DeviceVendor["VSN"] = "VSN";
    DeviceVendor["WOLKIE"] = "WOLKIE";
    DeviceVendor["WTVISION"] = "WTVISION";
    DeviceVendor["ZEDIA"] = "ZEDIA";
})(DeviceVendor || (DeviceVendor = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType["CC"] = "CC";
    DeviceType["CG"] = "CG";
    DeviceType["MAM"] = "MAM";
    DeviceType["PLAYOUT"] = "PLAYOUT";
    DeviceType["TP"] = "TP";
})(DeviceType || (DeviceType = {}));
