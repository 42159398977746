import { Reducer } from 'redux'

import { Institution } from '@anews/types'

import { ActionType, InstitutionAction } from '../actions/institution-actions'

export interface InstitutionsListState {
  data: Institution[]
  loading: boolean
}

export interface InstitutionsState {
  list: InstitutionsListState
  editing?: Institution
  saving: boolean
  removing: boolean
}

export const initialState: InstitutionsState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(
  list: InstitutionsListState,
  action: InstitutionAction,
): InstitutionsListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.institutions,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.institution, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(institution =>
          institution.id === action.institution.id ? action.institution : institution,
        ),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(institution => institution.id !== action.id) }

    default:
      return list
  }
}

const institutionsReducer: Reducer<InstitutionsState, InstitutionAction> = (
  state = initialState,
  action,
): InstitutionsState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT:
      return { ...state, editing: action.institution }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.institution, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default institutionsReducer
