import AntButton, { ButtonProps as AntButtonProps } from 'antd/es/button'

import styled from '@emotion/styled'
import {
  background,
  color,
  fontSize,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  space,
  width,
  BackgroundProps,
  ColorProps,
  FontSizeProps,
  HeightProps,
  MaxHeightProps,
  MaxWidthProps,
  MinWidthProps,
  SpaceProps,
  WidthProps,
  MinHeightProps,
} from 'styled-system'

export type ButtonProps = AntButtonProps &
  BackgroundProps &
  ColorProps &
  FontSizeProps &
  HeightProps &
  MaxHeightProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  SpaceProps &
  WidthProps

const Button = styled(AntButton)<ButtonProps>`
  ${background}
  ${color}
  ${fontSize}
  ${height}
  ${maxHeight}
  ${maxWidth}
  ${minHeight}
  ${minWidth}
  ${space}
  ${width}
`

export default Button
