import styled from '@emotion/styled'
import ReactSplitPane from 'react-split-pane'

import theme from '../../theme'

const SplitPane = styled(ReactSplitPane)`
  .Resizer {
    background: ${theme.palletes.grey[3]};
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;

    &:hover {
      transition: all 2s ease;
    }

    &.horizontal {
      height: 11px;
      margin: -5px 0;
      border-top: 5px solid rgba(255, 255, 255, 0);
      border-bottom: 5px solid rgba(255, 255, 255, 0);
      cursor: row-resize;
      width: 100%;

      &:hover {
        border-top: 5px solid rgba(0, 0, 0, 0.5);
        border-bottom: 5px solid rgba(0, 0, 0, 0.5);
      }
    }

    &.vertical {
      width: 11px;
      margin: 0 -5px;
      border-left: 5px solid rgba(255, 255, 255, 0);
      border-right: 5px solid rgba(255, 255, 255, 0);
      cursor: col-resize;

      &:hover {
        border-left: 5px solid rgba(0, 0, 0, 0.5);
        border-right: 5px solid rgba(0, 0, 0, 0.5);
      }
    }

    &.disabled {
      cursor: not-allowed;

      &:hover {
        border-color: transparent;
      }
    }
  }
`

export default SplitPane
