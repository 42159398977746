import styled from '@emotion/styled'
import {
  background,
  color,
  display,
  fontSize,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  space,
  width,
  BackgroundProps,
  ColorProps,
  DisplayProps,
  FontSizeProps,
  HeightProps,
  MaxHeightProps,
  MaxWidthProps,
  MinHeightProps,
  MinWidthProps,
  SpaceProps,
  WidthProps,
} from 'styled-system'
import { HTMLProps } from 'react'

export type SpanProps = HTMLProps<HTMLSpanElement> &
  BackgroundProps &
  ColorProps &
  DisplayProps &
  FontSizeProps &
  HeightProps &
  MaxHeightProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  SpaceProps &
  WidthProps

const Span = styled.span<SpanProps>`
  ${background}
  ${color}
  ${display}
  ${fontSize}
  ${height}
  ${maxHeight}
  ${maxWidth}
  ${minHeight}
  ${minWidth}
  ${space}
  ${width}
`

export default Span
