import i18n, { StringMap, TOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next, useTranslation as useT, UseTranslationResponse } from 'react-i18next'
import moment from 'moment'

import { TranslationDictionary, enUS, esES, ptBR } from '@anews/types'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources: {
      en: enUS,
      es: esES,
      pt: ptBR,
    },
    // lng: 'pt',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format) => {
        try {
          if (format === 'lowercase') {
            return value.toLowerCase()
          }
          if (format === 'uppercase') {
            return value.toUpperCase()
          }
          if (value instanceof Date) {
            return moment(value).format(format)
          }
          if (moment.isMoment(value)) {
            return value.format(format)
          }
          if (typeof value === 'string') {
            return moment(value).format(format)
          }
        } catch (error) {
          console.error(error)
        }

        return value
      },
    },
    ns: [
      'admin',
      'agency',
      'auth',
      'block',
      'chat',
      'calendar',
      'checklist',
      'common',
      'config',
      'contact',
      'device',
      'document',
      'editor',
      'error',
      'format',
      'googleApi',
      'group',
      'guideline',
      'index',
      'ldap',
      'license',
      'login',
      'main',
      'maps',
      'media',
      'news',
      'notices',
      'phrases',
      'post',
      'print',
      'program',
      'report',
      'reportage',
      'round',
      'rundown',
      'settings',
      'souv',
      'story',
      'template',
      'unsaved',
      'user',
      'validation',
      'wordpress',
      'words',
    ],
    defaultNS: 'common',
  })

// Override tipo para usar dicionario de traducoes criado
export const useTranslation = () =>
  useT() as Omit<UseTranslationResponse<string | string[]>, 't'> & {
    t: (
      key: TranslationDictionary | TranslationDictionary[],
      options?: string | TOptions<StringMap> | undefined,
    ) => string
  }

export default i18n as Omit<typeof i18n, 't'> & {
  t: (
    key: TranslationDictionary | TranslationDictionary[],
    options?: string | TOptions<StringMap> | undefined,
  ) => string
}
//
