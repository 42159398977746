import { Device } from '@anews/types'

export enum DeviceActionType {
  NEW = '@device/NEW',
  LIST_REQUEST = '@device/LIST_REQUEST',
  LIST_SUCCESS = '@device/LIST_SUCCESS',
  LIST_FAILURE = '@device/LIST_FAILURE',
  LIST_MAMS_REQUEST = '@device/LIST_MAMS_REQUEST',
  LIST_MAMS_SUCCESS = '@device/LIST_MAMS_SUCCESS',
  LIST_MAMS_FAILURE = '@device/LIST_MAMS_FAILURE',
  EDIT_REQUEST = '@device/EDIT_REQUEST',
  EDIT_SUCCESS = '@device/EDIT_SUCCESS',
  EDIT_FAILURE = '@device/EDIT_FAILURE',
  CREATE_REQUEST = '@device/CREATE_REQUEST',
  CREATE_SUCCESS = '@device/CREATE_SUCCESS',
  CREATE_FAILURE = '@device/CREATE_FAILURE',
  UPDATE_REQUEST = '@device/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@device/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@device/UPDATE_FAILURE',
  REMOVE_REQUEST = '@device/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@device/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@device/REMOVE_FAILURE',
}

export type DeviceActionMap<T extends DeviceActionType> = { type: T } & DeviceAction

export type DeviceAction = ReturnType<typeof DeviceActions[keyof typeof DeviceActions]>

export const DeviceActions = {
  //
  //	newDevice()
  //

  newDevice: (device?: Device) => <const>{ type: DeviceActionType.NEW, device },

  //
  //	listDevices()
  //

  listDevices: () => <const>{ type: DeviceActionType.LIST_REQUEST },
  listDevicesSuccess: (devices: Device[]) =>
    <const>{ type: DeviceActionType.LIST_SUCCESS, devices },
  listDevicesFailure: (error: Error) => <const>{ type: DeviceActionType.LIST_FAILURE, error },

  //
  //	listMams()
  //

  listMams: (embedded: boolean) => <const>{ type: DeviceActionType.LIST_MAMS_REQUEST, embedded },
  listMamsSuccess: (devices: Device[], embedded: boolean) =>
    <const>{ type: DeviceActionType.LIST_MAMS_SUCCESS, devices, embedded },
  listMamsFailure: (error: Error, embedded: boolean) =>
    <const>{ type: DeviceActionType.LIST_MAMS_FAILURE, error, embedded },

  //
  //	editDevice()
  //

  editDevice: (id: number) => <const>{ type: DeviceActionType.EDIT_REQUEST, id },
  editDeviceSuccess: (device: Device) => <const>{ type: DeviceActionType.EDIT_SUCCESS, device },
  editDeviceFailure: (error: Error) => <const>{ type: DeviceActionType.EDIT_FAILURE, error },

  //
  //	createDevice()
  //

  createDevice: (device: Device) => <const>{ type: DeviceActionType.CREATE_REQUEST, device },
  createDeviceSuccess: (device: Device) => <const>{ type: DeviceActionType.CREATE_SUCCESS, device },
  createDeviceFailure: (error: Error) => <const>{ type: DeviceActionType.CREATE_FAILURE, error },

  //
  //	updateDevice()
  //

  updateDevice: (device: Device) => <const>{ type: DeviceActionType.UPDATE_REQUEST, device },
  updateDeviceSuccess: (device: Device) => <const>{ type: DeviceActionType.UPDATE_SUCCESS, device },
  updateDeviceFailure: (error: Error) => <const>{ type: DeviceActionType.UPDATE_FAILURE, error },

  //
  //	removeDevice()
  //

  removeDevice: (ids: number[]) => <const>{ type: DeviceActionType.REMOVE_REQUEST, ids },
  removeDeviceSuccess: (ids: number[]) => <const>{ type: DeviceActionType.REMOVE_SUCCESS, ids },
  removeDeviceFailure: (error: Error) => <const>{ type: DeviceActionType.REMOVE_FAILURE, error },
}
