import React, { memo } from 'react'

import Tooltip from 'antd/es/tooltip'

import { isNotBlank } from '@anews/utils'

interface Props {
  color: string
  tip?: string
}

function TableStatus({ color, tip }: Props) {
  const indicator = <div style={{ backgroundColor: color, width: 8, borderRadius: 3 }}>&nbsp;</div>

  return isNotBlank(tip) ? <Tooltip title={tip}>{indicator}</Tooltip> : indicator
}

export default memo(TableStatus)
