import { ReportEvent } from '@anews/types'

export enum ReportEventActionType {
  NEW = '@report_event/NEW',
  EDIT = '@report_event/EDIT',
  LIST_REQUEST = '@report_event/LIST_REQUEST',
  LIST_SUCCESS = '@report_event/LIST_SUCCESS',
  LIST_FAILURE = '@report_event/LIST_FAILURE',
  CREATE_REQUEST = '@report_event/CREATE_REQUEST',
  CREATE_SUCCESS = '@report_event/CREATE_SUCCESS',
  CREATE_FAILURE = '@report_event/CREATE_FAILURE',
  UPDATE_REQUEST = '@report_event/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@report_event/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@report_event/UPDATE_FAILURE',
  REMOVE_REQUEST = '@report_event/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@report_event/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@report_event/REMOVE_FAILURE',
}

export type ReportEventActionMap<T extends ReportEventActionType> = {
  type: T
} & ReportEventAction

export type ReportEventAction = ReturnType<
  typeof ReportEventActions[keyof typeof ReportEventActions]
>

export const ReportEventActions = {
  //	newReportEvent()

  newReportEvent: (event?: ReportEvent) => <const>{ type: ReportEventActionType.NEW, event },

  //	listReportEvents()
  listReportEvents: () => <const>{ type: ReportEventActionType.LIST_REQUEST },

  listReportEventsSuccess: (events: ReportEvent[]) =>
    <const>{ type: ReportEventActionType.LIST_SUCCESS, events },

  listReportEventsFailure: (error: Error) =>
    <const>{ type: ReportEventActionType.LIST_FAILURE, error },

  //	editReportEvent()

  editReportEvent: (event: ReportEvent) => <const>{ type: ReportEventActionType.EDIT, event },

  //	createReportEvent()

  createReportEvent: (event: ReportEvent) =>
    <const>{ type: ReportEventActionType.CREATE_REQUEST, event },

  createReportEventSuccess: (event: ReportEvent) =>
    <const>{ type: ReportEventActionType.CREATE_SUCCESS, event },

  createReportEventFailure: (error: Error) =>
    <const>{ type: ReportEventActionType.CREATE_FAILURE, error },

  //	updateReportEvent()

  updateReportEvent: (event: ReportEvent) =>
    <const>{ type: ReportEventActionType.UPDATE_REQUEST, event },

  updateReportEventSuccess: (event: ReportEvent) =>
    <const>{ type: ReportEventActionType.UPDATE_SUCCESS, event },

  updateReportEventFailure: (error: Error) =>
    <const>{ type: ReportEventActionType.UPDATE_FAILURE, error },

  //	removeReportEvent()

  removeReportEvent: (id: number) => <const>{ type: ReportEventActionType.REMOVE_REQUEST, id },

  removeReportEventSuccess: (id: number) =>
    <const>{ type: ReportEventActionType.REMOVE_SUCCESS, id },

  removeReportEventFailure: (error: Error) =>
    <const>{ type: ReportEventActionType.REMOVE_FAILURE, error },
}
