var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export function toQueryString(params) {
    if (params === void 0) { params = {}; }
    var result = [];
    Object.entries(params).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        if (value === null) {
            result.push(key + "=");
        }
        else if (Array.isArray(value)) {
            result.push(key + "=" + value.join(','));
        }
        else if (value !== undefined) {
            result.push(key + "=" + encodeURIComponent(value));
        }
    });
    return result.join('&');
}
export function extractParam(name, url) {
    if (url === void 0) { url = window.location.href; }
    var aux = name.replace(/[[]]/g, '\\$&');
    var regex = new RegExp("[?&]" + aux + "(=([^&#]*)|&|#|$)", 'i');
    var results = regex.exec(url);
    if (!results || results.length < 3 || !results[2]) {
        return undefined;
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
