import { get, post, put, del, patch } from './client';
var endpoint = '/api/calendars';
var calendarApi = {
    list: function () {
        return get("" + endpoint);
    },
    find: function (id) {
        return get(endpoint + "/" + id);
    },
    create: function (calendar) {
        return post(endpoint, calendar);
    },
    update: function (calendar) {
        return put(endpoint, calendar);
    },
    favorite: function (id, favorite) {
        return patch(endpoint + "/" + id, { favorite: favorite });
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default calendarApi;
