var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment';
import { sumDurations, timeToMoment, subtractDurations, formatIsoDuration, displayTime, } from './moment-utils';
export function calculateTimes(rundown, rundownConfig) {
    var result = {
        blocks: {},
        stories: {},
        rundown: {
            duration: 'PT0S',
            commercialTotal: 'PT0S',
            approvedProduction: 'PT0S',
            approvedTotal: 'PT0S',
            approvedDiff: 'PT0S',
            fullEstimated: 'PT0S',
            fullProduction: 'PT0S',
            fullTotal: 'PT0S',
            fullDiff: 'PT0S',
            productionApprovedDiff: 'PT0S',
            productionFullDiff: 'PT0S',
            notDisplayedCommercial: 'PT0S',
            notDisplayedProduction: 'PT0S',
            notDisplayedApprovedProduction: 'PT0S',
        },
    };
    // Não faz nada se o espelho não estiver carregado ou se os campos não estiverem ativos
    if (!rundown || !rundownConfig) {
        return result;
    }
    var ZERO = '00:00:00';
    var ZERO_DUR = 'PT0S';
    var blocksCommercialValues = [];
    var blocksApprovedValues = [];
    var blocksEstimatedValues = [];
    var blocksProductionValues = [];
    var notDisplayedCommercialValues = [];
    var notDisplayedProductionValues = [];
    var notDisplayedApprovedProductionValues = [];
    var blocks = rundown.blocks;
    for (var bIdx = 0; bIdx < blocks.length; bIdx += 1) {
        var block = blocks[bIdx];
        var estimatedValues = [];
        var totalValues = [];
        var approvedValues = [];
        if (!block.standBy) {
            if (!block.stopTime) {
                notDisplayedCommercialValues.push(block.commercial);
            }
            var stories = block.stories;
            for (var sIdx = 0; sIdx < stories.length; sIdx += 1) {
                var story = stories[sIdx];
                estimatedValues.push(story.estimated);
                totalValues.push(story.total);
                if (!story.stopTime) {
                    notDisplayedProductionValues.push(story.total);
                }
                if (story.approved) {
                    approvedValues.push(story.total);
                    if (!story.stopTime) {
                        notDisplayedApprovedProductionValues.push(story.total);
                    }
                }
            }
        }
        result.blocks[block.id] = {
            fullEstimated: sumDurations(estimatedValues),
            fullProduction: sumDurations(totalValues),
            approvedProduction: sumDurations(approvedValues),
        };
        // calcula o tempo do bloco stand by, mas não contabiliza nos tempos do espelho
        if (!block.standBy) {
            blocksCommercialValues.push(block.commercial);
            blocksApprovedValues.push(result.blocks[block.id].approvedProduction);
            blocksEstimatedValues.push(result.blocks[block.id].fullEstimated);
            blocksProductionValues.push(result.blocks[block.id].fullProduction);
        }
    }
    var start = timeToMoment(rundown.start);
    var end = timeToMoment(rundown.end);
    // caso a duração do espelho se estenda até o próximo dia
    if (start.isAfter(end)) {
        end.add(1, 'day');
    }
    var duration = moment.duration(end.diff(start)).toISOString();
    var commercialTotal = sumDurations(blocksCommercialValues);
    var approvedProduction = sumDurations(blocksApprovedValues);
    var fullEstimated = sumDurations(blocksEstimatedValues);
    var fullProduction = sumDurations(blocksProductionValues);
    var approvedTotal = sumDurations([approvedProduction, commercialTotal]);
    var fullTotal = sumDurations([fullProduction, commercialTotal]);
    var approvedDiff = subtractDurations([duration, approvedTotal]);
    var fullDiff = subtractDurations([duration, fullTotal]);
    var productionApprovedDiff = subtractDurations([rundown.production, approvedTotal]);
    var productionFullDiff = subtractDurations([rundown.production, fullProduction]);
    var notDisplayedCommercial = sumDurations(notDisplayedCommercialValues);
    var notDisplayedProduction = sumDurations(notDisplayedProductionValues);
    var notDisplayedApprovedProduction = sumDurations(notDisplayedApprovedProductionValues);
    result.rundown = __assign(__assign({}, result.rundown), { duration: duration,
        commercialTotal: commercialTotal,
        approvedProduction: approvedProduction,
        fullEstimated: fullEstimated,
        fullProduction: fullProduction,
        approvedTotal: approvedTotal,
        fullTotal: fullTotal,
        approvedDiff: approvedDiff,
        fullDiff: fullDiff,
        productionApprovedDiff: productionApprovedDiff,
        productionFullDiff: productionFullDiff,
        notDisplayedCommercial: notDisplayedCommercial,
        notDisplayedProduction: notDisplayedProduction,
        notDisplayedApprovedProduction: notDisplayedApprovedProduction });
    //
    // Quando o tempo acumulado, progressiva e regressiva estão desativados, coleta apenas os totais
    // das laudas e tempos de break para usar em alguns componentes.
    //
    if (!rundownConfig.accumulatedEnabled &&
        !rundownConfig.progressiveEnabled &&
        !rundownConfig.regressiveEnabled) {
        for (var bIdx = 0; bIdx < blocks.length; bIdx += 1) {
            var block = blocks[bIdx];
            var stories = block.stories;
            for (var sIdx = 0; sIdx < stories.length; sIdx += 1) {
                var story = stories[sIdx];
                var total = story.total;
                result.stories[story.id] = { total: total };
            }
            var commercial = block.commercial;
            result.blocks[block.id].commercial = block.standBy ? ZERO_DUR : commercial;
        }
    }
    //
    // Cálculo dos tempos acumulados
    //
    if (rundownConfig.accumulatedEnabled) {
        var sum = ZERO_DUR;
        for (var bIdx = 0; bIdx < blocks.length; bIdx += 1) {
            var block = blocks[bIdx];
            var stories = block.stories;
            if (rundownConfig.accumulateByBlock) {
                sum = ZERO_DUR;
            }
            for (var sIdx = 0; sIdx < stories.length; sIdx += 1) {
                var story = stories[sIdx];
                var total = story.total;
                if (block.standBy) {
                    result.stories[story.id] = { accumulated: ZERO, total: total };
                }
                else {
                    sum = sumDurations([sum, total]);
                    // Guarda o total para reutilizar no cálculo da progressiva e/ou regressiva
                    result.stories[story.id] = { accumulated: formatIsoDuration(sum, 'HH:mm:ss'), total: total };
                }
            }
            if (block.standBy) {
                result.blocks[block.id].accumulated = ZERO;
                result.blocks[block.id].commercial = ZERO_DUR;
            }
            else {
                var commercial = block.commercial;
                sum = sumDurations([sum, commercial]);
                // guarda o commercial para reutilizar no cálculo da progressiva e/ou regressiva
                result.blocks[block.id].accumulated = formatIsoDuration(sum, 'HH:mm:ss');
                result.blocks[block.id].commercial = commercial;
            }
        }
    }
    //
    // Cálculo das regressivas / Regressiva automática
    //
    if (rundownConfig.regressiveEnabled || rundownConfig.countdownEnabled) {
        var time = timeToMoment(rundown.end);
        var timeApp = timeToMoment(rundown.end);
        // Para a "regressiva automática" armazena em cada lauda/bloco o somatório da produção que tem após ela/ele
        var expectedEnd = rundown.end;
        var expectedAppEnd = rundown.end;
        for (var i = blocks.length - 1; i >= 0; i -= 1) {
            var block = blocks[i];
            if (block.standBy) {
                result.blocks[block.id].regressive = ZERO;
                result.blocks[block.id].regressiveApproved = ZERO;
                result.blocks[block.id].commercial = ZERO_DUR;
            }
            else {
                var commercial = result.blocks[block.id].commercial || block.commercial;
                result.blocks[block.id].expectedEnd = expectedEnd;
                result.blocks[block.id].expectedApprovedEnd = expectedAppEnd;
                expectedEnd = subtractDurations([expectedEnd, commercial]);
                expectedAppEnd = subtractDurations([expectedAppEnd, commercial]);
                time.subtract(commercial);
                timeApp.subtract(commercial);
                result.blocks[block.id].regressive = displayTime(time, true);
                result.blocks[block.id].regressiveApproved = displayTime(timeApp, true);
                result.blocks[block.id].commercial = commercial;
            }
            var stories = block.stories;
            for (var j = stories.length - 1; j >= 0; j -= 1) {
                var story = stories[j];
                result.stories[story.id] = result.stories[story.id] || {};
                var total = story.total;
                result.stories[story.id].total = total;
                if (block.standBy) {
                    result.stories[story.id].regressive = ZERO;
                    result.stories[story.id].regressiveApproved = ZERO;
                }
                else {
                    result.stories[story.id].expectedEnd = expectedEnd;
                    result.stories[story.id].expectedApprovedEnd = expectedAppEnd;
                    expectedEnd = subtractDurations([expectedEnd, total]);
                    if (story.approved) {
                        expectedAppEnd = subtractDurations([expectedAppEnd, total]);
                        timeApp.subtract(total);
                        result.stories[story.id].regressiveApproved = displayTime(timeApp, true);
                    }
                    else {
                        result.stories[story.id].regressiveApproved = ZERO;
                    }
                    time.subtract(total);
                    result.stories[story.id].regressive = displayTime(time, true);
                }
            }
        }
    }
    //
    //	Cálculo da progressiva
    //
    if (rundownConfig.progressiveEnabled) {
        var time = timeToMoment(rundown.start);
        var timeApp = timeToMoment(rundown.start);
        /*
         * NÃO ESTÁ UTILIZANDO ESSES VALORES, POR NÃO TER UMA PROGRESSIVA DINÂMICA.
         * Mas se for preciso, é só descomentar e talvez alterar o momento em que incrementa os valores.
         */
        // let expectedStart = rundown.start
        // let expectedAppStart = rundown.start
        // Na progressiva considera as laudas primeiro, depois o tempo de break
        for (var i = 0; i < blocks.length; i += 1) {
            var block = blocks[i];
            var stories = block.stories;
            for (var j = 0; j < stories.length; j += 1) {
                var story = stories[j];
                result.stories[story.id] = result.stories[story.id] || {};
                var total = story.total;
                result.stories[story.id].total = total;
                if (block.standBy) {
                    result.stories[story.id].progressive = ZERO;
                    result.stories[story.id].progressiveApproved = ZERO;
                }
                else {
                    // result.stories[story.id].expectedStart = expectedStart
                    // result.stories[story.id].expectedApprovedStart = expectedAppStart
                    // expectedStart = sumDurations([expectedStart, total])
                    // if (story.approved) {
                    //   expectedAppStart = sumDurations([expectedAppStart, total])
                    // }
                    // Na progressiva armazena o tempo na lauda, depois soma o total
                    result.stories[story.id].progressive = displayTime(time, true);
                    time.add(total);
                    if (story.approved) {
                        result.stories[story.id].progressiveApproved = displayTime(timeApp, true);
                        timeApp.add(total);
                    }
                    else {
                        result.stories[story.id].progressiveApproved = ZERO;
                    }
                }
            }
            // Depois das laudas considera o tempo de break
            if (block.standBy) {
                result.blocks[block.id].progressive = ZERO;
                result.blocks[block.id].progressiveApproved = ZERO;
            }
            else {
                var commercial = result.blocks[block.id].commercial || block.commercial;
                // result.blocks[block.id].expectedStart = expectedStart
                // result.blocks[block.id].expectedApprovedStart = expectedAppStart
                // expectedStart = sumDurations([expectedStart, commercial])
                // expectedAppStart = sumDurations([expectedAppStart, commercial])
                // Na progressiva armazena o tempo no bloco, depois soma o break
                result.blocks[block.id].progressive = displayTime(time, true);
                result.blocks[block.id].progressiveApproved = displayTime(timeApp, true);
                result.blocks[block.id].commercial = commercial;
                time.add(commercial);
                timeApp.add(commercial);
            }
        }
    }
    return result;
}
