export var IconNames = [
    'users',
    'star',
    'truck',
    'screwdriver',
    'music',
    'camera',
    'film',
    'microphone',
    'cogs',
    'heart',
    'laptop',
    'newspaper',
    'bell',
    'binoculars',
    'birthday-cake',
    'bus',
    'building',
    'calculator',
    'calendar-alt',
    'shopping-cart',
    'envelope',
    'file-alt',
    'flag',
    'folder-open',
    'futbol',
    'gift',
    'lock',
    'map-marker-alt',
    'medkit',
    'money-bill-alt',
    'tachometer-alt',
    'trash-alt',
];
