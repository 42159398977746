import React, { memo } from 'react'

import { Story } from '@anews/types'

import { displayTime } from '@anews/utils'

import { HGroup, ViewerItem } from '../../../global'
import { SelectorMap } from '../../../../redux/selectors/helpers'

import { useTranslation } from '../../../../i18n'

interface Props {
  story: Story
  companies: SelectorMap<string>
  users: SelectorMap<string>
}

function PreviewHeader({ story, companies, users }: Props) {
  const { changeDate, authorNickname } = story

  const company = companies[story.branchId!]
  const reporterNick = story.reporterId && story.visible ? users[story.reporterId] : undefined

  const { t } = useTranslation()

  return (
    <>
      <HGroup gutter={2}>
        {reporterNick && (
          <ViewerItem label={t('words:reporter')} style={{ marginBottom: 0 }}>
            {reporterNick}
          </ViewerItem>
        )}
        {company && story.visible && (
          <ViewerItem label={t('words:branch')} style={{ marginBottom: 0 }}>
            {company}
          </ViewerItem>
        )}

        {changeDate && authorNickname && (
          <ViewerItem style={{ marginBottom: 0 }}>
            {t('phrases:changedAtBy', { time: displayTime(changeDate), name: authorNickname })}
          </ViewerItem>
        )}
      </HGroup>
    </>
  )
}

export default memo(PreviewHeader)
