import { Reducer } from 'redux'

import { RssCategory } from '@anews/types'

import {
  RssCategoryActionType as ActionType,
  RssCategoryAction,
} from '../actions/rsscategory-actions'

export interface RssCategoriesListState {
  data: RssCategory[]
  loading: boolean
}

export interface RssCategoriesState {
  list: RssCategoriesListState
  editing?: RssCategory
  saving: boolean
  removing: boolean
}

export const initialState: RssCategoriesState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(
  list: RssCategoriesListState,
  action: RssCategoryAction,
): RssCategoriesListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.categories,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.category, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(category =>
          category.id === action.category.id ? action.category : category,
        ),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(category => category.id !== action.id) }

    default:
      return list
  }
}

const categoriesReducer: Reducer<RssCategoriesState, RssCategoryAction> = (
  state = initialState,
  action,
): RssCategoriesState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT:
      return { ...state, editing: action.category }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.category, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default categoriesReducer
