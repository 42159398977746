import { call, put, takeLatest } from 'redux-saga/effects'

import { twitterUserApi as api } from '@anews/api'
import { TwitterUser } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  TwitterUserActions,
  TwitterUserActionMap as ActionMap,
  TwitterUserActionType as ActionType,
} from '../actions/twitteruser-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions
const {
  listTwitterUsersSuccess,
  listTwitterUsersFailure,
  createTwitterUserSuccess,
  createTwitterUserFailure,
  updateTwitterUserSuccess,
  updateTwitterUserFailure,
  removeTwitterUserSuccess,
  removeTwitterUserFailure,
} = TwitterUserActions

/* Watchers */

function* listTwitterUsersSaga(): Generator {
  try {
    const users = yield call(api.list)
    yield put(listTwitterUsersSuccess(users as TwitterUser[]))
  } catch (error) {
    yield put(listTwitterUsersFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createTwitterUserSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const user = yield call(api.create, action.user)
    yield put(createTwitterUserSuccess(user as TwitterUser))
  } catch (error) {
    yield put(createTwitterUserFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:createFailed')}`,
        error,
      }),
    )
  }
}

function* updateTwitterUserSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const user = yield call(api.update, action.user)
    yield put(updateTwitterUserSuccess(user as TwitterUser))
  } catch (error) {
    yield put(updateTwitterUserFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:updateFailed')}`,
        error,
      }),
    )
  }
}

function* removeTwitterUserSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeTwitterUserSuccess(action.id))
  } catch (error) {
    yield put(removeTwitterUserFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listTwitterUsersSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createTwitterUserSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateTwitterUserSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeTwitterUserSaga)
  },
])
