import { useCallback, useContext, useMemo } from 'react'

import { UnsavedContext } from './context'

import { AllowedType, Section } from './types'

export default function useUnsavedSection<T extends AllowedType>(sectionKey: Section) {
  const {
    unsavedData: _data,
    clearUnsavedSection: _clearAll,
    clearUnsavedEntry: _clear,
    updateUnsavedEntry: _update,
  } = useContext(UnsavedContext)

  const clearUnsavedSection = useCallback(() => {
    _clearAll && _clearAll(sectionKey)
  }, [sectionKey, _clearAll])

  const clearUnsavedEntry = useCallback(
    (uuid: string) => {
      _clear && _clear(sectionKey, uuid)
    },
    [sectionKey, _clear],
  )

  const updateUnsavedEntry = useCallback(
    (uuid: string, newData: T) => {
      _update && _update(sectionKey, uuid, newData)
    },
    [sectionKey, _update],
  )

  return useMemo(
    () => ({
      unsavedData: _data[sectionKey],
      clearUnsavedSection,
      clearUnsavedEntry,
      updateUnsavedEntry,
    }),
    [sectionKey, _data, clearUnsavedSection, clearUnsavedEntry, updateUnsavedEntry],
  )
}
