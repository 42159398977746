import React, { CSSProperties, PureComponent } from 'react'

import Upload, { UploadChangeParam, RcFile } from 'antd/es/upload'
import message from 'antd/es/message'

import { LoadingOutlined, InboxOutlined, CloseOutlined } from '@ant-design/icons'

import { Button } from 'antd/es'

import { storageFileApi } from '@anews/api'

import { notify } from '../../utils/notification-utils'
import i18n from '../../i18n'

interface Props {
  value?: number
  style?: CSSProperties
  thumbStyle?: CSSProperties
  onChange?: (id?: number) => void
}

interface State {
  over: boolean
  uploading: boolean
}

// getFieldDecorator() do antd exigiu que o componente fosse uma classe
// deu problema com o uso de ref
class SingleImageUpload extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { over: false, uploading: false }
  }

  beforeUpload = (file: RcFile) => {
    const isJPG = file.type === 'image/jpeg'
    const isPNG = file.type === 'image/png'

    if (!isJPG && !isPNG) {
      message.warn(i18n.t('phrases:invalidImgType'))
    }

    const isLt10M = file.size / 1024 / 1024 < 10
    if (!isLt10M) {
      message.warn(i18n.t('phrases:invalidImgSize'))
    }

    return (isJPG || isPNG) && isLt10M
  }

  uploadChange = (info: UploadChangeParam) => {
    const { onChange } = this.props
    const { status, response } = info.file

    this.setState({ uploading: status === 'uploading' })

    if (status === 'done' && onChange) {
      onChange(response.id)
    }
    if (status === 'error') {
      notify({
        category: 'error',
        key: 'upload-error',
        message: i18n.t('error:operation'),
        description: i18n.t('error:uploadFailed'),
        details: JSON.stringify(response),
      })
    }
  }

  mouseOver = () => {
    this.setState({ over: true })
  }

  mouseOut = () => {
    this.setState({ over: false })
  }

  remove = () => {
    const { onChange } = this.props
    if (onChange) {
      onChange(undefined)
    }
  }

  render() {
    const { style, thumbStyle, value } = this.props
    const { over, uploading } = this.state

    const dragContent = (
      <>
        <p className="ant-upload-drag-icon">
          {uploading ? <LoadingOutlined /> : <InboxOutlined />}
        </p>
        <p className="ant-upload-text">{i18n.t('phrases:uploadHint')}</p>
        <p className="ant-upload-hint">{i18n.t('phrases:imgRules')}</p>
      </>
    )

    return (
      <div style={{ position: 'relative' }}>
        <Upload.Dragger
          action="/api/files/single"
          style={style}
          accept="image/jpeg,image/png"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={this.beforeUpload}
          onChange={this.uploadChange}
        >
          {value ? (
            <img src={storageFileApi.getThumbSrc(value)} alt="thumbnail" style={thumbStyle} />
          ) : (
            dragContent
          )}
        </Upload.Dragger>

        {value && (
          <Button
            style={{ position: 'absolute', top: 16, right: 16 }}
            onMouseOver={this.mouseOver}
            onMouseOut={this.mouseOut}
            onClick={this.remove}
            icon={<CloseOutlined />}
            size="small"
            type="primary"
            danger
          >
            <span style={{ display: over ? undefined : 'none' }}>Remover</span>
          </Button>
        )}
      </div>
    )
  }
}

export default SingleImageUpload
