import { call, put, takeLatest } from 'redux-saga/effects'

import { deviceApi as api } from '@anews/api'
import { Device } from '@anews/types'

import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { DeviceActionMap, DeviceActions, DeviceActionType } from '../actions/device-actions'

import { createRootSaga } from './helpers'

const {
  listDevicesSuccess,
  listDevicesFailure,
  editDeviceSuccess,
  editDeviceFailure,
  createDeviceSuccess,
  createDeviceFailure,
  updateDeviceSuccess,
  updateDeviceFailure,
  removeDeviceSuccess,
  removeDeviceFailure,
  listMamsSuccess,
  listMamsFailure,
} = DeviceActions

const { notifyError } = NotificationActions

/* Watchers */

function* listDevicesSaga(): Generator {
  try {
    const devices = yield call(api.list)
    yield put(listDevicesSuccess(devices as Device[]))
  } catch (error) {
    yield put(listDevicesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listMamsSaga(action: DeviceActionMap<DeviceActionType.LIST_MAMS_REQUEST>): Generator {
  try {
    const mams = yield call(api.mams)
    yield put(listMamsSuccess(mams as Device[], action.embedded))
  } catch (error) {
    yield put(listMamsFailure(error, action.embedded))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* editDeviceSaga(action: DeviceActionMap<DeviceActionType.EDIT_REQUEST>): Generator {
  try {
    const device = yield call(api.load, action.id)
    yield put(editDeviceSuccess(device as Device))
  } catch (error) {
    yield put(editDeviceFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createDeviceSaga(action: DeviceActionMap<DeviceActionType.CREATE_REQUEST>): Generator {
  try {
    const device = yield call(api.create, action.device)
    yield put(createDeviceSuccess(device as Device))
  } catch (error) {
    yield put(createDeviceFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateDeviceSaga(action: DeviceActionMap<DeviceActionType.UPDATE_REQUEST>): Generator {
  try {
    const device = yield call(api.update, action.device)
    yield put(updateDeviceSuccess(device as Device))
  } catch (error) {
    yield put(updateDeviceFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeDeviceSaga(action: DeviceActionMap<DeviceActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.ids)
    yield put(removeDeviceSuccess(action.ids))
  } catch (error) {
    yield put(removeDeviceFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('device:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(DeviceActionType.LIST_REQUEST, listDevicesSaga)
  },
  function* () {
    yield takeLatest(DeviceActionType.LIST_MAMS_REQUEST, listMamsSaga)
  },
  function* () {
    yield takeLatest(DeviceActionType.EDIT_REQUEST, editDeviceSaga)
  },
  function* () {
    yield takeLatest(DeviceActionType.CREATE_REQUEST, createDeviceSaga)
  },
  function* () {
    yield takeLatest(DeviceActionType.UPDATE_REQUEST, updateDeviceSaga)
  },
  function* () {
    yield takeLatest(DeviceActionType.REMOVE_REQUEST, removeDeviceSaga)
  },
])
