import React, { memo, ComponentType } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { extractParam } from '@anews/utils'

// https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html

export interface UnauthenticatedRouteProps extends Omit<RouteProps, 'component'> {
  authenticated: boolean
  component: ComponentType<any>
}

function UnauthenticatedRoute({ component: C, authenticated, ...rest }: UnauthenticatedRouteProps) {
  const redirect = extractParam('redirect')

  return (
    <Route
      {...rest}
      render={props =>
        !authenticated ? (
          <C {...props} />
        ) : (
          <Redirect to={redirect === '' || redirect === undefined ? '/' : redirect} />
        )
      }
    />
  )
}

export default memo(UnauthenticatedRoute)
