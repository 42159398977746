import {
  AgendaContact,
  ChecklistTask,
  Contact,
  Guideline,
  TranslationDictionary,
} from '@anews/types'
import { displayDate, displayTime, isNotBlank, mapJoin, richToPlain } from '@anews/utils'

import i18n from '../../../i18n'
import { SelectorMap } from '../../../redux/selectors/helpers'
import { copyToClipboard } from '../../../utils/clipboard-utils'
import { removeMarkTags } from '../../../utils/xml-utils'

function getTasksText(task: ChecklistTask) {
  const { done, label, date, information, time } = task
  const taskText: string[] = []

  if (label) {
    taskText.push(
      done
        ? `${i18n.t('checklist:task')}: ${label} (${i18n.t('checklist:done')})`
        : `${i18n.t('checklist:task')}: ${label}`,
    )
  }

  if (date) {
    taskText.push(`${i18n.t('words:date')}: ${displayDate(date)}`)
  }

  if (time) {
    taskText.push(`${i18n.t('words:time')}: ${displayTime(time)}`)
  }

  if (information) {
    taskText.push(`${i18n.t('words:info')}: ${richToPlain(information)}`)
  }

  taskText.push('')

  return taskText.join('\n').toString()
}

function getContactText(contact: Contact) {
  const { name, profession, numbers, emails, address, info } = contact as AgendaContact
  const contactText: string[] = []

  contactText.push(`${i18n.t('words:contact')}: ${name}`)

  if (profession) {
    contactText.push(`${i18n.t('contact:profession')}: ${profession}`)
  }

  if (numbers && numbers.length > 0) {
    contactText.push(`${i18n.t('contact:numbers')}: `)

    numbers.forEach(number => {
      contactText.push(
        `${number.value}(${i18n.t(
          `contact:numberType_${number.label.toLowerCase()}` as TranslationDictionary,
        )})`,
      )
    })
  }

  if (emails && emails.length > 0) {
    contactText.push(`${i18n.t('contact:emails')}: `)

    emails.forEach(email => {
      contactText.push(`${email}`)
    })
  }

  if (address) {
    contactText.push(`${i18n.t('words:address')}: ${address}`)
  }

  if (isNotBlank(info)) {
    contactText.push(`${i18n.t('words:info')}: ${info}`)
  }

  return contactText.join('\n')
}

export function guidelineToText(
  guideline: Guideline,
  {
    programs,
    users,
    companies,
    editorials,
    vehicles,
    classifications,
  }: {
    programs: SelectorMap<string>
    users: SelectorMap<string>
    companies: SelectorMap<string>
    editorials: SelectorMap<string>
    vehicles: SelectorMap<string>
    classifications: SelectorMap<string>
  },
) {
  const guidelineText: string[] = []

  const {
    branchId,
    cameraman,
    editorialsIds,
    editorsIds,
    guides,
    information,
    production,
    programsIds,
    proposal,
    referral,
    reportersIds,
    producersIds,
    slug,
    vehiclesIds,
  } = guideline

  const classification = classifications[guideline.classificationId!]

  const editorsNames = mapJoin(users, editorsIds)
  const producersNames = mapJoin(users, producersIds)
  const reportersNames = mapJoin(users, reportersIds)

  const editorialsNames = mapJoin(editorials, editorialsIds)
  const programsNames = mapJoin(programs, programsIds) || i18n.t('program:drawer')
  const vehiclesNames = mapJoin(vehicles, vehiclesIds)

  // Retranca
  if (slug) {
    guidelineText.push(`${i18n.t('words:slug')}: ${guideline.slug}`)
  }

  // Data
  guidelineText.push(`${i18n.t('words:date')}: ${displayDate(guideline.date)}`)

  // Praça
  if (branchId) {
    guidelineText.push(`${i18n.t('words:branch')}: ${companies[branchId]}`)
  }

  // Programas
  if (programsIds) {
    if (programsIds.length === 0) {
      guidelineText.push(`${i18n.t('words:program')}: ${programsNames}`)
    } else {
      guidelineText.push(`${i18n.t('words:programs')}: ${programsNames}`)
    }
  }

  // Pauteiros
  if (producersIds && producersIds.length > 0) {
    guidelineText.push(`${i18n.t('guideline:producers')}: ${producersNames}`)
  }

  // Repórteres
  if (reportersIds && reportersIds.length > 0) {
    guidelineText.push(`${i18n.t('words:reporters')}: ${reportersNames}`)
  }

  // Editores
  if (editorsIds && editorsIds.length > 0) {
    guidelineText.push(`${i18n.t('words:editors')}: ${editorsNames}`)
  }

  // Editoriais
  if (editorialsIds && editorialsIds.length > 0) {
    guidelineText.push(`${i18n.t('words:editorials')}: ${editorialsNames}`)
  }

  // Veículos
  if (vehiclesIds && vehiclesIds.length > 0) {
    guidelineText.push(`${i18n.t('words:vehicles')}: ${vehiclesNames}`)
  }

  // Classificação
  if (classification) {
    guidelineText.push(`${i18n.t('words:classification')}: ${classification}`)
  }

  // Imagens
  if (cameraman) {
    guidelineText.push(`${i18n.t('words:cameraman')}: ${cameraman}`)
  }

  guidelineText.push(' ')

  const contentChildren = []

  // Proposta
  if (isNotBlank(richToPlain(proposal))) {
    contentChildren.push(`${i18n.t('guideline:proposal')}: ${richToPlain(proposal)}`)
  }

  // Encaminhamento
  if (isNotBlank(richToPlain(referral))) {
    contentChildren.push(`${i18n.t('guideline:referral')}: ${richToPlain(referral)}`)
  }

  // Informações
  if (isNotBlank(richToPlain(information))) {
    contentChildren.push(`${i18n.t('words:info')}: ${richToPlain(information)}`)
  }

  if (contentChildren.length > 0) {
    guidelineText.push(`[${i18n.t('guideline:content')}]`)
    guidelineText.push(contentChildren.join('\n').toString())
  }

  guidelineText.push('')

  //
  // Roteiros
  //

  const guidesChildren: string[] = []

  if (guides && guides.length > 0) {
    guidesChildren.push(`[${i18n.t('guideline:guides')}]`)

    guides.forEach(guide => {
      const { address, contacts, interviewee, observation, scheduleDate, scheduleTime } = guide
      const guideText: string[] = []

      // Data
      guideText.push(`${i18n.t('words:date')}: ${displayDate(scheduleDate)}`)

      // Hora
      if (scheduleTime) {
        guideText.push(`${i18n.t('guideline:scheduleTime')}: ${scheduleTime}`)
      }

      // Entrevistado
      if (interviewee) {
        guideText.push(`${i18n.t('guideline:interviewee')}: ${interviewee}`)
      }

      // Endereço
      if (address) {
        guideText.push(`${i18n.t('words:address')}: ${address}`)
      }

      // Observações
      if (observation) {
        guideText.push(`${i18n.t('guideline:observation')}: ${richToPlain(observation)}`)
      }

      guideText.push(' ')

      // Contatos
      if (contacts) {
        contacts.forEach(contact => {
          guideText.push(getContactText(contact))
          guideText.push(' ')
        })
      }

      guidesChildren.push(guideText.join('\n'))
    })
  }

  //
  // Tarefas
  //

  const tasksChildren: string[] = []

  if (production) {
    const { tasks } = production

    if (tasks && tasks.length > 0) {
      tasksChildren.push(`[${i18n.t('words:tasks')}]`)
      tasks.forEach(task => {
        tasksChildren.push(getTasksText(task))
      })
    }
  }

  // Adiciona a string de roteiros e tarefas
  guidelineText.push(guidesChildren.join('\n'))
  guidelineText.push(tasksChildren.join('\n'))

  return removeMarkTags(guidelineText.join('\n'))
}

export default function guidelineToClipboard(
  guideline: Guideline,
  {
    classifications,
    companies,
    editorials,
    programs,
    users,
    vehicles,
  }: {
    classifications: SelectorMap<string>
    companies: SelectorMap<string>
    editorials: SelectorMap<string>
    programs: SelectorMap<string>
    users: SelectorMap<string>
    vehicles: SelectorMap<string>
  },
) {
  const text = guidelineToText(guideline, {
    classifications,
    companies,
    editorials,
    programs,
    users,
    vehicles,
  })

  return copyToClipboard(text, i18n.t('phrases:copiedToClipboard'), i18n.t('error:actionFailed'))
}
