import { call, put, takeLatest } from 'redux-saga/effects'

import { typeApi as api } from '@anews/api'
import { DocumentType } from '@anews/types'

import { NotificationActions, TypeActions, TypeActionType } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { listTypesSuccess, listTypesFailure } = TypeActions
const { notifyError } = NotificationActions

/* Watchers */

function* listTypesSaga(): Generator {
  try {
    const types = yield call(api.list)
    yield put(listTypesSuccess(types as DocumentType[]))
  } catch (error) {
    yield put(listTypesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(TypeActionType.LIST_REQUEST, listTypesSaga)
  },
])
