import { Reducer } from 'redux'

import { Device } from '@anews/types'

import { Item } from '../../mos/types'

import { MosPluginActionType, MosPluginAction } from '../actions/mosplugin-actions'

export type ItemType = 'CG' | 'MEDIA' | 'UNKNOWN'
export type PluginMode = 'CREATE' | 'EDIT'

export type ItemReceivedHandler = (
  item: Item,
  handlerId: string,
  type: ItemType,
  device?: Device,
  isNew?: boolean,
) => void

export interface MosPluginState {
  open: boolean
  mode?: PluginMode
  device?: Device
  roId?: string
  storyId?: string
  programUuid?: string
  item?: Item
  queue: (Item | undefined)[]
  handlerId?: string
  onItemReceived?: ItemReceivedHandler
}

export const initialState: MosPluginState = {
  open: false,
  queue: [],
}

const mosPluginReducer: Reducer<MosPluginState, MosPluginAction> = (
  state = initialState,
  action,
): MosPluginState => {
  const { open, queue } = state

  switch (action.type) {
    case MosPluginActionType.OPEN: {
      const { device, roId, storyId, handlerId, programUuid, onItemReceived } = action

      return {
        ...state,
        device,
        roId,
        storyId,
        handlerId,
        programUuid,
        onItemReceived,
        open: true,
        mode: 'CREATE',

        /*
         * Quando edito um item e, sem fechar o plugin, solicito adicionar um novo pelo Arion, esse
         * item vai continuar preenchido.
         *
         * Isso só porque pensei que o plugin pode vir a considerar que está em edição e vir a mandar
         * um <ncsItemRequest>.
         *
         * Não está documentado no MOS o que fazer quando se quer cancelar a edição e iniciar um
         * cadastro sem fechar... então segue como era no ANEWS 2.
         */
        // item: undefined,

        /*
         * undefined serve para indicar ao plugin que o usuário não quer mais editar e sim criar um novo.
         */
        queue: open ? [...queue, undefined] : [],
      }
    }

    case MosPluginActionType.EDIT: {
      const { device, roId, storyId, handlerId, programUuid, onItemReceived, item } = action

      return {
        ...state,
        device,
        roId,
        storyId,
        handlerId,
        programUuid,
        onItemReceived,
        open: true,
        mode: 'EDIT',
        item,
        queue: open ? [...queue, item] : [],
      }
    }

    case MosPluginActionType.REF: {
      const { handlerId, onItemReceived } = action
      return state.handlerId === handlerId ? { ...state, onItemReceived } : state
    }

    case MosPluginActionType.CLOSE:
      return initialState

    default:
      return state
  }
}

export default mosPluginReducer
