import React, { forwardRef, memo, useEffect, useState, ChangeEvent } from 'react'
import Input, { InputProps } from 'antd/es/input'

import { displayToIsoDuration, formatIsoDuration } from '@anews/utils'

function isValid(value: string, format: string) {
  // Transforma "mm:ss" em /^\d\d:\d\d$/
  const regex = new RegExp(`^${format.replace(/\w/g, '\\d')}$`)
  return regex.test(value)
}

export interface DurationInputProps extends Omit<Omit<InputProps, 'value'>, 'onChange'> {
  value: string | undefined
  format: 'HH:mm' | 'HH:mm:ss' | 'mm:ss'
  onChange?: (value: string) => void
}

const DurationInput = forwardRef<Input, DurationInputProps>((props: DurationInputProps, ref) => {
  const { format, onChange, ...rest } = props
  const value = props.value || 'PT0S'

  const [formatted, setFormatted] = useState(formatIsoDuration(value, format))

  useEffect(() => {
    setFormatted(formatIsoDuration(value, format))
  }, [value, format])

  const formatValue = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value || ''

    // Não aplica máscara se estiver apagando...
    if (formatted && newValue.length < formatted.length) {
      setFormatted(newValue)
      return
    }

    newValue = newValue.replace(/[^\d:]/g, '')

    const tokens = format.split(':')
    let actualSize = 0

    for (let i = 0; i < tokens.length - 1; i += 1) {
      actualSize += tokens[i].length + i
      if (actualSize === newValue.length) {
        newValue += ':'
      }
    }

    setFormatted(newValue)

    if (onChange && isValid(newValue, format)) {
      const result = displayToIsoDuration(newValue, format)
      if (result !== value) {
        onChange(result)
      }
    }
  }

  return (
    <Input {...rest} ref={ref} value={formatted} maxLength={format.length} onChange={formatValue} />
  )
})

DurationInput.displayName = 'DurationInput'

export default memo(DurationInput)
