var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get, post, put, query, del, patch } from './client';
var endpoint = '/api/productions';
var checklistApi = {
    dateList: function (date, onlyNotDone) {
        return get(query(endpoint, { date: date, onlyNotDone: onlyNotDone }));
    },
    filter: function (params, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", __assign(__assign({}, params), { page: page, size: size })));
    },
    load: function (id, lock) {
        return get(query(endpoint + "/" + id, { lock: lock }));
    },
    loadByGuideline: function (guidelineId, lock) {
        return get(query(endpoint + "/assignment/" + guidelineId, { lock: lock }));
    },
    create: function (checklist, lock) {
        return post(query(endpoint, { lock: lock }), checklist);
    },
    createFromGuideline: function (guidelineId) {
        return post(query(endpoint + "/assignment/" + guidelineId));
    },
    update: function (checklist) {
        return put(endpoint, checklist);
    },
    updateField: function (id, field, value) {
        var _a;
        // Enviar undefined não funciona para limpar o valor
        return patch(endpoint + "/" + id, (_a = {}, _a[field] = value === undefined ? null : value, _a));
    },
    restore: function (ids, date) {
        return put(query(endpoint + "/restore", { ids: ids, date: date }));
    },
    listExcluded: function (filter, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/excluded", __assign(__assign({}, filter), { page: page, size: size })));
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
    mail: function (id) {
        return post(endpoint + "/" + id + "/mail");
    },
    loadChangesHistory: function (id) {
        return get(query(endpoint + "/" + id + "/revisions"));
    },
    loadRevision: function (id, revisionNumber, showDiff) {
        return get(query(endpoint + "/" + id + "/revisions/" + revisionNumber, { showDiff: showDiff }));
    },
    sendEmail: function (ids, usersIds) {
        return post(query(endpoint + "/email", { ids: ids, usersIds: usersIds }));
    },
    sendEmailGrid: function (date, usersIds) {
        return post(query(endpoint + "/email/grid", { date: date, usersIds: usersIds }));
    },
    //
    //  config
    //
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default checklistApi;
