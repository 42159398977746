import React, { memo } from 'react'

import theme from '../../theme'

export default memo(() => (
  <div
    style={{
      backgroundColor: theme.colors.red,
      borderRadius: '2px',
      color: 'white',
      fontSize: '12px',
      maxHeight: '36px',
      maxWidth: '36px',
      textAlign: 'center',
    }}
  >
    TP
  </div>
))
