import { call, put, takeLatest } from 'redux-saga/effects'

import { companyApi as api } from '@anews/api'
import {
  Company,
  CompanyData,
  Program,
  Classification,
  User,
  Vehicle,
  DocumentConfig,
  GuidelineConfig,
  Editorial,
  Team,
  DocumentType,
  ReportageConfig,
  StoryConfig,
  RundownConfig,
  CustomMetadata,
} from '@anews/types'

import { CompanyActionMap, CompanyActions, CompanyActionType } from '../actions/company-actions'
import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

const {
  listCompaniesFailure,
  listCompaniesSuccess,
  listRemoteClassificationsFailure,
  listRemoteClassificationsSuccess,
  listRemoteCompaniesFailure,
  listRemoteCompaniesSuccess,
  listRemoteDocumentConfigFailure,
  listRemoteDocumentConfigSuccess,
  listRemoteEditorialsFailure,
  listRemoteEditorialsSuccess,
  listRemoteEditorsFailure,
  listRemoteEditorsSuccess,
  listRemoteGuidelineConfigFailure,
  listRemoteGuidelineConfigSuccess,
  listRemoteMetadataFailure,
  listRemoteMetadataSuccess,
  listRemoteProducersFailure,
  listRemoteProducersSuccess,
  listRemoteProgramsFailure,
  listRemoteProgramsSuccess,
  listRemoteReportageConfigFailure,
  listRemoteReportageConfigSuccess,
  listRemoteReportersFailure,
  listRemoteReportersSuccess,
  listRemoteRundownConfigFailure,
  listRemoteRundownConfigSuccess,
  listRemoteStoryConfigFailure,
  listRemoteStoryConfigSuccess,
  listRemoteTeamsFailure,
  listRemoteTeamsSuccess,
  listRemoteTypesFailure,
  listRemoteTypesSuccess,
  listRemoteUsersFailure,
  listRemoteUsersSuccess,
  listRemoteVehiclesFailure,
  listRemoteVehiclesSuccess,
} = CompanyActions
/* Watchers */

function* listCompaniesSaga(): Generator {
  try {
    const companies = yield call(api.list)
    yield put(listCompaniesSuccess(companies as Company[]))
  } catch (error) {
    yield put(listCompaniesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteProgramsSaga(
  action: CompanyActionMap<CompanyActionType.LIST_PROGRAMS_REQUEST>,
): Generator {
  try {
    const programs = yield call(api.loadCompanyData, action.companyId, CompanyData.PROGRAMS)
    yield put(listRemoteProgramsSuccess(action.companyId, programs as Program[]))
  } catch (error) {
    yield put(listRemoteProgramsFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteClassificationsSaga(
  action: CompanyActionMap<CompanyActionType.LIST_CLASSIFICATIONS_REQUEST>,
): Generator {
  try {
    const classifications = yield call(
      api.loadCompanyData,
      action.companyId,
      CompanyData.CLASSIFICATIONS,
    )
    yield put(
      listRemoteClassificationsSuccess(action.companyId, classifications as Classification[]),
    )
  } catch (error) {
    yield put(listRemoteClassificationsFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteMetadataSaga(
  action: CompanyActionMap<CompanyActionType.LIST_METADATA_REQUEST>,
): Generator {
  try {
    const metadata = yield call(api.loadCompanyData, action.companyId, CompanyData.METADATA)
    yield put(listRemoteMetadataSuccess(action.companyId, metadata as CustomMetadata[]))
  } catch (error: unknown) {
    yield put(listRemoteMetadataFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteUsersSaga(
  action: CompanyActionMap<CompanyActionType.LIST_USERS_REQUEST>,
): Generator {
  try {
    const users = yield call(api.loadCompanyData, action.companyId, CompanyData.USERS)
    yield put(listRemoteUsersSuccess(action.companyId, users as User[]))
  } catch (error) {
    yield put(listRemoteUsersFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteEditorsSaga(
  action: CompanyActionMap<CompanyActionType.LIST_EDITORS_REQUEST>,
): Generator {
  try {
    const editors = yield call(api.loadCompanyData, action.companyId, CompanyData.EDITORS)
    yield put(listRemoteEditorsSuccess(action.companyId, editors as User[]))
  } catch (error) {
    yield put(listRemoteEditorsFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteReportersSaga(
  action: CompanyActionMap<CompanyActionType.LIST_REPORTERS_REQUEST>,
): Generator {
  try {
    const reporters = yield call(api.loadCompanyData, action.companyId, CompanyData.REPORTERS)
    yield put(listRemoteReportersSuccess(action.companyId, reporters as User[]))
  } catch (error) {
    yield put(listRemoteReportersFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteProducersSaga(
  action: CompanyActionMap<CompanyActionType.LIST_PRODUCERS_REQUEST>,
): Generator {
  try {
    const producers = yield call(api.loadCompanyData, action.companyId, CompanyData.PRODUCERS)
    yield put(listRemoteProducersSuccess(action.companyId, producers as User[]))
  } catch (error) {
    yield put(listRemoteProducersFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteVehiclesSaga(
  action: CompanyActionMap<CompanyActionType.LIST_VEHICLES_REQUEST>,
): Generator {
  try {
    const vehicles = yield call(api.loadCompanyData, action.companyId, CompanyData.VEHICLES)
    yield put(listRemoteVehiclesSuccess(action.companyId, vehicles as Vehicle[]))
  } catch (error) {
    yield put(listRemoteVehiclesFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteDocumentConfigSaga(
  action: CompanyActionMap<CompanyActionType.LIST_DOCUMENT_CONFIG_REQUEST>,
): Generator {
  try {
    const documentConfig = yield call(
      api.loadCompanyData,
      action.companyId,
      CompanyData.DOCUMENT_CONFIG,
    )
    yield put(listRemoteDocumentConfigSuccess(action.companyId, documentConfig as DocumentConfig))
  } catch (error) {
    yield put(listRemoteDocumentConfigFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteGuidelineConfigSaga(
  action: CompanyActionMap<CompanyActionType.LIST_GUIDELINE_CONFIG_REQUEST>,
): Generator {
  try {
    const guidelineConfig = yield call(
      api.loadCompanyData,
      action.companyId,
      CompanyData.GUIDELINE_CONFIG,
    )
    yield put(
      listRemoteGuidelineConfigSuccess(action.companyId, guidelineConfig as GuidelineConfig),
    )
  } catch (error) {
    yield put(listRemoteGuidelineConfigFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteReportageConfigSaga(
  action: CompanyActionMap<CompanyActionType.LIST_REPORTAGE_CONFIG_REQUEST>,
): Generator {
  try {
    const reportageConfig = yield call(
      api.loadCompanyData,
      action.companyId,
      CompanyData.REPORTAGE_CONFIG,
    )
    yield put(
      listRemoteReportageConfigSuccess(action.companyId, reportageConfig as ReportageConfig),
    )
  } catch (error) {
    yield put(listRemoteReportageConfigFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteStoryConfigSaga(
  action: CompanyActionMap<CompanyActionType.LIST_STORY_CONFIG_REQUEST>,
): Generator {
  try {
    const storyConfig = yield call(api.loadCompanyData, action.companyId, CompanyData.STORY_CONFIG)
    yield put(listRemoteStoryConfigSuccess(action.companyId, storyConfig as StoryConfig))
  } catch (error) {
    yield put(listRemoteStoryConfigFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteRundownConfigSaga(
  action: CompanyActionMap<CompanyActionType.LIST_RUNDOWN_CONFIG_REQUEST>,
): Generator {
  try {
    const rundownConfig = yield call(
      api.loadCompanyData,
      action.companyId,
      CompanyData.RUNDOWN_CONFIG,
    )
    yield put(listRemoteRundownConfigSuccess(action.companyId, rundownConfig as RundownConfig))
  } catch (error) {
    yield put(listRemoteRundownConfigFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteCompaniesSaga(
  action: CompanyActionMap<CompanyActionType.LIST_REMOTE_REQUEST>,
): Generator {
  try {
    const companies = yield call(api.loadCompanyData, action.companyId, CompanyData.COMPANIES)
    yield put(listRemoteCompaniesSuccess(action.companyId, companies as Company[]))
  } catch (error) {
    yield put(listRemoteCompaniesFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteEditorialsSaga(
  action: CompanyActionMap<CompanyActionType.LIST_EDITORIALS_REQUEST>,
): Generator {
  try {
    const editorials = yield call(api.loadCompanyData, action.companyId, CompanyData.EDITORIALS)
    yield put(listRemoteEditorialsSuccess(action.companyId, editorials as Editorial[]))
  } catch (error) {
    yield put(listRemoteEditorialsFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteTeamsSaga(
  action: CompanyActionMap<CompanyActionType.LIST_TEAMS_REQUEST>,
): Generator {
  try {
    const teams = yield call(api.loadCompanyData, action.companyId, CompanyData.TEAMS)
    yield put(listRemoteTeamsSuccess(action.companyId, teams as Team[]))
  } catch (error) {
    yield put(listRemoteTeamsFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* listRemoteTypesSaga(
  action: CompanyActionMap<CompanyActionType.LIST_TYPES_REQUEST>,
): Generator {
  try {
    const types = yield call(api.loadCompanyData, action.companyId, CompanyData.TYPES)
    yield put(listRemoteTypesSuccess(action.companyId, types as DocumentType[]))
  } catch (error) {
    yield put(listRemoteTypesFailure(action.companyId, error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(CompanyActionType.LIST_REQUEST, listCompaniesSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_PROGRAMS_REQUEST, listRemoteProgramsSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_CLASSIFICATIONS_REQUEST, listRemoteClassificationsSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_METADATA_REQUEST, listRemoteMetadataSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_USERS_REQUEST, listRemoteUsersSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_EDITORS_REQUEST, listRemoteEditorsSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_REPORTERS_REQUEST, listRemoteReportersSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_PRODUCERS_REQUEST, listRemoteProducersSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_VEHICLES_REQUEST, listRemoteVehiclesSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_DOCUMENT_CONFIG_REQUEST, listRemoteDocumentConfigSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_GUIDELINE_CONFIG_REQUEST, listRemoteGuidelineConfigSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_REPORTAGE_CONFIG_REQUEST, listRemoteReportageConfigSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_STORY_CONFIG_REQUEST, listRemoteStoryConfigSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_RUNDOWN_CONFIG_REQUEST, listRemoteRundownConfigSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_EDITORIALS_REQUEST, listRemoteCompaniesSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_EDITORIALS_REQUEST, listRemoteEditorialsSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_TEAMS_REQUEST, listRemoteTeamsSaga)
  },
  function* () {
    yield takeLatest(CompanyActionType.LIST_TYPES_REQUEST, listRemoteTypesSaga)
  },
])
