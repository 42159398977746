import { get, post, put, del } from './client';
var endpoint = '/api/vehicles';
var vehicleApi = {
    list: function () {
        return get(endpoint);
    },
    usageCount: function (id) {
        return get(endpoint + "/" + id + "/usage_count");
    },
    create: function (vehicle) {
        return post(endpoint, vehicle);
    },
    update: function (vehicle) {
        return put("" + endpoint, vehicle);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default vehicleApi;
