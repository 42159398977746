import { AssetField, Media, Page, DeviceProtocol } from '@anews/types'
import { Query } from '@anews/api'

import { PAGE_SIZE } from '../../consts/pagination'

export enum MediaActionType {
  CLEAR = '@media/CLEAR',
  CLEAR_RESULTS = '@media/CLEAR_RESULTS',
  // listSearchableFields()
  SEARCHABLE_FIELDS_REQUEST = '@media/SEARCHABLE_FIELDS_REQUEST',
  SEARCHABLE_FIELDS_SUCCESS = '@media/SEARCHABLE_FIELDS_SUCCESS',
  SEARCHABLE_FIELDS_FAILURE = '@media/SEARCHABLE_FIELDS_FAILURE',
  // filterMedias()
  FILTER_MEDIAS_REQUEST = '@media/FILTER_MEDIAS_REQUEST',
  FILTER_MEDIAS_SUCCESS = '@media/FILTER_MEDIAS_SUCCESS',
  FILTER_MEDIAS_FAILURE = '@media/FILTER_MEDIAS_FAILURE',
  // websocket
  WS_CREATE = '@media/WS_CREATE',
  WS_DELETE = '@media/WS_DELETE',
  WS_UPDATE = '@media/WS_UPDATE',
}

export type MediaActionMap<T extends MediaActionType> = { type: T } & MediaAction

export type MediaAction = ReturnType<typeof MediaActions[keyof typeof MediaActions]>

export const MediaActions = {
  //
  // cleanUp()
  //

  cleanUp: () => <const>{ type: MediaActionType.CLEAR },

  //
  //  clearResults()
  //

  clearResults: (embedded: boolean, clearFields: boolean) =>
    <const>{ type: MediaActionType.CLEAR_RESULTS, embedded, clearFields },

  //
  //  listSearchableFields()
  //

  listSearchableFields: (mamId: number, protocol: DeviceProtocol, embedded: boolean) =>
    <const>{ type: MediaActionType.SEARCHABLE_FIELDS_REQUEST, mamId, protocol, embedded },

  listSearchableFieldsSuccess: (fields: AssetField[], embedded: boolean) =>
    <const>{ type: MediaActionType.SEARCHABLE_FIELDS_SUCCESS, fields, embedded },

  listSearchableFieldsFailure: (error: Error, embedded: boolean) =>
    <const>{ type: MediaActionType.SEARCHABLE_FIELDS_FAILURE, error, embedded },

  //
  //  filterMedias()
  //

  filterMedias: (mamId: number, params: Query, page = 0, size = PAGE_SIZE, embedded: boolean) =>
    <const>{ type: MediaActionType.FILTER_MEDIAS_REQUEST, mamId, params, page, size, embedded },

  filterMediasSuccess: (result: Page<Media>, embedded: boolean) =>
    <const>{ type: MediaActionType.FILTER_MEDIAS_SUCCESS, result, embedded },

  filterMediasFailure: (error: Error, embedded: boolean) =>
    <const>{ type: MediaActionType.FILTER_MEDIAS_FAILURE, error, embedded },

  //
  //  wsCreateMedia()
  //

  wsCreateMedia: (media: Media) => <const>{ type: MediaActionType.WS_CREATE, media },

  //
  //  wsUpdateMedia()
  //

  wsUpdateMedia: (media: Media) => <const>{ type: MediaActionType.WS_UPDATE, media },

  //
  //  wsDeleteMedia()
  //

  wsDeleteMedia: (id: string) => <const>{ type: MediaActionType.WS_DELETE, id },
}
