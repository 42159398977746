/** @jsx jsx */

import { jsx, css } from '@emotion/react'

import React, { forwardRef, memo, ReactNode } from 'react'

import Form, { FormInstance } from 'antd/es/form'
import Input from 'antd/es/input'
import Select from 'antd/es/select'
import Tooltip from 'antd/es/tooltip'

import { ReportageConfig, DocumentConfig, CustomMetadata, CustomMetadataType } from '@anews/types'

import { DatePicker, HGroup, VGroup, SelectWithSearch } from '../../global'
import { VehiclesListState } from '../../../redux/reducers/vehicles-reducer'
import { UsersListState } from '../../../redux/reducers/users-reducer'
import { ProgramsListState } from '../../../redux/reducers/programs-reducer'
import { EditorialsListState } from '../../../redux/reducers/editorials-reducer'
import { CompaniesListState } from '../../../redux/reducers/companies-reducer'
import { ClassificationsListState } from '../../../redux/reducers/classifications-reducer'
import { useTranslation } from '../../../i18n'
import { MetadataFormItem } from '../../global/CustomMetadata'
import { splitEvenly } from '../../../utils/array-utils'

interface Props {
  form: FormInstance
  actions: ReactNode[]
  documentConfig?: DocumentConfig
  reportageConfig?: ReportageConfig
  customMetadata: CustomMetadata[]
  classifications: ClassificationsListState
  companies: CompaniesListState
  editors: UsersListState
  editorials: EditorialsListState
  accessiblePrograms: ProgramsListState
  reporters: UsersListState
  vehicles: VehiclesListState
  showSubtitle: boolean
  onReporterChange: (reporterId: number) => void
  onVehiclesChange: (vehiclesIds: number[]) => void
  onProgramsChange: (programsIds: number[]) => void
}

const ReportageFormHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    form,
    actions,
    customMetadata,
    classifications,
    companies,
    documentConfig,
    reportageConfig,
    editors,
    editorials,
    accessiblePrograms,
    reporters,
    vehicles,
    showSubtitle,
    onReporterChange,
    onVehiclesChange,
    onProgramsChange,
    ...rest
  } = props

  const { t } = useTranslation()

  const fields = [
    // Data
    <Tooltip key="date" title={t('words:date')}>
      <Form.Item name="date" rules={[{ required: true, message: t('validation:required') }]}>
        <DatePicker placeholder={t('words:date')} style={{ width: 128 }} />
      </Form.Item>
    </Tooltip>,

    // Retranca
    <Tooltip key="slug" title={t('words:slug')}>
      <Form.Item
        name="slug"
        style={{ flex: 3 }}
        rules={[
          { required: true, message: t('validation:required'), whitespace: true },
          { max: 100, message: t('validation:maxChars', { count: 100 }) },
        ]}
      >
        <Input
          className="upperCase"
          placeholder={t('words:slug')}
          maxLength={100}
          style={{ width: '100%' }}
          autoComplete="off"
        />
      </Form.Item>
    </Tooltip>,

    // Repórter
    <Tooltip key="reporter" title={t('words:reporter')}>
      <Form.Item
        name="reporterId"
        style={{ flex: 1 }}
        rules={[{ required: true, message: t('validation:required') }]}
      >
        <SelectWithSearch
          placeholder={t('words:reporter')}
          loading={reporters.loading}
          style={{ width: '100%' }}
          onChange={onReporterChange}
        >
          {reporters.data.map(r => (
            <Select.Option key={r.id} value={r.id}>
              {r.nickname}
            </Select.Option>
          ))}
        </SelectWithSearch>
      </Form.Item>
    </Tooltip>,
    // Editar, Criar uma nova reportagem
    // Programas
    <Tooltip key="accessiblePrograms" title={t('words:programs')}>
      <Form.Item name="programsIds" style={{ flex: 2 }}>
        <SelectWithSearch
          placeholder={t('words:programs')}
          mode="multiple"
          loading={accessiblePrograms.loading}
          onChange={onProgramsChange}
        >
          {accessiblePrograms.data.map(p => (
            <Select.Option key={p.id} value={p.id}>
              {p.name}
            </Select.Option>
          ))}
        </SelectWithSearch>
      </Form.Item>
    </Tooltip>,
  ]

  if (reportageConfig) {
    // Veículos
    if (reportageConfig.vehiclesEnabled) {
      fields.push(
        <Tooltip key="vehicles" title={t('words:vehicles')}>
          <Form.Item name="vehiclesIds" style={{ flex: 2 }}>
            <SelectWithSearch
              placeholder={t('words:vehicles')}
              mode="multiple"
              loading={vehicles.loading}
              onChange={onVehiclesChange}
            >
              {vehicles.data.map(v => (
                <Select.Option key={v.id} value={v.id}>
                  {v.name}
                </Select.Option>
              ))}
            </SelectWithSearch>
          </Form.Item>
        </Tooltip>,
      )
    }

    // Editor
    if (reportageConfig.editorEnabled) {
      fields.push(
        <Tooltip key="editor" title={t('words:editor')}>
          <Form.Item name="editorId" style={{ flex: 1 }}>
            <SelectWithSearch placeholder={t('words:editor')} loading={editors.loading}>
              {editors.data.map(r => (
                <Select.Option key={r.id} value={r.id}>
                  {r.nickname}
                </Select.Option>
              ))}
            </SelectWithSearch>
          </Form.Item>
        </Tooltip>,
      )
    }

    // Imagens
    if (reportageConfig.cameramanEnabled) {
      fields.push(
        <Tooltip key="cameraman" title={t('words:cameraman')}>
          <Form.Item name="cameraman" style={{ flex: 1 }}>
            <Input placeholder={t('words:cameraman')} maxLength={60} />
          </Form.Item>
        </Tooltip>,
      )
    }

    // Classificação
    if (reportageConfig.classificationEnabled) {
      fields.push(
        <Tooltip key="classification" title={t('words:classification')}>
          <Form.Item name="classificationId" style={{ flex: 1 }}>
            <SelectWithSearch
              placeholder={t('words:classification')}
              loading={classifications.loading}
            >
              {classifications.data.map(c => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </SelectWithSearch>
          </Form.Item>
        </Tooltip>,
      )
    }

    // Editorias
    if (reportageConfig.editorialsEnabled) {
      fields.push(
        <Tooltip key="editorials" title={t('words:editorials')}>
          <Form.Item name="editorialsIds" style={{ flex: 2 }}>
            <SelectWithSearch
              placeholder={t('words:editorials')}
              mode="multiple"
              optionFilterProp="children"
              loading={editorials.loading}
            >
              {editorials.data.map(v => (
                <Select.Option key={v.id} value={v.id}>
                  {v.name}
                </Select.Option>
              ))}
            </SelectWithSearch>
          </Form.Item>
        </Tooltip>,
      )
    }
  }

  // Praça
  if (documentConfig && documentConfig.branchEnabled) {
    fields.push(
      <Tooltip key="branch" title={t('words:branch')}>
        <Form.Item name="branchId" style={{ flex: 1 }}>
          <SelectWithSearch placeholder={t('words:branch')} loading={companies.loading}>
            {companies.data.map(c => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </SelectWithSearch>
        </Form.Item>
      </Tooltip>,
    )
  }

  const metaFields = customMetadata
    .filter(m => !(m.type === CustomMetadataType.TEXT && m.multiple))
    .map(m => <MetadataFormItem key={m.name} metadata={m} placeholderAsLabel />)

  fields.push(...metaFields)

  // Divide os campos em linhas de até 5 campos
  const lines = splitEvenly(fields, 5)

  return (
    <VGroup
      ref={ref}
      {...rest}
      css={css`
        .ant-form-item {
          margin-bottom: 0;
        }
        .ant-select-selector {
          max-height: 40px !important;
          overflow: hidden auto;
        }
      `}
    >
      {lines.map((line, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <HGroup key={index} alignItems="flex-start">
          {index === 0 ? (
            <React.Fragment>
              <HGroup alignItems="flex-start" flexGrow={1}>
                {line}
              </HGroup>
              {actions}
            </React.Fragment>
          ) : (
            line
          )}
        </HGroup>
      ))}

      {showSubtitle && (
        <Tooltip title={t('reportage:lead')}>
          <Form.Item name="lead">
            <Input placeholder={t('reportage:lead')} />
          </Form.Item>
        </Tooltip>
      )}
    </VGroup>
  )
})

export default memo(ReportageFormHeader)
