import { RssFeed } from '@anews/types'

export enum RssFeedActionType {
  NEW = '@rss_feed/NEW',
  EDIT = '@rss_feed/EDIT',
  LIST_REQUEST = '@rss_feed/LIST_REQUEST',
  LIST_SUCCESS = '@rss_feed/LIST_SUCCESS',
  LIST_FAILURE = '@rss_feed/LIST_FAILURE',
  CREATE_REQUEST = '@rss_feed/CREATE_REQUEST',
  CREATE_SUCCESS = '@rss_feed/CREATE_SUCCESS',
  CREATE_FAILURE = '@rss_feed/CREATE_FAILURE',
  UPDATE_REQUEST = '@rss_feed/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@rss_feed/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@rss_feed/UPDATE_FAILURE',
  REMOVE_REQUEST = '@rss_feed/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@rss_feed/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@rss_feed/REMOVE_FAILURE',
}

export type RssFeedActionMap<T extends RssFeedActionType> = { type: T } & RssFeedAction

export type RssFeedAction = ReturnType<typeof RssFeedActions[keyof typeof RssFeedActions]>

export const RssFeedActions = {
  //
  //	newRssFeed()
  //

  newRssFeed: (feed?: RssFeed) => <const>{ type: RssFeedActionType.NEW, feed },

  //
  //	listRssFeeds()
  //

  listRssFeeds: () => <const>{ type: RssFeedActionType.LIST_REQUEST },

  listRssFeedsSuccess: (feeds: RssFeed[]) => <const>{ type: RssFeedActionType.LIST_SUCCESS, feeds },

  listRssFeedsFailure: (error: Error) => <const>{ type: RssFeedActionType.LIST_FAILURE, error },

  //
  //	editRssFeed()
  //

  editRssFeed: (feed: RssFeed) => <const>{ type: RssFeedActionType.EDIT, feed },

  //
  //	createRssFeed()
  //

  createRssFeed: (feed: RssFeed) => <const>{ type: RssFeedActionType.CREATE_REQUEST, feed },

  createRssFeedSuccess: (feed: RssFeed) => <const>{ type: RssFeedActionType.CREATE_SUCCESS, feed },

  createRssFeedFailure: (error: Error) => <const>{ type: RssFeedActionType.CREATE_FAILURE, error },

  //
  //	updateRssFeed()
  //

  updateRssFeed: (feed: RssFeed) => <const>{ type: RssFeedActionType.UPDATE_REQUEST, feed },

  updateRssFeedSuccess: (feed: RssFeed) => <const>{ type: RssFeedActionType.UPDATE_SUCCESS, feed },

  updateRssFeedFailure: (error: Error) => <const>{ type: RssFeedActionType.UPDATE_FAILURE, error },

  //
  //	removeRssFeed()
  //

  removeRssFeed: (id: number) => <const>{ type: RssFeedActionType.REMOVE_REQUEST, id },

  removeRssFeedSuccess: (id: number) => <const>{ type: RssFeedActionType.REMOVE_SUCCESS, id },

  removeRssFeedFailure: (error: Error) => <const>{ type: RssFeedActionType.REMOVE_FAILURE, error },
}
