import { Group } from '@anews/types'

export enum ActionType {
  NEW = '@group/NEW',
  LIST_REQUEST = '@group/LIST_REQUEST',
  LIST_SUCCESS = '@group/LIST_SUCCESS',
  LIST_FAILURE = '@group/LIST_FAILURE',
  EDIT_REQUEST = '@group/EDIT_REQUEST',
  EDIT_SUCCESS = '@group/EDIT_SUCCESS',
  EDIT_FAILURE = '@group/EDIT_FAILURE',
  CREATE_REQUEST = '@group/CREATE_REQUEST',
  CREATE_SUCCESS = '@group/CREATE_SUCCESS',
  CREATE_FAILURE = '@group/CREATE_FAILURE',
  UPDATE_REQUEST = '@group/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@group/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@group/UPDATE_FAILURE',
  REMOVE_REQUEST = '@group/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@group/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@group/REMOVE_FAILURE',
}

export interface EditGroupAction {
  type: ActionType.EDIT_REQUEST
  id: number
  copy: boolean
}

export interface CreateGroupAction {
  type: ActionType.CREATE_REQUEST
  group: Group
}

export interface UpdateGroupAction {
  type: ActionType.UPDATE_REQUEST
  group: Group
}

export interface RemoveGroupAction {
  type: ActionType.REMOVE_REQUEST
  ids: number[]
}

export type GroupAction =
  /* newGroup() */
  | { type: ActionType.NEW; group?: Group }
  /* listGroups() */
  | { type: ActionType.LIST_REQUEST }
  | { type: ActionType.LIST_SUCCESS; groups: Group[] }
  | { type: ActionType.LIST_FAILURE; error: Error }
  /* editGroup() */
  | EditGroupAction
  | { type: ActionType.EDIT_SUCCESS; group: Group }
  | { type: ActionType.EDIT_FAILURE; error: Error }
  /* createGroup() */
  | CreateGroupAction
  | { type: ActionType.CREATE_SUCCESS; group: Group }
  | { type: ActionType.CREATE_FAILURE; error: Error }
  /* updateGroup() */
  | UpdateGroupAction
  | { type: ActionType.UPDATE_SUCCESS; group: Group }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* removeGroup() */
  | RemoveGroupAction
  | { type: ActionType.REMOVE_SUCCESS; ids: number[] }
  | { type: ActionType.REMOVE_FAILURE; error: Error }

//
//	newGroup()
//

export const newGroup = (group?: Group): GroupAction => ({ type: ActionType.NEW, group })

//
//	listGroups()
//

export const listGroups = (): GroupAction => ({ type: ActionType.LIST_REQUEST })

export const listGroupsSuccess = (groups: Group[]): GroupAction => ({
  type: ActionType.LIST_SUCCESS,
  groups,
})

export const listGroupsFailure = (error: Error): GroupAction => ({
  type: ActionType.LIST_FAILURE,
  error,
})

//
//	editGroup()
//

export const editGroup = (id: number, copy: boolean): GroupAction => ({
  type: ActionType.EDIT_REQUEST,
  id,
  copy,
})

export const editGroupSuccess = (group: Group): GroupAction => ({
  type: ActionType.EDIT_SUCCESS,
  group,
})

export const editGroupFailure = (error: Error): GroupAction => ({
  type: ActionType.EDIT_FAILURE,
  error,
})

//
//	createGroup()
//

export const createGroup = (group: Group): GroupAction => ({
  type: ActionType.CREATE_REQUEST,
  group,
})

export const createGroupSuccess = (group: Group): GroupAction => ({
  type: ActionType.CREATE_SUCCESS,
  group,
})

export const createGroupFailure = (error: Error): GroupAction => ({
  type: ActionType.CREATE_FAILURE,
  error,
})

//
//	updateGroup()
//

export const updateGroup = (group: Group): GroupAction => ({
  type: ActionType.UPDATE_REQUEST,
  group,
})

export const updateGroupSuccess = (group: Group): GroupAction => ({
  type: ActionType.UPDATE_SUCCESS,
  group,
})

export const updateGroupFailure = (error: Error): GroupAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//	removeGroup()
//

export const removeGroup = (ids: number[]): GroupAction => ({
  type: ActionType.REMOVE_REQUEST,
  ids,
})

export const removeGroupSuccess = (ids: number[]): GroupAction => ({
  type: ActionType.REMOVE_SUCCESS,
  ids,
})

export const removeGroupFailure = (error: Error): GroupAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})
