import { call, put, takeLatest } from 'redux-saga/effects'

import { iiTemplateApi as api } from '@anews/api'
import { IITemplate } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  EditTemplateAction,
  CreateTemplateAction,
  UpdateTemplateAction,
  RemoveTemplateAction,
  ListTemplatesAction,
  listTemplatesSuccess,
  listTemplatesFailure,
  editTemplateSuccess,
  editTemplateFailure,
  createTemplateSuccess,
  createTemplateFailure,
  updateTemplateSuccess,
  updateTemplateFailure,
  removeTemplateSuccess,
  removeTemplateFailure,
} from '../actions/iitemplate-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listTemplatesSaga(action: ListTemplatesAction): Generator {
  try {
    const templates = yield call(api.list, action.deviceId)
    yield put(listTemplatesSuccess(templates as IITemplate[]))
  } catch (error) {
    yield put(listTemplatesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* editTemplateSaga(action: EditTemplateAction): Generator {
  try {
    const template = yield call(api.load, action.deviceId, action.templateId)
    yield put(editTemplateSuccess(template as IITemplate))
  } catch (error) {
    yield put(editTemplateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createTemplateSaga(action: CreateTemplateAction): Generator {
  try {
    const template = yield call(api.create, action.deviceId, action.template)
    yield put(createTemplateSuccess(template as IITemplate))
  } catch (error) {
    yield put(createTemplateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateTemplateSaga(action: UpdateTemplateAction): Generator {
  try {
    const template = yield call(api.update, action.deviceId, action.template)
    yield put(updateTemplateSuccess(template as IITemplate))
  } catch (error) {
    yield put(updateTemplateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeTemplateSaga(action: RemoveTemplateAction): Generator {
  try {
    yield call(api.remove, action.deviceId, action.templateIds)
    yield put(removeTemplateSuccess(action.templateIds))
  } catch (error) {
    yield put(removeTemplateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listTemplatesSaga)
  },
  function* () {
    yield takeLatest(ActionType.EDIT_REQUEST, editTemplateSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createTemplateSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateTemplateSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeTemplateSaga)
  },
])
