import { del, get, post, put, query } from './client';
var endpoint = '/api/facebook/categories';
var facebookCategoryApi = {
    list: function (pages) {
        return get(query(endpoint, { pages: pages }));
    },
    create: function (category) {
        return post(endpoint, category);
    },
    update: function (category) {
        return put("" + endpoint, category);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default facebookCategoryApi;
