import { Reducer } from 'redux'

import { AdminLog } from '@anews/types'

import { AdminLogsParams } from '@anews/api/build/adminlogs-api'

import { AdminLogsAction, AdminLogActionType } from '../actions/adminlogs-actions'

const initialState = {
  data: [],
  number: 0,
  size: 0,
  total: 0,
  loading: true,
} as AdminLogsStates

export interface AdminLogsStates {
  data: AdminLog[]
  filter?: AdminLogsParams
  loading: boolean
  number: number
  size: number
  total: number
}

export type AdminStateInitial = typeof initialState

const adminLogReducer: Reducer<AdminStateInitial, AdminLogsAction> = (
  state = initialState,
  action,
): AdminStateInitial => {
  switch (action.type) {
    case AdminLogActionType.FILTER_ADMIN_REQUEST:
      return { ...state, filter: action.filter, loading: true }

    case AdminLogActionType.FILTER_ADMIN_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        size: action.result.size,
        total: action.result.total,
        number: action.result.page,
        loading: false,
      }
    default:
      return state

    case AdminLogActionType.FILTER_ADMIN_FAILURE:
      return { ...state, loading: false }
  }
}

export default adminLogReducer
