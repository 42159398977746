/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { PureComponent } from 'react'
import { Moment } from 'moment'

import DatePicker from 'antd/es/date-picker'
import { RangePickerProps } from 'antd/es/date-picker/generatePicker'

const AntRangePicker = DatePicker.RangePicker

class RangePicker extends PureComponent<RangePickerProps<Moment>> {
  render() {
    return (
      <AntRangePicker
        format="L"
        css={css`
          min-width: 224px;
        `}
        {...this.props}
      />
    )
  }
}

export default RangePicker
