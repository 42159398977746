import { call, put, takeLatest } from 'redux-saga/effects'

import { checklistTypeApi as api } from '@anews/api'

import { ChecklistType } from '@anews/types'

import {
  ChecklistTypeActionType as ActionType,
  ChecklistTypeActions,
  NotificationActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { listTypesSuccess, listTypesFailure } = ChecklistTypeActions
const { notifyError } = NotificationActions

/* Watchers */

function* listChecklistTypesSaga(): Generator {
  try {
    const types = yield call(api.list)
    yield put(listTypesSuccess(types as ChecklistType[]))
  } catch (error) {
    yield put(listTypesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listChecklistTypesSaga)
  },
])
