var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { del, get, post, put, query, patch } from './client';
var endpoint = '/api/documents';
//
//  config
//
var documentApi = {
    states: function () {
        return get(endpoint + "/states");
    },
    loadById: function (id) {
        return get(endpoint + "/" + id);
    },
    getDocumentMap: function (id) {
        return get(endpoint + "/" + id + "/map");
    },
    getAssociations: function (id) {
        return get(endpoint + "/" + id + "/associate");
    },
    associateDocument: function (id, documentID) {
        return post(endpoint + "/" + id + "/associate/" + documentID);
    },
    removeAssociation: function (id, keepGuideline) {
        if (keepGuideline === void 0) { keepGuideline = false; }
        return del(query(endpoint + "/" + id + "/associate", { keepGuideline: keepGuideline }));
    },
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
    sendToDrawer: function (ids, programId) {
        return put(query(endpoint + "/drawer", { ids: ids, programId: programId }));
    },
    removeFromDrawer: function (ids, programId) {
        return del(query(endpoint + "/drawer", { ids: ids, programId: programId }));
    },
    filter: function (params, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", __assign(__assign({}, params), { page: page, size: size })));
    },
    togglePrivate: function (id) {
        return patch(endpoint + "/" + id + "/private");
    },
    updateShare: function (id, usersIds) {
        return put(endpoint + "/" + id + "/allowedUsers", usersIds);
    },
    moveFromDrawer: function (ids, rundownId, targetBlockId, targetStoryId, removeFromDrawer) {
        return post(query(endpoint + "/drawer/move", {
            ids: ids,
            rundownId: rundownId,
            targetBlockId: targetBlockId,
            targetStoryId: targetStoryId,
            removeFromDrawer: removeFromDrawer,
        }));
    },
    loadState: function (id) {
        return get(endpoint + "/" + id + "/state");
    },
};
export default documentApi;
