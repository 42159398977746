import React, { forwardRef, memo, useEffect, useState, ChangeEvent } from 'react'
import Input, { InputProps } from 'antd/es/input'

type Format = 'HH:mm' | 'HH:mm:ss' | 'mm:ss'

function isValid(value: string, format: Format) {
  // Transforma "mm:ss" em /^\d\d:\d\d$/
  const regex = new RegExp(`^${format.replace(/\w/g, '\\d')}$`)
  return regex.test(value)
}

// Nos formatos HH:mm e mm:ss, quando value vem com 3 unidades tem que cortar
function ajustInput(value: string, format: Format) {
  if (format.length === 5 && value.length > 5) {
    if (format === 'HH:mm') {
      return value.substring(0, 5)
    }
    if (format === 'mm:ss') {
      return value.substring(3)
    }
  }
  return value
}

// Para gerar a saída (onChange) é preciso deixar o valor com 3 unidades
function ajustOutput(value: string, format: Format) {
  if (format === 'HH:mm') {
    return `${value}:00`
  }
  if (format === 'mm:ss') {
    return `00:${value}`
  }
  return value
}

export interface TimeInputProps extends Omit<Omit<InputProps, 'value'>, 'onChange'> {
  value?: string
  format: Format
  onChange?: (value: string) => void
}

const TimeInput = forwardRef<Input, TimeInputProps>((props: TimeInputProps, ref) => {
  const { format, onChange, ...rest } = props
  const value = ajustInput(props.value || (format.length === 5 ? '00:00' : '00:00:00'), format)

  const [cache, setCache] = useState(value)

  useEffect(() => {
    setCache(value)
  }, [value, format])

  const formatValue = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value || ''

    // Não aplica máscara se estiver apagando...
    if (cache && newValue.length < cache.length) {
      setCache(newValue)
      return
    }

    newValue = newValue.replace(/[^\d:]/g, '')

    const tokens = format.split(':')
    let actualSize = 0

    for (let i = 0; i < tokens.length - 1; i += 1) {
      actualSize += tokens[i].length + i
      if (actualSize === newValue.length) {
        newValue += ':'
      }
    }

    setCache(newValue)

    const ajustedValue = ajustOutput(value, format)
    const ajustedNewValue = ajustOutput(newValue, format)

    if (onChange && isValid(ajustedNewValue, format)) {
      if (ajustedNewValue !== ajustedValue) {
        onChange(ajustedNewValue)
      }
    }
  }

  return (
    <Input {...rest} ref={ref} value={cache} maxLength={format.length} onChange={formatValue} />
  )
})

TimeInput.displayName = 'TimeInput'

export default memo(TimeInput)
