import { Reducer } from 'redux'
import produce from 'immer'

import { EntityLock } from '@anews/types'

import { EntityLockActionType as ActionType, EntityLockAction } from '../actions'

export interface EntityLocksState {
  [uuid: string]: EntityLock
}

export const initialState: EntityLocksState = {}

const entityLocksReducer: Reducer<EntityLocksState, EntityLockAction> = (
  state = initialState,
  action,
): EntityLocksState => {
  switch (action.type) {
    case ActionType.LIST_ACTIVE_SUCCESS: {
      const newState: EntityLocksState = {}
      action.locks.forEach(lock => {
        newState[lock.uuid] = lock
      })
      return newState
    }

    case ActionType.RENEW_SUCCESS:
    case ActionType.TAKE_OVER_SUCCESS:
    case ActionType.WS_LOCK:
      // Usa o immer para não alterar o state quando o lock for o mesmo (renew)
      return produce(state, draft => {
        draft[action.lock.uuid] = action.lock
      })

    case ActionType.WS_UNLOCK: {
      // eslint-disable-next-line no-unused-vars
      const { [action.lock.uuid]: lock, ...otherLocks } = state
      return otherLocks
    }

    // unlock vai ser feito via notificação web socket

    default:
      return state
  }
}

export default entityLocksReducer
