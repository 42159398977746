import React, { memo } from 'react'

import Col from 'antd/es/col'
import Row from 'antd/es/row'

import { Story, StoryConfig, RundownConfig } from '@anews/types'

import { displayDate, formatIsoDuration, displayTimestamp, mapJoin } from '@anews/utils'

import { ViewerItem } from '../../../global'
import { SelectorMap } from '../../../../redux/selectors/helpers'

import { useTranslation } from '../../../../i18n'

interface Props {
  story: Story
  companies: SelectorMap<string>
  programs: SelectorMap<string>
  users: SelectorMap<string>
  rundownConfig?: RundownConfig
  storyConfig?: StoryConfig
  editorials?: SelectorMap<string>
}

function FullHeader({
  story,
  companies,
  programs,
  users,
  rundownConfig,
  storyConfig,
  editorials,
}: Props) {
  const { mediaCode, estimated, link, head, vt, vo, total, changeDate, authorNickname } = story

  const date = displayDate(story.date)
  const company = companies[story.branchId!]

  const programName = story.programId ? programs[story.programId] : undefined
  const editorNick = story.editorId ? users[story.editorId] : undefined
  const imgEdNick = story.imageEditorId ? users[story.imageEditorId] : undefined
  const reporterNick = story.reporterId ? users[story.reporterId] : undefined
  const editorialsNames = mapJoin(editorials, story.editorialsIds)

  const firstRowCols =
    1 /* data */ + (company ? 1 : 0) + (programName ? 1 : 0) + (mediaCode ? 1 : 0)
  const secondRowCols = (editorNick ? 1 : 0) + (imgEdNick ? 1 : 0) + (reporterNick ? 1 : 0)

  const ZERO = 'PT0S'

  const displayEstimated = rundownConfig?.estimatedEnabled && estimated !== ZERO
  const displayLink = storyConfig?.linkEnabled && link && link !== ZERO
  const displayVT = !displayLink || vt !== ZERO
  const displayOff = vo && vo !== ZERO

  const thirdRowCols =
    2 /* head, total */ +
    (displayEstimated ? 1 : 0) +
    (displayLink ? 1 : 0) +
    (displayVT ? 1 : 0) +
    (displayOff ? 1 : 0)

  const fourthRowCols = (changeDate ? 1 : 0) + (authorNickname ? 1 : 0)

  const { t } = useTranslation()

  return (
    <>
      {/* Praça, Data e Programa */}
      <Row>
        {company && (
          <Col span={24 / firstRowCols}>
            <ViewerItem label={t('words:branch')}>{company}</ViewerItem>
          </Col>
        )}
        <Col span={24 / firstRowCols}>
          <ViewerItem label={t('words:date')}>{date}</ViewerItem>
        </Col>
        {programName && (
          <Col span={24 / firstRowCols}>
            <ViewerItem label={t('words:program')}>{programName}</ViewerItem>
          </Col>
        )}
        {mediaCode && (
          <Col span={24 / firstRowCols}>
            <ViewerItem label={t('words:medias')}>{mediaCode}</ViewerItem>
          </Col>
        )}
      </Row>

      {/* Repórter, Editor, Editor de Imagens e Editorias  */}
      <Row>
        {reporterNick && (
          <Col span={24 / secondRowCols}>
            <ViewerItem label={t('words:reporter')}>{reporterNick}</ViewerItem>
          </Col>
        )}
        {!story?.radio && imgEdNick && (
          <Col span={24 / secondRowCols}>
            <ViewerItem label={t('story:imageEditorAbbrev')}>{imgEdNick}</ViewerItem>
          </Col>
        )}
        {editorNick && (
          <Col span={24 / secondRowCols}>
            <ViewerItem label={t('words:editor')}>{editorNick}</ViewerItem>
          </Col>
        )}
        {editorialsNames && (
          <Col span={24 / secondRowCols}>
            <ViewerItem label={t('words:editorials')}>{editorialsNames}</ViewerItem>
          </Col>
        )}
      </Row>

      {/* Previsto, Cabeça, VT, Link, Off, Total */}
      {!story.legacy && (
        <Row>
          {displayEstimated && (
            <Col span={Math.ceil(24 / thirdRowCols)}>
              <ViewerItem label={t('words:estimatedAbbrev')}>
                {formatIsoDuration(estimated, 'mm:ss')}
              </ViewerItem>
            </Col>
          )}
          <Col span={Math.ceil(24 / thirdRowCols)}>
            <ViewerItem label={story?.radio ? t('story:voiceover') : t('story:head')}>
              {formatIsoDuration(head, 'mm:ss')}
            </ViewerItem>
          </Col>
          {displayVT && (
            <Col span={Math.ceil(24 / thirdRowCols)}>
              <ViewerItem label={story?.radio ? t('story:voiced') : t('story:vt')}>
                {formatIsoDuration(vt, 'mm:ss')}
              </ViewerItem>
            </Col>
          )}
          {displayLink && (
            <Col span={Math.ceil(24 / thirdRowCols)}>
              <ViewerItem label={story?.radio ? t('story:vivo') : t('story:link')}>
                {formatIsoDuration(link!, 'mm:ss')}
              </ViewerItem>
            </Col>
          )}
          {displayOff && (
            <Col span={Math.ceil(24 / thirdRowCols)}>
              <ViewerItem label={t('story:off')}>{formatIsoDuration(vo!, 'mm:ss')}</ViewerItem>
            </Col>
          )}
          <Col span={Math.ceil(24 / thirdRowCols)}>
            <ViewerItem label={t('story:total')}>{formatIsoDuration(total, 'mm:ss')}</ViewerItem>
          </Col>
        </Row>
      )}

      {/* Ultima alteração, Alterado por */}
      <Row>
        {changeDate && (
          <Col span={24 / fourthRowCols}>
            <ViewerItem label={t('phrases:lastChange')}>{displayTimestamp(changeDate)}</ViewerItem>
          </Col>
        )}
        {authorNickname && (
          <Col span={24 / fourthRowCols}>
            <ViewerItem label={t('phrases:changedBy')}>{authorNickname}</ViewerItem>
          </Col>
        )}
      </Row>
    </>
  )
}

export default memo(FullHeader)
