import { call, put, takeLatest } from 'redux-saga/effects'

import { adminlogsApi as api } from '@anews/api'

import { AdminLog, Page } from '@anews/types'

import {
  AdminLogActionType as ActionType,
  AdminLogActions,
  AdminLogActionMap,
} from '../actions/adminlogs-actions'

import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { filterAdminLogsSuccess, filterAdminLogsFailure } = AdminLogActions
const { notifyError } = NotificationActions

function* filterAdminLogsSaga(
  action: AdminLogActionMap<ActionType.FILTER_ADMIN_REQUEST>,
): Generator {
  try {
    const result = yield call(api.logs, action.filter, action.page, action.size)
    yield put(filterAdminLogsSuccess(result as Page<AdminLog>))
  } catch (error) {
    yield put(filterAdminLogsFailure(error as Error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.FILTER_ADMIN_REQUEST, filterAdminLogsSaga)
  },
])
