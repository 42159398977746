import React from 'react'
import styled from '@emotion/styled'

import { TimePickerProps } from 'antd/es/time-picker'

import TimePicker from './TimePicker'

const StyledPicker = styled(TimePicker)`
  &.ant-time-picker {
    width: 40px;
  }
  &.ant-time-picker .ant-time-picker-input {
    border: 0;
    border-radius: 0;
    padding: 0;
  }
  &.ant-time-picker-small .ant-time-picker-icon {
    display: none;
  }
`

function CleanTimePicker(props: TimePickerProps) {
  return <StyledPicker size="small" allowClear={false} {...props} />
}

export default CleanTimePicker
