export function fixProxyUrl(url, _a) {
    var isGrassValley = _a.isGrassValley;
    if (isGrassValley && /^\\\\/.test(url)) {
        /*
         * Para a Grass Valley é preciso converter a URL de
         *    "\\STRATUS65\proxy\e789c445890e4ed89b6b4b74a5c77273\proxy.mp4"
         * para
         *    "http://STRATUS65/proxy/e789c445890e4ed89b6b4b74a5c77273/proxy.mp4"
         *
         * Mais detalhes em https://softwarenews.atlassian.net/browse/SDM-543
         *
         * Posteriormente, tratando o problema da TV Pajuçara, o Danilo Gaspardo da GV disse que
         * poderiamos usar sempre http, já que o https seria exclusivo das versões mais novas do
         * Stratus e, teoricamente, todas as versões suportariam http.
         *
         * Outro detalhe é que, pelo que eu percebi, é apenas um certificado auto assinado, e o
         * navegador do cliente está reclamando.
         */
        var result = url.replace(/^\\\\/, 'http://').replaceAll(/\\/g, '/');
        console.info("Converting URL format. From \"" + url + "\" to \"" + result + "\".");
        return result;
    }
    return url;
}
