//
// Souv Enums
//
export var SouvDocumentType;
(function (SouvDocumentType) {
    SouvDocumentType["DOCUMENT"] = "DOCUMENT";
    SouvDocumentType["ASSIGNMENT"] = "ASSIGNMENT";
    SouvDocumentType["NEWS_REPORT"] = "NEWS_REPORT";
    SouvDocumentType["TP"] = "TP";
    SouvDocumentType["NOTE"] = "NOTE";
})(SouvDocumentType || (SouvDocumentType = {}));
export var SouvDocumentStatus;
(function (SouvDocumentStatus) {
    SouvDocumentStatus["NEW"] = "NEW";
    SouvDocumentStatus["PRODUCING"] = "PRODUCING";
    SouvDocumentStatus["DONE"] = "DONE";
})(SouvDocumentStatus || (SouvDocumentStatus = {}));
export var SouvMediaType;
(function (SouvMediaType) {
    SouvMediaType["IMAGE"] = "IMAGE";
    SouvMediaType["VIDEO"] = "VIDEO";
    SouvMediaType["AUDIO"] = "AUDIO";
    SouvMediaType["OTHER"] = "OTHER";
})(SouvMediaType || (SouvMediaType = {}));
export var SouvSectionType;
(function (SouvSectionType) {
    SouvSectionType["TEXT"] = "TEXT";
    SouvSectionType["DIVIDER"] = "DIVIDER";
    SouvSectionType["CHECKLIST"] = "CHECKLIST";
    SouvSectionType["MEDIA"] = "MEDIA";
    SouvSectionType["MEDIA_GALLERY"] = "MEDIA_GALLERY";
    SouvSectionType["LOCATION"] = "LOCATION";
    SouvSectionType["CONTACT"] = "CONTACT";
    SouvSectionType["INTERVIEWEE"] = "INTERVIEWEE";
    SouvSectionType["CLASSIFICATION"] = "CLASSIFICATION";
    SouvSectionType["VEHICLES"] = "VEHICLES";
    SouvSectionType["PRODUCERS"] = "PRODUCERS";
    SouvSectionType["EDITORS"] = "EDITORS";
    SouvSectionType["DATE_TIME"] = "DATE_TIME";
    SouvSectionType["OBSERVATION"] = "OBSERVATION";
    SouvSectionType["EDITORIALS"] = "EDITORIALS";
    SouvSectionType["VO"] = "VO";
    SouvSectionType["TEASER"] = "TEASER";
    SouvSectionType["APPEARANCE"] = "ON_CAMERA";
    SouvSectionType["ART"] = "ART";
    SouvSectionType["HEADING"] = "HEADING";
    SouvSectionType["INFORMATION"] = "INFORMATION";
    SouvSectionType["NOTE"] = "NOTE";
    SouvSectionType["SOUND_UP"] = "SOUNDUP";
    SouvSectionType["INTERVIEW"] = "PLAYBACK";
    SouvSectionType["AUDIO"] = "AUDIO";
})(SouvSectionType || (SouvSectionType = {}));
export var SouvSectionSubType;
(function (SouvSectionSubType) {
    SouvSectionSubType["IMAGE"] = "IMAGE";
    SouvSectionSubType["VIDEO"] = "VIDEO";
    SouvSectionSubType["AUDIO"] = "AUDIO";
    SouvSectionSubType["DOCUMENT"] = "DOCUMENT";
    SouvSectionSubType["TITLE"] = "TITLE";
    SouvSectionSubType["SUBTITLE"] = "SUBTITLE";
    SouvSectionSubType["TP"] = "TP";
    SouvSectionSubType["PARAGRAPH"] = "PARAGRAPH";
    SouvSectionSubType["INVALID"] = "INVALID";
})(SouvSectionSubType || (SouvSectionSubType = {}));
