import { PaginationProps } from 'antd/es/pagination'

import i18n from '../i18n'

export const defaultPaginationProps: PaginationProps = {
  showTotal: (max: any, range: any[]) => `${range[0]}-${range[1]} ${i18n.t('words:of')} ${max} `,
  showQuickJumper: true,
  showSizeChanger: false,
  size: 'default',
}

export function normalizePagination<T extends PaginationProps>(pagination?: T | false) {
  return typeof pagination === 'object' ? { ...defaultPaginationProps, ...pagination } : pagination
}
