import AntDivider, { DividerProps as AntDividerProps } from 'antd/es/divider'

import styled from '@emotion/styled'
import {
  color,
  fontSize,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  space,
  width,
  ColorProps,
  FontSizeProps,
  HeightProps,
  MaxHeightProps,
  MaxWidthProps,
  MinHeightProps,
  MinWidthProps,
  SpaceProps,
  WidthProps,
} from 'styled-system'

export type DividerProps = AntDividerProps &
  ColorProps &
  FontSizeProps &
  HeightProps &
  MaxHeightProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  SpaceProps &
  WidthProps

const Divider = styled(AntDivider)<DividerProps>`
  ${color}
  ${fontSize}
  ${height}
  ${maxHeight}
  ${maxWidth}
  ${minHeight}
  ${minWidth}
  ${space}
  ${width}
`

export default Divider
