import { Reducer } from 'redux'
import { normalize, schema } from 'normalizr'

import produce from 'immer'

import { Block, RundownTemplate, Story } from '@anews/types'

import {
  RundownTemplateAction,
  RundownTemplateActionType as ActionType,
} from '../actions/rundowntemplate-actions'

import {
  StoryTemplateAction,
  ActionType as StoryActionType,
} from '../actions/storytemplate-actions'

//
//  Schema
//

const storySchema = new schema.Entity('stories')
const blockSchema = new schema.Entity('blocks', {
  stories: [storySchema],
})
const templateSchema = new schema.Entity('templates', {
  blocks: [blockSchema],
})

function normalizeRundown(template: RundownTemplate) {
  return normalize(template, templateSchema)
}

function normalizeBlock(block: Block) {
  return normalize(block, blockSchema)
}

function normalizeStory(story: Story) {
  return normalize(story, storySchema)
}

export type NormalizedTemplate = Omit<RundownTemplate, 'blocks'> & { blocks: number[] }
export type NormalizedBlock = Omit<Block, 'stories'> & { stories: number[] }

//
//  State
//

interface RundownTemplatesState {
  list: RundownTemplate[]
  selectedProgram?: number
  selectedTemplate?: number
  loading: boolean
  data: {
    templates: { [templateId: number]: NormalizedTemplate }
    blocks: { [blockId: number]: NormalizedBlock }
    stories: { [storyId: number]: Story }
  }
}

const initialState: RundownTemplatesState = {
  list: [],
  selectedTemplate: undefined,
  loading: false,
  data: {
    templates: {},
    blocks: {},
    stories: {},
  },
}

//
//  Reducers
//

function storiesReducer(
  partialState: RundownTemplatesState['data']['stories'],
  action: RundownTemplateAction | StoryTemplateAction,
): RundownTemplatesState['data']['stories'] {
  switch (action.type) {
    case ActionType.LOAD_SUCCESS:
    case ActionType.COPY_SUCCESS: {
      if (action.template) {
        const normalized = normalizeRundown(action.template)
        const { stories = {} } = normalized.entities
        return { ...partialState, ...stories }
      }
      return partialState
    }

    case ActionType.CREATE_STORY_SUCCESS: {
      const { id } = action.story

      // Se o ID já tá no state, já foi tratado
      if (partialState[id]) {
        return partialState
      }

      const normalized = normalizeStory(action.story)
      const { stories = {} } = normalized.entities

      return {
        ...partialState,
        ...stories,
      }
    }

    case StoryActionType.UPDATE_SUCCESS:
      return produce(partialState, draft => {
        const { story } = action
        draft[story.id] = story
      })

    case StoryActionType.WS_UPDATE:
      return produce(partialState, draft => {
        const { stories } = action
        stories.forEach(story => {
          draft[story.id] = story
        })
      })

    case StoryActionType.PATCH_REQUEST:
      // Atualização otimista (não espera o sucesso para atualizar o state)
      return produce(partialState, draft => {
        const { storyId, field, newValue } = action
        Object.assign(draft[storyId], { [field]: newValue })
      })

    case StoryActionType.PATCH_FAILURE:
      // Se o patch falhar, retorna o valor antigo
      return produce(partialState, draft => {
        const { storyId, field, oldValue } = action
        Object.assign(draft[storyId], { [field]: oldValue })
      })

    case StoryActionType.WS_PATCH:
      return produce(partialState, draft => {
        action.changes.forEach(storyChange => {
          const { id, changes } = storyChange
          if (draft[id]) {
            Object.assign(draft[id], changes)
          }
        })
      })

    case StoryActionType.REMOVE_SUCCESS:
    case StoryActionType.WS_DELETE:
      return produce(partialState, draft => {
        const { ids } = action

        // Apaga os dados das laudas
        ids.forEach(id => {
          delete draft[id]
        })
      })

    default:
      return partialState
  }
}

function blocksReducer(
  partialState: RundownTemplatesState['data']['blocks'],
  action: RundownTemplateAction | StoryTemplateAction,
): RundownTemplatesState['data']['blocks'] {
  switch (action.type) {
    case ActionType.LOAD_SUCCESS:
    case ActionType.CREATE_SUCCESS:
    case ActionType.COPY_SUCCESS: {
      if (action.template) {
        const normalized = normalizeRundown(action.template)
        const { blocks = {} } = normalized.entities
        return { ...partialState, ...blocks }
      }
      return partialState
    }

    case ActionType.CREATE_BLOCK_SUCCESS: {
      const normalized = normalizeBlock(action.block)
      const { blocks = {} } = normalized.entities

      return {
        ...partialState,
        ...blocks,
      }
    }

    case ActionType.PATCH_BLOCK_REQUEST:
      // Atualização otimista (não espera o sucesso para atualizar o state)
      return produce(partialState, draft => {
        const { blockId, field, newValue } = action
        Object.assign(draft[blockId], { [field]: newValue })
      })

    case ActionType.PATCH_BLOCK_FAILURE:
      // Se o patch falhar, retorna o valor antigo
      return produce(partialState, draft => {
        const { blockId, field, oldValue } = action
        Object.assign(draft[blockId], { [field]: oldValue })
      })

    case ActionType.WS_PATCH_BLOCK:
      return produce(partialState, draft => {
        const { blockId, changes } = action
        if (draft[blockId]) {
          Object.assign(draft[blockId], changes)
        }
      })

    case ActionType.REMOVE_BLOCK_SUCCESS: {
      const { blockId } = action

      if (!partialState[blockId]) {
        return partialState
      }

      return produce(partialState, draft => {
        delete draft[blockId]
      })
    }

    case ActionType.CREATE_STORY_SUCCESS: {
      const { id, blockId } = action.story

      const blocks = produce(partialState, draft => {
        const { stories } = draft[blockId!]
        stories.push(id)
      })

      return blocks
    }

    case ActionType.MOVE_STORY_REQUEST:
      // Atualização otimista (não espera o sucesso para atualizar o state)
      return produce(partialState, draft => {
        const { storyId, targetBlockId, targetStoryId } = action
        const targetBlock = draft[targetBlockId]

        // Não usar array.filter() por causa do proxy do immer
        // (descobri que o problema não era o filter... mas vamos deixar assim)
        Object.values(draft).forEach(b => {
          for (let i = b.stories.length - 1; i >= 0; i -= 1) {
            if (storyId === b.stories[i]) {
              b.stories.splice(i, 1)
              break
            }
          }
        })

        const targetIndex =
          targetStoryId === undefined
            ? targetBlock.stories.length
            : targetBlock.stories.indexOf(targetStoryId)

        targetBlock.stories.splice(targetIndex, 0, storyId)
      })

    case StoryActionType.REMOVE_SUCCESS:
    case StoryActionType.WS_DELETE:
      return produce(partialState, draft => {
        const { ids } = action

        // Remove elas dos blocos
        Object.entries(draft).forEach(([_, block]) => {
          block.stories = block.stories.filter(storyId => !ids.includes(storyId))
        })
      })

    default:
      return partialState
  }
}

function templatesReducer(
  partialState: RundownTemplatesState['data']['templates'],
  action: RundownTemplateAction | StoryTemplateAction,
): RundownTemplatesState['data']['templates'] {
  switch (action.type) {
    /* loadTemplate */
    case ActionType.LOAD_SUCCESS:
    case ActionType.CREATE_SUCCESS:
    case ActionType.COPY_SUCCESS: {
      if (action.template) {
        const normalized = normalizeRundown(action.template)
        const { templates = {} } = normalized.entities
        return { ...partialState, ...templates }
      }
      return partialState
    }

    /* patchTemplate */
    case ActionType.PATCH_REQUEST:
      return produce(partialState, draft => {
        const { templateId, field, newValue } = action
        Object.assign(draft[templateId], { [field]: newValue })
      })
    case ActionType.PATCH_FAILURE:
      return produce(partialState, draft => {
        const { templateId, field, oldValue } = action
        Object.assign(draft[templateId], { [field]: oldValue })
      })

    case ActionType.REMOVE_SUCCESS: {
      const { templateId } = action

      if (!partialState[templateId]) {
        return partialState
      }

      return produce(partialState, draft => {
        delete draft[templateId]
      })
    }

    case ActionType.CREATE_BLOCK_SUCCESS: {
      const { id, rundownId } = action.block

      // Não tem esse espelho aberto
      if (!partialState[rundownId]) {
        return partialState
      }

      const rundowns = produce(partialState, draft => {
        const { blocks } = draft[rundownId]
        blocks.splice(blocks.length - 1, 0, id)
      })

      return rundowns
    }

    case ActionType.REMOVE_BLOCK_SUCCESS: {
      const { blockId } = action

      return produce(partialState, draft => {
        // Remove o bloco do espelho
        Object.entries(draft).forEach(([_, rundown]) => {
          rundown.blocks = rundown.blocks.filter(id => id !== blockId)
        })
      })
    }

    default:
      return partialState
  }
}

function loadingReducer(
  partialState: RundownTemplatesState['loading'],
  action: RundownTemplateAction | StoryTemplateAction,
): RundownTemplatesState['loading'] {
  switch (action.type) {
    case ActionType.LOAD_REQUEST:
    case ActionType.LIST_REQUEST:
    case ActionType.CREATE_BLOCK_REQUEST:
    case ActionType.REMOVE_BLOCK_REQUEST:
    case ActionType.CREATE_STORY_REQUEST:
    case ActionType.REPAGINATE_REQUEST:
    case ActionType.REMOVE_REQUEST:
    case StoryActionType.UPDATE_REQUEST:
    case StoryActionType.REMOVE_REQUEST:
      return true
    case ActionType.LOAD_SUCCESS:
    case ActionType.LOAD_FAILURE:
    case ActionType.LIST_SUCCESS:
    case ActionType.LIST_FAILURE:
    case ActionType.CREATE_BLOCK_SUCCESS:
    case ActionType.CREATE_BLOCK_FAILURE:
    case ActionType.REMOVE_BLOCK_SUCCESS:
    case ActionType.REMOVE_BLOCK_FAILURE:
    case ActionType.CREATE_STORY_SUCCESS:
    case ActionType.CREATE_STORY_FAILURE:
    case ActionType.REPAGINATE_FAILURE:
    case ActionType.REPAGINATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
    case ActionType.REMOVE_FAILURE:
    case StoryActionType.UPDATE_SUCCESS:
    case StoryActionType.UPDATE_FAILURE:
    case StoryActionType.REMOVE_SUCCESS:
    case StoryActionType.REMOVE_FAILURE:
      return false
    default:
      return partialState
  }
}

function selectedTemplateReducer(
  partialState: RundownTemplatesState['selectedTemplate'],
  action: RundownTemplateAction | StoryTemplateAction,
  fullState: RundownTemplatesState,
): RundownTemplatesState['selectedTemplate'] {
  switch (action.type) {
    case ActionType.LOAD_SUCCESS:
    case ActionType.CREATE_SUCCESS:
      return action.template?.id
    case ActionType.REMOVE_SUCCESS:
      return undefined
    case ActionType.COPY_SUCCESS: {
      const { template } = action
      if (template.programId === fullState.selectedTemplate) {
        return template.id
      }
      return partialState
    }
    default:
      return partialState
  }
}

function listReducer(
  partialState: RundownTemplatesState['list'],
  action: RundownTemplateAction | StoryTemplateAction,
  fullState: RundownTemplatesState,
): RundownTemplatesState['list'] {
  switch (action.type) {
    case ActionType.LIST_SUCCESS:
      return action.templates
    case ActionType.REMOVE_SUCCESS:
      return partialState.filter(template => template.id !== action.templateId)
    case ActionType.CREATE_SUCCESS:
    case ActionType.COPY_SUCCESS: {
      const { template } = action
      if (template.programId === fullState.selectedProgram) {
        return [...partialState, template]
      }
      return partialState
    }
    default:
      return partialState
  }
}

function selectedProgramReducer(
  partialState: RundownTemplatesState['selectedProgram'],
  action: RundownTemplateAction | StoryTemplateAction,
): RundownTemplatesState['selectedProgram'] {
  if (action.type === ActionType.LIST_REQUEST) {
    return action.programId
  }
  return partialState
}

const targetTypes = [...Object.values(ActionType), ...Object.values(StoryActionType)]

const rundownTemplatesReducer: Reducer<
  RundownTemplatesState,
  RundownTemplateAction | StoryTemplateAction
> = (state = initialState, action): RundownTemplatesState => {
  if (action.type === ActionType.CLEAR) {
    return initialState
  }
  if (targetTypes.includes(action.type)) {
    return {
      list: listReducer(state.list, action, state),
      selectedProgram: selectedProgramReducer(state.selectedProgram, action),
      selectedTemplate: selectedTemplateReducer(state.selectedTemplate, action, state),
      loading: loadingReducer(state.loading, action),
      data: {
        templates: templatesReducer(state.data.templates, action),
        blocks: blocksReducer(state.data.blocks, action),
        stories: storiesReducer(state.data.stories, action),
      },
    }
  }

  return state
}

export default rundownTemplatesReducer
