import moment from 'moment';
// Formatos que o backend utiliza. Não envolve internacionalização!
var date = { pattern: /^\d{4}-\d{2}-\d{2}$/, format: 'YYYY-MM-DD' };
var time = {
    pattern: /^\d{2}:\d{2}(:\d{2})?(\.\d{1,})?([+-]\d{2}:\d{2})?Z?$/,
    formats: ['HH:mm:ssZ', 'HH:mm:ss'],
};
var timestamp = {
    pattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([.,]\d{1,})?([+-]\d{2}:?\d{2})?Z?$/,
    formats: ['YYYY-MM-DDTHH:mm:ssZZ', 'YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:ss'],
};
var ZERO_SECONDS = 'PT0S';
var ZERO_DAYS = 'P0D';
export function isDate(value) {
    return date.pattern.test(value);
}
export function isTime(value) {
    return time.pattern.test(value);
}
export function isTimestamp(value) {
    return timestamp.pattern.test(value);
}
export function dateToMoment(value) {
    if (!value) {
        return undefined;
    }
    if (moment.isMoment(value)) {
        return value;
    }
    if (typeof value === 'string' && date.pattern.test(value)) {
        return moment(value, date.format);
    }
    // Quando o pattern não bate exibe um erro e deixa o moment tentar converter
    console.warn("Invalid date format: " + value + ". The result can be wrong.");
    return moment(value);
}
export function timeToMoment(value) {
    if (typeof value === 'string') {
        if (time.pattern.test(value)) {
            // A conversão estava dando errada quando não tinha os segundos e tinha um offset (-03:00), então coloca os segundos ":00"
            if (/^\d{2}:\d{2}[^:]/.test(value)) {
                var fix = value.replace(/(^\d{2}:\d{2})(.*)$/, '$1:00$2');
                return moment(fix, time.formats);
            }
            return moment(value, time.formats);
        }
        // Quando o pattern não bate exibe um erro e deixa o moment tentar converter
        console.warn("Invalid time format: " + value + ". The result can be wrong.");
        return moment(value);
    }
    return undefined;
}
export function timestampToMoment(value) {
    if (typeof value === 'string') {
        if (timestamp.pattern.test(value)) {
            return moment(value, timestamp.formats);
        }
        // Quando o pattern não bate exibe um erro e deixa o moment tentar converter
        console.warn("Invalid timestamp format: " + value + ". The result can be wrong.");
        return moment(value);
    }
    return undefined;
}
export function momentToDate(value) {
    if (!value) {
        return undefined;
    }
    if (moment.isMoment(value)) {
        return value.format(date.format);
    }
    throw new Error("Invalid moment object: " + value);
}
export function momentToTime(value) {
    if (!value) {
        return undefined;
    }
    if (moment.isMoment(value)) {
        return value.format(time.formats[1]);
    }
    throw new Error("Invalid moment object: " + value);
}
export function momentToTimestamp(value) {
    if (!value) {
        return undefined;
    }
    if (moment.isMoment(value)) {
        return value.format(timestamp.formats[1]);
    }
    throw new Error("Invalid moment object: " + value);
}
export function todayDate() {
    return momentToDate(moment());
}
export function displayDate(value) {
    var m = dateToMoment(value);
    return m ? m.format('L') : undefined;
}
export function displayTime(value, displaySeconds) {
    if (displaySeconds === void 0) { displaySeconds = false; }
    if (!value) {
        return undefined;
    }
    var m = moment.isMoment(value) ? value : timeToMoment(value);
    return m.format(displaySeconds ? 'LTS' : 'LT');
}
export function displayTimestamp(value, displaySeconds) {
    if (displaySeconds === void 0) { displaySeconds = false; }
    if (!value) {
        return undefined;
    }
    var m = moment.isMoment(value) ? value : timestampToMoment(value);
    return m.format(displaySeconds ? 'L LTS' : 'L LT');
}
export function secondsToMoment(value) {
    if (typeof value === 'number') {
        return moment().startOf('day').seconds(value);
    }
    if (moment.isDuration(value)) {
        return moment.utc(value.as('milliseconds'));
    }
    if (moment.isMoment(value)) {
        return value;
    }
    return undefined;
}
export function momentToSeconds(value) {
    if (moment.isMoment(value)) {
        var midnight = moment().startOf('day');
        return value.diff(midnight, 'seconds');
    }
    return undefined;
}
export function durationToMoment(value) {
    if (typeof value === 'string') {
        return moment.utc(moment.duration(value).as('milliseconds'));
    }
    if (moment.isDuration(value)) {
        return moment.utc(value.as('milliseconds'));
    }
    if (moment.isMoment(value)) {
        return value;
    }
    return undefined;
}
export function momentToDuration(value) {
    if (!moment.isMoment(value)) {
        return undefined;
    }
    var millis = value.valueOf();
    var duration = moment.duration(millis, 'milliseconds');
    return duration.toISOString();
}
export function formatIsoDuration(value, format) {
    var negative = false;
    var m;
    /*
     * No momento a formatação não é suportada para objetos "duration" (pelo menos
     * não no formado de hora, minutos e segundos), então converte para um objeto
     * "moment" para então formatar.
     *
     * No caso de uma duração negativa, ela vem na forma "PT-13M-1S", por exemplo,
     * que deve resultar em "-00:13:01". Ao converter uma duração negativa para um
     * objeto "moment", ele vai jogar para uma hora do dia anterior. Por isso
     * remove os hífens antes da conversão.
     */
    if (typeof value === 'string') {
        negative = value.includes('-');
        m = durationToMoment(value.replace(/-/g, ''));
    }
    else {
        m = durationToMoment(value);
    }
    return moment.isMoment(m)
        ? "" + (negative ? '-' : '') + m.format(format)
        : undefined;
}
export function formatSeconds(value, format) {
    var negative = value < 0;
    var total = Math.abs(value);
    var hours = Math.floor(total / 3600);
    var minutes = Math.floor((total - hours * 3600) / 60);
    var seconds = Math.floor(total - hours * 3600 - minutes * 60);
    var millis = Math.floor((total - hours * 3600 - minutes * 60 - seconds) * 1000);
    var result = negative ? '-' : '';
    if (/HH/.test(format)) {
        result += (hours < 10 ? '0' : '') + hours + ":";
    }
    if (/mm/.test(format)) {
        result += (minutes < 10 ? '0' : '') + minutes;
    }
    if (/ss/.test(format)) {
        result += ":" + (seconds < 10 ? '0' : '') + seconds;
    }
    if (/SSS/.test(format)) {
        result += "." + (millis < 10 ? '00' : millis < 100 ? '0' : '') + millis;
    }
    return result;
}
export function displayToIsoDuration(value, format) {
    if (!value || !/^\d{2}:\d{2}:?\d{0,2}$/.test(value)) {
        return value;
    }
    // moment.duration() aceita HH:mm:ss e HH:mm
    var ajusted = value;
    if (format === 'mm:ss') {
        ajusted = "00:" + value;
    }
    if (format === 'HH:mm') {
        ajusted = value + ":00";
    }
    var duration = moment.duration(ajusted);
    return duration.toISOString();
}
export function displayToSeconds(value, format) {
    if (!value || !/^-?\d{2}:\d{2}:?\d{0,2}$/.test(value)) {
        return 0;
    }
    var negative = value.startsWith('-');
    var ajusted = negative ? value.substr(1) : value;
    if (format === 'mm:ss') {
        ajusted = "00:" + ajusted;
    }
    if (format === 'HH:mm') {
        ajusted = ajusted + ":00";
    }
    var tokens = ajusted.split(':');
    var hours = parseInt(tokens[0], 10);
    var minutes = parseInt(tokens[1], 10);
    var seconds = parseInt(tokens[2], 10);
    return ((hours * 60 + minutes) * 60 + seconds) * (negative ? -1 : 1);
}
export function isDurZero(duration) {
    return duration === ZERO_DAYS || duration === ZERO_SECONDS;
}
export function sumDurations(durations) {
    try {
        var result = durations
            .map(function (str) { return moment.duration(str || ZERO_SECONDS); })
            .reduce(function (acc, curr) { return acc.add(curr); }, moment.duration(0))
            .toISOString();
        // Retorna PT0S no lugar de P0D para não causar diff nos formulários
        return result === ZERO_DAYS ? ZERO_SECONDS : result;
    }
    catch (error) {
        console.error(error);
        return ZERO_SECONDS;
    }
}
export function subtractDurations(durations) {
    try {
        var result = durations
            .map(function (str) { return moment.duration(str || ZERO_SECONDS); })
            .reduce(function (acc, curr) { return acc.subtract(curr); })
            .toISOString();
        // Retorna PT0S no lugar de P0D para não causar diff nos formulários
        return result === ZERO_DAYS ? ZERO_SECONDS : result;
    }
    catch (error) {
        console.error(error);
        return ZERO_SECONDS;
    }
}
export function compareDurations(a, b) {
    var millisA = (moment.isDuration(a) ? a : moment.duration(a || ZERO_SECONDS)).asMilliseconds();
    var millisB = (moment.isDuration(b) ? b : moment.duration(b || ZERO_SECONDS)).asMilliseconds();
    return millisA === millisB ? 0 : millisA > millisB ? 1 : -1;
}
export function secondsToIsoDuration(value) {
    if (value === void 0) { value = 0; }
    var negative = value < 0;
    /*
     * Nos cálculos de tempo de leitura aconteceu de gerar valores com muitas casas
     * após a vírgula. Um exemplo real: 44.940000000000005
     *
     * Então utiliza o toFixed(3) para limitar a 3 casas.
     */
    var seconds = Math.abs(value)
        .toFixed(3)
        .replace(/\.?0+$/, '');
    return (negative ? '-' : '') + "PT" + seconds + "S";
    // const seconds = Math.round(value)
    // // Modifica função usada aqui pois de forma manual estava calculando de forma incorreta com o que é calculado no backend.
    // // Ex: 610s no Frontend estava aparecendo PT610S, e no backend era calculado como PT10M10S, aí dava erro em comparar as mudanças.
    // return seconds === 0 ? ZERO_SECONDS : moment.duration(seconds, 'seconds').toISOString()
}
export function roundIsoDuration(value) {
    try {
        var tokens = value.match(/\d+\.\d+/);
        return tokens ? value.replace(tokens[0], "" + Math.round(parseFloat(tokens[0]))) : value;
    }
    catch (error) {
        console.error(error);
        return value;
    }
}
