import { del, get, put } from './client';
var endpoint = '/api/locks';
var entityLockApi = {
    activeLocks: function () {
        return get(endpoint);
    },
    renewLock: function (uuid) {
        return put(endpoint + "/" + uuid);
    },
    takeOver: function (uuid) {
        return put(endpoint + "/" + uuid + "/take_over");
    },
    unlock: function (uuid) {
        return del(endpoint + "/" + uuid);
    },
};
export default entityLockApi;
