import { Reducer } from 'redux'

import { TwitterUser } from '@anews/types'

import {
  TwitterUserActionType as ActionType,
  TwitterUserAction,
} from '../actions/twitteruser-actions'

export interface TwitterUsersListState {
  data: TwitterUser[]
  loading: boolean
}

export interface TwitterUsersState {
  list: TwitterUsersListState
  editing?: TwitterUser
  saving: boolean
  removing: boolean
}

export const initialState: TwitterUsersState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(
  list: TwitterUsersListState,
  action: TwitterUserAction,
): TwitterUsersListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.users,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.user, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(user => (user.id === action.user.id ? action.user : user)),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(user => user.id !== action.id) }

    default:
      return list
  }
}

const usersReducer: Reducer<TwitterUsersState, TwitterUserAction> = (
  state = initialState,
  action,
): TwitterUsersState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT:
      return { ...state, editing: action.user }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.user, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default usersReducer
