import { v4 as uuidV4 } from 'uuid'
import { Reducer } from 'redux'
import produce from 'immer'

import { Guideline, GuidelineConfig } from '@anews/types'
import { previous, isNew, todayDate } from '@anews/utils'

import { ActionType, GuidelineAction } from '../actions/guideline-actions'

import { ConfigState } from './types'

/*
 *  TODO: Refatorar esse reducer para deixar no estilo do checklist-reducer
 *
 *  Esse tá muito desorganizado e confuso
 */

const defaultTabUuid = uuidV4()
const today = todayDate()

interface ListTab {
  type: 'list'
  uuid: string
  date?: string
  programs: number[]
  allPrograms: boolean
  guidelines: number[]
}

interface FormTab {
  type: 'form'
  uuid: string
  guideline: Guideline
}

export type GuidelineTab = ListTab | FormTab

export interface GuidelinesState {
  activeTab: string
  tabs: GuidelineTab[]
  guidelines: { [guidelineId: number]: Guideline }
  viewing?: Guideline
  loading: boolean
  saving: boolean
  disabling: boolean
  config: ConfigState<GuidelineConfig>
}

function newListTab(uuid: string, date?: string): ListTab {
  return {
    uuid,
    date,
    type: 'list',
    programs: [],
    allPrograms: true,
    guidelines: [],
  }
}

function newFormTab(guideline: Guideline): FormTab {
  return { type: 'form', uuid: guideline.uuid, guideline }
}

export const defaultState: GuidelinesState = {
  tabs: [newListTab(defaultTabUuid, today)],
  guidelines: [],
  activeTab: defaultTabUuid,
  viewing: undefined,
  loading: false,
  saving: false,
  disabling: false,
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
}

function configReducer(
  config: ConfigState<GuidelineConfig>,
  action: GuidelineAction,
): ConfigState<GuidelineConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

const guidelinesReducer: Reducer<GuidelinesState, GuidelineAction> = (
  state = defaultState,
  action,
): GuidelinesState => {
  switch (action.type) {
    case ActionType.NEW_TAB: {
      const tabUuid = uuidV4()
      return { ...state, activeTab: tabUuid, tabs: [...state.tabs, newListTab(tabUuid)] }
    }

    case ActionType.CHANGE_TAB:
      return { ...state, activeTab: action.uuid }

    case ActionType.CLOSE_TAB: {
      let { activeTab } = state
      // Se fechou a tab corrente, ativa a tab anterior
      if (activeTab === action.uuid) {
        const previousTab = previous<GuidelineTab>(state.tabs, tab => tab.uuid === action.uuid)
        if (previousTab) {
          activeTab = previousTab.uuid
        } else if (state.tabs.length > 0) {
          activeTab = state.tabs[0].uuid
        }
      }
      return { ...state, activeTab, tabs: state.tabs.filter(tab => tab.uuid !== action.uuid) }
    }

    case ActionType.DATE_LIST_REQUEST:
      return produce(state, draft => {
        draft.loading = true

        for (const tab of draft.tabs) {
          if (tab.type === 'list' && tab.uuid === action.uuid) {
            tab.date = action.date
            tab.programs = action.programs
            tab.allPrograms = action.allPrograms
          }
        }
      })

    case ActionType.DATE_LIST_SUCCESS:
      return produce(state, draft => {
        draft.loading = false

        for (const tab of draft.tabs) {
          if (tab.type === 'list' && tab.uuid === action.uuid) {
            tab.guidelines = action.guidelines.map(guideline => guideline.id)
          }
        }

        action.guidelines.forEach(guideline => {
          draft.guidelines[guideline.id] = guideline
        })
      })

    case ActionType.DATE_LIST_FAILURE:
      return { ...state, loading: false }

    case ActionType.NEW: {
      // No cadastro, procura pela tab que tenha uma pauta não salva
      const match = state.tabs.find(
        tab => tab.type === 'form' && tab.guideline && isNew(tab.guideline),
      )
      if (match) {
        return { ...state, activeTab: match.uuid }
      }
      return {
        ...state,
        activeTab: action.guideline.uuid!,
        tabs: [...state.tabs, newFormTab(action.guideline)],
      }
    }

    // Reinicia o state. Chamado ao fechar a área de reportagens.
    case ActionType.CLEAR: {
      const { tabs, viewing } = defaultState
      return { ...state, tabs, viewing }
    }

    case ActionType.UNLOAD:
      return { ...state, viewing: undefined }

    case ActionType.LOAD_SUCCESS: {
      const { guideline } = action

      if (!action.edit) {
        return { ...state, viewing: guideline }
      }
      // Na edição procura pelo uuid igual (optei por não usar ID aqui)
      const match = state.tabs.find(tab => tab.uuid === guideline.uuid)
      if (match) {
        return { ...state, viewing: undefined, activeTab: match.uuid }
      }
      return produce(state, draft => {
        draft.viewing = undefined
        draft.activeTab = guideline.uuid!
        draft.tabs.push(newFormTab(guideline))
        draft.guidelines[guideline.id] = guideline
      })
    }

    case ActionType.UPDATE_FORM:
      // Procura e atualiza o form com os dados alterados da pauta pelo usuário
      return {
        ...state,
        tabs: state.tabs.map(tab =>
          tab.type === 'list' || tab.uuid !== action.uuid
            ? tab
            : { ...tab, guideline: { ...tab.guideline, ...action.changes } },
        ),
      }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      // Atualiza o formulário aberto
      return {
        ...state,
        tabs: state.tabs.map(tab =>
          tab.uuid !== action.guideline.uuid ? tab : { ...tab, guideline: action.guideline },
        ),
        saving: true,
      }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.WS_CREATE:
    case ActionType.WS_UPDATE:
      /*
       * - Pode ter mais de uma listagem aberta, na mesma data ou não
       * - Para uma nova pauta, tem que ver se tem listas abertas com a data dela e adicionar
       * - Para a pauta editada, tem que atualizar seus dados caso esteja na mesma data
       * - Se a data da pauta foi alterada, remove das listas com a data antiga (se tiver)
       * - Se tiver listas com a data nova, adiciona a pauta nelas
       */
      return produce(state, draft => {
        const { guideline } = action
        const current = state.guidelines[guideline.id]

        const dateChanged = current && current.date !== guideline.date

        // Atualiza o formulário aberto
        for (const tab of draft.tabs) {
          if (tab.type === 'form' && tab.uuid === guideline.uuid) {
            // Verifica se a atualização é somente da produção
            if (action.type === ActionType.WS_UPDATE && action.onlyUpdateProduction === true) {
              tab.guideline = { ...current, production: guideline.production }
            } else {
              tab.guideline = guideline
            }
          }
          if (tab.type === 'list') {
            // Se a data da tab é a data antiga, remove o ID da pauta
            if (dateChanged && tab.date === current.date) {
              tab.guidelines.splice(tab.guidelines.indexOf(current.id), 1)
            }
            // Se a data da tab é a data nova, adiciona o ID da pauta
            if (tab.date === guideline.date && !tab.guidelines.includes(guideline.id)) {
              tab.guidelines.push(guideline.id)
            }
          }
        }

        draft.guidelines[guideline.id] = guideline
        draft.saving = false
      })

    case ActionType.REMOVE_SUCCESS:
    case ActionType.WS_DELETE:
      return {
        ...state,
        viewing: state.viewing && action.ids.includes(state.viewing.id) ? undefined : state.viewing,
        tabs: state.tabs
          // fecha formulários das pautas excluídas
          .filter(tab => tab.type === 'list' || !action.ids.includes(tab.guideline.id))
          // retira pautas excluídas das listas abertas
          .map(tab =>
            tab.type === 'form'
              ? tab
              : {
                  ...tab,
                  guidelines: tab.guidelines.filter(id => !action.ids.includes(id)),
                },
          ),
      }

    case ActionType.PATCH_REQUEST:
      return produce(state, draft => {
        // Atualização otimista (não espera o sucesso para atualizar o state)
        const { guidelineId, field, newValue } = action
        Object.assign(draft.guidelines[guidelineId], { [field]: newValue })
      })

    case ActionType.PATCH_FAILURE:
      // Se o patch falhar, retorna o valor antigo
      return produce(state, draft => {
        const { guidelineId, field, oldValue } = action
        Object.assign(draft.guidelines[guidelineId], { [field]: oldValue })
      })

    case ActionType.WS_PATCH:
      return produce(state, draft => {
        const { guidelineId, changes } = action
        if (draft.guidelines[guidelineId]) {
          Object.assign(draft.guidelines[guidelineId], changes)
        }
      })

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.REMOVE_REQUEST:
      return { ...state, disabling: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, disabling: false }

    case ActionType.LOAD_CONFIG_REQUEST:
    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...state, config: configReducer(state.config, action) }

    default:
      return state
  }
}

export default guidelinesReducer
