import { call, put, takeLatest } from 'redux-saga/effects'

import { guidelineApi as api } from '@anews/api'
import { Guideline, GuidelineConfig } from '@anews/types'

import { isNew } from '@anews/utils'

import {
  ActionType,
  DateListGuidelineAction,
  dateListGuidelinesSuccess,
  dateListGuidelinesFailure,
  LoadGuidelineAction,
  loadGuidelineSuccess,
  loadGuidelineFailure,
  CreateGuidelineAction,
  createGuidelineSuccess,
  createGuidelineFailure,
  UpdateGuidelineAction,
  updateGuidelineSuccess,
  updateGuidelineFailure,
  RemoveGuidelinesAction,
  removeGuidelinesSuccess,
  removeGuidelinesFailure,
  PatchGuidelineAction,
  patchGuidelineFailure,
  patchGuidelineSuccess,
  ChangeStateGuidelineAction,
  changeStateGuidelineSuccess,
  changeStateGuidelineFailure,
  loadConfigSuccess,
  loadConfigFailure,
  CreateGuidelineConfigAction,
  UpdateGuidelineConfigAction,
  createConfigSuccess,
  createConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
} from '../actions/guideline-actions'
import { EntityLockActions, NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { renewLock } = EntityLockActions
const { notifyError, notifySuccess } = NotificationActions

/* Watchers */

function* dateListGuidelinesSaga(action: DateListGuidelineAction): Generator {
  try {
    const guidelines = yield call(
      api.dateList,
      action.date,
      action.programs,
      action.allPrograms,
      action.drawer,
    )
    yield put(
      dateListGuidelinesSuccess(
        guidelines as Guideline[],
        action.uuid,
        action.date,
        action.programs,
        action.allPrograms,
        action.drawer,
      ),
    )
  } catch (error) {
    yield put(dateListGuidelinesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadGuidelineSaga(action: LoadGuidelineAction): Generator {
  try {
    if (typeof action.target === 'number') {
      const guideline = yield call(api.load, action.target, action.edit)
      yield put(loadGuidelineSuccess(guideline as Guideline, action.edit))
    } else {
      const guideline = action.target
      if (!isNew(guideline)) {
        yield put(renewLock(guideline.uuid))
      }
      yield put(loadGuidelineSuccess(guideline, action.edit))
    }
  } catch (error) {
    yield put(loadGuidelineFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createGuidelineSaga(action: CreateGuidelineAction): Generator {
  try {
    const guideline = yield call(api.create, action.guideline, action.lock)
    yield put(createGuidelineSuccess(guideline as Guideline))
  } catch (error) {
    yield put(createGuidelineFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateGuidelineSaga(action: UpdateGuidelineAction): Generator {
  try {
    const guideline = yield call(api.update, action.guideline)
    yield put(updateGuidelineSuccess(guideline as Guideline))
  } catch (error) {
    yield put(updateGuidelineFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeGuidelinesSaga(action: RemoveGuidelinesAction): Generator {
  try {
    const ids = yield call(api.remove, action.ids)
    yield put(removeGuidelinesSuccess(ids as number[]))
  } catch (error) {
    yield put(removeGuidelinesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

function* patchGuidelineSaga(action: PatchGuidelineAction): Generator {
  try {
    yield call(api.updateField, action.guidelineId, action.field, action.newValue)
    yield put(patchGuidelineSuccess())
  } catch (error) {
    yield put(
      patchGuidelineFailure(
        error,
        action.guidelineId,
        action.field,
        action.newValue,
        action.oldValue,
      ),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* changeStateGuidelineSaga(action: ChangeStateGuidelineAction): Generator {
  try {
    yield call(api.updateState, action.guidelinesIds, action.state)
    yield put(changeStateGuidelineSuccess())
  } catch (error) {
    yield put(changeStateGuidelineFailure(error, action.guidelinesIds, action.state))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

//
//  Config
//

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as GuidelineConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: CreateGuidelineConfigAction): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as GuidelineConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: UpdateGuidelineConfigAction): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as GuidelineConfig))
    yield put(
      notifySuccess({
        message: i18n.t('words:success'),
        description: i18n.t('config:updateSuccess'),
      }),
    )
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.DATE_LIST_REQUEST, dateListGuidelinesSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_REQUEST, loadGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.PATCH_REQUEST, patchGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.CHANGE_STATE_REQUEST, changeStateGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeGuidelinesSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
