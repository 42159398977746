import { RssCategory } from '@anews/types'

export enum RssCategoryActionType {
  NEW = '@rss_category/NEW',
  EDIT = '@rss_category/EDIT',
  LIST_REQUEST = '@rss_category/LIST_REQUEST',
  LIST_SUCCESS = '@rss_category/LIST_SUCCESS',
  LIST_FAILURE = '@rss_category/LIST_FAILURE',
  CREATE_REQUEST = '@rss_category/CREATE_REQUEST',
  CREATE_SUCCESS = '@rss_category/CREATE_SUCCESS',
  CREATE_FAILURE = '@rss_category/CREATE_FAILURE',
  UPDATE_REQUEST = '@rss_category/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@rss_category/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@rss_category/UPDATE_FAILURE',
  REMOVE_REQUEST = '@rss_category/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@rss_category/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@rss_category/REMOVE_FAILURE',
}

export type RssCategoryActionMap<T extends RssCategoryActionType> = { type: T } & RssCategoryAction

export type RssCategoryAction = ReturnType<
  typeof RssCategoryActions[keyof typeof RssCategoryActions]
>

export const RssCategoryActions = {
  //
  //	newRssCategory()
  //

  newRssCategory: (category?: RssCategory) => <const>{ type: RssCategoryActionType.NEW, category },

  //
  //	listRssCategories()
  //

  listRssCategories: (feeds: boolean) => <const>{ type: RssCategoryActionType.LIST_REQUEST, feeds },

  listRssCategoriesSuccess: (categories: RssCategory[]) =>
    <const>{ type: RssCategoryActionType.LIST_SUCCESS, categories },

  listRssCategoriesFailure: (error: Error) =>
    <const>{ type: RssCategoryActionType.LIST_FAILURE, error },

  //
  //	editRssCategory()
  //

  editRssCategory: (category: RssCategory) => <const>{ type: RssCategoryActionType.EDIT, category },

  //
  //	createRssCategory()
  //

  createRssCategory: (category: RssCategory) =>
    <const>{ type: RssCategoryActionType.CREATE_REQUEST, category },

  createRssCategorySuccess: (category: RssCategory) =>
    <const>{ type: RssCategoryActionType.CREATE_SUCCESS, category },

  createRssCategoryFailure: (error: Error) =>
    <const>{ type: RssCategoryActionType.CREATE_FAILURE, error },

  //
  //	updateRssCategory()
  //

  updateRssCategory: (category: RssCategory) =>
    <const>{ type: RssCategoryActionType.UPDATE_REQUEST, category },

  updateRssCategorySuccess: (category: RssCategory) =>
    <const>{ type: RssCategoryActionType.UPDATE_SUCCESS, category },

  updateRssCategoryFailure: (error: Error) =>
    <const>{ type: RssCategoryActionType.UPDATE_FAILURE, error },

  //
  //	removeRssCategory()
  //

  removeRssCategory: (id: number) => <const>{ type: RssCategoryActionType.REMOVE_REQUEST, id },

  removeRssCategorySuccess: (id: number) =>
    <const>{ type: RssCategoryActionType.REMOVE_SUCCESS, id },

  removeRssCategoryFailure: (error: Error) =>
    <const>{ type: RssCategoryActionType.REMOVE_FAILURE, error },
}
