import React, { memo, useCallback } from 'react'

import Col from 'antd/es/col'
import Row from 'antd/es/row'

import { Descendant } from 'slate'

import {
  APPEARANCE,
  ART,
  INTERVIEW,
  OFF,
  SOUND_UP,
  ReportageSection,
  RichTextBlock,
} from '@anews/types'

import { useTranslation } from '../../../i18n'

import ReportageText from './ReportageText'

interface Props {
  value: ReportageSection
  number: string
  tvType?: boolean
  onChange: (section: ReportageSection) => void
  onRemove: (sectionConst: string) => void
  onToggleCase: (uuid: string, prop: 'content' | 'observation') => void
  onDurationChange: (
    uuid: string,
    manual: boolean,
    duration: string,
    text?: RichTextBlock[],
  ) => void
  onMoveUp?: (uuid: string) => void
  onMoveDown?: (uuid: string) => void
}

function ReportageSectionInput({
  value,
  number,
  tvType,
  onRemove,
  onToggleCase,
  onDurationChange,
  onChange,
  onMoveUp,
  onMoveDown,
}: Props) {
  const { t } = useTranslation()

  let { duration } = value
  const { uuid, type, manual } = value

  switch (type) {
    // Seções que não contam tempo
    case ART:
    case SOUND_UP:
      duration = undefined
      break
    default:
      break
  }

  // Para o tipo "INTERVIEW" os campos são invertidos: content ficá à esquerda e observation à direita
  const [leftProp, rightProp]: ['content' | 'observation', 'content' | 'observation'] =
    type !== INTERVIEW ? ['observation', 'content'] : ['content', 'observation']

  let leftHeader = ''
  let rightHeader = ''

  // Pega headers da internacionalização, adicionando o número da seção na frente
  switch (type) {
    case APPEARANCE:
      leftHeader = t('words:local')
      rightHeader = `${t(tvType ? 'reportage:appearance' : 'reportage:loc')} ${number}`
      break
    case ART:
      leftHeader = t('reportage:data')
      rightHeader = `${t('reportage:art')} ${number}`
      break
    case INTERVIEW:
      leftHeader = t('reportage:interviewee')
      rightHeader = `${t('reportage:interview')} ${number} - ${t('reportage:quote')}`
      break
    case OFF:
      leftHeader = t('reportage:indications')
      rightHeader = `${t('reportage:off')} ${number}`
      break
    case SOUND_UP:
      leftHeader = t('reportage:indications')
      rightHeader = `${t('reportage:soundup')} ${number}`
      break
    default:
      break
  }

  const handleChange = useCallback(
    (content: Descendant[], prop: 'content' | 'observation') => {
      // if (text !== value[prop]) {
      onChange({ ...value, [prop]: content })
      // }
    },
    [onChange, value],
  )

  const handleLeftChange = useCallback(
    (content: Descendant[]) => {
      handleChange(content, leftProp)
    },
    [handleChange, leftProp],
  )

  const handleRightChange = useCallback(
    (content: Descendant[]) => {
      handleChange(content, rightProp)
    },
    [handleChange, rightProp],
  )

  const handleDurationChange = useCallback(
    (m: boolean, dur: string) => {
      onDurationChange(uuid, m, dur, value[rightProp])
    },
    [onDurationChange, rightProp, uuid, value],
  )
  const handleRemove = useCallback(() => {
    onRemove(uuid)
  }, [onRemove, uuid])

  const handleMoveDown = useCallback(() => {
    onMoveDown && onMoveDown(uuid)
  }, [onMoveDown, uuid])

  const handleMoveUp = useCallback(() => {
    onMoveUp && onMoveUp(uuid)
  }, [onMoveUp, uuid])

  return (
    <Row gutter={24}>
      <Col span={9}>
        <ReportageText header={leftHeader} value={value[leftProp]} onChange={handleLeftChange} />
      </Col>
      <Col span={15}>
        <ReportageText
          header={rightHeader}
          value={value[rightProp]}
          duration={duration}
          manual={manual}
          onChange={handleRightChange}
          onDurationChange={handleDurationChange}
          onRemove={handleRemove}
          onMoveUp={onMoveUp && handleMoveUp}
          onMoveDown={onMoveDown && handleMoveDown}
        />
      </Col>
    </Row>
  )
}

export default memo(ReportageSectionInput)
