var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get, post, put, del, query } from './client';
var endpoint = '/api/branches';
var companyApi = {
    list: function () {
        return get(endpoint);
    },
    load: function (id) {
        return get(query(endpoint + "/" + id));
    },
    create: function (company) {
        return post(endpoint, company);
    },
    update: function (company) {
        return put(endpoint, company);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
    // Métodos de Consultas Remotas.
    loadRemoteGuidelines: function (id, params, page, size) {
        return get(query(endpoint + "/" + id + "/assignments", __assign(__assign({}, params), { page: page, size: size })));
    },
    loadRemoteGuidelineById: function (id, guidelineId) {
        return get(endpoint + "/" + id + "/assignments/" + guidelineId);
    },
    copyRemoteGuideline: function (id, date, guidelinesIds, programsIds, producerId) {
        return get(query(endpoint + "/" + id + "/assignments/copy", {
            guidelinesIds: guidelinesIds,
            programsIds: programsIds,
            date: date,
            producerId: producerId,
        }));
    },
    loadRemoteReportages: function (id, params, page, size) {
        return get(query(endpoint + "/" + id + "/news_reports", __assign(__assign({}, params), { page: page, size: size })));
    },
    loadRemoteReportageById: function (id, reportageId) {
        return get(endpoint + "/" + id + "/news_reports/" + reportageId);
    },
    copyRemoteReportage: function (id, date, reportagesIds, programsIds, reporterId) {
        return get(query(endpoint + "/" + id + "/news_reports/copy", {
            reportagesIds: reportagesIds,
            date: date,
            programsIds: programsIds,
            reporterId: reporterId,
        }));
    },
    loadRemoteStories: function (id, params, page, size) {
        return get(query(endpoint + "/" + id + "/stories", __assign(__assign({}, params), { page: page, size: size })));
    },
    loadRemoteStoryById: function (id, storyId) {
        return get(endpoint + "/" + id + "/stories/" + storyId);
    },
    copyRemoteStories: function (id, storiesIds, date, programId) {
        return get(query(endpoint + "/" + id + "/stories/copy", {
            storiesIds: storiesIds,
            date: date,
            programId: programId,
        }));
    },
    loadRemoteNews: function (id, params, page, size) {
        return get(query(endpoint + "/" + id + "/assignment_proposals", __assign(__assign({}, params), { page: page, size: size })));
    },
    copyRemoteNews: function (id, newsIds) {
        return get(query(endpoint + "/" + id + "/assignment_proposals/copy", {
            newsIds: newsIds,
        }));
    },
    loadRemoteRundown: function (id, date, programId) {
        return get(query(endpoint + "/" + id + "/rundowns", { date: date, programId: programId }));
    },
    checkRemoteRundown: function (id, date, programId) {
        return get(query(endpoint + "/" + id + "/rundowns/check", { date: date, programId: programId }));
    },
    // Método de consulta aos dados adicionais
    loadCompanyData: function (id, info) {
        return get(endpoint + "/" + id + "/" + info);
    },
    checkCompany: function (id) {
        return get(endpoint + "/" + id + "/check");
    },
    pageFilterRemoteNotice: function (id, page, size) {
        return get(query(endpoint + "/" + id + "/notices", { page: page, size: size }));
    },
    createRemoteNotice: function (id, notice) {
        return post(endpoint + "/" + id + "/notices", notice);
    },
};
export default companyApi;
