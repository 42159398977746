import React, { PureComponent } from 'react'

import Button, { ButtonProps } from './Button'

class TableButton extends PureComponent<ButtonProps> {
  render() {
    const { children, ...props } = this.props

    return (
      <Button type="link" height="auto" m={0} p={0} {...props}>
        {children}
      </Button>
    )
  }
}

export default TableButton
