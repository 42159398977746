import { del, get, query, post, put } from './client';
var endpoint = '/api/rounds';
var roundApi = {
    dateList: function (date) {
        return get(query(endpoint, { date: date }));
    },
    load: function (id) {
        return get(query(endpoint + "/" + id));
    },
    create: function (round) {
        return post(endpoint, round);
    },
    update: function (round) {
        return put(endpoint, round);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
};
export default roundApi;
