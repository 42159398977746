import { Reducer } from 'redux'

import { DocumentType } from '@anews/types'

import { TypeAction, TypeActionType } from '../actions'

export interface TypesListState {
  data: DocumentType[]
  loading: boolean
}

export interface TypesState {
  list: TypesListState
}

export const initialState: TypesState = {
  list: {
    data: [],
    loading: true,
  },
}

function listReducer(list: TypesListState, action: TypeAction): TypesListState {
  switch (action.type) {
    case TypeActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case TypeActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case TypeActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.types,
        loading: false,
      }

    default:
      return list
  }
}

const typesReducer: Reducer<TypesState, TypeAction> = (
  state = initialState,
  action,
): TypesState => {
  switch (action.type) {
    case TypeActionType.LIST_REQUEST:
    case TypeActionType.LIST_FAILURE:
    case TypeActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    default:
      return state
  }
}

export default typesReducer
