import { Block, RundownTemplate, Story } from '@anews/types'

export enum RundownTemplateActionType {
  CLEAR = '@template/CLEAR',
  // listTemplates()
  LIST_REQUEST = '@template/LIST_REQUEST',
  LIST_SUCCESS = '@template/LIST_SUCCESS',
  LIST_FAILURE = '@template/LIST_FAILURE',
  // loadTemplate()
  LOAD_REQUEST = '@template/LOAD_REQUEST',
  LOAD_SUCCESS = '@template/LOAD_SUCCESS',
  LOAD_FAILURE = '@template/LOAD_FAILURE',
  // createTemplate()
  CREATE_REQUEST = '@template/CREATE_REQUEST',
  CREATE_SUCCESS = '@template/CREATE_SUCCESS',
  CREATE_FAILURE = '@template/CREATE_FAILURE',
  // copyTemplate()
  COPY_REQUEST = '@template/COPY_REQUEST',
  COPY_SUCCESS = '@template/COPY_SUCCESS',
  COPY_FAILURE = '@template/COPY_FAILURE',
  // patchTemplate()
  PATCH_REQUEST = '@template/PATCH_REQUEST',
  PATCH_SUCCESS = '@template/PATCH_SUCCESS',
  PATCH_FAILURE = '@template/PATCH_FAILURE',
  // removeTemplate()
  REMOVE_REQUEST = '@template/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@template/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@template/REMOVE_FAILURE',
  // create block
  CREATE_BLOCK_REQUEST = '@template/CREATE_BLOCK_REQUEST',
  CREATE_BLOCK_SUCCESS = '@template/CREATE_BLOCK_SUCCESS',
  CREATE_BLOCK_FAILURE = '@template/CREATE_BLOCK_FAILURE',
  // patchBlock()
  PATCH_BLOCK_REQUEST = '@template/PATCH_BLOCK_REQUEST',
  PATCH_BLOCK_SUCCESS = '@template/PATCH_BLOCK_SUCCESS',
  PATCH_BLOCK_FAILURE = '@template/PATCH_BLOCK_FAILURE',
  // removeBlock()
  REMOVE_BLOCK_REQUEST = '@template/REMOVE_BLOCK_REQUEST',
  REMOVE_BLOCK_SUCCESS = '@template/REMOVE_BLOCK_SUCCESS',
  REMOVE_BLOCK_FAILURE = '@template/REMOVE_BLOCK_FAILURE',
  // create story
  CREATE_STORY_REQUEST = '@template/CREATE_STORY_REQUEST',
  CREATE_STORY_SUCCESS = '@template/CREATE_STORY_SUCCESS',
  CREATE_STORY_FAILURE = '@template/CREATE_STORY_FAILURE',
  // copy stories
  COPY_STORIES_REQUEST = '@template/COPY_STORIES_REQUEST',
  COPY_STORIES_SUCCESS = '@template/COPY_STORIES_SUCCESS',
  COPY_STORIES_FAILURE = '@template/COPY_STORIES_FAILURE',
  // move story
  MOVE_STORY_REQUEST = '@template/MOVE_STORY_REQUEST',
  MOVE_STORY_SUCCESS = '@template/MOVE_STORY_SUCCESS',
  MOVE_STORY_FAILURE = '@template/MOVE_STORY_FAILURE',
  // repaginate stories
  REPAGINATE_REQUEST = '@template/REPAGINATE_REQUEST',
  REPAGINATE_SUCCESS = '@template/REPAGINATE_SUCCESS',
  REPAGINATE_FAILURE = '@template/REPAGINATE_FAILURE',
  // websocket
  WS_PATCH_BLOCK = '@template/WS_PATCH_BLOCK',
}

export type RundownTemplateActionMap<T extends RundownTemplateActionType> = {
  type: T
} & RundownTemplateAction

export type RundownTemplateAction = ReturnType<
  typeof RundownTemplateActions[keyof typeof RundownTemplateActions]
>

export const RundownTemplateActions = {
  //
  //  clear()
  //

  clearState: () => <const>{ type: RundownTemplateActionType.CLEAR },

  //
  //	listTemplates()
  //
  listTemplates: (programId: number) =>
    <const>{ type: RundownTemplateActionType.LIST_REQUEST, programId },
  listTemplatesSuccess: (templates: RundownTemplate[]) =>
    <const>{ type: RundownTemplateActionType.LIST_SUCCESS, templates },
  listTemplatesFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.LIST_FAILURE, error },

  //
  //	loadTemplate()
  //
  loadTemplate: (id?: number) => <const>{ type: RundownTemplateActionType.LOAD_REQUEST, id },
  loadTemplateSuccess: (template?: RundownTemplate) =>
    <const>{ type: RundownTemplateActionType.LOAD_SUCCESS, template },
  loadTemplateFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.LOAD_FAILURE, error },

  //
  //	createTemplate()
  //
  createTemplate: (name: string, programId: number) =>
    <const>{ type: RundownTemplateActionType.CREATE_REQUEST, name, programId },
  createTemplateSuccess: (template: RundownTemplate) =>
    <const>{ type: RundownTemplateActionType.CREATE_SUCCESS, template },
  createTemplateFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.CREATE_FAILURE, error },

  //
  //	copyTemplate()
  //
  copyTemplate: (templateId: number, name: string, programId: number) =>
    <const>{ type: RundownTemplateActionType.COPY_REQUEST, name, programId, templateId },
  copyTemplateSuccess: (template: RundownTemplate) =>
    <const>{ type: RundownTemplateActionType.COPY_SUCCESS, template },
  copyTemplateFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.COPY_FAILURE, error },

  //
  //	patchTemplate()
  //
  patchTemplate: (templateId: number, field: keyof RundownTemplate, newValue: any, oldValue: any) =>
    <const>{ type: RundownTemplateActionType.PATCH_REQUEST, templateId, field, newValue, oldValue },
  patchTemplateSuccess: () => <const>{ type: RundownTemplateActionType.PATCH_SUCCESS },
  patchTemplateFailure: (
    error: Error,
    templateId: number,
    field: keyof RundownTemplate,
    newValue: any,
    oldValue: any,
  ) =>
    <const>{
      type: RundownTemplateActionType.PATCH_FAILURE,
      error,
      templateId,
      field,
      newValue,
      oldValue,
    },

  //
  //	removeTemplate()
  //
  removeTemplate: (templateId: number) =>
    <const>{ type: RundownTemplateActionType.REMOVE_REQUEST, templateId },
  removeTemplateSuccess: (templateId: number) =>
    <const>{ type: RundownTemplateActionType.REMOVE_SUCCESS, templateId },
  removeTemplateFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.REMOVE_FAILURE, error },

  //
  //	createBlock()
  //
  createBlock: (templateId: number) =>
    <const>{ type: RundownTemplateActionType.CREATE_BLOCK_REQUEST, templateId },
  createBlockSuccess: (block: Block) =>
    <const>{ type: RundownTemplateActionType.CREATE_BLOCK_SUCCESS, block },
  createBlockFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.CREATE_BLOCK_FAILURE, error },

  //
  //	patchBlock()
  //
  patchBlock: (blockId: number, field: keyof Block, newValue: any, oldValue: any) =>
    <const>{
      type: RundownTemplateActionType.PATCH_BLOCK_REQUEST,
      blockId,
      field,
      newValue,
      oldValue,
    },
  patchBlockSuccess: () => <const>{ type: RundownTemplateActionType.PATCH_BLOCK_SUCCESS },
  patchBlockFailure: (
    error: Error,
    blockId: number,
    field: keyof Block,
    newValue: any,
    oldValue: any,
  ) =>
    <const>{
      type: RundownTemplateActionType.PATCH_BLOCK_FAILURE,
      error,
      blockId,
      field,
      newValue,
      oldValue,
    },

  //
  //	removeBlock()
  //
  removeBlock: (blockId: number) =>
    <const>{ type: RundownTemplateActionType.REMOVE_BLOCK_REQUEST, blockId },
  removeBlockSuccess: (blockId: number) =>
    <const>{ type: RundownTemplateActionType.REMOVE_BLOCK_SUCCESS, blockId },
  removeBlockFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.REMOVE_BLOCK_FAILURE, error },

  //
  //	createStory()
  //
  createStory: (blockId: number) =>
    <const>{ type: RundownTemplateActionType.CREATE_STORY_REQUEST, blockId },
  createStorySuccess: (story: Story) =>
    <const>{ type: RundownTemplateActionType.CREATE_STORY_SUCCESS, story },
  createStoryFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.CREATE_STORY_FAILURE, error },

  //
  //	copyStories()
  //
  copyStories: (id: number, source: 'rundown' | 'template', storiesIds: number[]) =>
    <const>{
      type: RundownTemplateActionType.COPY_STORIES_REQUEST,
      id,
      source,
      storiesIds,
    },
  copyStoriesSuccess: (story: Story) =>
    <const>{ type: RundownTemplateActionType.COPY_STORIES_SUCCESS, story },
  copyStoriesFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.COPY_STORIES_FAILURE, error },

  //
  //	moveStory()
  //
  moveStory: (storyId: number, targetBlockId: number, targetStoryId: number | undefined) =>
    <const>{
      type: RundownTemplateActionType.MOVE_STORY_REQUEST,
      storyId,
      targetBlockId,
      targetStoryId,
    },
  moveStorySuccess: () => <const>{ type: RundownTemplateActionType.MOVE_STORY_SUCCESS },
  moveStoryFailure: (
    error: Error,
    storyId: number,
    targetBlockId: number,
    targetStoryId: number | undefined,
  ) =>
    <const>{
      type: RundownTemplateActionType.MOVE_STORY_FAILURE,
      error,
      storyId,
      targetBlockId,
      targetStoryId,
    },

  //
  //	repaginate()
  //
  repaginate: (templateId: number) =>
    <const>{ type: RundownTemplateActionType.REPAGINATE_REQUEST, templateId },
  repaginateSuccess: () => <const>{ type: RundownTemplateActionType.REPAGINATE_SUCCESS },
  repaginateFailure: (error: Error) =>
    <const>{ type: RundownTemplateActionType.REPAGINATE_FAILURE, error },

  //
  //  websocket
  //
  wsPatchBlock: (blockId: number, changes: Partial<Block>) =>
    <const>{ type: RundownTemplateActionType.WS_PATCH_BLOCK, blockId, changes },
}
