import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons'

import AutoComplete, { AutoCompleteProps } from 'antd/es/auto-complete'
import Search from 'antd/es/input/Search'
import Tooltip from 'antd/es/tooltip'

import { mapsApi } from '@anews/api'
import { getLocation } from '@anews/utils'

import { DoubleIcon, HGroup } from '.'

type Result = { label: JSX.Element; value: string }

const renderResult = (key: string, title: string) => (
  <Tooltip key={key} title={title}>
    <DoubleIcon icon={faMapMarkerAlt} /> {title}
  </Tooltip>
)

export default function PlacesAutocompleteInput(props: AutoCompleteProps & { maxLength?: number }) {
  const [results, setResult] = useState<Result[]>([])
  const [searching, setSearching] = useState(false)

  const location = useMemo(() => getLocation(mapsApi.getLocation), [])

  const debouncedSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          setSearching(true)
          const result = await mapsApi.autocomplete(value, await location)
          setResult(
            result.map(({ key, label }) => ({ label: renderResult(key, label), value: label })),
          )
        } catch (error) {
          console.error(error)
          // message.error(t('error:loadFailed')) // não exibie a mensagem e apenas deixa o usuário informar manualmente
        } finally {
          setSearching(false)
        }
      }, 1000),
    [location],
  )

  const search = (input: string | ChangeEvent<HTMLInputElement>) => {
    const value = typeof input === 'string' ? input : input.target.value
    if (value.length < 3) {
      setResult([])
    } else {
      debouncedSearch(value)
    }
  }

  const { value: inputValue, maxLength } = props

  const onOpenMaps = useCallback(() => {
    inputValue && mapsApi.openOnMaps(inputValue)
  }, [inputValue])

  return (
    <HGroup>
      <AutoComplete {...props} allowClear options={results}>
        <Search maxLength={maxLength} loading={searching} onSearch={search} onChange={search} />
      </AutoComplete>

      {inputValue && <DoubleIcon onClick={onOpenMaps} icon={faMapMarkerAlt} />}
    </HGroup>
  )
}
