import React, { ReactNode, useLayoutEffect, useMemo, useState } from 'react'
import AntCollapse, { CollapsePanelProps, CollapseProps } from 'antd/es/collapse'
import styled from '@emotion/styled'

import { LAYOUT_CONTENT_MARGIN, TAB_PADDING } from './CompactTable'

const CollpaseContainer = styled.div`
  overflow: auto;
  width: 100%;

  .ant-collapse {
    min-width: 100%;
    overflow: hidden;
    display: inline-block;

    .ant-collapse-header {
      padding-bottom: 4px !important;
      padding-top: 4px !important;

      .ant-collapse-arrow {
        padding: 4px;
      }
    }

    .ant-collapse-content .ant-collapse-content-box {
      padding: 0;
    }
  }
`

const StyledCollapse = styled(AntCollapse)`
  overflow: hidden;
`

const MAIN = LAYOUT_CONTENT_MARGIN + TAB_PADDING

interface Props extends CollapseProps {
  children?: React.ReactNode
  extraHeight?: number | (number | undefined)[]
}

const Collapse = ({ extraHeight, style, ...props }: Props) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null) // usRef não dispara re-render
  const [fix, setFix] = useState(0)

  const extraHeightSum = useMemo(
    () =>
      Array.isArray(extraHeight)
        ? extraHeight.reduce((total, curr) => (total || 0) + (curr || 0), 0)
        : extraHeight,
    [extraHeight],
  )

  useLayoutEffect(() => {
    if (ref) {
      const { top } = ref.getBoundingClientRect()

      // top fica zero quando a aba é trocada, então deve ignorar
      if (top === 0) {
        return
      }

      const calc = top + MAIN + (extraHeightSum || 0)

      setFix(calc)
    }
  }, [ref, extraHeightSum])

  return (
    <CollpaseContainer
      ref={divRef => setRef(divRef)}
      style={{ maxHeight: `calc(100vh - ${fix}px)`, ...(style || {}) }}
    >
      <StyledCollapse {...props} />
    </CollpaseContainer>
  )
}

export interface PanelProps extends CollapsePanelProps {
  children?: ReactNode
  extraData?: boolean
}

const Panel = ({ children, header, extra, extraData, ...props }: PanelProps) => {
  /*
   * Coloca um div em torno do "header" e utiliza o evento de click para parar a propagação e
   * evitar o collapse ao clicar no "header".
   *
   * Foi preciso colocar uma largura máxima no "header" para cobrir toda a área de clique do
   * componente (exceto o ícone), e colocar o "extra" sobre o "header" para ele aceitar cliques.
   */

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const headerWrapper = (
    <div
      style={{
        position: extraData ? 'relative' : 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 28,
        padding: extraData ? 'auto' : '4px 8px',
        zIndex: 0,
        cursor: 'auto',
      }}
      onClick={e => e.stopPropagation()}
    >
      {header}
    </div>
  )

  const extraWrapper = <span style={{ zIndex: 1 }}>{extra}</span>

  return (
    <AntCollapse.Panel header={headerWrapper} extra={extraWrapper} {...props}>
      {children}
    </AntCollapse.Panel>
  )
}

Collapse.Panel = Panel

export default Collapse
