export var EMPTY_RICH_TEXT = [
    {
        type: 'paragraph',
        children: [
            {
                text: '',
                color: 'inherit',
                bold: false,
                italic: false,
                underline: false,
            },
        ],
    },
];
