import { TwitterConfig } from '@anews/types'

export enum TwitterActionType {
  LOAD_CONFIG_REQUEST = '@twitter/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@twitter/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@twitter/LOAD_CONFIG_FAILURE',
  CREATE_CONFIG_REQUEST = '@twitter/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@twitter/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@twitter/CREATE_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@twitter/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@twitter/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@twitter/UPDATE_CONFIG_FAILURE',
}

export type TwitterActionMap<T extends TwitterActionType> = { type: T } & TwitterAction

export type TwitterAction = ReturnType<typeof TwitterActions[keyof typeof TwitterActions]>

export const TwitterActions = {
  //
  //  loadConfig()
  //

  loadConfig: () => <const>{ type: TwitterActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: TwitterConfig) =>
    <const>{ type: TwitterActionType.LOAD_CONFIG_SUCCESS, config },

  loadConfigFailure: (error: Error) =>
    <const>{ type: TwitterActionType.LOAD_CONFIG_FAILURE, error },

  //
  //  createConfig()
  //

  createConfig: (config: TwitterConfig) =>
    <const>{ type: TwitterActionType.CREATE_CONFIG_REQUEST, config },

  createConfigSuccess: (config: TwitterConfig) =>
    <const>{ type: TwitterActionType.CREATE_CONFIG_SUCCESS, config },

  createConfigFailure: (error: Error) =>
    <const>{ type: TwitterActionType.CREATE_CONFIG_FAILURE, error },

  //
  //  updateConfig()
  //

  updateConfig: (config: TwitterConfig) =>
    <const>{ type: TwitterActionType.UPDATE_CONFIG_REQUEST, config },

  updateConfigSuccess: (config: TwitterConfig) =>
    <const>{ type: TwitterActionType.UPDATE_CONFIG_SUCCESS, config },

  updateConfigFailure: (error: Error) =>
    <const>{ type: TwitterActionType.UPDATE_CONFIG_FAILURE, error },
}
