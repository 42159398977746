export function isDarkColor(color) {
    var rgb = parseInt(color.substring(1), 16);
    /* eslint no-bitwise: "off" */
    var red = (rgb >> 16) & 0xff;
    var green = (rgb >> 8) & 0xff;
    var blue = (rgb >> 0) & 0xff;
    // Calcula luma (per ITU-R BT.709)
    var luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue;
    return luma <= 127.5;
}
