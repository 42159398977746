import React, { useCallback } from 'react'

import { InboxOutlined, DownloadOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'

// import { Button, List, Tooltip, Upload, notification } from 'antd/es'
import Button from 'antd/es/button'
import List from 'antd/es/list'
import Tooltip from 'antd/es/tooltip'
import Upload from 'antd/es/upload'
import Typography from 'antd/es/typography'
import notification from 'antd/es/notification'

import { useTranslation } from 'react-i18next'

import { storageFileApi } from '@anews/api'
import { StorageFile } from '@anews/types'

import { VGroup } from '../../global'

const isDisplayable = ({ contentType }: StorageFile) =>
  /^(audio|image|text|video)\//.test(contentType) || /\/pdf$/.test(contentType)

interface Props {
  files: StorageFile[]
  editable: boolean
  onAddFile?: (files: StorageFile) => void
  onRemoveFile?: (file: StorageFile) => void
}

const GuidelineFiles = ({ files, editable, onAddFile, onRemoveFile }: Props) => {
  const { t } = useTranslation()

  const uploadFile = useCallback(
    async (file: File) => {
      try {
        const storageFile = await storageFileApi.uploadFile(file)
        if (onAddFile) {
          onAddFile(storageFile)
        }
      } catch (error) {
        notification.warning({
          message: t('words:warning'),
          description: t('phrases:fileRules'),
        })
      }
    },
    [onAddFile, t],
  )

  const onDownload = (file: StorageFile) => {
    window.open(storageFileApi.getFileSrc(file.id), '_blank')
  }

  const onRemove = (file: StorageFile) => {
    if (onRemoveFile) {
      onRemoveFile(file)
    }
  }

  const onView = (file: StorageFile) => {
    window.open(storageFileApi.previewFile(file.id), '_blank')
  }

  return (
    <VGroup>
      {files.length > 0 ? (
        <List
          bordered
          size="small"
          dataSource={files}
          renderItem={file => (
            <List.Item
              key={file.id}
              actions={[
                <div style={{ display: 'flex', gap: 4 }}>
                  <Tooltip key="viewFile" title={t('words:view')}>
                    <Button
                      disabled={!isDisplayable(file)}
                      onClick={() => onView(file)}
                      size="small"
                    >
                      <EyeOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip key="downloadFile" title={t('words:download')}>
                    <Button onClick={() => onDownload(file)} size="small">
                      <DownloadOutlined />
                    </Button>
                  </Tooltip>
                  {editable ? (
                    <Tooltip key="deleteFile" title={t('words:remove')}>
                      <Button onClick={() => onRemove(file)} size="small">
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  ) : null}
                </div>,
              ]}
            >
              {/* <List.Item.Meta title={file.originalFilename} /> */}
              <Typography.Text>{file.originalFilename}</Typography.Text>
            </List.Item>
          )}
        />
      ) : null}

      {editable ? (
        <Upload.Dragger beforeUpload={file => uploadFile(file)} showUploadList={false}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('phrases:uploadHint')}</p>
          <p className="ant-upload-hint">{t('phrases:fileRules')}</p>
        </Upload.Dragger>
      ) : null}
    </VGroup>
  )
}

export default GuidelineFiles
