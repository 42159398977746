import { call, put, takeLatest } from 'redux-saga/effects'

import { reportCategoryApi as api } from '@anews/api'
import { ReportCategory } from '@anews/types'

import {
  NotificationActions,
  ReportCategoryActions,
  ReportCategoryActionMap as ActionMap,
  ReportCategoryActionType as ActionType,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  listReportCategoriesSuccess,
  listReportCategoriesFailure,
  createReportCategorySuccess,
  createReportCategoryFailure,
  updateReportCategorySuccess,
  updateReportCategoryFailure,
  removeReportCategorySuccess,
  removeReportCategoryFailure,
} = ReportCategoryActions

const { notifyError } = NotificationActions

/* Watchers */

function* listReportCategoriesSaga(action: ActionMap<ActionType.LIST_REQUEST>): Generator {
  try {
    const categories = yield call(api.list, action.events)
    yield put(listReportCategoriesSuccess(categories as ReportCategory[]))
  } catch (error) {
    yield put(listReportCategoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createReportCategorySaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const category = yield call(api.create, action.category)
    yield put(createReportCategorySuccess(category as ReportCategory))
  } catch (error) {
    yield put(createReportCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateReportCategorySaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const category = yield call(api.update, action.category)
    yield put(updateReportCategorySuccess(category as ReportCategory))
  } catch (error) {
    yield put(updateReportCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeReportCategorySaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeReportCategorySuccess(action.id))
  } catch (error) {
    yield put(removeReportCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listReportCategoriesSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createReportCategorySaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateReportCategorySaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeReportCategorySaga)
  },
])
