import React, { Component, ErrorInfo } from 'react'

interface Props {}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Enviar para serviço de coleta de erros (Sentry?)
    console.warn(error, errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? <h1>Something went wrong</h1> : children
  }
}

export default ErrorBoundary
