import moment from 'moment'
import isEqual from 'react-fast-compare'

import { isTime, isTimestamp } from '@anews/utils'

function extendedIsEqual(a: any, b: any) {
  /*
   * Valores de tempo podem dar como diferentes mesmo representando a mesma coisa, como ao comparar
   * os valores '12:00-03:00' e '12:00:00-03:00'. Por isso faz a comparação usando o moment quando
   * possível. Para datas sem horas não parece ser necessário.
   */

  const momentA = moment.isMoment(a)
    ? a
    : isTimestamp(a)
    ? moment(a)
    : isTime(a)
    ? moment(`2021-12-31T${a}`) // adiciona uma data qualquer
    : undefined

  const momentB = moment.isMoment(b)
    ? b
    : isTimestamp(b)
    ? moment(b)
    : isTime(b)
    ? moment(`2021-12-31T${b}`) // adiciona uma data qualquer
    : undefined

  if (momentA && momentB) {
    return momentA.diff(momentB, 'seconds') === 0
  }

  // Comparação para todos os outros tipos de valores
  return isEqual(a, b)
}

export function anyFieldChanged<T>(fields: (keyof T)[], a?: T, b?: T): boolean {
  // O isEqual() foi utilizado para tratar melhor a comparação entre arrays
  // O trecho "<valor> || ''" serve para que undefined e '' não sejam considerados diferentes
  return !!fields.find(field => !extendedIsEqual((a && a[field]) || '', (b && b[field]) || ''))

  // VERSÃO DEBUG - Imprime o que mudou
  // return !!fields.find(field => {
  //   if (!extendedIsEqual((a && a[field]) || '', (b && b[field]) || '')) {
  //     console.log(field, a && a[field], b && b[field])
  //     return true
  //   }
  //   return false
  // })
}
