import { Reducer } from 'redux'

import { StreamingConfig } from '@anews/types'

import { StreamingAction, ActionType } from '../actions/streaming-actions'

import { ConfigState } from './types'

export interface StreamingState {
  config: ConfigState<StreamingConfig>
}

export const initialState: StreamingState = {
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
}

function configReducer(
  config: ConfigState<StreamingConfig>,
  action: StreamingAction,
): ConfigState<StreamingConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

const streamingReducer: Reducer<StreamingState, StreamingAction> = (
  state = initialState,
  action,
): StreamingState => {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.UPDATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...state, config: configReducer(state.config, action) }

    default:
      return state
  }
}

export default streamingReducer
