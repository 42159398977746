import { Reducer } from 'redux'

import { AuthenticationLog } from '@anews/types'

import { AuthenticationLogAction, AuthenticationLogActionType } from '../actions/authlog-actions'

const initialState = {
  data: [],
  number: 0,
  size: 0,
  total: 0,
  filter: '',
  activeOnly: true,
  loading: true,
} as AuthenticationLogsStates

export interface AuthenticationLogsStates {
  data: AuthenticationLog[]
  number: number
  size: number
  total: number
  filter: string
  success?: boolean
  from?: string
  to?: string
  loading: boolean
  activeOnly: boolean
}

export type AuthenticationStateInitial = typeof initialState

const authenticationLogReducer: Reducer<AuthenticationStateInitial, AuthenticationLogAction> = (
  state = initialState,
  action,
): AuthenticationStateInitial => {
  switch (action.type) {
    case AuthenticationLogActionType.FILTER_REQUEST:
      return { ...state, filter: action.filter, loading: true }

    case AuthenticationLogActionType.FILTER_FAILURE:
      return { ...state, loading: false }

    case AuthenticationLogActionType.FILTER_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        size: action.result.size,
        total: action.result.total,
        number: action.result.page,
        loading: false,
      }
    default:
      return state
  }
}

export default authenticationLogReducer
