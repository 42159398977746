/* eslint-disable prefer-destructuring */
import parseHtml from 'react-html-parser'
import { v4 as uuid } from 'uuid'

import { ReactNode } from 'react'

import { Media, MosPath, ReportageCG, StorySubSection, StorySubSectionType } from '@anews/types'

import { Item, Path } from '../mos/types/Item'

//
//  ID parsing
//

const REPORTAGE_RO = 'NEWSREPORTS'
const REPORTAGE_STORY_PREFIX = 'newsreport_'

export const mosRoId = (id?: number) => (id ? `R_${id}` : '')

export const mosReportagesRoId = () => REPORTAGE_RO

export const mosReportageId = (id: number) => `${REPORTAGE_STORY_PREFIX}${id || 0}`

export const mosStoryId = (id: number) => `${id}`

export const isReportage = (storyId?: string) =>
  storyId && storyId.startsWith(REPORTAGE_STORY_PREFIX)

export const parseReportageId = (storyId: string) =>
  parseInt(storyId.replace(REPORTAGE_STORY_PREFIX, ''), 10)

export function generateSubSectionItemId(sub: StorySubSection) {
  if (sub.type === StorySubSectionType.MOS_CG) {
    return `C_${sub.id || sub.uuid}`
  }
  if (sub.type === StorySubSectionType.MOS_VIDEO) {
    return `V_${sub.id || sub.uuid}`
  }
  return `${sub.id || sub.uuid}`
}

export const generateReportageCgItemId = (cg: ReportageCG) => `C_${cg.id || cg.uuid}`

//
//  Item
//

export function isNewItem(item: Item) {
  return !item.itemID || item.itemID === '' || item.itemID === '0'
}

export const getStoryItem = (
  oldItens: Item[],
  sectionOrSubSection: StorySubSection,
  mosId?: string,
): Item[] => {
  if (
    sectionOrSubSection.type !== StorySubSectionType.MOS_VIDEO &&
    sectionOrSubSection.type !== StorySubSectionType.MOS_CG
  ) {
    return oldItens
  }

  const item: Item = { itemID: '', objID: '', mosID: '' }

  item.itemID = generateSubSectionItemId(sectionOrSubSection)
  item.itemSlug = sectionOrSubSection.slug
  item.itemChannel = sectionOrSubSection.channel || ''
  item.itemEdStart = sectionOrSubSection.edStart || 0
  item.itemEdDur = sectionOrSubSection.edDur || 0
  item.itemUserTimingDur = sectionOrSubSection.userTimingDur || 0
  item.macroIn = sectionOrSubSection.macroIn || ''
  item.macroOut = sectionOrSubSection.macroOut || ''

  const { media } = sectionOrSubSection

  // Dados da mídia.
  if (media) {
    item.objSlug = media.slug
    item.objID = media.objectId || ''
    item.mosID = mosId || ''
    item.objDur = parseInt(media.duration, 10) || 0
    item.objTB = media.timeBase || 0
    item.mosAbstract = media.abstractText || ''
    item.objType = media.type

    if (media.paths || media.proxyPaths || media.metadataPath) {
      item.objPaths = {}

      if (media.paths) {
        const paths: Path[] = []
        media.paths.map(path =>
          paths.push({ techDescription: path.techDescription, value: path.url }),
        )

        if (paths.length > 0) {
          item.objPaths.objPaths = paths
        }
      }

      if (media.proxyPaths) {
        const paths: Path[] = []
        media.proxyPaths.map(path =>
          paths.push({ techDescription: path.techDescription, value: path.url }),
        )

        if (paths.length > 0) {
          item.objPaths.objProxyPaths = paths
        }
      }

      if (media.metadataPath) {
        item.objPaths.objMetadataPaths = [
          {
            techDescription: media.metadataPath.techDescription,
            value: media.metadataPath.url,
          },
        ]
      }
    }

    item.mosExternalMetadata =
      media.mosExternalMetadata
        ?.replace(/<mosExternalMetadata>/gm, '')
        ?.split(/<\/mosExternalMetadata>/gm)
        ?.filter(s => !!s) || []
  }

  oldItens.push(item)

  return oldItens
}

export function toMosPath(
  path: Path[] | Path | string,
  origin: 'PATH' | 'PROXY' | 'METADATA',
  techDescription?: string,
): MosPath[] {
  if (typeof path === 'string') {
    return [buidMosPath(path, origin, techDescription)]
  }
  if (Array.isArray(path)) {
    return toMosPathArray(path, origin)
  }
  return [toMosPathSingle(path, origin)]
}

const toMosPathArray = (paths: Path[], origin: 'PATH' | 'PROXY' | 'METADATA'): MosPath[] =>
  paths.map(path => toMosPathSingle(path, origin))

const toMosPathSingle = (path: Path, origin: 'PATH' | 'PROXY' | 'METADATA'): MosPath =>
  buidMosPath(path.value, origin, path.techDescription)

const buidMosPath = (
  url: string,
  origin: 'PATH' | 'PROXY' | 'METADATA',
  techDescription: string = '',
): MosPath => ({
  id: 0,
  uuid: uuid(),
  origin,
  techDescription,
  url,
})

export function mediaTextDisplay(media?: Media): ReactNode {
  if (!media) {
    return null
  }

  const mem = media.mosExternalMetadata
  if (typeof mem === 'string' && /<editableObject /.test(mem)) {
    /*
     * Lógica para extrair os valores preenchidos nos GCs da ClassX.
     *
     * Estrutura da ClassX:
     * <mosExternalMetadata>
     *   <mosScope>PLAYLIST</mosScope>
     *   <mosSchema>http://www.classx.it/mos/xml.classxmos.dtd.xml</mosSchema>
     *   <mosPayload>
     *     <projectName>{base.dir}\CastaliaCG\project\events\NAB\2017\breaking_news.ccg</projectName>
     *     <visualPriority>50</visualPriority>
     *     <editableObject type="TEXT">
     *       <name>breaking_news</name>
     *       <value>TESTANDO A INTEGRAÇÃO</value>
     *     </editableObject>
     *   </mosPayload>
     * </mosExternalMetadata>
     */

    const fields: string[] = []
    const matches = mem.matchAll(/<(name|value)>(.*)<\/.*>/gm)

    let name = ''

    for (const match of matches) {
      if (/name/.test(match[1])) {
        name = match[2]
      } else {
        fields.push(`${name}: ${match[2]}`)
      }
    }

    if (fields.length > 0) {
      return fields.join(' / ')
    }
  }

  if (media.abstractText && media.abstractText !== media.slug) {
    return parseHtml(media?.abstractText)
  }
  return null
}
