import { ChecklistConfig, Checklist, Guideline } from '@anews/types'

export enum ChecklistActionType {
  NEW = '@checklist/NEW',
  CLEAR = '@checklist/CLEAR',
  UNLOAD = '@checklist/UNLOAD',
  CLOSE_TAB = '@checklist/CLOSE_TAB',
  CHANGE_TAB = '@checklist/CHANGE_TAB',
  // listDateChecklists()
  DATE_LIST_REQUEST = '@checklist/DATE_LIST_REQUEST',
  DATE_LIST_SUCCESS = '@checklist/DATE_LIST_SUCCESS',
  DATE_LIST_FAILURE = '@checklist/DATE_LIST_FAILURE',
  // loadByGuideline()
  LOAD_BY_GUIDELINE_REQUEST = '@checklist/LOAD_BY_GUIDELINE_REQUEST',
  LOAD_BY_GUIDELINE_SUCCESS = '@checklist/LOAD_BY_GUIDELINE_SUCCESS',
  LOAD_BY_GUIDELINE_FAILURE = '@checklist/LOAD_BY_GUIDELINE_FAILURE',
  // loadChecklist()
  LOAD_REQUEST = '@checklist/LOAD_REQUEST',
  LOAD_SUCCESS = '@checklist/LOAD_SUCCESS',
  LOAD_FAILURE = '@checklist/LOAD_FAILURE',
  // loadGuideline()
  LOAD_GUIDELINE_REQUEST = '@checklist/GUIDELINE_LOAD_REQUEST',
  LOAD_GUIDELINE_SUCCESS = '@checklist/GUIDELINE_LOAD_SUCCESS',
  LOAD_GUIDELINE_FAILURE = '@checklist/GUIDELINE_LOAD_FAILURE',
  // createChecklist()
  CREATE_REQUEST = '@checklist/CREATE_REQUEST',
  CREATE_SUCCESS = '@checklist/CREATE_SUCCESS',
  CREATE_FAILURE = '@checklist/CREATE_FAILURE',
  // createChecklistFromGuideline()
  CREATE_FROM_GUIDELINE_REQUEST = '@checklist/CREATE_FROM_GUIDELINE_REQUEST',
  CREATE_FROM_GUIDELINE_SUCCESS = '@checklist/CREATE_FROM_GUIDELINE_SUCCESS',
  CREATE_FROM_GUIDELINE_FAILURE = '@checklist/CREATE_FROM_GUIDELINE_FAILURE',
  // updateChecklist()
  UPDATE_REQUEST = '@checklist/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@checklist/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@checklist/UPDATE_FAILURE',
  // patchChecklist()
  PATCH_REQUEST = '@checklist/PATCH_REQUEST',
  PATCH_SUCCESS = '@checklist/PATCH_SUCCESS',
  PATCH_FAILURE = '@checklist/PATCH_FAILURE',
  // removeChecklist()
  REMOVE_REQUEST = '@checklist/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@checklist/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@checklist/REMOVE_FAILURE',
  // loadConfig()
  LOAD_CONFIG_REQUEST = '@checklist/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@checklist/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@checklist/LOAD_CONFIG_FAILURE',
  // createConfig()
  CREATE_CONFIG_REQUEST = '@checklist/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@checklist/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@checklist/CREATE_CONFIG_FAILURE',
  // updateConfig()
  UPDATE_CONFIG_REQUEST = '@checklist/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@checklist/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@checklist/UPDATE_CONFIG_FAILURE',
  // websocket
  WS_CREATE = '@checklist/WS_CREATE',
  WS_DELETE = '@checklist/WS_DELETE',
  WS_UPDATE = '@checklist/WS_UPDATE',
  WS_PATCH = '@checklist/WS_PATCH',
}

export type ChecklistActionMap<T extends ChecklistActionType> = {
  type: T
} & ChecklistAction

export type ChecklistAction = ReturnType<typeof ChecklistActions[keyof typeof ChecklistActions]>

export const ChecklistActions = {
  //
  //	dateListChecklists()
  //

  dateListChecklists: (date: string, onlyNotDone: boolean) =>
    <const>{ type: ChecklistActionType.DATE_LIST_REQUEST, date, onlyNotDone },
  dateListChecklistsSuccess: (checklists: Checklist[], date: string, onlyNotDone: boolean) =>
    <const>{ type: ChecklistActionType.DATE_LIST_SUCCESS, date, onlyNotDone, checklists },
  dateListChecklistsFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.DATE_LIST_FAILURE, error },

  //
  // loadChecklist()
  //
  loadChecklist: (target: number | Checklist, edit: boolean) =>
    <const>{ type: ChecklistActionType.LOAD_REQUEST, target, edit },
  loadChecklistSuccess: (checklist: Checklist, edit: boolean) =>
    <const>{ type: ChecklistActionType.LOAD_SUCCESS, checklist, edit },
  loadChecklistFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.LOAD_BY_GUIDELINE_FAILURE, error },

  //
  // loadByGuideline()
  //
  loadByGuideline: (guidelineId: number, edit: boolean, date?: string) =>
    <const>{ type: ChecklistActionType.LOAD_BY_GUIDELINE_REQUEST, guidelineId, edit, date },

  // Ao invés de usar essa action, está aproveitando o loadChecklistSuccess nas saga
  // loadByGuidelineSuccess:(checklist:Checklist,edit:boolean)=><const>({
  // type:ChecklistActionType.LOAD_BY_GUIDELINE_SUCCESS,
  // checklist,
  // edit,
  // })
  loadByGuidelineFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.LOAD_BY_GUIDELINE_FAILURE, error },

  //
  // loadGuideline()
  //
  loadGuideline: (id: number) => <const>{ type: ChecklistActionType.LOAD_GUIDELINE_REQUEST, id },
  loadGuidelineSuccess: (guideline: Guideline) =>
    <const>{ type: ChecklistActionType.LOAD_GUIDELINE_SUCCESS, guideline },
  loadGuidelineFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.LOAD_GUIDELINE_FAILURE, error },

  //
  // createChecklist()
  //
  createChecklist: (checklist: Checklist, lock: boolean) =>
    <const>{ type: ChecklistActionType.CREATE_REQUEST, checklist, lock },
  createChecklistSuccess: (checklist: Checklist) =>
    <const>{ type: ChecklistActionType.CREATE_SUCCESS, checklist },
  createChecklistFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.CREATE_FAILURE, error },

  //
  // createChecklistFromGuideline()
  //
  createChecklistFromGuideline: (guidelineId: number) =>
    <const>{ type: ChecklistActionType.CREATE_FROM_GUIDELINE_REQUEST, guidelineId },
  createChecklistFromGuidelineSuccess: (checklist: Checklist) =>
    <const>{ type: ChecklistActionType.CREATE_FROM_GUIDELINE_SUCCESS, checklist },
  createChecklistFromGuidelineFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.CREATE_FROM_GUIDELINE_FAILURE, error },

  //
  // updateChecklist()
  //
  updateChecklist: (checklist: Checklist) =>
    <const>{ type: ChecklistActionType.UPDATE_REQUEST, checklist },
  updateChecklistSuccess: (checklist: Checklist) =>
    <const>{ type: ChecklistActionType.UPDATE_SUCCESS, checklist },
  updateChecklistFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.UPDATE_FAILURE, error },

  //
  // removeChecklists()
  //
  removeChecklists: (ids: number[]) => <const>{ type: ChecklistActionType.REMOVE_REQUEST, ids },
  removeChecklistsSuccess: (ids: number[]) =>
    <const>{ type: ChecklistActionType.REMOVE_SUCCESS, ids },
  removeChecklistsFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.REMOVE_FAILURE, error },

  //
  // patchChecklist()
  //
  patchChecklist: (checklistId: number, field: keyof Checklist, newValue: any, oldValue: any) =>
    <const>{ type: ChecklistActionType.PATCH_REQUEST, checklistId, field, newValue, oldValue },
  patchChecklistSuccess: () => <const>{ type: ChecklistActionType.PATCH_SUCCESS },
  patchChecklistFailure: (
    error: Error,
    checklistId: number,
    field: keyof Checklist,
    newValue: any,
    oldValue: any,
  ) =>
    <const>{
      type: ChecklistActionType.PATCH_FAILURE,
      error,
      checklistId,
      field,
      newValue,
      oldValue,
    },

  //
  // newChecklist()
  //
  newChecklist: (checklist: Checklist) => <const>{ type: ChecklistActionType.NEW, checklist },

  //
  // unloadChecklist()
  //
  unloadChecklist: () => <const>{ type: ChecklistActionType.UNLOAD },

  //
  // clearChecklists()
  //
  clearChecklists: () => <const>{ type: ChecklistActionType.CLEAR },

  //
  // changeChecklistTab()
  //
  changeChecklistTab: (key: string) => <const>{ type: ChecklistActionType.CHANGE_TAB, key },

  //
  // closeChecklistTab()
  //
  closeChecklistTab: (key: string) => <const>{ type: ChecklistActionType.CLOSE_TAB, key },

  //
  // wsCreateChecklist()
  //
  wsCreateChecklist: (checklist: Checklist) =>
    <const>{ type: ChecklistActionType.WS_CREATE, checklist },

  //
  // wsUpdateChecklist()
  //
  wsUpdateChecklist: (checklist: Checklist) =>
    <const>{ type: ChecklistActionType.WS_UPDATE, checklist },

  //
  // wsDeleteChecklist()
  //
  wsDeleteChecklist: (ids: number[]) => <const>{ type: ChecklistActionType.WS_DELETE, ids },

  //
  // wsPatchChecklist()
  //
  wsPatchChecklist: (checklistId: number, changes: Partial<Checklist>) =>
    <const>{ type: ChecklistActionType.WS_PATCH, checklistId, changes },

  //
  // loadConfig()
  //
  loadConfig: () => <const>{ type: ChecklistActionType.LOAD_CONFIG_REQUEST },
  loadConfigSuccess: (config: ChecklistConfig) =>
    <const>{ type: ChecklistActionType.LOAD_CONFIG_SUCCESS, config },
  loadConfigFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.LOAD_CONFIG_FAILURE, error },

  //
  // createConfig()
  //
  createConfig: (config: ChecklistConfig) =>
    <const>{ type: ChecklistActionType.CREATE_CONFIG_REQUEST, config },
  createConfigSuccess: (config: ChecklistConfig) =>
    <const>{ type: ChecklistActionType.CREATE_CONFIG_SUCCESS, config },
  createConfigFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.CREATE_CONFIG_FAILURE, error },

  //
  // updateConfig()
  //
  updateConfig: (config: ChecklistConfig) =>
    <const>{ type: ChecklistActionType.UPDATE_CONFIG_REQUEST, config },
  updateConfigSuccess: (config: ChecklistConfig) =>
    <const>{ type: ChecklistActionType.UPDATE_CONFIG_SUCCESS, config },
  updateConfigFailure: (error: Error) =>
    <const>{ type: ChecklistActionType.UPDATE_CONFIG_FAILURE, error },
}
