import { createSelector } from 'reselect'

import { RootState } from '../reducers'

const getMetadata = (state: RootState) => state.customMetadata.list.data

export const getAssignmentMetadata = createSelector([getMetadata], metadata =>
  metadata.filter(m => m.assignmentMetadata),
)

export const getNewsReportMetadata = createSelector([getMetadata], metadata =>
  metadata.filter(m => m.newsReportMetadata),
)

export const getProductionMetadata = createSelector([getMetadata], metadata =>
  metadata.filter(m => m.productionMetadata),
)

export const getStoryMetadata = createSelector([getMetadata], metadata =>
  metadata.filter(m => m.storyMetadata),
)
