import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { userApi as api, twoFactorsApi } from '@anews/api'
import { User, Page } from '@anews/types'

import { NotificationActions, UserActionType, UserActions, UserActionMap } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

const {
  removeUserFailure,
  restoreUserSuccess,
  restoreUserFailure,
  updateUserSuccess,
  updateUserFailure,
  createUserFailure,
  editUserSuccess,
  editUserFailure,
  filterUsersSuccess,
  filterUsersFailure,
  listUserByCategorySuccess,
  listUserByCategoryFailure,
  resetPasswordFailure,
  resetPasswordSuccess,
  reset2FaPasswordSuccess,
  reset2FaPasswordFailure,
} = UserActions
/* Watchers */

function* listUsersByCategorySaga(
  action: UserActionMap<UserActionType.LIST_BY_CATEGORY_REQUEST>,
): Generator {
  try {
    let users: unknown

    switch (action.category) {
      case 'all':
        users = yield call(api.all)
        break
      case 'active':
        users = yield call(api.activeUsers)
        break
      case 'editors':
        users = yield call(api.editors)
        break
      case 'imageEditors':
        users = yield call(api.imageEditors)
        break
      case 'producers':
        users = yield call(api.producers)
        break
      case 'reporters':
        users = yield call(api.reporters)
        break
      default:
        throw new Error(`Unknown user category: ${action.category}`)
    }
    yield put(listUserByCategorySuccess(users as User[], action.category))
  } catch (error) {
    yield put(listUserByCategoryFailure(error, action.category))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* filterUsersSaga(action: UserActionMap<UserActionType.FILTER_REQUEST>): Generator {
  try {
    const result = yield call(
      api.pageFilter,
      action.filter,
      action.activeOnly,
      action.page,
      action.size,
    )
    yield put(filterUsersSuccess(result as Page<User>))
  } catch (error) {
    yield put(filterUsersFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* editUserSaga(action: UserActionMap<UserActionType.EDIT_REQUEST>): Generator {
  try {
    const user = yield call(api.load, action.id)
    yield put(editUserSuccess(user as User))
  } catch (error) {
    yield put(editUserFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createUserSaga(action: UserActionMap<UserActionType.CREATE_REQUEST>): Generator {
  try {
    yield call(api.create, action.user)
  } catch (error) {
    yield put(createUserFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateUserSaga(action: UserActionMap<UserActionType.UPDATE_REQUEST>): Generator {
  try {
    const user = yield call(api.update, action.user)
    yield put(updateUserSuccess(user as User))
  } catch (error) {
    yield put(updateUserFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeUserSaga(action: UserActionMap<UserActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.ids)
  } catch (error) {
    yield put(removeUserFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

function* restoreUserSaga(action: UserActionMap<UserActionType.RESTORE_REQUEST>): Generator {
  try {
    const user = yield call(api.restore, action.id)
    yield put(restoreUserSuccess(user as User))
  } catch (error) {
    yield put(restoreUserFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:actionFailed'),
        error,
      }),
    )
  }
}

function* resetPasswordSaga(
  action: UserActionMap<UserActionType.RESET_PASSWORD_REQUEST>,
): Generator {
  try {
    const user = yield call(api.resetPassword, action.id)
    yield put(resetPasswordSuccess(user as User))
  } catch (error) {
    yield put(resetPasswordFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:actionFailed'),
        error,
      }),
    )
  }
}

function* reset2faPasswordSaga(
  action: UserActionMap<UserActionType.RESET_2FA_PASSWORD_REQUEST>,
): Generator {
  try {
    const user = yield call(twoFactorsApi.reset2FaCodePassword, action.id)
    yield put(reset2FaPasswordSuccess(user as User))
  } catch (error) {
    yield put(reset2FaPasswordFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:actionFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeEvery(UserActionType.LIST_BY_CATEGORY_REQUEST, listUsersByCategorySaga)
  },
  function* () {
    yield takeLatest(UserActionType.FILTER_REQUEST, filterUsersSaga)
  },
  function* () {
    yield takeLatest(UserActionType.EDIT_REQUEST, editUserSaga)
  },
  function* () {
    yield takeLatest(UserActionType.CREATE_REQUEST, createUserSaga)
  },
  function* () {
    yield takeLatest(UserActionType.UPDATE_REQUEST, updateUserSaga)
  },
  function* () {
    yield takeLatest(UserActionType.REMOVE_REQUEST, removeUserSaga)
  },
  function* () {
    yield takeLatest(UserActionType.RESTORE_REQUEST, restoreUserSaga)
  },
  function* () {
    yield takeLatest(UserActionType.RESET_PASSWORD_REQUEST, resetPasswordSaga)
  },
  function* () {
    yield takeLatest(UserActionType.RESET_2FA_PASSWORD_REQUEST, reset2faPasswordSaga)
  },
])
