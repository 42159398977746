import React, { memo, useEffect, useState } from 'react'
import moment from 'moment'

import Button from 'antd/es/button'
import List from 'antd/es/list'
import Tooltip from 'antd/es/tooltip'

import { WarningOutlined } from '@ant-design/icons'

import Modal from 'antd/es/modal'

import { VGroup } from '../components/global'

import { useTranslation } from '../i18n'

import { AllowedType, Section } from './types'
import useUnsavedSection from './useUnsavedSection'

interface Props {
  section: Section
  onRestore: (document: AllowedType) => void
}

function UnsavedAlert({ section, onRestore }: Props) {
  const { unsavedData, clearUnsavedSection, clearUnsavedEntry } = useUnsavedSection(section)

  const [checked, setChecked] = useState(false)
  const [alert, setAlert] = useState(false)

  // Só deve exibir o alerta na primeira vez que abre a área
  // Quando ficar sem dados no cache, deve ocultar
  useEffect(() => {
    const empty = Object.keys(unsavedData).length === 0

    if (!checked) {
      setAlert(!empty)
      setChecked(true)
    } else if (empty) {
      setAlert(false)
    }
  }, [checked, unsavedData])

  const [modalVisible, setModalVisible] = useState(false)

  const restore = (document: AllowedType) => {
    setModalVisible(false)
    onRestore(document)
  }

  const discardAll = () => {
    setModalVisible(false)
    clearUnsavedSection()
  }

  const { t } = useTranslation()

  return alert ? (
    <>
      <Tooltip title={t('unsaved:tooltip')} defaultVisible>
        <Button onClick={() => setModalVisible(true)} type="primary" danger>
          <WarningOutlined />
        </Button>
      </Tooltip>

      <Modal
        title={t('unsaved:title')}
        cancelText={t('words:close')}
        okText={t('unsaved:discardAll')}
        okButtonProps={{ danger: true }}
        onCancel={() => setModalVisible(false)}
        onOk={discardAll}
        visible={modalVisible}
        maskClosable
      >
        <VGroup>
          <p>{t('unsaved:message')}</p>
          <p>{t('unsaved:hint')}</p>
          <List
            dataSource={Object.values(unsavedData)}
            itemLayout="horizontal"
            renderItem={({ document, date }) => (
              <List.Item
                actions={[
                  <Button onClick={() => restore(document)} key="edit" type="link" size="small">
                    {t('words:edit')}
                  </Button>,
                  <Button
                    onClick={() => clearUnsavedEntry(document.uuid)}
                    key="discard"
                    type="link"
                    size="small"
                  >
                    {t('unsaved:discard')}
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={document.slug || 'Sem retranca'}
                  description={`(${moment(date).fromNow()})`}
                />
              </List.Item>
            )}
          />
        </VGroup>
      </Modal>
    </>
  ) : null
}

export default memo(UnsavedAlert)
