import { useEffect } from 'react'

import { Shortcuts, useShortcut } from './useShortcut'

interface FormProps {
  onSave: () => void
  onClose: () => void
  isActive: boolean
}

export function useFormShortcuts({ onSave, onClose, isActive }: FormProps) {
  useShortcut(Shortcuts.CLOSE, onClose, [onClose], { disabled: !isActive })

  useEffect(() => {
    const onFormKeydown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 's') {
        e.preventDefault()
        onSave()
      }
    }
    if (isActive) {
      window.addEventListener('keydown', onFormKeydown, false)
    }
    return function cleanup() {
      window.removeEventListener('keydown', onFormKeydown, false)
    }
  }, [isActive, onClose, onSave])
}
