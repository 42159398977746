import { get, query, post } from './client';
var endpoint = '/api/license';
var licenseApi = {
    check: function (url) {
        return get(query(endpoint + "/check" + (url ? "?url=" + url : '')));
    },
    getDaysToExpire: function () {
        return get(query(endpoint + "/daysToExpire"));
    },
    getInfo: function () {
        return get(query(endpoint + "/info"));
    },
    register: function (license) {
        return post(query(endpoint + "/register"), license);
    },
    setServerUrl: function (url) {
        return post(query(endpoint + "/set_server_url"), { serverUrl: url });
    },
};
export default licenseApi;
