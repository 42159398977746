export var Reports;
(function (Reports) {
    Reports["CALENDAR_EVENT"] = "calendarEvent.pdf";
    Reports["CALENDAR_EVENT_PERIOD"] = "calendarEventPeriod.pdf";
    Reports["CHECKLIST_ARCHIVE"] = "productions-archive.pdf";
    Reports["CHECKLIST_CONTENT"] = "production.pdf";
    Reports["CHECKLIST_GRID"] = "productions-grid.pdf";
    Reports["CGS_CONTENT"] = "cgs.pdf";
    Reports["GUIDELINE_ARCHIVE"] = "assignments-archive.pdf";
    Reports["GUIDELINE_CONTENT"] = "assignment.pdf";
    Reports["GUIDELINE_GRID"] = "assignments-grid.pdf";
    Reports["NEWS_CONTENT"] = "assignment-proposal.pdf";
    Reports["REPORTAGE_ARCHIVE"] = "news-reports-archive.pdf";
    Reports["REPORTAGE_CONTENT"] = "news-report.pdf";
    Reports["REPORT_CONTENT"] = "report.pdf";
    Reports["REPORT_COMPACT_CONTENT"] = "report-compact.pdf";
    Reports["ROUND_CONTENT"] = "round.pdf";
    Reports["RUNDOWN_GRID"] = "rundown-grid.pdf";
    Reports["RUNDOWN_DISPLAY"] = "rundown-display.pdf";
    Reports["STORY_CONTENT"] = "story.pdf";
    Reports["STORY_COMPACT_CONTENT"] = "story-compact.pdf";
    Reports["STORY_PLAYLIST_CONTENT"] = "story-playlist.pdf";
    Reports["STORY_GRID"] = "stories-grid.pdf";
    Reports["STORY_VO"] = "story-vo.pdf";
    Reports["STORY_CONTENT_DRAWER"] = "story-drawer.pdf";
    Reports["TEAM_GRID"] = "team-grid.pdf";
})(Reports || (Reports = {}));
export var ReportOrigin;
(function (ReportOrigin) {
    ReportOrigin["ASSIGNMENT"] = "ASSIGNMENT";
    ReportOrigin["PRODUCTION"] = "PRODUCTION";
    ReportOrigin["NEWS_REPORT"] = "NEWS_REPORT";
    ReportOrigin["STORY"] = "STORY";
})(ReportOrigin || (ReportOrigin = {}));
