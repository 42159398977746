import React, { memo } from 'react'
import styled from '@emotion/styled'

import { TranslationDictionary } from '@anews/types'

import { useTranslation } from '../../i18n'

const Exception = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 500px;
`
const ImageBox = styled.div`
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
  @media screen and (max-width: 1200px) {
    /* ant: @screen-xl */
    padding-right: 88px;
  }
`
const Image = styled.div<{ src: string }>`
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-image: url(${props => props.src});
`
const Content = styled.div`
  flex: auto;
`
const Title = styled.h1`
  margin-bottom: 24px;
  color: #434e59;
  font-weight: 500;
  font-size: 72px;
  line-height: 72px;
`
const Description = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 28px;
`

export const errorsDesc: {
  [x: number]: { img: string; title: string; desc: TranslationDictionary }
} = {
  403: {
    img: '/403.svg',
    title: '403',
    desc: 'error:route403',
  },
  404: {
    img: '/404.svg',
    title: '404',
    desc: 'error:route404',
  },
  500: {
    img: '/500.svg',
    title: '500',
    desc: 'error:route500',
  },
}

export interface ExceptionMessageProps {
  errorCode: 403 | 404 | 500
}

function ExceptionMessage({ errorCode }: ExceptionMessageProps) {
  const { t } = useTranslation()
  const values = errorCode in errorsDesc ? errorsDesc[errorCode] : errorsDesc[500]
  return (
    <Exception>
      <ImageBox>
        <Image src={values.img} />
      </ImageBox>
      <Content>
        <Title>{values.title}</Title>
        <Description>{t(values.desc)}</Description>
      </Content>
    </Exception>
  )
}

export default memo(ExceptionMessage)
