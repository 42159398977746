export var CounterEntityType;
(function (CounterEntityType) {
    CounterEntityType["SUMMARY"] = "SUMMARY";
    CounterEntityType["DISPLAYED"] = "DISPLAYED";
    CounterEntityType["PROGRAM"] = "PROGRAM";
    CounterEntityType["DOCUMENT_KIND"] = "DOCUMENT_KIND";
    CounterEntityType["STATUS"] = "STATUS";
    CounterEntityType["PRODUCER"] = "PRODUCER";
    CounterEntityType["REPORTER"] = "REPORTER";
    CounterEntityType["EDITOR"] = "EDITOR";
    CounterEntityType["VEHICLE"] = "VEHICLE";
    CounterEntityType["EDITORIAL"] = "EDITORIAL";
    CounterEntityType["CLASSIFICATION"] = "CLASSIFICATION";
    CounterEntityType["IMAGE_EDITOR"] = "IMAGE_EDITOR";
})(CounterEntityType || (CounterEntityType = {}));
export var CounterSummary;
(function (CounterSummary) {
    CounterSummary[CounterSummary["ALL"] = 0] = "ALL";
    CounterSummary[CounterSummary["DRAWER"] = 1] = "DRAWER";
    CounterSummary[CounterSummary["STAND_BY"] = 2] = "STAND_BY";
    CounterSummary[CounterSummary["DISPLAYED"] = 3] = "DISPLAYED";
})(CounterSummary || (CounterSummary = {}));
var GuidelineState;
(function (GuidelineState) {
    GuidelineState[GuidelineState["TO_AIR"] = 0] = "TO_AIR";
    GuidelineState[GuidelineState["ON_DRAWER"] = 1] = "ON_DRAWER";
    GuidelineState[GuidelineState["ON_STANDBY"] = 2] = "ON_STANDBY";
})(GuidelineState || (GuidelineState = {}));
