/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import { memo, ReactNode } from 'react'
import { CloseOutlined, InboxOutlined } from '@ant-design/icons'

import Drawer, { DrawerProps } from 'antd/es/drawer'
import Tooltip from 'antd/es/tooltip'

import theme from '../../theme'

// Baseado no visual de SettingDrawer do projeto https://github.com/ant-design/ant-design-pro-layout

const handlerIconCss = css`
  color: #fff;
  font-size: 20px;
`

interface Props extends DrawerProps {
  children?: ReactNode
  hidden?: boolean
  open?: boolean
  tooltip: string
  onOpenToggle: () => void
}

function DrawerWithHandle({
  children,
  hidden,
  mask,
  open,
  tooltip,
  width,
  onOpenToggle,
  ...rest
}: Props) {
  const widthPx = typeof width === 'number' ? `${width}px` : width

  const handler = (
    <div
      role="button"
      tabIndex={0}
      onClick={() => onOpenToggle()}
      onKeyUp={() => onOpenToggle()}
      css={css`
        position: absolute;
        top: 300px;
        right: ${widthPx};
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: 16px;
        text-align: center;
        background-color: ${theme.colors.blue};
        border-radius: 4px 0 0 4px;
        cursor: pointer;
        pointer-events: auto;
      `}
    >
      {open ? <CloseOutlined css={handlerIconCss} /> : <InboxOutlined css={handlerIconCss} />}
    </div>
  )

  return (
    <Drawer
      handler={
        open ? (
          handler
        ) : hidden ? null : (
          <Tooltip title={tooltip} placement="left">
            {handler}
          </Tooltip>
        )
      }
      // Quando mask={false}, o handler estava ficando fora do lugar quando a drawer estava fechada
      // Deixando a mask ativa quando a drawer está fechada resolveu
      mask={!open || mask}
      closable={false}
      keyboard={false}
      width={width}
      placement="right"
      visible={open}
      onClose={() => onOpenToggle()}
      // Esse CSS foi baseado na versão 4.2.2 do antd. Sem isso, o efeito do drag fica prejudicado
      css={css`
        z-index: 0;
        width: 100% !important;
        height: 0;
        .ant-drawer-content-wrapper {
          width: ${widthPx} !important;
          height: 100vh;
        }
      `}
      {...rest}
    >
      <div
        css={css`
          position: relative;
          min-height: 100%;
        `}
      >
        {children}
      </div>
    </Drawer>
  )
}

export default memo(DrawerWithHandle)
