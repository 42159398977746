import { DocumentType } from '@anews/types'

import { RootState } from '../reducers'

import { createMapSelector } from './helpers'

const getTypes = (state: RootState) => state.types.list.data

export const typesMap = createMapSelector<DocumentType, Partial<DocumentType>>(
  getTypes,
  'id',
  ({ abbreviation, color }) => ({
    abbreviation,
    color,
  }),
)
