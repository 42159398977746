import { del, get, query, post as httpPost, put } from './client';
var endpoint = '/api/posts';
var postApi = {
    pageFilter: function (from, to, filter, page, size) {
        if (filter === void 0) { filter = ''; }
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", { from: from, to: to, filter: filter, page: page, size: size }));
    },
    load: function (id, lock) {
        return get(query(endpoint + "/" + id, { lock: lock }));
    },
    create: function (post) {
        return httpPost(endpoint, post);
    },
    createCopy: function (id) {
        return httpPost(endpoint + "/" + id + "/copy");
    },
    createFromFacebookPost: function (postId) {
        return httpPost(endpoint + "/facebook/" + postId);
    },
    createFromNews: function (newsId) {
        return httpPost(endpoint + "/proposal/" + newsId);
    },
    createFromGuideline: function (guidelineId) {
        return httpPost(endpoint + "/assignment/" + guidelineId);
    },
    createFromReportage: function (reportageId) {
        return httpPost(endpoint + "/reportage/" + reportageId);
    },
    createFromRssItem: function (itemId) {
        return httpPost(endpoint + "/rss/" + itemId);
    },
    createFromStory: function (storyId) {
        return httpPost(endpoint + "/story/" + storyId);
    },
    createFromTweet: function (tweet) {
        return httpPost(endpoint + "/twitter", tweet);
    },
    update: function (post) {
        return put(endpoint, post);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
    publish: function (wordpressId, id, authorId, categoryId) {
        return httpPost(query(endpoint + "/" + id + "/publish", { wordpressId: wordpressId, authorId: authorId, categoryId: categoryId }));
    },
    unpublish: function (id) {
        return del(endpoint + "/" + id + "/unpublish");
    },
};
export default postApi;
