import { get, query, post, put, del } from './client';
var endpoint = '/api/teams';
var teamApi = {
    // listAll(): Promise<Team[]> {
    //   return get(`${endpoint}/all`)
    // },
    load: function (id) {
        return get(endpoint + "/" + id);
    },
    listByDate: function (date, includeRecentDeactivations) {
        if (includeRecentDeactivations === void 0) { includeRecentDeactivations = false; }
        return get(query(endpoint, { date: date, includeRecentDeactivations: includeRecentDeactivations }));
    },
    pastUsed: function (id, date) {
        return get(query(endpoint + "/" + id + "/past_used", { date: date }));
    },
    dayUsed: function (id, date) {
        return get(query(endpoint + "/" + id + "/day_used", { date: date }));
    },
    createTeam: function (team) {
        return post(endpoint, team);
    },
    createOneDayChange: function (originalId, date, team) {
        return post(query(endpoint + "/" + originalId + "/one_day_change", { date: date }), team);
    },
    createForwardChange: function (originalId, date, team) {
        return post(query(endpoint + "/" + originalId + "/forward_change", { date: date }), team);
    },
    updateTeam: function (team) {
        return put(endpoint, team);
    },
    remove: function (id, date) {
        return del(query(endpoint + "/" + id, { date: date }));
    },
};
export default teamApi;
