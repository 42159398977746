import { DebouncedFunc, DebounceSettings } from 'lodash'
import debounce from 'lodash/debounce'
import { useMemo } from 'react'

export default function useDebouncedFunction<T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings,
): DebouncedFunc<T> {
  return useMemo(() => debounce(func, wait, options), [func, wait, options])
}
