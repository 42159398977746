import { call, put, takeLatest } from 'redux-saga/effects'

import { vehicleApi as api } from '@anews/api'
import { Vehicle } from '@anews/types'

import { NotificationActions, VehicleActionType as ActionType, VehicleActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { listVehiclesSuccess, listVehiclesFailure } = VehicleActions
const { notifyError } = NotificationActions

/* Watchers */

function* listVehiclesSaga(): Generator {
  try {
    const vehicles = yield call(api.list)
    yield put(listVehiclesSuccess(vehicles as Vehicle[]))
  } catch (error) {
    yield put(listVehiclesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listVehiclesSaga)
  },
])
