import { del, get, patch, put, query } from './client';
var endpoint = '/api/story_templates';
var storyTemplateApi = {
    update: function (story) {
        return put(endpoint, story);
    },
    updateField: function (id, field, value) {
        var _a;
        // Enviar undefined não funciona para limpar o valor
        return patch(endpoint + "/" + id, (_a = {}, _a[field] = value === undefined ? null : value, _a));
    },
    remove: function (ids) {
        return del(query("" + endpoint, { ids: ids }));
    },
    load: function (id) {
        return get(query(endpoint + "/" + id));
    },
};
export default storyTemplateApi;
