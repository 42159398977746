import { call, put, takeLatest } from 'redux-saga/effects'

import { newsApi as api } from '@anews/api'
import { News, Page } from '@anews/types'

import { NewsActions, NewsActionMap, NewsActionType } from '../actions/news-actions'

import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  refreshSidebarSuccess,
  refreshSidebarFailure,
  filterNewsSuccess,
  filterNewsFailure,
  loadNewsSuccess,
  loadNewsFailure,
  createNewsSuccess,
  createNewsFailure,
  updateNewsSuccess,
  updateNewsFailure,
  removeNewsSuccess,
  removeNewsFailure,
} = NewsActions

const { notifyError, notifyWarning } = NotificationActions

/* Watchers */

function* refreshSidebarSaga(
  action: NewsActionMap<NewsActionType.REFRESH_SIDEBAR_REQUEST>,
): Generator {
  try {
    const result = yield call(api.pageFilter, undefined, undefined, undefined, 0, action.size)
    yield put(refreshSidebarSuccess(result as Page<News>))
  } catch (error) {
    yield put(refreshSidebarFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* filterNewsSaga(action: NewsActionMap<NewsActionType.FILTER_REQUEST>): Generator {
  try {
    const result = yield call(
      api.pageFilter,
      action.from,
      action.to,
      action.filter,
      action.page,
      action.size,
    )
    yield put(filterNewsSuccess(result as Page<News>))
  } catch (error) {
    yield put(filterNewsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadNewsSaga(action: NewsActionMap<NewsActionType.LOAD_REQUEST>): Generator {
  try {
    const news = yield call(api.load, action.id, action.edit)
    yield put(loadNewsSuccess(news as News, action.edit))
  } catch (error) {
    yield put(loadNewsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createNewsSaga(action: NewsActionMap<NewsActionType.CREATE_REQUEST>): Generator {
  try {
    const news = yield call(api.create, action.news)
    yield put(createNewsSuccess(news as News))
  } catch (error) {
    yield put(createNewsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateNewsSaga(action: NewsActionMap<NewsActionType.UPDATE_REQUEST>): Generator {
  try {
    const news = yield call(api.update, action.news)
    yield put(updateNewsSuccess(news as News))
  } catch (error) {
    yield put(updateNewsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeNewsSaga(action: NewsActionMap<NewsActionType.REMOVE_REQUEST>): Generator {
  try {
    const successIds = (yield call(api.remove, action.ids)) as number[]
    yield put(removeNewsSuccess(successIds as number[]))

    if (action.ids.length > successIds.length) {
      yield put(
        notifyWarning({
          message: i18n.t('words:warning'),
          description: i18n.t('phrases:inUseCantDelete'),
        }),
      )
    }
  } catch (error) {
    yield put(removeNewsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(NewsActionType.REFRESH_SIDEBAR_REQUEST, refreshSidebarSaga)
  },
  function* () {
    yield takeLatest(NewsActionType.FILTER_REQUEST, filterNewsSaga)
  },
  function* () {
    yield takeLatest(NewsActionType.LOAD_REQUEST, loadNewsSaga)
  },
  function* () {
    yield takeLatest(NewsActionType.CREATE_REQUEST, createNewsSaga)
  },
  function* () {
    yield takeLatest(NewsActionType.UPDATE_REQUEST, updateNewsSaga)
  },
  function* () {
    yield takeLatest(NewsActionType.REMOVE_REQUEST, removeNewsSaga)
  },
])
