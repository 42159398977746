import { EntityLock } from '@anews/types'

export enum EntityLockActionType {
  LIST_ACTIVE_REQUEST = '@lock/LIST_ACTIVE_REQUEST',
  LIST_ACTIVE_SUCCESS = '@lock/LIST_ACTIVE_SUCCESS',
  LIST_ACTIVE_FAILURE = '@lock/LIST_ACTIVE_FAILURE',
  RENEW_REQUEST = '@lock/RENEW_REQUEST',
  RENEW_SUCCESS = '@lock/RENEW_SUCCESS',
  RENEW_FAILURE = '@lock/RENEW_FAILURE',
  TAKE_OVER_REQUEST = '@lock/TAKE_OVER_REQUEST',
  TAKE_OVER_SUCCESS = '@lock/TAKE_OVER_SUCCESS',
  TAKE_OVER_FAILURE = '@lock/TAKE_OVER_FAILURE',
  UNLOCK_REQUEST = '@lock/UNLOCK_REQUEST',
  UNLOCK_SUCCESS = '@lock/UNLOCK_SUCCESS',
  UNLOCK_FAILURE = '@lock/UNLOCK_FAILURE',
  WS_LOCK = '@lock/WS_LOCK',
  WS_UNLOCK = '@lock/WS_UNLOCK',
}

export type EntityLockActionMap<T extends EntityLockActionType> = { type: T } & EntityLockAction

export type EntityLockAction = ReturnType<typeof EntityLockActions[keyof typeof EntityLockActions]>

export const EntityLockActions = {
  //  listActiveLocks()

  listActiveLocks: () => <const>{ type: EntityLockActionType.LIST_ACTIVE_REQUEST },
  listActiveLocksSuccess: (locks: EntityLock[]) =>
    <const>{ type: EntityLockActionType.LIST_ACTIVE_SUCCESS, locks },
  listActiveLocksFailure: (error: Error) =>
    <const>{ type: EntityLockActionType.LIST_ACTIVE_FAILURE, error },

  //  renewLock()

  renewLock: (uuid: string) => <const>{ type: EntityLockActionType.RENEW_REQUEST, uuid },
  renewLockSuccess: (lock: EntityLock) => <const>{ type: EntityLockActionType.RENEW_SUCCESS, lock },
  renewLockFailure: (error: Error) => <const>{ type: EntityLockActionType.RENEW_FAILURE, error },

  //  takeOverLock()

  takeOverLock: (uuid: string) => <const>{ type: EntityLockActionType.TAKE_OVER_REQUEST, uuid },
  takeOverLockSuccess: (lock: EntityLock) =>
    <const>{ type: EntityLockActionType.TAKE_OVER_SUCCESS, lock },
  takeOverLockFailure: (error: Error) =>
    <const>{ type: EntityLockActionType.TAKE_OVER_FAILURE, error },

  //  unlock()

  unlock: (uuid: string) => <const>{ type: EntityLockActionType.UNLOCK_REQUEST, uuid },
  unlockSuccess: (uuid: string) => <const>{ type: EntityLockActionType.UNLOCK_SUCCESS, uuid },
  unlockFailure: (error: Error) => <const>{ type: EntityLockActionType.UNLOCK_FAILURE, error },

  //  wsLock()

  wsLock: (lock: EntityLock) => <const>{ type: EntityLockActionType.WS_LOCK, lock },

  //  wsUnlock()

  wsUnlock: (lock: EntityLock) => <const>{ type: EntityLockActionType.WS_UNLOCK, lock },
}
