import { Chat, Message } from '@anews/types'

export enum ChatsActionType {
  // openChat(chatId)
  OPEN_CHAT = '@chat/OPEN_CHAT',
  // minimizar chat/minimizeChat(chatId)
  MINIMIZE_CHAT = '@chat/MINIMIZE_CHAT',
  MINIMIZE_ALL = '@chat/MINIMIZE_ALL',
  // closeChat()
  CLOSE_CHAT = '@chat/CLOSE_CHAT',
  // createChat()
  CREATE_REQUEST = '@chat/CREATE_REQUEST',
  CREATE_SUCCESS = '@chat/CREATE_SUCCESS',
  CREATE_FAILURE = '@chat/CREATE_FAILURE',
  // loadChat(id)
  LOAD_REQUEST = '@chat/LOAD_REQUEST',
  LOAD_SUCCESS = '@chat/LOAD_SUCCESS',
  LOAD_FAILURE = '@chat/LOAD_FAILURE',
  // loadByMembers(userIds)
  LOAD_BY_MEMBERS_REQUEST = '@chat/LOAD_BY_MEMBERS_REQUEST',
  LOAD_BY_MEMBERS_SUCCESS = '@chat/LOAD_BY_MEMBERS_SUCCESS',
  LOAD_BY_MEMBERS_FAILURE = '@chat/LOAD_BY_MEMBERS_FAILURE',
  // addMembers(userIds)
  ADD_MEMBERS_REQUEST = '@chat/ADD_MEMBERS_REQUEST',
  ADD_MEMBERS_SUCCESS = '@chat/ADD_MEMBERS_SUCCESS',
  ADD_MEMBERS_FAILURE = '@chat/ADD_MEMBERS_FAILURE',
  MEMBER_LEFT = '@chat/MEMBER_LEFT',
  // leaveChat(id)
  LEAVE_REQUEST = '@chat/LEAVE_REQUEST',
  LEAVE_SUCCESS = '@chat/LEAVE_SUCCESS',
  LEAVE_FAILURE = '@chat/LEAVE_FAILURE',
  // sendMessage(id, message)
  SEND_MESSAGE_REQUEST = '@chat/SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS = '@chat/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE = '@chat/SEND_MESSAGE_FAILURE',
  // unreads()
  UNREAD_REQUEST = '@chat/UNREAD_REQUEST',
  UNREAD_SUCCESS = '@chat/UNREAD_SUCCESS',
  UNREAD_FAILURE = '@chat/UNREAD_FAILURE',
  // previousMessages(id, date)
  PREVIOUS_MESSAGES_REQUEST = '@chat/PREVIOUS_MESSAGES_REQUEST',
  PREVIOUS_MESSAGES_SUCCESS = '@chat/PREVIOUS_MESSAGES_SUCCESS',
  PREVIOUS_MESSAGES_FAILURE = '@chat/PREVIOUS_MESSAGES_FAILURE',
  // loadMessage(id)
  LOAD_MESSAGE_REQUEST = '@chat/LOAD_MESSAGE_REQUEST',
  LOAD_MESSAGE_SUCCESS = '@chat/LOAD_MESSAGE_SUCCESS',
  LOAD_MESSAGE_FAILURE = '@chat/LOAD_MESSAGE_FAILURE',
  // markRead(id)
  MARK_READ_REQUEST = '@chat/MARK_READ_REQUEST',
  MARK_READ_SUCCESS = '@chat/MARK_READ_SUCCESS',
  MARK_READ_FAILURE = '@chat/MARK_READ_FAILURE',
  // addGroup(groupId, currentMembersIds)
  ADD_GROUP = '@chat/ADD_GROUP',
  // clear
  CLEAR = '@chat/CLEAR',
}

export type ChatsActionMap<T extends ChatsActionType> = {
  type: T
} & ChatsAction

export type ChatsAction = ReturnType<typeof ChatsActions[keyof typeof ChatsActions]>

export const ChatsActions = {
  openChat: (chatId: number[]) => <const>{ type: ChatsActionType.OPEN_CHAT, chatId },
  minimizeChat: (chatId: number) => <const>{ type: ChatsActionType.MINIMIZE_CHAT, chatId },
  minimizeAll: () => <const>{ type: ChatsActionType.MINIMIZE_ALL },
  closeChat: (chat: Chat) => <const>{ type: ChatsActionType.CLOSE_CHAT, chat },

  // createChat()

  createChat: (userIds: number[]) => <const>{ type: ChatsActionType.CREATE_REQUEST, userIds },
  createChatSuccess: (chat: Chat) => <const>{ type: ChatsActionType.CREATE_SUCCESS, chat },
  createChatFailure: (error: Error) => <const>{ type: ChatsActionType.CREATE_FAILURE, error },

  // loadChat()

  loadChat: (id: number) => <const>{ type: ChatsActionType.LOAD_REQUEST, id },
  loadChatSuccess: (chat: Chat) => <const>{ type: ChatsActionType.LOAD_SUCCESS, chat },
  loadChatFailure: (error: Error) => <const>{ type: ChatsActionType.LOAD_FAILURE, error },

  // loadByMembers()

  loadByMembers: (userIds: number[]) =>
    <const>{ type: ChatsActionType.LOAD_BY_MEMBERS_REQUEST, userIds },
  loadByMembersSuccess: (chat: Chat) =>
    <const>{ type: ChatsActionType.LOAD_BY_MEMBERS_SUCCESS, chat },
  loadByMembersFailure: (error: Error) =>
    <const>{ type: ChatsActionType.LOAD_BY_MEMBERS_FAILURE, error },

  // markAsRead()
  /**
   * Recebe as mensagens que não são do usuário logado
   * e que não foram lidas ainda e para cada uma, faz um request
   * no servidor para marcar como lida
   * @param {number[]} messageIds ids das mensagens
   */
  markAsRead: (messageIds: number[]) =>
    <const>{ type: ChatsActionType.MARK_READ_REQUEST, messageIds },
  markAsReadSuccess: (message: Message) =>
    <const>{ type: ChatsActionType.MARK_READ_SUCCESS, message },
  markAsReadFailure: (error: Error) => <const>{ type: ChatsActionType.MARK_READ_FAILURE, error },

  // unreadChats()
  unreadChats: () => <const>{ type: ChatsActionType.UNREAD_REQUEST },
  unreadChatsSuccess: (chats: Chat[]) => <const>{ type: ChatsActionType.UNREAD_SUCCESS, chats },
  unreadChatsFailure: (error: Error) => <const>{ type: ChatsActionType.UNREAD_FAILURE, error },

  // leaveChat()
  leaveChat: (id: number) => <const>{ type: ChatsActionType.LEAVE_REQUEST, id },
  leaveChatSuccess: (chat: Chat) => <const>{ type: ChatsActionType.LEAVE_SUCCESS, chat },
  leaveChatFailure: (error: Error) => <const>{ type: ChatsActionType.LEAVE_FAILURE, error },

  // addMembers()
  addMembers: (id: number, userIds: number[], currentMembersIds: number[]) =>
    <const>{ type: ChatsActionType.ADD_MEMBERS_REQUEST, id, userIds, currentMembersIds },
  addMembersSuccess: (chat: Chat) => <const>{ type: ChatsActionType.ADD_MEMBERS_SUCCESS, chat },
  addMembersFailure: (error: Error) => <const>{ type: ChatsActionType.ADD_MEMBERS_FAILURE, error },
  memberLeft: (chat: Chat) => <const>{ type: ChatsActionType.MEMBER_LEFT, chat },

  addGroup: (id: number, groupId: number, currentMembersIds: number[]) =>
    <const>{ type: ChatsActionType.ADD_GROUP, id, groupId, currentMembersIds },

  cleanChats: () => <const>{ type: ChatsActionType.CLEAR },
}
