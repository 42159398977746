import { User, DEFAULT_READ_RATE } from '@anews/types'
import { secondsToIsoDuration } from '@anews/utils'

export function calculateReadDuration(user: User | undefined, text: string | undefined): string {
  const readRate = user?.readRate || DEFAULT_READ_RATE
  const readableText = (text || '')
    // .replace(/\[+[^\]]*\]*/gm, '') // não exige fechar o colchete
    .replace(/\[+[^\]]*\]+/gm, '') // exige fechar o colchete
    .replace(/[\n\t\r]/gm, '')
    .trim()

  const seconds = Math.round(readableText.length * readRate)
  return secondsToIsoDuration(seconds)
}
