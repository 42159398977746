import React, { memo, useCallback, useEffect, useState } from 'react'

import Modal from 'antd/es/modal'
import Table from 'antd/es/table'
import Tooltip from 'antd/es/tooltip'

import { EyeOutlined, PlusOutlined } from '@ant-design/icons'

import { TableRowSelection } from 'antd/es/table/interface'

import Space from 'antd/es/space'

import { iiTemplateApi, storageFileApi } from '@anews/api'
import { useCall } from '@anews/hooks'
import { Device, IITemplate } from '@anews/types'

import { useTranslation } from '../../../i18n'

import FilterCGTemplates from './FilterCGTemplates'

interface Props {
  visible: boolean
  cg: Device
  onSelect: (...selection: IITemplate[]) => void
  onClose: () => void
}

function SelectCGTemplateModal({ visible, cg, onSelect, onClose }: Props) {
  const { t } = useTranslation()

  const [templates] = useCall(iiTemplateApi.list, [], [cg.id])
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
  const [data, setData] = useState<IITemplate[]>([])

  const selectHandler = () => {
    onSelect(
      ...selectedRowKeys
        .map(id => templates.data.find(template => template.id === id))
        .filter(template => template !== undefined)
        .map(template => template!),
    )
  }

  const rowSelection: TableRowSelection<IITemplate> = {
    onChange: (selectedKeys: (number | string)[]) => {
      setSelectedRowKeys(selectedKeys as number[])
    },
    selectedRowKeys,
  }

  useEffect(() => {
    setData(templates.data)
  }, [templates.data])

  // Filtro dos templates GCs
  const onFilterChange = useCallback(
    (text: string | undefined) => {
      setData(
        templates.data.filter(template =>
          template.name.toLocaleLowerCase().includes(text!.toLowerCase()),
        ),
      )
    },
    [templates.data],
  )

  return (
    <Modal
      title={t('template:choose')}
      cancelText={t('words:close')}
      okText={t('words:add')}
      okButtonProps={{ disabled: selectedRowKeys.length === 0 }}
      onOk={selectHandler}
      onCancel={onClose}
      visible={visible}
      maskClosable
      bodyStyle={{ maxHeight: 600, overflowY: 'auto', padding: '0 8px' }}
    >
      <FilterCGTemplates onFilterChange={onFilterChange} />

      <Table
        dataSource={data}
        columns={[
          {
            key: 'name',
            render: (_, { name, fields }) => (
              <>
                <span>{name} </span>
                <span>
                  {fields.length === 0 ? '' : `(${fields.map(field => field.name).join(', ')})`}
                </span>
              </>
            ),
          },
          {
            key: 'actions',
            render: (_, template) => (
              <Space size="middle">
                {template.thumbnailId ? (
                  <Tooltip
                    title={
                      template.thumbnailId && (
                        <img
                          src={storageFileApi.getThumbSrc(template.thumbnailId)}
                          style={{ maxWidth: 234 }}
                          alt="thumbnail"
                        />
                      )
                    }
                  >
                    <EyeOutlined />
                  </Tooltip>
                ) : (
                  <EyeOutlined style={{ visibility: 'hidden' }} />
                )}
                <Tooltip key="add" title={t('words:add')}>
                  <PlusOutlined
                    onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                      event.stopPropagation()
                      onSelect(template)
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Space>
            ),
          },
        ]}
        rowSelection={rowSelection}
        onRow={template => ({
          onClick: () => setSelectedRowKeys([template.id]),
        })}
        pagination={false}
        showHeader={false}
        rowKey="id"
        size="small"
      />
    </Modal>
  )
}

export default memo(SelectCGTemplateModal)
