import { del, get, post, put, query } from './client';
var endpoint = '/api/twitter/categories';
var twitterCategoryApi = {
    list: function (users) {
        return get(query(endpoint, { users: users }));
    },
    create: function (category) {
        return post(endpoint, category);
    },
    update: function (category) {
        return put("" + endpoint, category);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default twitterCategoryApi;
