import { del, get, post } from './client';
var authApi = {
    login: function (username, password, isResetPassword) {
        var data = "username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&isResetPassword=" + isResetPassword;
        return post('/api/authentication', data, {
            headers: { 'Contenty-Type': 'application/x-www-form-urlencoded' },
        });
    },
    logout: function () {
        return post('/api/logout');
    },
    me: function () {
        return get('/api/users/me');
    },
    kickOut: function (id) {
        return del("/api/users/" + id + "/session");
    },
    authorities: function () {
        return get('/api/authorities');
    },
};
export default authApi;
