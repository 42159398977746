import { get, post, put, query } from './client';
var endpoint = '/api/souv';
var souvApi = {
    getWorkspaces: function () {
        return get(query(endpoint + "/workspaces"));
    },
    //
    // Souv Documents
    //
    getDocument: function (workspaceId, id) {
        return get(query(endpoint + "/documents/" + id, { workspaceId: workspaceId }));
    },
    filterDocuments: function (workspaceId, text, type, page, limit) {
        if (page === void 0) { page = 0; }
        if (limit === void 0) { limit = 25; }
        return get(query(endpoint + "/documents/search", { workspaceId: workspaceId, text: text, type: type, page: page, limit: limit }));
    },
    checkCredentials: function () {
        return get(endpoint + "/check_credentials");
    },
    //
    // Souv Config
    //
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default souvApi;
