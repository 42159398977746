import { CopyOutlined } from '@ant-design/icons'

import React, { memo, useCallback, useState } from 'react'

import { useTranslation } from '../../../../i18n'
import { copyToClipboard } from '../../../../utils/clipboard-utils'

function CopyableCGValue({ value }: { value: string }) {
  const { t } = useTranslation()

  const [hover, setHover] = useState(false)

  const onOver = useCallback(() => setHover(true), [])
  const onOut = useCallback(() => setHover(false), [])

  return (
    <span
      role="button"
      tabIndex={0}
      style={{ outline: 'none', cursor: 'pointer' }}
      onClick={e => {
        if (e.detail === 1) {
          copyToClipboard(
            value,
            `${t('phrases:copiedToClipboard')}: "${value}"`,
            t('error:actionFailed'),
          )
        }
      }}
      onKeyPress={() => {}}
      onMouseOver={onOver}
      onMouseOut={onOut}
      onFocus={onOver}
      onBlur={onOut}
    >
      <span>{value} </span>
      <CopyOutlined style={{ visibility: hover ? 'visible' : 'hidden' }} />
    </span>
  )
}

export default memo(CopyableCGValue)
