export function richToPlain(content, stripComments) {
    if (stripComments === void 0) { stripComments = false; }
    var result = '';
    if (!content) {
        return result;
    }
    content.forEach(function (p) {
        if (Array.isArray(p.children)) {
            p.children.forEach(function (leaf) {
                if (!(stripComments && leaf.comment))
                    result += leaf.text;
            });
        }
        result += '\n';
    });
    return result;
}
export function toggleRichFormat(text, format) {
    if (!text) {
        return undefined;
    }
    var clonedText = JSON.parse(JSON.stringify(text));
    clonedText.forEach(function (p) {
        if (Array.isArray(p.children)) {
            p.children.forEach(function (leaf) {
                leaf[format] = !leaf[format];
            });
        }
    });
    return clonedText;
}
export function richHasContent(text) {
    return !!(Array.isArray(text) &&
        text.find(function (t) {
            return Array.isArray(t === null || t === void 0 ? void 0 : t.children) &&
                t.children.find(function (c) { return typeof (c === null || c === void 0 ? void 0 : c.text) === 'string' && c.text.trim().length > 0; });
        }));
}
