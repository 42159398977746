import { Reducer } from 'redux'
import produce from 'immer'

import { ChecklistConfig, Checklist, Guideline } from '@anews/types'

import { ChecklistAction, ChecklistActionType as ActionType } from '../actions/checklist-actions'

import { ConfigState } from './types'

export interface ChecklistsState {
  dictionary: { [checklistId: number]: Checklist }
  list: {
    date?: string
    onlyNotDone: boolean
    ids: number[]
    loading: boolean
  }
  editing: {
    ids: number[]
    newData?: Checklist
    active?: string
    saving: boolean
  }
  viewing?: Checklist
  viewingGuideline?: Guideline
  config: ConfigState<ChecklistConfig>
}

export const initialState: ChecklistsState = {
  dictionary: {},
  list: {
    onlyNotDone: false,
    ids: [],
    loading: false,
  },
  editing: {
    ids: [],
    saving: false,
  },
  config: {
    loading: false,
    saving: false,
  },
}

//
//  dictionary reducer
//

function dictionaryReducer(
  state: ChecklistsState['dictionary'],
  action: ChecklistAction,
): ChecklistsState['dictionary'] {
  switch (action.type) {
    //
    // DATE_LIST
    //
    case ActionType.DATE_LIST_SUCCESS:
      return produce(state, (draft: ChecklistsState['dictionary']) => {
        action.checklists.forEach(checklist => {
          draft[checklist.id] = checklist
        })
      })

    //
    //  LOAD/CREATE/UPDATE
    //
    case ActionType.LOAD_SUCCESS:
    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.CREATE_FROM_GUIDELINE_SUCCESS:
    case ActionType.WS_CREATE:
    case ActionType.WS_UPDATE:
      return produce(state, (draft: ChecklistsState['dictionary']) => {
        draft[action.checklist.id] = action.checklist
      })

    case ActionType.PATCH_REQUEST:
      return produce(state, draft => {
        // Atualização otimista (não espera o sucesso para atualizar o state)
        const { checklistId, field, newValue } = action
        Object.assign(draft[checklistId], { [field]: newValue })
      })

    case ActionType.PATCH_FAILURE:
      // Se o patch falhar, retorna o valor antigo
      return produce(state, draft => {
        const { checklistId, field, oldValue } = action
        Object.assign(draft[checklistId], { [field]: oldValue })
      })

    case ActionType.WS_PATCH:
      return produce(state, (draft: ChecklistsState['dictionary']) => {
        const { checklistId, changes } = action
        if (draft[checklistId]) {
          Object.assign(draft[checklistId], changes)
        }
      })

    default:
      return state
  }
}

//
//  list reducer
//

function listReducer(
  state: ChecklistsState['list'],
  action: ChecklistAction,
): ChecklistsState['list'] {
  switch (action.type) {
    //
    //  DATE_LIST...
    //
    case ActionType.DATE_LIST_REQUEST:
      return { ...state, loading: true }
    case ActionType.DATE_LIST_FAILURE:
      return { ...state, loading: false }
    case ActionType.DATE_LIST_SUCCESS:
      return {
        date: action.date,
        onlyNotDone: action.onlyNotDone,
        ids: action.checklists.map(c => c.id),
        loading: false,
      }

    //
    //  CREATE/UPDATE
    //
    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.CREATE_FROM_GUIDELINE_SUCCESS:
    case ActionType.WS_CREATE:
    case ActionType.WS_UPDATE: {
      const { id, date, done, tasks } = action.checklist
      const index = state.ids.findIndex(curr => curr === id)
      const sameDate = date === state.date || !!tasks?.find(t => t.date === state.date)
      const doneInclude = state.onlyNotDone ? !done : true

      // adiciona à lista
      if (index === -1 && sameDate && doneInclude) {
        return { ...state, ids: [id, ...state.ids] }
      }
      // remove da lista
      if (index !== -1 && (!sameDate || !doneInclude)) {
        const copy = Array.from(state.ids)
        copy.splice(index, 1)
        return { ...state, ids: copy }
      }
      return state
    }

    case ActionType.REMOVE_SUCCESS:
    case ActionType.WS_DELETE:
      return { ...state, ids: state.ids.filter(id => !action.ids.includes(id)) }

    //
    //  DEFAULT
    //
    default:
      return state
  }
}

//
//  viewing reducer
//

function viewingReducer(
  state: Checklist | undefined,
  action: ChecklistAction,
): Checklist | undefined {
  switch (action.type) {
    case ActionType.DATE_LIST_SUCCESS:
    case ActionType.UNLOAD:
    case ActionType.CLEAR:
      return undefined

    case ActionType.LOAD_SUCCESS:
      return action.edit ? undefined : action.checklist

    case ActionType.WS_UPDATE:
      return state?.id === action.checklist.id ? action.checklist : state

    case ActionType.REMOVE_SUCCESS:
    case ActionType.WS_DELETE:
      return state && action.ids.includes(state.id) ? undefined : state

    default:
      return state
  }
}

//
//  viewing guideline reducer
//

function viewingGuidelineReducer(
  state: Guideline | undefined,
  action: ChecklistAction,
): Guideline | undefined {
  switch (action.type) {
    case ActionType.DATE_LIST_SUCCESS:
    case ActionType.UNLOAD:
    case ActionType.CLEAR:
      return undefined

    case ActionType.LOAD_GUIDELINE_SUCCESS:
      return action.guideline

    default:
      return state
  }
}

//
//  editing reducer
//

function editingReducer(
  editing: ChecklistsState['editing'],
  dictionary: ChecklistsState['dictionary'],
  action: ChecklistAction,
): ChecklistsState['editing'] {
  switch (action.type) {
    case ActionType.NEW: {
      const { newData } = editing
      const { checklist } = action

      if (newData) {
        return { ...editing, active: newData.uuid }
      }
      return { ...editing, active: checklist.uuid, newData: checklist }
    }

    case ActionType.CREATE_FROM_GUIDELINE_SUCCESS: {
      const { checklist } = action
      const match = editing.ids.find(id => id === checklist.id)
      if (match) {
        return { ...editing, active: checklist.uuid }
      }
      return { ...editing, ids: [...editing.ids, checklist.id], active: checklist.uuid }
    }

    case ActionType.LOAD_SUCCESS: {
      const { edit, checklist } = action
      if (!edit) {
        return editing
      }
      const match = editing.ids.find(id => id === checklist.id)
      if (match) {
        return { ...editing, active: checklist.uuid }
      }
      return { ...editing, ids: [...editing.ids, checklist.id], active: checklist.uuid }
    }

    case ActionType.REMOVE_SUCCESS:
    case ActionType.WS_DELETE:
      return { ...editing, ids: editing.ids.filter(id => !action.ids.includes(id)) }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...editing, saving: true }

    case ActionType.CREATE_SUCCESS:
      // Esse if serve para evitar o bug de salvar produção nova ao fechar onde o form ficava aberto
      if (action.checklist.uuid === editing.newData?.uuid) {
        return {
          ...editing,
          saving: false,
          newData: undefined,
          ids: [action.checklist.id, ...editing.ids],
        }
      }
      return {
        ...editing,
        saving: false,
      }

    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.UPDATE_FAILURE:
      return { ...editing, saving: false }

    case ActionType.CHANGE_TAB:
      return { ...editing, active: action.key }

    case ActionType.CLOSE_TAB: {
      const checklist = Object.values(dictionary).find(c => c.uuid === action.key)
      const ids = editing.ids.filter(id => !checklist || checklist.id !== id)
      const newData =
        editing.newData && editing.newData.uuid === action.key ? undefined : editing.newData

      return { ...editing, active: undefined, ids, newData }
    }

    default:
      return editing
  }
}

//
//  config reducer
//

function configReducer(
  config: ConfigState<ChecklistConfig>,
  action: ChecklistAction,
): ConfigState<ChecklistConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

//
//  main reducer
//

const checklistReducer: Reducer<ChecklistsState, ChecklistAction> = (
  state = initialState,
  action,
): ChecklistsState => {
  if (action.type === ActionType.CLEAR) {
    return initialState
  }
  if (Object.values(ActionType).includes(action.type)) {
    return {
      ...state,
      config: configReducer(state.config, action),
      dictionary: dictionaryReducer(state.dictionary, action),
      list: listReducer(state.list, action),
      viewing: viewingReducer(state.viewing, action),
      viewingGuideline: viewingGuidelineReducer(state.viewingGuideline, action),
      editing: editingReducer(state.editing, state.dictionary, action),
    }
  }
  return state
}

export default checklistReducer
