import React, { memo } from 'react'

import Col from 'antd/es/col'
import Divider from 'antd/es/divider'
import Row from 'antd/es/row'
import Space from 'antd/es/space'

import { StorySection, StorySectionType, StorySubSectionType } from '@anews/types'
import { formatIsoDuration, isNotBlank, isBlank } from '@anews/utils'

import i18n, { useTranslation } from '../../../../i18n'
import { SelectorMap } from '../../../../redux/selectors/helpers'
import theme from '../../../../theme'

import { ViewerItem } from '../../../global'

import StorySubSectionView from './StorySubSectionView'

export function sectionLabel(section: StorySection, isRadio: boolean) {
  switch (section.type) {
    case StorySectionType.FOOTER:
      return i18n.t('story:footnote')
    case StorySectionType.HEADER:
      return isRadio ? i18n.t('story:voiceover') : i18n.t('story:head')
    case StorySectionType.LINK:
      return isRadio ? i18n.t('story:vivoIn') : i18n.t('story:linkIn')
    case StorySectionType.LIVE_VO:
      return i18n.t('story:imageIn')
    case StorySectionType.RECORDED_VO:
      return i18n.t('story:off')
    case StorySectionType.VT:
      return isRadio ? i18n.t('story:voicedIn') : i18n.t('story:vtIn')
    default:
      return null
  }
}

interface Props {
  section?: StorySection
  duration?: string
  users: SelectorMap<string>
  isRadio: boolean
  preview?: boolean
  hideCgs?: boolean
}

function StorySectionView({ section, duration, users, isRadio, preview, hideCgs }: Props) {
  const { t } = useTranslation()

  const formattedDuration =
    duration || section?.duration
      ? formatIsoDuration((duration || section?.duration)!, 'mm:ss')
      : undefined

  // Se a seção estiver completamente em branco, retorna null
  if (
    !section ||
    (isBlank(section.cueIn) &&
      isBlank(section.cueOut) &&
      formattedDuration === '00:00' &&
      section.subSections.length === 0)
  ) {
    return null
  }

  return (
    <>
      <Divider style={{ margin: '10px 0' }} />

      {/* Label da seção + Duração */}
      <Row>
        <Col span={18}>
          <ViewerItem.Header
            className="viewer-header"
            style={{ color: theme.colors.red, textTransform: 'uppercase' }}
          >
            [{sectionLabel(section, isRadio)}]
          </ViewerItem.Header>
        </Col>
        <Col span={6}>
          <ViewerItem.Header
            className="viewer-header"
            style={{ color: theme.colors.red, textAlign: 'right' }}
          >
            {formattedDuration}
          </ViewerItem.Header>
        </Col>
      </Row>

      {/* Sub-seções */}
      <Space direction="vertical" style={{ width: '100%', marginBottom: 8 }}>
        {/* Se a Section for do tipo 'LINK', mostra as subsection do tipo 'TEXT'/subsections da direita primeiro */}
        {section.type === StorySectionType.LINK
          ? section.subSections
              .filter(sub => sub.type === StorySubSectionType.TEXT)
              .concat(section.subSections.filter(sub => sub.type !== StorySubSectionType.TEXT))
              .map(sub => (
                <StorySubSectionView
                  key={sub.uuid}
                  section={section}
                  subSection={sub}
                  users={users}
                  preview={preview}
                  hideCgs={hideCgs}
                />
              ))
          : section.subSections.map(sub => (
              <StorySubSectionView
                key={sub.uuid}
                section={section}
                subSection={sub}
                users={users}
                preview={preview}
                hideCgs={hideCgs}
              />
            ))}
      </Space>

      {/* Deixa inicial */}
      {isNotBlank(section.cueIn) && (
        <Space align="baseline" style={{ width: '100%' }}>
          <ViewerItem.Header
            className="viewer-header"
            style={{ color: theme.colors.red, textTransform: 'uppercase' }}
          >
            {t('story:cueIn')}
          </ViewerItem.Header>
          <ViewerItem>{section.cueIn}</ViewerItem>
        </Space>
      )}

      {/* Deixa final */}
      {isNotBlank(section.cueOut) && (
        <Space align="baseline" style={{ width: '100%' }}>
          <ViewerItem.Header
            className="viewer-header"
            style={{ color: theme.colors.red, textTransform: 'uppercase' }}
          >
            {isNotBlank(section.cueIn) ? t('story:cueOut') : t('story:cue')}
          </ViewerItem.Header>
          <ViewerItem>{section.cueOut}</ViewerItem>
        </Space>
      )}

      {/* Sai imagem */}
      {section.type === StorySectionType.LIVE_VO && (
        <ViewerItem.Header
          className="viewer-header"
          style={{ color: theme.colors.red, textTransform: 'uppercase' }}
        >
          {t('story:imageOut')}
        </ViewerItem.Header>
      )}
    </>
  )
}

export default memo(StorySectionView)
