/** @jsx jsx */

import { jsx, css } from '@emotion/react'

import { memo, FC, ReactElement, useMemo } from 'react'
import styled from '@emotion/styled'

import Button from 'antd/es/button'
import Form, { Rule } from 'antd/es/form'

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import HGroup from './HGroup'

const FixedFormItem = styled(Form.Item)`
  .ant-form-item-label {
    line-height: 32px;
  }
`
const iconCss = css`
  cursor: pointer;
  color: #999;
  transition: all 0.3s;

  &:hover {
    color: #777;
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

function empty(value: any) {
  return !(Array.isArray(value) && value.length > 0)
}

export interface FormItemListProps {
  name: string
  label: string
  buttonLabel: string
  labelSpan: number
  wrapperSpan: number
  validationRules?: Rule[]
  inputRender: () => ReactElement
}

// Ideia retirada do exemplo "Dynamic Form Item" da página do Ant Design
// https://ant.design/components/form/
// https://codesandbox.io/s/4x224mnrw4
const FormItemList: FC<FormItemListProps> = ({
  name,
  label,
  buttonLabel,
  labelSpan,
  wrapperSpan,
  validationRules,
  inputRender,
}: FormItemListProps) => {
  const [formItemLayout, formItemLayoutWithOutLabel] = useMemo(
    () => [
      {
        labelCol: { span: labelSpan },
        wrapperCol: { span: wrapperSpan },
      },
      { wrapperCol: { span: wrapperSpan, offset: labelSpan } },
    ],
    [labelSpan, wrapperSpan],
  )

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <FixedFormItem
              {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
              label={index === 0 ? label : ''}
              style={{ marginBottom: '8px' }}
              required={false}
              key={field.key}
            >
              <HGroup>
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={validationRules}
                  noStyle
                >
                  {inputRender()}
                </Form.Item>
                <DeleteOutlined css={iconCss} onClick={() => remove(index)} />
              </HGroup>
            </FixedFormItem>
          ))}

          <FixedFormItem
            label={empty(fields) ? label : ''}
            {...(empty(fields) ? formItemLayout : formItemLayoutWithOutLabel)}
          >
            <HGroup>
              <Button type="dashed" onClick={() => add()} style={{ width: '100%' }}>
                <PlusOutlined /> {buttonLabel}
              </Button>
              {!empty(fields) && <DeleteOutlined css={iconCss} style={{ visibility: 'hidden' }} />}
            </HGroup>
          </FixedFormItem>
        </div>
      )}
    </Form.List>
  )
}

export default memo(FormItemList)
