import { normalize, schema } from 'normalizr'
import produce from 'immer'
import { v4 as uuidV4 } from 'uuid'

import { Reducer } from 'redux'

import { previous, todayDate } from '@anews/utils'
import { Rundown, Block, RundownConfig, Story, Displayable, DisplayStatus } from '@anews/types'

import { RundownActionType, RundownAction } from '../actions/rundown-actions'
import { ActionType as PreviewActionType, PreviewAction } from '../actions/preview-actions'
import { StoryActionType, StoryAction } from '../actions/story-actions'

import { ConfigState } from './types'

const defaultTabUuid = uuidV4()
const today = todayDate()

export const PREVIEW_UUID = '__PREVIEW__'

interface Tab {
  uuid: string
  type: 'rundown' | 'form'
  suffix?: string
}

interface RundownTab extends Tab {
  type: 'rundown'
  rundownId?: number
  date: string
  programId?: number
}

interface FormTab extends Tab {
  type: 'form'
  parentUuid: string | undefined
  storyId: number
  isRadio: boolean
}

export type RundownStateTab = RundownTab | FormTab

function newListTab(uuid: string, date: string): RundownTab {
  return {
    uuid,
    date,
    type: 'rundown',
    rundownId: undefined,
    programId: undefined,
  }
}

// Obtem index para aba de story form depois do espelho correspondente
const findNewStoryTabIndex = (list: RundownStateTab[], parentUuid?: string) => {
  if (Array.isArray(list) && parentUuid) {
    for (let idx = 0; idx < list.length; idx += 1) {
      const element = list[idx]
      if (element.uuid === parentUuid) {
        // acha espelho pai do story form
        return idx + 1
      }
    }
  }
  return list.length
}

const storySchema = new schema.Entity('stories')
const blockSchema = new schema.Entity('blocks', {
  stories: [storySchema],
})
const rundownSchema = new schema.Entity('rundowns', {
  blocks: [blockSchema],
})

function normalizeRundown(rundown: Rundown) {
  return normalize(rundown, rundownSchema)
}

function normalizeBlock(block: Block) {
  return normalize(block, blockSchema)
}

function normalizeStory(story: Story) {
  return normalize(story, storySchema)
}

export type NormalizedRundown = Omit<Rundown, 'blocks'> & { blocks: number[] }
export type NormalizedBlock = Omit<Block, 'stories'> & { stories: number[] }

export interface RundownsTabsState {
  activeTab: string
  loading: boolean
  list: RundownStateTab[]
  rundowns: { [rundownId: number]: NormalizedRundown }
  blocks: { [blockId: number]: NormalizedBlock }
  stories: { [storyId: number]: Story }
}

export interface RundownOperationState {
  type: 'copy' | 'cut' | undefined
  targets: number[]
}

export interface RundownPlayingInfo extends Displayable {
  id: number
  total: string
}

export interface RundownsState {
  tabs: RundownsTabsState
  config: ConfigState<RundownConfig>
  viewing?: number
  tpContent?: {
    loading: boolean
    stories?: Story[]
  }
  previewViewing: [number | undefined, number | undefined]
  saving: boolean
  operation: RundownOperationState
  onAir: { [rundownId: number]: RundownPlayingInfo | undefined }
  transientStories: { [storyId: number]: Story }
  mos: {
    toggling: boolean
  }
}

export const defaultState: RundownsState = {
  tabs: {
    activeTab: defaultTabUuid,
    loading: false,
    list: [newListTab(defaultTabUuid, today), newListTab(PREVIEW_UUID, today)],
    rundowns: {},
    blocks: {},
    stories: {},
  },
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
  viewing: undefined,
  previewViewing: [undefined, undefined],
  saving: false,
  operation: {
    type: undefined,
    targets: [],
  },
  onAir: {},
  transientStories: {},
  mos: {
    toggling: false,
  },
}

//
//  config reducer
//

function configReducer(
  config: ConfigState<RundownConfig>,
  action: RundownAction | PreviewAction | StoryAction,
): ConfigState<RundownConfig> {
  switch (action.type) {
    case RundownActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case RundownActionType.UPDATE_CONFIG_REQUEST:
    case RundownActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case RundownActionType.LOAD_CONFIG_FAILURE:
    case RundownActionType.CREATE_CONFIG_FAILURE:
    case RundownActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case RundownActionType.LOAD_CONFIG_SUCCESS:
    case RundownActionType.CREATE_CONFIG_SUCCESS:
    case RundownActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

//
//  tab reducer
//

function tabReducer(tab: RundownTab, action: RundownAction | StoryAction): RundownTab {
  switch (action.type) {
    case RundownActionType.LOAD_REQUEST:
    case RundownActionType.CREATE_REQUEST:
      return { ...tab, date: action.date, programId: action.programId }

    case RundownActionType.LOAD_SUCCESS:
    case RundownActionType.CREATE_SUCCESS:
      return { ...tab, rundownId: action.rundown.id }

    default:
      return tab
  }
}

//
//  tabs reducer
//

function tabsReducer(
  tabs: RundownsTabsState,
  action: RundownAction | PreviewAction | StoryAction,
): RundownsTabsState {
  switch (action.type) {
    //
    // tabs actions
    //

    case RundownActionType.SET_TAB_EXTRAS:
      if (action.key !== PREVIEW_UUID) {
        return {
          ...tabs,
          list: tabs.list.map(tab => {
            if (tab.uuid === action.key && action.extras.suffix !== undefined) {
              return {
                ...tab,
                suffix: action.extras.suffix,
              }
            }
            return tab
          }),
        }
      }
      return { ...tabs }

    case RundownActionType.CHANGE_TAB:
      return { ...tabs, activeTab: action.key }

    case RundownActionType.CLOSE_TAB: {
      const { key } = action
      let { activeTab } = tabs

      if (activeTab === key) {
        let previousTab: RundownStateTab | undefined
        const parentUuid = (tabs.list.find(t => t.uuid === key) as FormTab)?.parentUuid
        if (parentUuid) {
          previousTab = tabs.list.find(t => t.uuid === parentUuid)
        } else {
          previousTab = previous<RundownStateTab>(tabs.list, tab => tab.uuid === key)
        }

        if (previousTab && previousTab.uuid !== PREVIEW_UUID) {
          activeTab = previousTab.uuid
        } else {
          activeTab = tabs.list[0]?.uuid || ''
        }
      }
      return { ...tabs, activeTab, list: tabs.list.filter(tab => tab.uuid !== key) }
    }

    case RundownActionType.NEW_TAB: {
      const newTabUuid = uuidV4()

      // Cria tab de espelho sempre no final(novas abas de story form sao criadas depois da aba de espelho correspondente)
      const list = [...tabs.list, newListTab(newTabUuid, today)]
      return { ...tabs, list, activeTab: newTabUuid }
    }

    //
    // loading true
    //

    case RundownActionType.CREATE_BLOCK_REQUEST:
    case RundownActionType.CREATE_STORY_REQUEST:
    case RundownActionType.CREATE_STORY_AFTER_REQUEST:
    case RundownActionType.REMOVE_BLOCK_REQUEST:
    case RundownActionType.REPAGINATE_REQUEST:
    case RundownActionType.APPROVE_RUNDOWN_REQUEST:
    case RundownActionType.APPROVE_RUNDOWN_BLOCK_REQUEST:
    case StoryActionType.REMOVE_REQUEST:
      return { ...tabs, loading: true }

    //
    // loading false
    //

    case RundownActionType.LOAD_FAILURE:
    case RundownActionType.CREATE_FAILURE:
    case RundownActionType.CREATE_BLOCK_FAILURE:
    case RundownActionType.CREATE_STORY_FAILURE:
    case RundownActionType.CREATE_STORY_AFTER_FAILURE:
    case RundownActionType.REMOVE_BLOCK_FAILURE:
    case RundownActionType.REPAGINATE_FAILURE:
    case RundownActionType.REPAGINATE_SUCCESS:
    case RundownActionType.APPROVE_RUNDOWN_FAILURE:
    case RundownActionType.APPROVE_RUNDOWN_SUCCESS:
    case RundownActionType.APPROVE_RUNDOWN_BLOCK_FAILURE:
    case RundownActionType.APPROVE_RUNDOWN_BLOCK_SUCCESS:
    case StoryActionType.REMOVE_FAILURE:
      return { ...tabs, loading: false }

    //
    //
    //

    case RundownActionType.LOAD_REQUEST:
    case RundownActionType.CREATE_REQUEST:
      return {
        ...tabs,
        loading: true,
        list: tabs.list.map(tab =>
          tab.type === 'form' || tab.uuid !== action.uuid ? tab : tabReducer(tab, action),
        ),
      }

    case RundownActionType.LOAD_SUCCESS:
    case RundownActionType.CREATE_SUCCESS: {
      const normalized = normalizeRundown(action.rundown)
      const { rundowns = {}, blocks = {}, stories = {} } = normalized.entities

      return {
        ...tabs,
        loading: false,
        list: tabs.list.map(tab =>
          tab.type === 'form' || tab.uuid !== action.uuid ? tab : tabReducer(tab, action),
        ),
        rundowns: { ...tabs.rundowns, ...rundowns },
        blocks: { ...tabs.blocks, ...blocks },
        stories: { ...stories, ...tabs.stories },
      }
    }

    case RundownActionType.PATCH_REQUEST:
      // Atualização otimista (não espera o sucesso para atualizar o state)
      return produce(tabs, draft => {
        const { rundownId, field, newValue } = action
        Object.assign(draft.rundowns[rundownId], { [field]: newValue })
      })

    case RundownActionType.PATCH_FAILURE:
      // Se o patch falhar, retorna o valor antigo
      return produce(tabs, draft => {
        const { rundownId, field, oldValue } = action
        Object.assign(draft.rundowns[rundownId], { [field]: oldValue })
      })

    //
    //  blocos
    //

    // case RundownActionType.CREATE_BLOCK_SUCCESS:
    case RundownActionType.WS_CREATE_BLOCK: {
      const { id, rundownId, index } = action.block

      // Não tem esse espelho aberto
      if (!tabs.rundowns[rundownId]) {
        return tabs
      }
      // Se o ID já tá no state, já foi tratado
      if (tabs.blocks[id]) {
        return tabs
      }

      const rundowns = produce(tabs.rundowns, draft => {
        const { blocks } = draft[rundownId]
        blocks.splice(index, 0, id)
      })

      const normalized = normalizeBlock(action.block)
      const { blocks = {}, stories = {} } = normalized.entities

      return {
        ...tabs,
        loading: false,
        rundowns,
        blocks: { ...tabs.blocks, ...blocks },
        stories: { ...tabs.stories, ...stories },
      }
    }

    case RundownActionType.PATCH_BLOCK_REQUEST:
      // Atualização otimista (não espera o sucesso para atualizar o state)
      return produce(tabs, draft => {
        const { blockId, field, newValue } = action
        Object.assign(draft.blocks[blockId], { [field]: newValue })
      })

    case RundownActionType.PATCH_BLOCK_FAILURE:
      // Se o patch falhar, retorna o valor antigo
      return produce(tabs, draft => {
        const { blockId, field, oldValue } = action
        Object.assign(draft.blocks[blockId], { [field]: oldValue })
      })

    case RundownActionType.REMOVE_BLOCK_SUCCESS:
    case RundownActionType.WS_DELETE_BLOCK: {
      const { blockId } = action

      if (!tabs.blocks[blockId]) {
        return tabs
      }

      return produce(tabs, draft => {
        // Apaga os dados do bloco
        delete draft.blocks[blockId]

        // Remove o bloco do espelho
        Object.entries(draft.rundowns).forEach(([_, rundown]) => {
          rundown.blocks = rundown.blocks.filter(id => id !== blockId)
        })

        draft.loading = false
      })
    }

    //
    //  laudas
    //

    // case RundownActionType.CREATE_STORY_SUCCESS:
    // case RundownActionType.CREATE_STORY_AFTER_SUCCESS: {
    //   const { id, blockId } = action.story

    //   // Se o ID já tá no state, já foi tratado
    //   if (tabs.stories[id]) {
    //     return tabs
    //   }

    //   const blocks = produce(tabs.blocks, draft => {
    //     const { stories } = draft[blockId!]
    //     stories.push(id)
    //   })

    //   const normalized = normalizeStory(action.story)
    //   const { stories = {} } = normalized.entities

    //   return {
    //     ...tabs,
    //     loading: false,
    //     blocks,
    //     stories: { ...tabs.stories, ...stories },
    //   }
    // }

    case RundownActionType.WS_CREATE_STORY: {
      const { stories: createdStories, targetStoryId } = action

      if (createdStories.length === 0) {
        return tabs
      }

      const { blockId } = createdStories[0]
      const targetBlock = tabs.blocks[blockId || 0]

      if (!targetBlock) {
        return tabs
      }

      const targetIndex =
        targetStoryId === undefined
          ? targetBlock.stories.length
          : targetBlock.stories.indexOf(targetStoryId)

      const blocks = produce(tabs.blocks, draft => {
        const { stories } = draft[blockId!]
        const storiesIds = createdStories.map(story => story.id)
        stories.splice(targetIndex, 0, ...storiesIds)
      })

      const updatedStories = produce(tabs.stories, draft => {
        createdStories.forEach(story => {
          const normalized = normalizeStory(story)
          const { stories = {} } = normalized.entities
          Object.assign(draft, stories)
        })
      })

      return {
        ...tabs,
        loading: false,
        blocks,
        stories: updatedStories,
      }
    }

    case RundownActionType.COPY_PASTE_STORIES_SUCCESS:
      return {
        ...tabs,
        loading: false,
      }
    // Removido pois pode estar causando o bug q duplica laudas após colar
    /*
    return produce(tabs, draft => {
      const { stories, targetBlockId, targetStoryId } = action
      const storiesIds = stories.map(story => story.id)
      const targetBlock = draft.blocks[targetBlockId]

      // Se o bloco já tiver alguma das laudas novas, significa que já foi tratado
      if (targetBlock.stories.find(id => storiesIds.includes(id))) {
        return
      }

      stories.forEach(story => {
        draft.stories[story.id] = story
      })

      const targetIndex =
        targetStoryId === undefined
          ? targetBlock.stories.length
          : targetBlock.stories.indexOf(targetStoryId)

      targetBlock.stories.splice(targetIndex, 0, ...storiesIds)
    })
    */

    case RundownActionType.MOVE_STORIES_REQUEST:
      // Atualização otimista (não espera o sucesso para atualizar o state)
      return produce(tabs, draft => {
        draft.loading = true

        const { storiesIds, targetBlockId, targetStoryId } = action
        const targetBlock = draft.blocks[targetBlockId]

        // Não usar array.filter() por causa do proxy do immer
        // (descobri que o problema não era o filter... mas vamos deixar assim)
        Object.values(draft.blocks).forEach(b => {
          for (let i = b.stories.length - 1; i >= 0; i -= 1) {
            if (storiesIds.includes(b.stories[i])) {
              b.stories.splice(i, 1)
            }
          }
        })

        const targetIndex =
          targetStoryId === undefined
            ? targetBlock.stories.length
            : targetBlock.stories.indexOf(targetStoryId)

        targetBlock.stories.splice(targetIndex, 0, ...storiesIds)
      })

    case RundownActionType.MOVE_STORIES_SUCCESS:
    case RundownActionType.MOVE_STORIES_FAILURE:
      return { ...tabs, loading: false }

    // case RundownActionType.MOVE_STORY_FAILURE:
    // rundown-sagas está recarregando os espelho na falha

    case StoryActionType.LOAD_SUCCESS:
      return produce(tabs, draft => {
        const { story, config } = action
        const { edit, parentTab } = config || {}
        draft.stories[story.id] = story

        if (!edit) {
          return
        }

        const existing = draft.list
          .filter(tab => tab.type === 'form')
          .find(tab => tab.uuid === story.uuid)

        if (existing) {
          draft.activeTab = existing.uuid
        } else {
          const addIdx = findNewStoryTabIndex(draft.list, parentTab)
          draft.list.splice(addIdx, 0, {
            type: 'form',
            uuid: story.uuid,
            storyId: story.id,
            parentUuid: parentTab,
            isRadio: story.radio,
          })
          draft.activeTab = story.uuid
        }
      })

    case StoryActionType.UPDATE_SUCCESS:
      return produce(tabs, draft => {
        const { story } = action
        draft.stories[story.id] = story
      })

    case StoryActionType.PATCH_REQUEST:
      // Atualização otimista (não espera o sucesso para atualizar o state)
      return produce(tabs, draft => {
        const { storyId, field, newValue } = action
        Object.assign(draft.stories[storyId], { [field]: newValue })
        draft.loading = true
      })

    case StoryActionType.PATCH_FAILURE:
      // Se o patch falhar, retorna o valor antigo
      return produce(tabs, draft => {
        const { storyId, field, oldValue } = action
        Object.assign(draft.stories[storyId], { [field]: oldValue })
        draft.loading = false
      })

    case StoryActionType.PATCH_SUCCESS:
      return { ...tabs, loading: false }

    case StoryActionType.REMOVE_SUCCESS:
    case StoryActionType.WS_DELETE:
      return produce(tabs, draft => {
        const { ids } = action

        // Apaga os dados das laudas
        ids.forEach(id => {
          delete draft.stories[id]
        })

        // Remove elas dos blocos
        Object.entries(draft.blocks).forEach(([_, block]) => {
          block.stories = block.stories.filter(storyId => !ids.includes(storyId))
        })

        draft.loading = false
      })

    //
    //  Preview
    //

    case PreviewActionType.LOAD_STORIES_SUCCESS:
      return produce(tabs, draft => {
        const [first, second] = action.stories
        if (first) {
          draft.stories[first.id] = first
        }
        if (second) {
          draft.stories[second.id] = second
        }
      })

    //
    //  Rundown web socket updates
    //

    case RundownActionType.WS_UPDATE_META: {
      return produce(tabs, draft => {
        const { id, blocks, ...rest } = action.rundown
        if (tabs.rundowns[id]) {
          Object.assign(draft.rundowns[id], rest)
        }
      })
    }

    case RundownActionType.WS_PATCH:
      return produce(tabs, draft => {
        const { rundownId, changes } = action
        if (tabs.rundowns[rundownId]) {
          Object.assign(draft.rundowns[rundownId], changes)

          if (changes.date) {
            const idx = draft.list.findIndex(l => (l as RundownTab).rundownId === rundownId)
            const tab = draft.list[idx] as RundownTab
            if (tab?.date) {
              tab.date = changes.date
              Object.assign(draft.list[idx], tab)
            }
          }
        }
      })

    //
    //  Block web socket updates
    //

    case RundownActionType.WS_UPDATE_BLOCK_META:
      return produce(tabs, draft => {
        const { id, stories, ...rest } = action.block
        if (tabs.blocks[id]) {
          Object.assign(draft.blocks[id], rest)
        }
      })

    case RundownActionType.WS_MOVE_STORIES:
      return produce(tabs, draft => {
        const { blockId, blockStoriesIds, movedStories } = action

        movedStories.forEach(story => {
          if (!draft.stories[story.id]) {
            draft.stories[story.id] = story
          }
        })

        // Não usar array.filter() por causa do proxy do immer
        // (descobri que o problema não era o filter... mas vamos deixar assim)
        Object.values(draft.blocks).forEach(b => {
          for (let i = b.stories.length - 1; i >= 0; i -= 1) {
            for (const story of movedStories) {
              if (story.id === b.stories[i]) {
                b.stories.splice(i, 1)
                break
              }
            }
          }
        })

        if (draft.blocks[blockId]) {
          draft.blocks[blockId].stories = blockStoriesIds
        }
      })

    case RundownActionType.WS_PATCH_BLOCK:
      return produce(tabs, draft => {
        const { blockId, changes } = action
        if (draft.blocks[blockId]) {
          Object.assign(draft.blocks[blockId], changes)
        }
      })

    //
    //  Story web socket updates
    //

    case StoryActionType.WS_PATCH:
      return produce(tabs, draft => {
        const { user } = action

        action.changes.forEach(storyChange => {
          const { id, changes } = storyChange
          if (draft.stories[id]) {
            if (user) {
              if (
                !draft.stories[id].ownerId ||
                draft.stories[id].ownerId === user.id ||
                (draft.stories[id].allowedUsers || []).includes(user.id)
              ) {
                Object.assign(draft.stories[id], changes)
              } else {
                Object.assign(draft.stories[id], {
                  ...changes,
                  visible: false,
                })
              }
            } else {
              Object.assign(draft.stories[id], changes)
            }
          }
        })
      })
    case StoryActionType.WS_UPDATE:
      return produce(tabs, draft => {
        const { stories, user } = action

        stories.forEach(story => {
          if (user) {
            if (
              !story.ownerId ||
              story.ownerId === user.id ||
              (story.allowedUsers || []).includes(user.id)
            ) {
              draft.stories[story.id] = story
            } else {
              Object.assign(
                (draft.stories[story.id] = {
                  ...story,
                  visible: false,
                }),
              )
            }
          } else {
            draft.stories[story.id] = story
          }
        })
      })

    default:
      return tabs
  }
}

//
//  viewing reducer
//

function viewingReducer(
  viewing: RundownsState['viewing'],
  action: RundownAction | PreviewAction | StoryAction,
): RundownsState['viewing'] {
  switch (action.type) {
    case StoryActionType.UNLOAD:
      return undefined
    case StoryActionType.LOAD_SUCCESS:
      if (action.config?.clearViewing) {
        return undefined
      }
      if (!action.config?.edit) {
        return action.story.id
      }
      return viewing
    default:
      return viewing
  }
}

//
//  viewing for tp reducer
//

function tpContentReducer(
  state: RundownsState['tpContent'],
  action: RundownAction | PreviewAction | StoryAction,
): RundownsState['tpContent'] {
  switch (action.type) {
    case StoryActionType.UNLOAD:
      return undefined
    case RundownActionType.LOAD_TP_CONTENT_REQUEST:
      return {
        loading: true,
        stories: undefined,
      }
    case RundownActionType.LOAD_TP_CONTENT_SUCCESS:
      return {
        loading: false,
        stories: action.stories,
      }
    case RundownActionType.LOAD_TP_CONTENT_FAILURE:
      return {
        loading: false,
        stories: undefined,
      }
    default:
      return state
  }
}

//
//  previewViewing reducer
//

function previewViewing(
  state: RundownsState['previewViewing'],
  action: RundownAction | PreviewAction | StoryAction,
): RundownsState['previewViewing'] {
  switch (action.type) {
    case PreviewActionType.LOAD_STORIES_SUCCESS: {
      const [first, second] = action.stories
      return [first ? first.id : undefined, second ? second.id : undefined]
    }
    default:
      return state
  }
}

//
//  operation reducer
//

function operationReducer(
  operation: RundownsState['operation'],
  action: RundownAction | PreviewAction | StoryAction,
): RundownsState['operation'] {
  switch (action.type) {
    case RundownActionType.COPY_CUT_SELECT: {
      const { operation: type, targets } = action

      if (operation.type !== action.operation) {
        return { type, targets }
      }

      // Quando seleciona um ID que já estava selecionado, retira a seleção
      const toAdd = targets.filter(id => !operation.targets.includes(id))
      const toKeep = operation.targets.filter(id => !targets.includes(id))

      return { ...operation, targets: [...toKeep, ...toAdd] }
    }

    case RundownActionType.COPY_CUT_CLEAR:
    case RundownActionType.COPY_PASTE_STORIES_SUCCESS:
    case RundownActionType.MOVE_STORIES_SUCCESS:
      return defaultState.operation

    default:
      return operation
  }
}

//
//  onAir reducer
//

function onAirReducer(
  onAir: RundownsState['onAir'],
  action: RundownAction | PreviewAction | StoryAction,
  state: RundownsState,
): RundownsState['onAir'] {
  switch (action.type) {
    case RundownActionType.LOAD_SUCCESS: {
      const { rundown } = action

      for (const block of rundown.blocks) {
        if (block.displayStatus === DisplayStatus.PLAY) {
          return {
            ...onAir,
            [rundown.id]: { id: block.id, playTime: block.playTime, total: block.commercial },
          }
        }
        for (const story of block.stories) {
          if (story.displayStatus === DisplayStatus.PLAY) {
            return {
              ...onAir,
              [rundown.id]: { id: story.id, playTime: story.playTime, total: story.total },
            }
          }
        }
      }

      return { ...onAir, [rundown.id]: undefined }
    }

    /*
     * PLAY e STOP do bloco
     */
    case RundownActionType.WS_PATCH_BLOCK: {
      const {
        blockId,
        rundownId,
        changes: { displayStatus, playTime },
      } = action
      const current = onAir[rundownId]

      // PLAY no bloco
      if (displayStatus === DisplayStatus.PLAY) {
        const block = state.tabs.blocks[blockId]
        return {
          ...onAir,
          [rundownId]: { id: blockId, playTime, total: block?.commercial || 'PT0S' },
        }
      }
      // STOP no bloco
      if (displayStatus === DisplayStatus.STOP && current && current.id === blockId) {
        return { ...onAir, [rundownId]: undefined }
      }
      return onAir
    }

    /*
     * PLAY e STOP da lauda
     */
    case StoryActionType.WS_PATCH: {
      const { changes } = action

      // PLAY na lauda
      const play = changes.find(c => c.changes.displayStatus === DisplayStatus.PLAY)
      if (play) {
        const story = state.tabs.stories[play.id]
        return {
          ...onAir,
          [play.rundownId]: {
            id: play.id,
            playTime: play.changes.playTime,
            total: story?.total || 'PT0S',
          },
        }
      }

      // STOP na lauda
      const stops = changes.filter(c => c.changes.displayStatus === DisplayStatus.STOP)
      for (const stop of stops) {
        const current = onAir[stop.rundownId]
        if (current && current.id === stop.id) {
          return { ...onAir, [stop.rundownId]: undefined }
        }
      }

      return onAir
    }
    default:
      return onAir
  }
}

//
//  saving reducer
//

function savingReducer(
  saving: RundownsState['saving'],
  action: RundownAction | PreviewAction | StoryAction,
): RundownsState['saving'] {
  switch (action.type) {
    case StoryActionType.UPDATE_REQUEST:
      return true
    case StoryActionType.UPDATE_SUCCESS:
    case StoryActionType.UPDATE_FAILURE:
      return false
    default:
      return saving
  }
}

//
//  transient reducer
//

function transientReducer(
  state: RundownsState['transientStories'],
  action: RundownAction | PreviewAction | StoryAction,
): RundownsState['transientStories'] {
  switch (action.type) {
    case StoryActionType.LOAD_SUCCESS:
      return action.config?.edit ? { ...state, [action.story.id]: action.story } : state

    case StoryActionType.STORE_TRANSIENT:
    case StoryActionType.UPDATE_SUCCESS:
      return { ...state, [action.story.id]: action.story }

    default:
      return state
  }
}

//
//  mos reducer
//

function mosReducer(
  state: RundownsState['mos'],
  action: RundownAction | PreviewAction | StoryAction,
): RundownsState['mos'] {
  switch (action.type) {
    case RundownActionType.ENABLE_MOS_REQUEST:
    case RundownActionType.DISABLE_MOS_REQUEST:
    case RundownActionType.RUN_MOS_ACTION_REQUEST:
      return { ...state, toggling: true }

    case RundownActionType.ENABLE_MOS_SUCCESS:
    case RundownActionType.ENABLE_MOS_FAILURE:
    case RundownActionType.DISABLE_MOS_SUCCESS:
    case RundownActionType.DISABLE_MOS_FAILURE:
    case RundownActionType.RUN_MOS_ACTION_SUCCESS:
    case RundownActionType.RUN_MOS_ACTION_FAILURE:
      return { ...state, toggling: false }

    default:
      return state
  }
}

//
//  rundowns reducer
//

const targetTypes = [
  ...Object.values(RundownActionType),
  ...Object.values(StoryActionType),
  ...Object.values(PreviewActionType),
]

const rundownsReducer: Reducer<RundownsState, RundownAction | StoryAction> = (
  state = defaultState,
  action,
): RundownsState => {
  if (action.type === RundownActionType.CLEAR) {
    return defaultState
  }
  if (targetTypes.includes(action.type)) {
    return {
      ...state,
      tabs: tabsReducer(state.tabs, action),
      config: configReducer(state.config, action),
      viewing: viewingReducer(state.viewing, action),
      tpContent: tpContentReducer(state.tpContent, action),
      previewViewing: previewViewing(state.previewViewing, action),
      saving: savingReducer(state.saving, action),
      operation: operationReducer(state.operation, action),
      onAir: onAirReducer(state.onAir, action, state),
      transientStories: transientReducer(state.transientStories, action),
      mos: mosReducer(state.mos, action),
    }
  }

  return state
}

export default rundownsReducer
