import React, { memo } from 'react'
import ReactHtmlParser from 'react-html-parser'
import styled from '@emotion/styled'

import Tooltip from 'antd/es/tooltip'
import Popconfirm from 'antd/es/popconfirm'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faChevronDown,
  faChevronUp,
  faCopy,
  faEdit,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import { ReportageCG, CIIDataField } from '@anews/types'

import { useTranslation } from '../../../i18n'

import { HGroup, VGroup, TextArea } from '../../global'

interface Props {
  cg: ReportageCG
  lastIndex: number
  innerRef: any
  provided: any
  onEdit: (cg: ReportageCG) => void
  onRemove: (removeIndex: number) => void
  onMove: (movedIndex: number, moveUp: boolean) => void
  onDuplicate: (duplicateIndex: number) => void
  onFieldChange: (newValue: string, fieldIndex: number, cgIndex: number) => void
}

const StyledLabel = styled.span`
  color: black;
  font-size: 16px;
`

const StyledTextArea = styled(TextArea)`
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  resize: none;

  &:focus {
    box-shadow: none;
  }
`

const ReportageCGForm = ({
  cg,
  lastIndex,
  innerRef,
  provided,
  onEdit,
  onRemove,
  onMove,
  onDuplicate,
  onFieldChange,
}: Props) => {
  const { t } = useTranslation()

  return (
    <VGroup ref={innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
      <HGroup>
        <StyledLabel className="configFontSize" style={{ flexGrow: 1 }}>
          {cg.ciiData?.templateName || cg.mosData?.slug}
        </StyledLabel>

        {cg.mosData && (
          <Tooltip key="edit" title={t('words:edit')}>
            <FontAwesomeIcon icon={faEdit} cursor="pointer" onClick={() => onEdit(cg)} />
          </Tooltip>
        )}

        {cg.index !== 0 && (
          <Tooltip key="up" title={t('phrases:moveUp')}>
            <FontAwesomeIcon
              icon={faChevronUp}
              cursor="pointer"
              onClick={() => onMove(cg.index, true)}
            />
          </Tooltip>
        )}

        {cg.index !== lastIndex && (
          <Tooltip key="down" title={t('phrases:moveDown')}>
            <FontAwesomeIcon
              icon={faChevronDown}
              cursor="pointer"
              onClick={() => onMove(cg.index, false)}
            />
          </Tooltip>
        )}

        <Tooltip key="duplicate" title={t('words:duplicate')}>
          <FontAwesomeIcon icon={faCopy} cursor="pointer" onClick={() => onDuplicate(cg.index)} />
        </Tooltip>

        <Tooltip key="remove" title={t('words:delete')}>
          <Popconfirm
            title={t('phrases:sure')}
            trigger="click"
            onConfirm={() => onRemove(cg.index)}
            onCancel={() => {}}
            placement="leftBottom"
            cancelText={t('words:no')}
            okText={t('words:yes')}
          >
            <FontAwesomeIcon icon={faTrashAlt} cursor="pointer" />
          </Popconfirm>
        </Tooltip>
      </HGroup>

      {cg.ciiData?.fields &&
        cg.ciiData?.fields.map((field: CIIDataField, index: number) => (
          <HGroup alignItems="baseline" key={field.uuid}>
            <StyledLabel className="upperCase configFontSize">{field.name}:</StyledLabel>
            <StyledTextArea
              className="configFontSize"
              placeholder={t('phrases:typePlaceholder')}
              maxLength={field.size && field.size > 0 ? field.size : undefined}
              value={field.value}
              onChange={event => onFieldChange(event.target.value, index, cg.index)}
              autoSize
            />
          </HGroup>
        ))}

      {cg.mosData?.mosAbstract && <div>{ReactHtmlParser(cg.mosData.mosAbstract)}</div>}
    </VGroup>
  )
}

export default memo(ReportageCGForm)
