/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { memo, useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'

import Layout from 'antd/es/layout'
import List from 'antd/es/list'

import { displayTimestamp } from '@anews/utils'

import { convertToArray } from '../../../utils/json-utils'
import { getRevisionTranslate } from '../../../utils/translate-utils'
import { useTranslation } from '../../../i18n'
import theme from '../../../theme'

const { Header, Content } = Layout

function colorText(text: string): string {
  return (text || '')
    .replace(/<ins>/g, `<ins style="color: ${theme.colors.green}">`)
    .replace(/<del>/g, `<del style="color: ${theme.colors.red} ">`)
    .replace(/<\/?none>/g, '')
}

interface Props {
  date?: string
  nickname?: string
  ip?: string
  slug: string
  content: any
  isRadio?: boolean
}

const RevisionCard = ({ date, nickname, ip, slug, content, isRadio }: Props) => {
  const [contentArray, setContentArray] = useState<string[][]>([])
  const { t } = useTranslation()

  useEffect(() => {
    const {
      revisionDate,
      revisorNickname,
      revisorIP,
      revisionNumber,
      slug: _slug,
      ...rest
    } = content
    setContentArray(convertToArray(rest))
  }, [content])

  return (
    <div style={{ height: '80vh' }}>
      <Layout style={{ background: 'rgba(255, 255, 255, 1)' }}>
        <Header
          className="header"
          css={css`
            background: ${theme.palletes.yellow[0]};
            text-align: center;
            height: inherit;
            line-height: inherit;
            padding: 8px;
          `}
        >
          {`${displayTimestamp(date)} - ${nickname} (${ip})`}
        </Header>
        <div style={{ overflowY: 'auto', height: '70vh' }}>
          <Content style={{ textAlign: 'left' }}>
            <List
              header={slug && ReactHtmlParser(`<h2>${colorText(slug)}</h2>`)}
              dataSource={contentArray}
              renderItem={(item: string[]) => (
                <List.Item>
                  <div>
                    <b>{t(getRevisionTranslate(item[0], isRadio))}</b>
                    <p>{ReactHtmlParser(colorText(item[1].toString()))}</p>
                  </div>
                </List.Item>
              )}
            />
          </Content>
        </div>
      </Layout>
    </div>
  )
}

export default memo(RevisionCard)
