import { call, put, takeLatest } from 'redux-saga/effects'

import { souvApi as api } from '@anews/api'
import { Page, SouvConfig, SouvDocument, SouvWorkspace } from '@anews/types'

import { NotificationActions } from '../actions'
import { SouvActionType as ActionType, SouvActionMap, SouvActions } from '../actions/souv-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError, notifySuccess } = NotificationActions

const {
  listWorkspacesFailure,
  listWorkspacesSuccess,
  filterDocumentsFailure,
  filterDocumentsSuccess,
  loadDocumentFailure,
  loadDocumentSuccess,
  createConfigFailure,
  createConfigSuccess,
  loadConfigFailure,
  loadConfigSuccess,
  updateConfigFailure,
  updateConfigSuccess,
} = SouvActions

/* Watchers */

function* listWorskpacesSaga(): Generator {
  try {
    const workspaces = yield call(api.getWorkspaces)
    yield put(listWorkspacesSuccess(workspaces as SouvWorkspace[]))
  } catch (error) {
    yield put(listWorkspacesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* filterDocumentsSaga(
  action: SouvActionMap<ActionType.FILTER_DOCUMENT_REQUEST>,
): Generator {
  try {
    const result = yield call(
      api.filterDocuments,
      action.workspaceId,
      action.text,
      action.docType,
      action.page,
      action.limit,
    )
    yield put(filterDocumentsSuccess(result as Page<SouvDocument>))
  } catch (error) {
    yield put(filterDocumentsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadDocumentSaga(action: SouvActionMap<ActionType.LOAD_DOCUMENT_REQUEST>): Generator {
  try {
    const doc = yield call(api.getDocument, action.workspaceId, action.documentId)
    yield put(loadDocumentSuccess(doc as SouvDocument))
  } catch (error) {
    yield put(loadDocumentFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as SouvConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: SouvActionMap<ActionType.CREATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as SouvConfig))

    const status = yield call(api.checkCredentials)

    if (status) {
      yield put(
        notifySuccess({
          message: i18n.t('words:souv'),
          description: i18n.t('souv:credentialsOk'),
        }),
      )
    }
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: SouvActionMap<ActionType.UPDATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)

    yield put(updateConfigSuccess(config as SouvConfig))

    const status = yield call(api.checkCredentials)

    if (status) {
      yield put(
        notifySuccess({
          message: i18n.t('words:souv'),
          description: i18n.t('souv:credentialsOk'),
        }),
      )
    }
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_WORKSPACES_REQUEST, listWorskpacesSaga)
  },
  function* () {
    yield takeLatest(ActionType.FILTER_DOCUMENT_REQUEST, filterDocumentsSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_DOCUMENT_REQUEST, loadDocumentSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
