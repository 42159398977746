import { get, patch, post, put, query, del } from './client';
var endpoint = '/api/rundowns';
var rundownApi = {
    check: function (date, programId) {
        return get(query(endpoint + "/check", { date: date, programId: programId }));
    },
    load: function (date, programId) {
        return get(query(endpoint, { date: date, programId: programId }));
    },
    create: function (date, programId, templateId) {
        return post(query(endpoint, { date: date, programId: programId, templateId: templateId }));
    },
    createBlock: function (rundownId) {
        return post(endpoint + "/" + rundownId + "/blocks");
    },
    createStory: function (blockId) {
        return post(endpoint + "/blocks/" + blockId + "/stories");
    },
    createStoryAfter: function (afterStory) {
        return post(query(endpoint + "/blocks/stories", { afterStory: afterStory }));
    },
    patchRundown: function (id, field, value) {
        var _a;
        // Enviar undefined não funciona para limpar o valor
        return patch(endpoint + "/" + id, (_a = {}, _a[field] = value === undefined ? null : value, _a));
    },
    recreateRundown: function (id, templateId) {
        return patch(query(endpoint + "/" + id + "/recreate", { templateId: templateId }));
    },
    patchBlock: function (blockId, field, value) {
        var _a;
        // Enviar undefined não funciona para limpar o valor
        return patch(endpoint + "/blocks/" + blockId, (_a = {}, _a[field] = value === undefined ? null : value, _a));
    },
    orderBlocks: function (blocksIds, rundownId) {
        return patch(query(endpoint + "/" + rundownId + "/blocks/reorder", { blocksIds: blocksIds }));
    },
    toggleCurrentStatus: function (rundownId) {
        return post(endpoint + "/" + rundownId + "/display_status");
    },
    toggleBlockStatus: function (id) {
        return post(endpoint + "/blocks/" + id + "/display_status");
    },
    removeBlock: function (blockId) {
        return del(endpoint + "/blocks/" + blockId);
    },
    loadStories: function (rundownId, lazy) {
        if (lazy === void 0) { lazy = true; }
        return get(query(endpoint + "/" + rundownId + "/stories", { lazy: lazy }));
    },
    moveStories: function (blockId, storiesIds, targetStoryId) {
        return patch(query(endpoint + "/blocks/" + blockId + "/stories/move", { storiesIds: storiesIds, targetStoryId: targetStoryId }));
    },
    moveToRundown: function (date, programId, storiesIds) {
        return patch(query(endpoint + "/move", { date: date, programId: programId, storiesIds: storiesIds }));
    },
    copyStoriesToBlock: function (blockId, storiesIds, targetStoryId) {
        return patch(query(endpoint + "/blocks/" + blockId + "/stories/paste", { storiesIds: storiesIds, targetStoryId: targetStoryId }));
    },
    copyStoriesToRundown: function (date, programId, storiesIds) {
        return patch(query(endpoint + "/paste", { date: date, programId: programId, storiesIds: storiesIds }));
    },
    repaginateStories: function (rundownId) {
        return patch(endpoint + "/" + rundownId + "/repaginate");
    },
    approveRundown: function (rundownId, approve) {
        return patch(endpoint + "/" + rundownId + "/approve/" + approve);
    },
    approveRundownBlock: function (blockId, approve) {
        return patch(endpoint + "/blocks/" + blockId + "/approve/" + approve);
    },
    logs: function (rundownId, description, rundownActions, blockActions, storyActions, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/" + rundownId + "/logs", {
            description: description,
            rundownActions: rundownActions,
            blockActions: blockActions,
            storyActions: storyActions,
            page: page,
            size: size,
        }));
    },
    sendCredits: function (rundownId) {
        return put(endpoint + "/" + rundownId + "/credits/send");
    },
    //
    //  MOS
    //
    notifyMosCreate: function (rundownId, deviceId) {
        return put(query(endpoint + "/" + rundownId + "/mos/create", {
            deviceId: deviceId || undefined,
        }));
    },
    notifyMosReady: function (rundownId, deviceId) {
        return put(query(endpoint + "/" + rundownId + "/mos/ready", {
            deviceId: deviceId || undefined,
        }));
    },
    mosReplace: function (rundownId, deviceId) {
        return put(query(endpoint + "/" + rundownId + "/mos/replace", {
            deviceId: deviceId || undefined,
        }));
    },
    enableMos: function (rundownId) {
        return put(endpoint + "/" + rundownId + "/mos/enable");
    },
    disableMos: function (rundownId) {
        return put(endpoint + "/" + rundownId + "/mos/disable");
    },
    //
    //  config
    //
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default rundownApi;
