import { DocumentConfig } from '@anews/types'

export enum DocumentActionType {
  LOAD_CONFIG_REQUEST = '@document/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@document/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@document/LOAD_CONFIG_FAILURE',
  CREATE_CONFIG_REQUEST = '@document/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@document/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@document/CREATE_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@document/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@document/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@document/UPDATE_CONFIG_FAILURE',
}

export type DocumentActionMap<T extends DocumentActionType> = {
  type: T
} & DocumentAction

export type DocumentAction = ReturnType<typeof DocumentActions[keyof typeof DocumentActions]>

export const DocumentActions = {
  //  loadConfig()

  loadConfig: () => <const>{ type: DocumentActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: DocumentConfig) =>
    <const>{ type: DocumentActionType.LOAD_CONFIG_SUCCESS, config },

  loadConfigFailure: (error: Error) =>
    <const>{ type: DocumentActionType.LOAD_CONFIG_FAILURE, error },

  //  createConfig()

  createConfig: (config: DocumentConfig) =>
    <const>{ type: DocumentActionType.CREATE_CONFIG_REQUEST, config },

  createConfigSuccess: (config: DocumentConfig) =>
    <const>{ type: DocumentActionType.CREATE_CONFIG_SUCCESS, config },

  createConfigFailure: (error: Error) =>
    <const>{ type: DocumentActionType.CREATE_CONFIG_FAILURE, error },

  //  updateConfig()

  updateConfig: (config: DocumentConfig) =>
    <const>{ type: DocumentActionType.UPDATE_CONFIG_REQUEST, config },

  updateConfigSuccess: (config: DocumentConfig) =>
    <const>{ type: DocumentActionType.UPDATE_CONFIG_SUCCESS, config },

  updateConfigFailure: (error: Error) =>
    <const>{ type: DocumentActionType.UPDATE_CONFIG_FAILURE, error },
}
