import { call, put, takeLatest } from 'redux-saga/effects'

import { streamingApi as api } from '@anews/api'
import { StreamingConfig } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  loadConfigSuccess,
  loadConfigFailure,
  UpdateStreamingConfigAction,
  updateConfigSuccess,
  updateConfigFailure,
  CreateStreamingConfigAction,
  createConfigSuccess,
  createConfigFailure,
} from '../actions/streaming-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as StreamingConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: CreateStreamingConfigAction): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as StreamingConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: UpdateStreamingConfigAction): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as StreamingConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
