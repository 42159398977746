import { ChecklistResource } from '@anews/types'

export enum ChecklistResourceActionType {
  LIST_REQUEST = '@checklistresouce/LIST_REQUEST',
  LIST_SUCCESS = '@checklistresouce/LIST_SUCCESS',
  LIST_FAILURE = '@checklistresouce/LIST_FAILURE',
}

export type ChecklistResourceActionMap<T extends ChecklistResourceActionType> = {
  type: T
} & ChecklistResourceAction

export type ChecklistResourceAction = ReturnType<
  typeof ChecklistResourceActions[keyof typeof ChecklistResourceActions]
>

export const ChecklistResourceActions = {
  //
  //	listResources()
  //

  listResources: () => <const>{ type: ChecklistResourceActionType.LIST_REQUEST },

  listResourcesSuccess: (resources: ChecklistResource[]) =>
    <const>{ type: ChecklistResourceActionType.LIST_SUCCESS, resources },

  listResourcesFailure: (error: Error) =>
    <const>{ type: ChecklistResourceActionType.LIST_FAILURE, error },
}
