import { News, Page } from '@anews/types'

import { PAGE_SIZE } from '../../consts/pagination'

export enum NewsActionType {
  NEW = '@news/NEW',
  UNLOAD = '@news/UNLOAD',
  TOGGLE_SIDEBAR = '@news/TOGGLE_SIDEBAR',
  // refreshSidebar()
  REFRESH_SIDEBAR_REQUEST = '@news/REFRESH_SIDEBAR_REQUEST',
  REFRESH_SIDEBAR_SUCCESS = '@news/REFRESH_SIDEBAR_SUCCESS',
  REFRESH_SIDEBAR_FAILURE = '@news/REFRESH_SIDEBAR_FAILURE',
  // filterNews()
  FILTER_REQUEST = '@news/FILTER_REQUEST',
  FILTER_SUCCESS = '@news/FILTER_SUCCESS',
  FILTER_FAILURE = '@news/FILTER_FAILURE',
  // loadNews()
  LOAD_REQUEST = '@news/LOAD_REQUEST',
  LOAD_SUCCESS = '@news/LOAD_SUCCESS',
  LOAD_FAILURE = '@news/LOAD_FAILURE',
  // createNews()
  CREATE_REQUEST = '@news/CREATE_REQUEST',
  CREATE_SUCCESS = '@news/CREATE_SUCCESS',
  CREATE_FAILURE = '@news/CREATE_FAILURE',
  // updateNews()
  UPDATE_REQUEST = '@news/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@news/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@news/UPDATE_FAILURE',
  // removeNews()
  REMOVE_REQUEST = '@news/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@news/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@news/REMOVE_FAILURE',
  // websocket
  WS_CREATE = '@news/WS_CREATE',
  WS_DELETE = '@news/WS_DELETE',
  WS_UPDATE = '@news/WS_UPDATE',
}

export type NewsActionMap<T extends NewsActionType> = { type: T } & NewsAction

export type NewsAction = ReturnType<typeof NewsActions[keyof typeof NewsActions]>

export const NewsActions = {
  //
  //	refreshSidebar()
  //

  refreshSidebar: (size: number = PAGE_SIZE) =>
    <const>{ type: NewsActionType.REFRESH_SIDEBAR_REQUEST, size },

  refreshSidebarSuccess: (result: Page<News>) =>
    <const>{ type: NewsActionType.REFRESH_SIDEBAR_SUCCESS, result },

  refreshSidebarFailure: (error: Error) =>
    <const>{ type: NewsActionType.REFRESH_SIDEBAR_FAILURE, error },

  //
  //	filterNews()
  //

  filterNews: (
    from?: string,
    to?: string,
    filter?: string,
    page: number = 0,
    size: number = PAGE_SIZE,
  ) => <const>{ type: NewsActionType.FILTER_REQUEST, from, to, filter, page, size },

  filterNewsSuccess: (result: Page<News>) => <const>{ type: NewsActionType.FILTER_SUCCESS, result },

  filterNewsFailure: (error: Error) => <const>{ type: NewsActionType.FILTER_FAILURE, error },

  //
  //	loadNews()
  //

  loadNews: (id: number, edit: boolean) => <const>{ type: NewsActionType.LOAD_REQUEST, id, edit },

  loadNewsSuccess: (news: News, edit: boolean) =>
    <const>{ type: NewsActionType.LOAD_SUCCESS, news, edit },

  loadNewsFailure: (error: Error) => <const>{ type: NewsActionType.LOAD_FAILURE, error },

  //
  //	createNews()
  //

  createNews: (news: News) => <const>{ type: NewsActionType.CREATE_REQUEST, news },

  createNewsSuccess: (news: News) => <const>{ type: NewsActionType.CREATE_SUCCESS, news },

  createNewsFailure: (error: Error) => <const>{ type: NewsActionType.CREATE_FAILURE, error },

  //
  //	updateNews()
  //

  updateNews: (news: News) => <const>{ type: NewsActionType.UPDATE_REQUEST, news },

  updateNewsSuccess: (news: News) => <const>{ type: NewsActionType.UPDATE_SUCCESS, news },

  updateNewsFailure: (error: Error) => <const>{ type: NewsActionType.UPDATE_FAILURE, error },

  //
  //	removeNews()
  //

  removeNews: (ids: number[]) => <const>{ type: NewsActionType.REMOVE_REQUEST, ids },

  removeNewsSuccess: (ids: number[]) => <const>{ type: NewsActionType.REMOVE_SUCCESS, ids },

  removeNewsFailure: (error: Error) => <const>{ type: NewsActionType.REMOVE_FAILURE, error },

  //
  //	Plain actions
  //

  newNews: (news?: News) => <const>{ type: NewsActionType.NEW, news },

  unloadNews: () => <const>{ type: NewsActionType.UNLOAD },

  toggleSidebar: () => <const>{ type: NewsActionType.TOGGLE_SIDEBAR },

  //
  //  websocket actions
  //

  wsCreateNews: (news: News) => <const>{ type: NewsActionType.WS_CREATE, news },

  wsUpdateNews: (news: News) => <const>{ type: NewsActionType.WS_UPDATE, news },

  wsDeleteNews: (id: number) => <const>{ type: NewsActionType.WS_DELETE, id },
}
