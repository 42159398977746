import React, { memo, HTMLAttributes, HTMLProps } from 'react'
import styled from '@emotion/styled'
import { flexbox, FlexboxProps } from 'styled-system'

interface OwnProps extends HTMLAttributes<HTMLDivElement> {
  gutter?: number
}

const StyledDiv = styled.div<OwnProps>`
  &&& {
    ${flexbox}
    display: flex;

    & > * {
      margin-left: ${props => `${(props.gutter !== undefined ? props.gutter : 1) * 4}px`};
      margin-right: ${props => `${(props.gutter !== undefined ? props.gutter : 1) * 4}px`};
    }
    & > :first-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
      margin-left: 0;
    }
    & > :last-child {
      margin-right: 0;
    }
  }
`

export type HGroupProps = HTMLProps<HTMLDivElement> & OwnProps & FlexboxProps

const HGroup = React.forwardRef<HTMLDivElement, HGroupProps>(({ as, ...props }, ref) => (
  <StyledDiv alignItems="center" {...props} ref={ref} />
))

HGroup.displayName = 'HGroup'

export default memo(HGroup)
