import { get, post, put } from './client';
var endpoint = '/api/mail';
var mailApi = {
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default mailApi;
