import { Reducer } from 'redux'

import { ReportEvent } from '@anews/types'

import { ReportEventAction, ReportEventActionType as ActionType } from '../actions'

export interface ReportEventsListState {
  data: ReportEvent[]
  loading: boolean
}

export interface ReportEventsState {
  list: ReportEventsListState
  editing?: ReportEvent
  saving: boolean
  removing: boolean
}

export const initialState: ReportEventsState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(
  list: ReportEventsListState,
  action: ReportEventAction,
): ReportEventsListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.events,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.event, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(event => (event.id === action.event.id ? action.event : event)),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(event => event.id !== action.id) }

    default:
      return list
  }
}

const eventsReducer: Reducer<ReportEventsState, ReportEventAction> = (
  state = initialState,
  action,
): ReportEventsState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT:
      return { ...state, editing: action.event }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.event, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default eventsReducer
