import styled from '@emotion/styled'
import Switch from 'antd/es/switch'

const TinySwitch = styled(Switch)`
  &.ant-switch {
    min-width: 20px;
    height: 12px;
    line-height: 12px;

    .ant-switch-handle {
      width: 8px;
      height: 8px;
    }
    .ant-switch-inner {
      margin: 0 4px 0 12px;
      font-size: 8px;
    }
  }
  &.ant-switch-checked {
    .ant-switch-handle {
      left: calc(100% - 10px);
    }
    .ant-switch-inner {
      margin: 0 12px 0 4px;
    }
  }
`

export default TinySwitch
