export var PROGRAM_ALL_ID = -2;
export var PROGRAM_DRAWER_ID = -1;
export var DocumentState;
(function (DocumentState) {
    DocumentState["PRODUCING"] = "PRODUCING";
    DocumentState["COVERING"] = "COVERING";
    DocumentState["COMPLETED"] = "COMPLETED";
    DocumentState["INTERRUPTED"] = "INTERRUPTED";
    DocumentState["EDITING"] = "EDITING";
})(DocumentState || (DocumentState = {}));
export var DocumentOrigin;
(function (DocumentOrigin) {
    DocumentOrigin["GUIDELINE"] = "ASSIGNMENT";
    DocumentOrigin["PRODUCTION"] = "PRODUCTION";
    DocumentOrigin["REPORTAGE"] = "NEWS_REPORT";
    DocumentOrigin["STORY"] = "STORY";
})(DocumentOrigin || (DocumentOrigin = {}));
export function inFinalState(document) {
    var state = document.state;
    return state === DocumentState.COMPLETED || state === DocumentState.INTERRUPTED;
}
