import { Reducer } from 'redux'

import { ContactGroup } from '@anews/types'

import { ActionType, ContactGroupAction } from '../actions/contactgroup-actions'

export interface ContactGroupsListState {
  data: ContactGroup[]
  loading: boolean
}

export interface ContactGroupsState {
  list: ContactGroupsListState
}

export const defaultState = {
  list: {
    data: [],
    loading: true,
  },
}

function listReducer(
  list: ContactGroupsListState,
  action: ContactGroupAction,
): ContactGroupsListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }
    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }
    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.groups,
        loading: false,
      }

    default:
      return list
  }
}

const groupsReducer: Reducer<ContactGroupsState, ContactGroupAction> = (
  state = defaultState,
  action,
): ContactGroupsState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    default:
      return state
  }
}

export default groupsReducer
