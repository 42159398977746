import { CIIDataField, MosItemPath, Reportage, ReportageCG, ReportageSection } from '@anews/types'

import { documentChanged } from './documentChanged'
import { anyFieldChanged } from './helpers'

const sectionBasicFields: (keyof ReportageSection)[] = [
  'content',
  'observation',
  'type',
  'manual',
  'approved',
  'index',
]
const sectionManualFields: (keyof ReportageSection)[] = [...sectionBasicFields, 'duration']

function sectionsChanged(a: ReportageSection[] = [], b: ReportageSection[] = []) {
  if (a.length !== b.length) {
    return true
  }
  for (let i = 0; i < a.length; i += 1) {
    if (
      anyFieldChanged(
        a[i].manual || b[i].manual ? sectionManualFields : sectionBasicFields,
        a[i],
        b[i],
      )
    ) {
      return true
    }
  }
  return false
}

function cgFieldsChanged(a: CIIDataField[] = [], b: CIIDataField[] = []) {
  if (a.length !== b.length) {
    return true
  }
  for (let i = 0; i < a.length; i += 1) {
    if (anyFieldChanged(['name', 'value'], a[i], b[i])) {
      return true
    }
  }
  return false
}

function objPathsChanged(a: MosItemPath[] = [], b: MosItemPath[] = []) {
  if (a.length !== b.length) {
    return true
  }
  for (let i = 0; i < a.length; i += 1) {
    if (anyFieldChanged(['techDescription', 'path'], a[i], b[i])) {
      return true
    }
  }
  return false
}

function cgsChanged(a: ReportageCG[] = [], b: ReportageCG[] = []) {
  if (a.length !== b.length) {
    return true
  }
  for (let i = 0; i < a.length; i += 1) {
    if (a[i].index !== b[i].index) {
      return true
    }
    // CII Data
    if (anyFieldChanged(['templateCode', 'templateName', 'comment'], a[i].ciiData, b[i].ciiData)) {
      return true
    }
    if (cgFieldsChanged(a[i].ciiData?.fields, b[i].ciiData?.fields)) {
      return true
    }
    // MOS Data
    if (
      anyFieldChanged(
        [
          'objId',
          'slug',
          'mosId',
          'mosAbstract',
          'channel',
          'edStart',
          'edDur',
          'userTimingDur',
          'trigger',
          'macroIn',
          'macroOut',
        ],
        a[i].mosData,
        b[i].mosData,
      )
    ) {
      return true
    }
    if (
      objPathsChanged(a[i].mosData?.paths, b[i].mosData?.paths) ||
      objPathsChanged(a[i].mosData?.proxyPaths, b[i].mosData?.proxyPaths) ||
      objPathsChanged(a[i].mosData?.metadataPaths, b[i].mosData?.metadataPaths)
    ) {
      return true
    }
  }
  return false
}

export function reportageChanged(a?: Reportage, b?: Reportage) {
  const fieldChanged = anyFieldChanged(
    [
      'cameraman',
      'information',
      'headingSuggestion',
      'noteSuggestion',
      'lead',
      'text',
      'teaser',
      'classificationId',
      'editorId',
      'reporterId',
      'editorialsIds',
      'vehiclesIds',
    ],
    a,
    b,
  )
  return (
    fieldChanged ||
    documentChanged(a, b) ||
    cgsChanged(a?.cgs, b?.cgs) ||
    sectionsChanged(a?.sections, b?.sections)
  )
}
