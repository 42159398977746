import { Round } from '@anews/types'

export enum ActionType {
  LIST_REQUEST = '@round/LIST_REQUEST',
  LIST_SUCCESS = '@round/LIST_SUCCESS',
  LIST_FAILURE = '@round/LIST_FAILURE',
  CREATE_REQUEST = '@round/CREATE_REQUEST',
  CREATE_SUCCESS = '@round/CREATE_SUCCESS',
  CREATE_FAILURE = '@round/CREATE_FAILURE',
  UPDATE_REQUEST = '@round/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@round/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@round/UPDATE_FAILURE',
  REMOVE_REQUEST = '@round/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@round/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@round/REMOVE_FAILURE',
  // websocket
  WS_CREATE = '@round/WS_CREATE',
  WS_DELETE = '@round/WS_DELETE',
  WS_UPDATE = '@round/WS_UPDATE',
}

export interface ListRoundsAction {
  type: ActionType.LIST_REQUEST
  date: string
}

export interface CreateRoundAction {
  type: ActionType.CREATE_REQUEST
  round: Round
}

export interface UpdateRoundAction {
  type: ActionType.UPDATE_REQUEST
  round: Round
}

export interface RemoveRoundsAction {
  type: ActionType.REMOVE_REQUEST
  ids: number[]
}

export type RoundAction =
  /* listRounds() */
  | ListRoundsAction
  | { type: ActionType.LIST_SUCCESS; rounds: Round[] }
  | { type: ActionType.LIST_FAILURE; error: Error }
  /* createRound() */
  | CreateRoundAction
  | { type: ActionType.CREATE_SUCCESS; round: Round }
  | { type: ActionType.CREATE_FAILURE; error: Error }
  /* updateRound() */
  | UpdateRoundAction
  | { type: ActionType.UPDATE_SUCCESS; round: Round }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* removeRounds() */
  | RemoveRoundsAction
  | { type: ActionType.REMOVE_SUCCESS; ids: number[] }
  | { type: ActionType.REMOVE_FAILURE; error: Error }
  /* websocket */
  | { type: ActionType.WS_CREATE; round: Round }
  | { type: ActionType.WS_UPDATE; round: Round }
  | { type: ActionType.WS_DELETE; id: number }

//
//	listRounds()
//

export const listRounds = (date: string): RoundAction => ({
  type: ActionType.LIST_REQUEST,
  date,
})

export const listRoundsSuccess = (rounds: Round[]): RoundAction => ({
  type: ActionType.LIST_SUCCESS,
  rounds,
})

export const listRoundsFailure = (error: Error): RoundAction => ({
  type: ActionType.LIST_FAILURE,
  error,
})

//
//	createRound()
//

export const createRound = (round: Round): RoundAction => ({
  type: ActionType.CREATE_REQUEST,
  round,
})

export const createRoundSuccess = (round: Round): RoundAction => ({
  type: ActionType.CREATE_SUCCESS,
  round,
})

export const createRoundFailure = (error: Error): RoundAction => ({
  type: ActionType.CREATE_FAILURE,
  error,
})

//
//	updateRound()
//

export const updateRound = (round: Round): RoundAction => ({
  type: ActionType.UPDATE_REQUEST,
  round,
})

export const updateRoundSuccess = (round: Round): RoundAction => ({
  type: ActionType.UPDATE_SUCCESS,
  round,
})

export const updateRoundFailure = (error: Error): RoundAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//	removeRounds()
//

export const removeRounds = (ids: number[]): RoundAction => ({
  type: ActionType.REMOVE_REQUEST,
  ids,
})

export const removeRoundsSuccess = (ids: number[]): RoundAction => ({
  type: ActionType.REMOVE_SUCCESS,
  ids,
})

export const removeRoundsFailure = (error: Error): RoundAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})

//
//  wsCreateRound()
//

export const wsCreateRound = (round: Round): RoundAction => ({ type: ActionType.WS_CREATE, round })

//
//  wsUpdateRound()
//

export const wsUpdateRound = (round: Round): RoundAction => ({ type: ActionType.WS_UPDATE, round })

//
//  wsDeleteRound()
//

export const wsDeleteRound = (id: number): RoundAction => ({ type: ActionType.WS_DELETE, id })
