import { Reducer } from 'redux'

import { LdapConfig } from '@anews/types'

import { LdapAction, ActionType } from '../actions/ldap-actions'

import { ConfigState } from './types'

export const initialState = {
  config: {
    loading: false,
    saving: false,
    data: undefined,
  } as ConfigState<LdapConfig>,
  sync: {
    syncing: false,
  },
}

export type LdapState = typeof initialState

function configReducer(
  config: ConfigState<LdapConfig>,
  action: LdapAction,
): ConfigState<LdapConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

const ldapReducer: Reducer<LdapState, LdapAction> = (state = initialState, action): LdapState => {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.UPDATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...state, config: configReducer(state.config, action) }

    case ActionType.SYNC_STATE_SUCCESS:
      return { ...state, sync: { syncing: action.isSyncing } }

    case ActionType.SYNC_STATE_FAILURE:
      return { ...state, sync: { syncing: false } }

    default:
      return state
  }
}

export default ldapReducer
