import { call, put, takeLatest } from 'redux-saga/effects'

import { storyApi as api } from '@anews/api'
import { Story } from '@anews/types'

import { NotificationActions } from '../actions'

import {
  ActionType,
  LoadStoriesAction,
  loadStoriesSuccess,
  loadStoriesFailure,
} from '../actions/preview-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* loadStoriesSaga(action: LoadStoriesAction): Generator {
  try {
    const [firstId, secondId] = action.ids

    const first = firstId ? ((yield call(api.load, firstId, false)) as Story) : undefined
    const second = secondId ? ((yield call(api.load, secondId, false)) as Story) : undefined

    yield put(loadStoriesSuccess([first, second]))
  } catch (error) {
    yield put(loadStoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LOAD_STORIES_REQUEST, loadStoriesSaga)
  },
])
