import { del, get, post, put } from './client';
var endpoint = '/api/editorials';
var editorialApi = {
    list: function () {
        return get(endpoint);
    },
    usageCount: function (id) {
        return get(endpoint + "/" + id + "/usage_count");
    },
    create: function (editorial) {
        return post(endpoint, editorial);
    },
    update: function (editorial) {
        return put(endpoint, editorial);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default editorialApi;
