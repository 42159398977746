import { Reducer } from 'redux'

import { TwitterConfig } from '@anews/types'

import { TwitterAction, TwitterActionType as ActionType } from '../actions/twitter-actions'

import { ConfigState } from './types'

export interface TwitterState {
  config: ConfigState<TwitterConfig>
}

export const initialState: TwitterState = {
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
}

function configReducer(
  config: ConfigState<TwitterConfig>,
  action: TwitterAction,
): ConfigState<TwitterConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

const twitterReducer: Reducer<TwitterState, TwitterAction> = (
  state = initialState,
  action,
): TwitterState => {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.UPDATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...state, config: configReducer(state.config, action) }

    default:
      return state
  }
}

export default twitterReducer
