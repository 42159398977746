export function splitEvenly<T>(array: T[], limit: number): T[][] {
  const groups = Math.ceil(array.length / limit)
  const size = Math.ceil(array.length / groups)
  const result = []

  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }
  return result
}
