import { call, put, takeLatest } from 'redux-saga/effects'

import { facebookPageApi as api } from '@anews/api'
import { FacebookPage } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  FacebookPageActions,
  FacebookPageActionMap as ActionMap,
  FacebookPageActionType as ActionType,
} from '../actions/facebookpage-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  listFacebookPagesSuccess,
  listFacebookPagesFailure,
  createFacebookPageSuccess,
  createFacebookPageFailure,
  updateFacebookPageSuccess,
  updateFacebookPageFailure,
  removeFacebookPageSuccess,
  removeFacebookPageFailure,
} = FacebookPageActions

const { notifyError } = NotificationActions

/* Watchers */

function* listFacebookPagesSaga(): Generator {
  try {
    const pages = yield call(api.list)
    yield put(listFacebookPagesSuccess(pages as FacebookPage[]))
  } catch (error) {
    yield put(listFacebookPagesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createFacebookPageSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const page = yield call(api.create, action.page)
    yield put(createFacebookPageSuccess(page as FacebookPage))
  } catch (error) {
    yield put(createFacebookPageFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:createFailed')}`,
        error,
      }),
    )
  }
}

function* updateFacebookPageSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const page = yield call(api.update, action.page)
    yield put(updateFacebookPageSuccess(page as FacebookPage))
  } catch (error) {
    yield put(updateFacebookPageFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:updateFailed')}`,
        error,
      }),
    )
  }
}

function* removeFacebookPageSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeFacebookPageSuccess(action.id))
  } catch (error) {
    yield put(removeFacebookPageFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listFacebookPagesSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createFacebookPageSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateFacebookPageSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeFacebookPageSaga)
  },
])
