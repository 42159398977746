import {
  APPEARANCE,
  ART,
  INTERVIEW,
  OFF,
  Reportage,
  ReportageSection,
  SOUND_UP,
} from '@anews/types'
import { displayDate, formatIsoDuration, isBlank, mapJoin, richToPlain } from '@anews/utils'

import i18n from '../../../i18n'
import { SelectorMap } from '../../../redux/selectors/helpers'
import { copyToClipboard } from '../../../utils/clipboard-utils'

import { removeMarkTags } from '../../../utils/xml-utils'

function formatAbstractText(str: string | undefined) {
  return removeMarkTags(str || '')
    .replace(/(\r\n|\n|\r)/gm, '') // remove as quebras de linha
    .replace(/\s+/g, ' ') // remove espaços duplos
}

// O texto da sonora é uma citação do que o entrevistado falou, então coloca em aspas
function quotes(text?: string) {
  if (isBlank(text)) {
    return text
  }
  let result = text!.trim()
  if (!result.startsWith('"')) {
    result = `"${result}`
  }
  if (result.charAt(result.length - 1) !== '"') {
    result = `${result}"`
  }
  return result
}

function sectionDuration(section: ReportageSection) {
  switch (section.type) {
    case ART:
    case SOUND_UP:
      return undefined
    default:
      return formatIsoDuration(section.duration || 'PT0S', 'mm:ss')
  }
}

const observationLabel = (section: ReportageSection) => {
  switch (section.type) {
    case APPEARANCE:
      return i18n.t('words:local')
    case ART:
      return i18n.t('reportage:data')
    case INTERVIEW:
      return undefined
    default:
      return i18n.t('reportage:indications')
  }
}

// Sonora tem os campos invertidos
function sectionContent(section: ReportageSection) {
  return richToPlain(section.type !== INTERVIEW ? section.content : section.observation)
}

// Sonora tem os campos invertidos. Aqui, content tem quotes
function sectionObservation(section: ReportageSection) {
  return section.type !== INTERVIEW
    ? richToPlain(section.observation)
    : quotes(richToPlain(section.content))
}

export default function reportageToClipboard(
  reportage: Reportage,
  {
    programs,
    users,
    companies,
    editorials,
    vehicles,
    classifications,
  }: {
    programs: SelectorMap<string>
    users: SelectorMap<string>
    companies: SelectorMap<string>
    editorials: SelectorMap<string>
    vehicles: SelectorMap<string>
    classifications: SelectorMap<string>
  },
) {
  const reportageText: string[] = []

  const {
    cgs,
    programsIds,
    slug,
    branchId,
    reporterId,
    editorId,
    editorialsIds,
    lead,
    vehiclesIds,
    classificationId,
    cameraman,
    teaser,
    headingSuggestion,
    noteSuggestion,
    information,
    sections,
    text,
  } = reportage

  // Retranca
  if (slug) {
    reportageText.push(`${i18n.t('words:slug')} : ${reportage.slug}`)
  }

  // Data
  reportageText.push(`${i18n.t('words:date')} : ${displayDate(reportage.date)}`)

  // Praça
  if (branchId) {
    reportageText.push(`${i18n.t('words:branch')} : ${companies[branchId]}`)
  }

  // Reporter
  if (reporterId) {
    reportageText.push(`${i18n.t('words:reporter')} : ${users[reporterId]}`)
  }

  // Editor
  if (editorId) {
    reportageText.push(`${i18n.t('words:editor')} : ${users[editorId]}`)
  }

  // Programas
  if (programsIds) {
    if (programsIds.length === 0) {
      reportageText.push(`${i18n.t('words:program')} : ${i18n.t('program:drawer')}`)
    } else {
      reportageText.push(`${i18n.t('words:programs')} : ${mapJoin(programs, programsIds)}`)
    }
  }

  // Editoriais
  if (editorialsIds && editorialsIds.length > 0) {
    reportageText.push(`${i18n.t('words:editorials')} : ${mapJoin(editorials, editorialsIds)}`)
  }

  // Veículos
  if (vehiclesIds && vehiclesIds.length > 0) {
    reportageText.push(`${i18n.t('words:vehicles')} : ${mapJoin(vehicles, vehiclesIds)}`)
  }

  // Classificação
  if (classificationId) {
    reportageText.push(
      `${i18n.t('words:classification')} : ${classifications[classificationId || 0]}`,
    )
  }

  // Imagens
  if (cameraman) {
    reportageText.push(`${i18n.t('words:cameraman')} : ${cameraman}`)
  }

  // GCs
  if (cgs && cgs.length > 0) {
    reportageText.push(`\n[${i18n.t('reportage:cgs')}] `)

    cgs
      .filter(cg => !!cg.ciiData)
      .forEach(({ ciiData }) => {
        if (ciiData) {
          const { templateName, fields } = ciiData

          if (templateName) {
            reportageText.push(templateName)
          }

          fields.forEach(field => {
            reportageText.push(`${field.name}: ${field.value}`)
          })
          reportageText.push('')
        }
      })

    cgs
      .filter(cg => !!cg.mosData)
      .forEach(({ mosData }) => {
        if (mosData) {
          const { mosAbstract } = mosData

          if (mosData.slug) {
            reportageText.push(mosData.slug)
          }

          if (mosAbstract) {
            reportageText.push(formatAbstractText(mosAbstract))
          }
          reportageText.push('')
        }
      })
  }

  reportageText.push('')

  // Lead
  if (lead) {
    reportageText.push(`${i18n.t('reportage:lead')} : ${lead}`)
  }

  // Text
  if (text) {
    reportageText.push(`${i18n.t('reportage:text')} : ${richToPlain(text)}`)
  }

  // Teaser
  if (teaser) {
    reportageText.push(`${i18n.t('reportage:teaser')} : ${richToPlain(teaser)}`)
  }

  // Sugestão de cabeça
  if (headingSuggestion) {
    reportageText.push(`${i18n.t('reportage:heading')} : ${richToPlain(headingSuggestion)}`)
  }

  // Sugestão de nota pé
  if (noteSuggestion) {
    reportageText.push(`${i18n.t('reportage:footnote')} : ${richToPlain(noteSuggestion)}`)
  }

  // Informações
  if (information) {
    reportageText.push(`${i18n.t('reportage:info')} : ${richToPlain(information)}`)
  }

  reportageText.push('\n')

  // Sections
  if (sections) {
    let appearanceCount = 0
    let artCount = 0
    let interviewCount = 0
    let offCount = 0
    let soundUpCount = 0

    const sectionName = (section: ReportageSection) => {
      const labels = []

      switch (section.type) {
        case APPEARANCE:
          appearanceCount += 1

          if (reportage.tv || !reportage.radio) {
            labels.push(i18n.t('reportage:appearance'))
          }
          if (reportage.radio) {
            labels.push(i18n.t('reportage:loc'))
          }

          return [labels.join('/'), ' ', appearanceCount > 9 ? '' : '0', appearanceCount].join('')
        case ART:
          artCount += 1
          return [i18n.t('reportage:art'), ' ', artCount > 9 ? '' : '0', artCount].join('')
        case INTERVIEW:
          interviewCount += 1
          return [
            i18n.t('reportage:interview'),
            ' ',
            interviewCount > 9 ? '' : '0',
            interviewCount,
          ].join('')
        case OFF:
          offCount += 1
          return [i18n.t('reportage:off'), ' ', offCount > 9 ? '' : '0', offCount].join('')
        case SOUND_UP:
          soundUpCount += 1
          return [i18n.t('reportage:soundup'), ' ', soundUpCount > 9 ? '' : '0', soundUpCount].join(
            '',
          )
        default:
          return ''
      }
    }

    sections.forEach(section => {
      const content = sectionContent(section)
      const observation = sectionObservation(section)

      const blankContent = isBlank(content)
      const blankObservation = isBlank(observation)

      if (blankContent && blankObservation) {
        return null
      }

      const obsLabel = observationLabel(section)
      const duration = sectionDuration(section)

      reportageText.push(
        `${sectionName(section)} ${duration ? ` - ${sectionDuration(section)}` : ''}`,
      )

      if (!blankContent) {
        reportageText.push(`${content}`)
      }

      if (!blankObservation) {
        reportageText.push(
          obsLabel !== undefined ? `${obsLabel}: ${observation}` : `${observation}`,
        )
      }
      reportageText.push('\n ')
    })
  }

  return copyToClipboard(
    removeMarkTags(reportageText.join('\n')),
    i18n.t('phrases:copiedToClipboard'),
    i18n.t('error:actionFailed'),
  )
}
