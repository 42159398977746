import React, { memo, ComponentType } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { TWO_FACTORS_ROUTE } from '../App'

// https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html

export interface AuthenticatedRouteProps extends Omit<RouteProps, 'component'> {
  authenticated: boolean
  component: ComponentType<any>
  twoFactorsAuthenticated?: boolean
  totpEnabled?: boolean
  userId?: number
}

function AuthenticatedRoute({
  component: C,
  authenticated,
  twoFactorsAuthenticated,
  totpEnabled,
  userId,
  ...rest
}: AuthenticatedRouteProps) {
  return (
    <Route
      {...rest}
      render={props => {
        if (authenticated) {
          if (totpEnabled) {
            if (twoFactorsAuthenticated) {
              return <C {...props} />
            }
            const redirectUrl = `${TWO_FACTORS_ROUTE}?redirect=${props.location.pathname}${props.location.search}`
            return <Redirect to={redirectUrl} />
          }
          return <C {...props} />
        }
        const redirectUrl = `/login?redirect=${props.location.pathname}${props.location.search}`
        return <Redirect to={redirectUrl} />
      }}
    />
  )
}

export default memo(AuthenticatedRoute)
