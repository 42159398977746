import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  ReactNode,
  FC,
  useCallback,
} from 'react'

import { connect } from 'react-redux'
import { Client, IMessage, StompSubscription } from '@stomp/stompjs'
import SockJS from 'sockjs-client'

import { SubscriptionEventMap, SubscriptionType } from '@anews/types'

import { PropsMapper, RootState } from '../redux/reducers'
import { notifyOnce } from '../utils/notification-utils'
import i18n from '../i18n'

const Context = createContext<Client | null>(null)
const subscriptions = new Map<(message: IMessage) => void, StompSubscription>()
const resubscribers: [(newClient: Client) => void] = [] as any

type Props = PropsMapper<
  typeof stateProps,
  unknown,
  {
    children?: ReactNode
  }
>

const Provider: FC<Props> = ({ authenticated, children }: Props) => {
  const [stomp, setStomp] = useState<Client | null>(null)
  const authenticatedRef = useRef<boolean>(authenticated)
  const sessionExpiredRef = useRef<boolean>(false)

  useEffect(() => {
    authenticatedRef.current = authenticated

    if (authenticated) {
      if (stomp == null || !stomp.active) {
        const client = new Client({
          reconnectDelay: 2500,
          // connectHeaders: getConnectHeaders(),

          onWebSocketClose: event => {
            if (authenticatedRef.current && event.wasClean && event.code === 1008) {
              sessionExpiredRef.current = true
            }
            setTimeout(() => {
              if (authenticatedRef.current) {
                console.error('WebSocket Close: ', event)
                if (!sessionExpiredRef.current) {
                  notifyOnce({
                    key: 'stomp_error',
                    category: 'error',
                    message: i18n.t('error:communication'),
                    description: i18n.t('error:stomp'),
                    details: JSON.stringify(event),
                  })
                } else {
                  notifyOnce({
                    key: 'stomp_sessionExpired',
                    category: 'error',
                    message: i18n.t('error:sessionExpired'),
                    description: i18n.t('error:sessionExpiredDetails'),
                    details: JSON.stringify(event),
                  })
                }
              }
            }, 150)
          },

          onConnect: () => {
            setStomp(client)
            sessionExpiredRef.current = false
            resubscribers.forEach(resubscribe => {
              resubscribe(client)
            })
          },

          onStompError: frame => {
            if (!stomp || !stomp.activate) {
              notifyOnce({
                key: 'stomp_error',
                category: 'error',
                message: i18n.t('error:communication'),
                description: i18n.t('error:stomp'),
                details: JSON.stringify(frame),
              })
            }
          },

          onWebSocketError: event => {
            if (!stomp || !stomp.activate) {
              notifyOnce({
                key: 'stomp_error',
                category: 'error',
                message: i18n.t('error:communication'),
                description: i18n.t('error:stomp'),
                details: JSON.stringify(event),
              })
            }
          },

          webSocketFactory: () => new SockJS('/ws'),
        })

        client.activate()
      }
    } else if (stomp) {
      stomp.deactivate()
    }
  }, [authenticated, stomp])

  return <Context.Provider value={stomp}>{children}</Context.Provider>
}

const stateProps = (state: RootState) => ({
  authenticated: state.auth.authenticated,
})

export const StompProvider = connect(stateProps)(Provider)

export function useSubscription<T extends SubscriptionType>(
  destination: T,
  callback: (event: SubscriptionEventMap[T], message: IMessage) => void,
) {
  const stomp = useContext(Context)
  const callbackRef = useRef<(e: SubscriptionEventMap[T], message: IMessage) => void>(callback)
  callbackRef.current = callback

  useEffect(() => {
    const messageHandler = (message: IMessage) => {
      if (callbackRef.current) {
        const body = message && message.body ? JSON.parse(message.body) : undefined
        callbackRef.current(body, message)
      }
    }

    if (stomp && stomp.active) {
      const subscription = stomp.subscribe(destination, messageHandler)
      subscriptions.set(messageHandler, subscription)

      const resubscribe = (newClient: Client) => {
        const newSubscription = newClient.subscribe(destination, messageHandler)
        const sub = subscriptions.get(messageHandler)
        sub && sub.unsubscribe()
        subscriptions.set(messageHandler, newSubscription)
      }

      resubscribers.push(resubscribe)

      return () => {
        const sub = subscriptions.get(messageHandler)
        sub && sub.unsubscribe()
        subscriptions.delete(messageHandler)
        resubscribers.splice(resubscribers.indexOf(resubscribe), 1)
      }
    }
  }, [destination, stomp])
}

export function useSendMessage(sendInterval: number = 0) {
  const stomp = useContext(Context)
  const [justSent, setJustSent] = useState(false)

  const sendMsg = useCallback(
    <T extends SubscriptionType>(destination: T, data: SubscriptionEventMap[T]) => {
      if (stomp && stomp.active) {
        if (!justSent || sendInterval <= 0) {
          const body = JSON.stringify(data)
          stomp.publish({ destination, body })

          // Impede envio de mais mensagens ws no intervalo especificado
          if (sendInterval > 0) {
            setJustSent(true)
            setTimeout(() => setJustSent(false), sendInterval)
          }
        }
      }
    },
    [stomp, justSent, sendInterval],
  )

  return sendMsg
}
