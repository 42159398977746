import { Reportage, ReportageConfig, Page } from '@anews/types'

import { ReportageFilterParams } from '../reducers/reportages-reducer'

export enum ReportageActionType {
  NEW = '@reportage/NEW',
  CLEAR = '@reportage/CLEAR',
  UNLOAD = '@reportage/UNLOAD',
  CLOSE_TAB = '@reportage/CLOSE_TAB',
  CHANGE_TAB = '@reportage/CHANGE_TAB',
  STORE_TRANSIENT = '@reportage/STORE_TRANSIENT',
  // filterReportages()
  FILTER_REQUEST = '@reportage/FILTER_REQUEST',
  FILTER_SUCCESS = '@reportage/FILTER_SUCCESS',
  FILTER_FAILURE = '@reportage/FILTER_FAILURE',
  // loadReportage()
  LOAD_REQUEST = '@reportage/LOAD_REQUEST',
  LOAD_SUCCESS = '@reportage/LOAD_SUCCESS',
  LOAD_FAILURE = '@reportage/LOAD_FAILURE',
  // createReportage()
  CREATE_REQUEST = '@reportage/CREATE_REQUEST',
  CREATE_SUCCESS = '@reportage/CREATE_SUCCESS',
  CREATE_FAILURE = '@reportage/CREATE_FAILURE',
  // createReportageFromGuideline()
  CREATE_FROM_GUIDELINE_REQUEST = '@reportage/CREATE_FROM_GUIDELINE_REQUEST',
  CREATE_FROM_GUIDELINE_SUCCESS = '@reportage/CREATE_FROM_GUIDELINE_SUCCESS',
  CREATE_FROM_GUIDELINE_FAILURE = '@reportage/CREATE_FROM_GUIDELINE_FAILURE',
  // updateReportage()
  UPDATE_REQUEST = '@reportage/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@reportage/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@reportage/UPDATE_FAILURE',
  // patchReportage()
  PATCH_REQUEST = '@reportage/PATCH_REQUEST',
  PATCH_SUCCESS = '@reportage/PATCH_SUCCESS',
  PATCH_FAILURE = '@reportage/PATCH_FAILURE',
  // changeStateReportage()
  CHANGE_STATE_REQUEST = '@reportage/CHANGE_STATE_REQUEST',
  CHANGE_STATE_SUCCESS = '@reportage/CHANGE_STATE_SUCCESS',
  CHANGE_STATE_FAILURE = '@reportage/cHANGE_STATE_FAILURE',
  // removeReportage()
  REMOVE_REQUEST = '@reportage/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@reportage/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@reportage/REMOVE_FAILURE',
  // copyReportage()
  COPY_REQUEST = '@reportage/COPY_REQUEST',
  COPY_SUCCESS = '@reportage/COPY_SUCCESS',
  COPY_FAILURE = '@reportage/COPY_FAILURE',
  // loadConfig()
  LOAD_CONFIG_REQUEST = '@reportage/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@reportage/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@reportage/LOAD_CONFIG_FAILURE',
  // createConfig()
  CREATE_CONFIG_REQUEST = '@reportage/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@reportage/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@reportage/CREATE_CONFIG_FAILURE',
  // updateConfig()
  UPDATE_CONFIG_REQUEST = '@reportage/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@reportage/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@reportage/UPDATE_CONFIG_FAILURE',
  // websocket
  WS_CREATE = '@reportage/WS_CREATE',
  WS_DELETE = '@reportage/WS_DELETE',
  WS_UPDATE = '@reportage/WS_UPDATE',
  WS_PATCH = '@reportage/WS_PATCH',
}

export type ReportageActionMap<T extends ReportageActionType> = {
  type: T
} & ReportageAction

export type ReportageAction = ReturnType<typeof ReportageActions[keyof typeof ReportageActions]>

export const ReportageActions = {
  //
  //	filterReportages()
  //

  filterReportages: ({
    filter,
    page,
    size,
  }: {
    filter: ReportageFilterParams
    page: number
    size: number
  }) => <const>{ type: ReportageActionType.FILTER_REQUEST, filter, page, size },

  filterReportagesSuccess: (result: Page<Reportage>) =>
    <const>{ type: ReportageActionType.FILTER_SUCCESS, result },
  filterReportagesFailure: (error: Error) =>
    <const>{ type: ReportageActionType.FILTER_FAILURE, error },

  //
  //	loadReportage()
  //
  loadReportage: (target: number | Reportage, edit: boolean) =>
    <const>{ type: ReportageActionType.LOAD_REQUEST, target, edit },
  loadReportageSuccess: (reportage: Reportage, edit: boolean) =>
    <const>{ type: ReportageActionType.LOAD_SUCCESS, reportage, edit },
  loadReportageFailure: (error: Error) => <const>{ type: ReportageActionType.LOAD_FAILURE, error },

  //
  //	createReportage()
  //
  createReportage: (reportage: Reportage, lock: boolean) =>
    <const>{ type: ReportageActionType.CREATE_REQUEST, reportage, lock },
  createReportageSuccess: (reportage: Reportage) =>
    <const>{ type: ReportageActionType.CREATE_SUCCESS, reportage },
  createReportageFailure: (error: Error) =>
    <const>{ type: ReportageActionType.CREATE_FAILURE, error },

  //
  //	createReportageFromGuideline()
  //
  createReportageFromGuideline: (guidelineId: number) =>
    <const>{ type: ReportageActionType.CREATE_FROM_GUIDELINE_REQUEST, guidelineId },
  createReportageFromGuidelineSuccess: (reportage: Reportage) =>
    <const>{ type: ReportageActionType.CREATE_FROM_GUIDELINE_SUCCESS, reportage },
  createReportageFromGuidelineFailure: (error: Error) =>
    <const>{ type: ReportageActionType.CREATE_FROM_GUIDELINE_FAILURE, error },

  //
  //  patchReportage()
  //
  patchReportage: (reportageId: number, field: keyof Reportage, newValue: any, oldValue: any) =>
    <const>{ type: ReportageActionType.PATCH_REQUEST, reportageId, field, newValue, oldValue },
  patchReportageSuccess: () => <const>{ type: ReportageActionType.PATCH_SUCCESS },
  patchReportageFailure: (
    error: Error,
    reportageId: number,
    field: keyof Reportage,
    newValue: any,
    oldValue: any,
  ) =>
    <const>{
      type: ReportageActionType.PATCH_FAILURE,
      error,
      reportageId,
      field,
      newValue,
      oldValue,
    },

  //
  //  changeStateReportage()
  //
  changeStateReportage: (reportageIds: number[], state: string) =>
    <const>{ type: ReportageActionType.CHANGE_STATE_REQUEST, reportageIds, state },
  changeStateReportageSuccess: () => <const>{ type: ReportageActionType.CHANGE_STATE_SUCCESS },
  changeStateReportageFailure: (error: Error, reportageIds: number[], state: string) =>
    <const>{
      type: ReportageActionType.CHANGE_STATE_FAILURE,
      error,
      reportageIds,
      state,
    },

  //
  //	updateReportage()
  //
  updateReportage: (reportage: Reportage) =>
    <const>{ type: ReportageActionType.UPDATE_REQUEST, reportage },
  updateReportageSuccess: (reportage: Reportage) =>
    <const>{ type: ReportageActionType.UPDATE_SUCCESS, reportage },
  updateReportageFailure: (error: Error) =>
    <const>{ type: ReportageActionType.UPDATE_FAILURE, error },

  //
  //	removeReportages()
  //
  removeReportages: (ids: number[]) => <const>{ type: ReportageActionType.REMOVE_REQUEST, ids },
  removeReportagesSuccess: (ids: number[]) =>
    <const>{ type: ReportageActionType.REMOVE_SUCCESS, ids },
  removeReportagesFailure: (error: Error) =>
    <const>{ type: ReportageActionType.REMOVE_FAILURE, error },

  //
  //	newReportage()
  //
  newReportage: (reportage: Reportage) => <const>{ type: ReportageActionType.NEW, reportage },

  //
  //	unloadReportage()
  //
  unloadReportage: () => <const>{ type: ReportageActionType.UNLOAD },

  //
  //	copyReportages()
  //
  copyReportages: (ids: number[], date: string, programsIds: number[]) =>
    <const>{ type: ReportageActionType.COPY_REQUEST, ids, date, programsIds },
  copyReportagesSuccess: (reportages: Reportage[]) =>
    <const>{ type: ReportageActionType.COPY_SUCCESS, reportages },
  copyReportagesFailure: (error: Error) => <const>{ type: ReportageActionType.COPY_FAILURE, error },

  //
  //	clearReportages()
  //
  clearReportages: () => <const>{ type: ReportageActionType.CLEAR },

  //
  //	changeReportageTab()
  //
  changeReportageTab: (key: string) => <const>{ type: ReportageActionType.CHANGE_TAB, key },

  //
  //	closeReportageTab()
  //
  closeReportageTab: (key: string) => <const>{ type: ReportageActionType.CLOSE_TAB, key },

  //
  //	storeTransient()
  //
  storeTransient: (reportage: Reportage) =>
    <const>{ type: ReportageActionType.STORE_TRANSIENT, reportage },

  //
  //  loadConfig()
  //
  loadConfig: () => <const>{ type: ReportageActionType.LOAD_CONFIG_REQUEST },
  loadConfigSuccess: (config: ReportageConfig) =>
    <const>{ type: ReportageActionType.LOAD_CONFIG_SUCCESS, config },
  loadConfigFailure: (error: Error) =>
    <const>{ type: ReportageActionType.LOAD_CONFIG_FAILURE, error },

  //
  //  createConfig()
  //
  createConfig: (config: ReportageConfig) =>
    <const>{ type: ReportageActionType.CREATE_CONFIG_REQUEST, config },
  createConfigSuccess: (config: ReportageConfig) =>
    <const>{ type: ReportageActionType.CREATE_CONFIG_SUCCESS, config },
  createConfigFailure: (error: Error) =>
    <const>{ type: ReportageActionType.CREATE_CONFIG_FAILURE, error },

  //
  //  updateConfig()
  //
  updateConfig: (config: ReportageConfig) =>
    <const>{ type: ReportageActionType.UPDATE_CONFIG_REQUEST, config },
  updateConfigSuccess: (config: ReportageConfig) =>
    <const>{ type: ReportageActionType.UPDATE_CONFIG_SUCCESS, config },
  updateConfigFailure: (error: Error) =>
    <const>{ type: ReportageActionType.UPDATE_CONFIG_FAILURE, error },

  //
  //  wsCreateReportage()
  //
  wsCreateReportage: (reportage: Reportage) =>
    <const>{ type: ReportageActionType.WS_CREATE, reportage },

  //
  //  wsUpdateReportage()
  //
  wsUpdateReportage: (reportage: Reportage) =>
    <const>{ type: ReportageActionType.WS_UPDATE, reportage },

  //
  //  wsDeleteReportage()
  //
  wsDeleteReportage: (ids: number[]) => <const>{ type: ReportageActionType.WS_DELETE, ids },

  //
  //  wsPatchReportage()
  //
  wsPatchReportage: (reportageId: number, changes: Partial<Reportage>) =>
    <const>{ type: ReportageActionType.WS_PATCH, reportageId, changes },
}
