import React, { memo } from 'react'

import Cell from './Cell'

import { ColumnProps, RowKey } from './index'

interface Props {
  rowKey: RowKey
  record: any
  columns: ColumnProps<any>[]
  isSelected?: boolean
  disabled?: boolean
}

const Cells = (props: Props) => {
  const { rowKey, record, columns, isSelected, disabled } = props

  return (
    <>
      {columns.map((column, index) => {
        const recordKey = record[rowKey]
        let recordValue = column.dataIndex ? record[column.dataIndex] : undefined

        if (column.extractor) {
          recordValue = column.extractor(recordValue, recordKey)
        }

        return (
          <Cell
            key={column.key}
            column={column}
            index={index}
            isSelected={isSelected}
            recordKey={recordKey}
            recordValue={recordValue}
            disabled={disabled}
          />
        )
      })}
    </>
  )
}

export default memo(Cells)
