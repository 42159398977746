import { Reducer } from 'redux'

import { IndexesState } from '@anews/types'

import { IndexActionType as ActionType, IndexAction } from '../actions'

export interface IndexState {
  loading: boolean
  saving: boolean
  data?: IndexesState
}

export const defaultState: IndexState = {
  loading: false,
  saving: false,
  data: undefined,
}

const indexReducer: Reducer<IndexState, IndexAction> = (
  state = defaultState,
  action,
): IndexState => {
  switch (action.type) {
    case ActionType.LOAD_STATE_REQUEST:
    case ActionType.REBUILD_REQUEST:
      return { ...state, loading: true }

    case ActionType.LOAD_STATE_FAILURE:
    case ActionType.REBUILD_FAILURE:
      return { ...state, loading: false }

    case ActionType.LOAD_STATE_SUCCESS:
    case ActionType.UPDATE_STATE:
      return { ...state, data: action.state, loading: false }
    default:
      return state
  }
}

export default indexReducer
