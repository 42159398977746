import React, { memo } from 'react'
import parseHtml from 'react-html-parser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faVideo } from '@fortawesome/pro-solid-svg-icons'

import Col from 'antd/es/col'
import Row from 'antd/es/row'
import Space from 'antd/es/space'

import { StorySection, StorySectionType, StorySubSection, StorySubSectionType } from '@anews/types'
import { formatIsoDuration, isNotBlank, richHasContent } from '@anews/utils'

import theme from '../../../../theme'

import { SelectorMap } from '../../../../redux/selectors/helpers'
import { useTranslation } from '../../../../i18n'
import { ViewerItem } from '../../../global'

import { mediaTextDisplay } from '../../../../utils/mos-utils'

import RichTextViewer from '../../../global/RichText/RichTextViewer'

import CopyableCGValue from './CopyableCGValue'

function statusStyle(subSection: StorySubSection) {
  const { comment, disabled } = subSection

  const backgroundColor = disabled
    ? theme.palletes.red[0]
    : comment
    ? theme.palletes.grey[2]
    : undefined

  return backgroundColor
    ? { backgroundColor, borderRadius: 2, margin: -8, marginBottom: 8, padding: 8 }
    : {}
}

interface Props {
  section: StorySection
  subSection: StorySubSection
  users: SelectorMap<string>
  preview?: boolean
  hideCgs?: boolean
}

type StoryGenericCGViewProps = Omit<Props, 'section' | 'users' | 'hideCgs'>

type StoryGenericVideoViewProps = Omit<Props, 'preview' | 'section' | 'users' | 'hideCgs'>

type StoryGenericTextViewProps = Props

type StorySubSectionViewProps = Props

function StoryGenericCGView({ subSection, preview }: StoryGenericCGViewProps) {
  const { t } = useTranslation()

  const { code, slug, templateName, cgFields, media } = subSection

  // Nos GCs II, apresenta o nome do template quando não tem campos
  const title = slug || (cgFields && cgFields.length > 0 ? undefined : templateName)

  const codeView = code ? (
    <ViewerItem.Header className="viewer-header" style={{ color: theme.colors.red }}>
      {t('story:cgItem')} {code}
    </ViewerItem.Header>
  ) : (
    <FontAwesomeIcon icon={faAddressCard} size="sm" />
  )
  const titleView = (
    <span>
      {title}
      {media && (
        <>
          <br />
          {mediaTextDisplay(media)}
        </>
      )}
    </span>
  )

  const fieldsView =
    cgFields &&
    cgFields.map(field => (
      <ViewerItem key={field.uuid}>
        {/* fields da 4S começam com "var " */}
        <b>{field.name.replace(/^var /, '')}: </b>
        {preview === true ? <CopyableCGValue value={field.value} /> : field.value}
      </ViewerItem>
    ))

  // Se tiver a retranca, apresenta os fields abaixo do "GC"
  // Se não tiver, apresenta do lado
  return (
    <div style={statusStyle(subSection)}>
      {isNotBlank(title) ? (
        <>
          <Space align="baseline" style={{ width: '100%' }}>
            {codeView}
            {titleView}
          </Space>

          {fieldsView}
        </>
      ) : (
        <>
          <Space align="baseline" style={{ width: '100%' }}>
            {codeView}
            {titleView}

            <div>{fieldsView}</div>
          </Space>
        </>
      )}
    </div>
  )
}

function StoryGenericTextView({
  section,
  subSection,
  users,
  preview,
  hideCgs,
}: StoryGenericTextViewProps) {
  const { presenterId, camera, duration, text, subSections } = subSection
  const linkType = section.type === StorySectionType.LINK

  const { t } = useTranslation()

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div>
        <Row>
          <Col span={18}>
            {!linkType ? (
              <ViewerItem.Header className="viewer-header">
                {presenterId && <span>[{users[presenterId]}]</span>}
                {(camera || 0) > 0 && (
                  <span>
                    {' '}
                    [{t('story:camera')} {camera}]
                  </span>
                )}
              </ViewerItem.Header>
            ) : (
              <ViewerItem.Header className="viewer-header" style={{ textTransform: 'uppercase' }}>
                [{t('words:reporter')}]
              </ViewerItem.Header>
            )}
          </Col>
          {!linkType && (
            <Col span={6}>
              <ViewerItem.Header className="viewer-header" style={{ textAlign: 'right' }}>
                {formatIsoDuration(duration!, 'mm:ss')}
              </ViewerItem.Header>
            </Col>
          )}
        </Row>
        {richHasContent(text) && (
          <ViewerItem highlightComments>
            <RichTextViewer content={text} />
          </ViewerItem>
        )}
      </div>

      {subSections?.map(subSub => (
        <StorySubSectionView
          key={subSub.uuid}
          section={section}
          subSection={subSub}
          users={users}
          preview={preview}
          hideCgs={hideCgs}
        />
      ))}
    </Space>
  )
}

function StoryGenericVideoView({ subSection }: StoryGenericVideoViewProps) {
  const { slug, status, duration, media, channel } = subSection
  const { t } = useTranslation()

  return (
    <div style={statusStyle(subSection)}>
      <Row>
        <Col span={18}>
          <Space align="baseline" style={{ width: '100%' }}>
            <FontAwesomeIcon icon={faVideo} size="sm" />

            <ViewerItem>
              {slug}
              {media?.abstractText && media?.abstractText !== slug ? (
                <>
                  <br />
                  {parseHtml(media?.abstractText)}
                </>
              ) : null}
            </ViewerItem>

            {status && <ViewerItem>({status})</ViewerItem>}
          </Space>
        </Col>
        <Col span={6}>
          <Space align="baseline" style={{ float: 'right' }}>
            {channel && (
              <ViewerItem>
                {t('device:channel')}: {channel}
              </ViewerItem>
            )}
            <ViewerItem.Header style={{ textAlign: 'right' }}>
              {formatIsoDuration(duration!, 'mm:ss')}
            </ViewerItem.Header>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

function StorySubSectionView({
  section,
  subSection,
  users,
  preview,
  hideCgs,
}: StorySubSectionViewProps) {
  const { type } = subSection

  switch (type) {
    case StorySubSectionType.CAMERA_TEXT:
    case StorySubSectionType.TEXT:
      return (
        <StoryGenericTextView
          section={section}
          subSection={subSection}
          users={users}
          preview={preview}
          hideCgs={hideCgs}
        />
      )

    case StorySubSectionType.CG:
    case StorySubSectionType.MOS_CG:
      return hideCgs ? null : <StoryGenericCGView subSection={subSection} preview={preview} />

    case StorySubSectionType.MOS_VIDEO:
      return <StoryGenericVideoView subSection={subSection} />

    default:
      return null
  }
}

export default memo(StorySubSectionView)
