import { del, get, post, put } from './client';
var endpoint = '/api/facebook/pages';
var facebookPageApi = {
    list: function () {
        return get(endpoint);
    },
    create: function (page) {
        return post(endpoint, page);
    },
    update: function (page) {
        return put("" + endpoint, page);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default facebookPageApi;
