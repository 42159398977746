import { del, get, post, put, query } from './client';
var endpoint = '/api/devices';
var iiTemplateApi = {
    list: function (deviceId) {
        return get(endpoint + "/" + deviceId + "/templates");
    },
    load: function (deviceId, templateId) {
        return get(endpoint + "/" + deviceId + "/templates/" + templateId);
    },
    create: function (deviceId, template) {
        return post(endpoint + "/" + deviceId + "/templates", template);
    },
    update: function (deviceId, template) {
        return put("" + (endpoint + "/" + deviceId + "/templates"), template);
    },
    remove: function (deviceId, templateIds) {
        return del(query("" + (endpoint + "/" + deviceId + "/templates"), { templateIds: templateIds }));
    },
    getGcMedias: function (deviceId) {
        return get(endpoint + "/" + deviceId + "/templates/mos");
    },
};
export default iiTemplateApi;
