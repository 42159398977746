import { Reducer } from 'redux'

import { User, AuthenticationLog } from '@anews/types'

import { AuthActionType, AuthAction } from '../actions/auth-actions'

const initialState = {
  authenticated: false,
  authenticating: false,
  authenticated2FA: false,
  authorities: {
    data: new Array<string>(),
    loading: true,
  },
  user: undefined as User | undefined,
  verifying: true,
  firstLogin: false,
}

export interface AuthenticationLogsStates {
  data: AuthenticationLog[]
  number: number
  size: number
  total: number
  filter: string
  loading: boolean
  activeOnly: boolean
}

export type AuthState = typeof initialState
export type AuthoritiesState = AuthState['authorities']

function authoritiesReducer(state: AuthoritiesState, action: AuthAction): AuthoritiesState {
  switch (action.type) {
    case AuthActionType.LIST_AUTHORITIES_REQUEST:
      return { ...state, loading: true }
    case AuthActionType.LIST_AUTHORITIES_FAILURE:
      return { ...state, loading: false }
    case AuthActionType.LIST_AUTHORITIES_SUCCESS:
      return { ...state, data: action.authorities, loading: false }
    default:
      return state
  }
}

const authReducer: Reducer<AuthState, AuthAction> = (state = initialState, action): AuthState => {
  switch (action.type) {
    case AuthActionType.LIST_AUTHORITIES_REQUEST:
    case AuthActionType.LIST_AUTHORITIES_SUCCESS:
    case AuthActionType.LIST_AUTHORITIES_FAILURE:
      return { ...state, authorities: authoritiesReducer(state.authorities, action) }

    case AuthActionType.LOAD_AUTH_REQUEST:
      return { ...state, verifying: true }
    case AuthActionType.LOAD_AUTH_SUCCESS:
      return {
        ...state,
        verifying: false,
        authenticated: true,
        authenticated2FA: action.user.authenticated2FA,
        user: action.user,
      }
    case AuthActionType.LOAD_AUTH_FAILURE:
      return {
        ...state,
        verifying: false,
        authenticated: false,
        user: undefined,
      }

    case AuthActionType.LOGIN_REQUEST:
      return { ...state, authenticating: true }
    case AuthActionType.LOGIN_SUCCESS:
      return { ...state, authenticating: false }
    case AuthActionType.LOGIN_FAILURE:
      return { ...state, authenticating: false }

    case AuthActionType.LOGIN_2FA_REQUEST:
      return { ...state, authenticating: true }
    case AuthActionType.LOGIN_2FA_SUCCESS:
      return { ...state, authenticated2FA: action.validCode }
    case AuthActionType.LOGIN_2FA_FAILURE:
      return { ...state, authenticating: false }

    case AuthActionType.VALIDATE_RECOVERY_CODE_REQUEST:
      return { ...state, authenticating: true }
    case AuthActionType.VALIDATE_RECOVERY_CODE_SUCCESS:
      return { ...state, authenticated2FA: action.validCode }
    case AuthActionType.VALIDATE_RECOVERY_CODE_FAILURE:
      return { ...state, authenticating: false }

    case AuthActionType.LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
        authenticated2FA: false,
        authenticating: false,
        user: undefined,
      }

    case AuthActionType.FIRST_LOGIN:
      return { ...state, firstLogin: action.firstLogin }

    default:
      return state
  }
}

export default authReducer
