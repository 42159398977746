import { del, get, post, put } from './client';
var endpoint = '/api/productions/types';
var checklistTypeApi = {
    list: function () {
        return get(endpoint);
    },
    load: function (id) {
        return get(endpoint + "/" + id);
    },
    usageCount: function (id) {
        return get(endpoint + "/" + id + "/usage_count");
    },
    create: function (type) {
        return post(endpoint, type);
    },
    update: function (type) {
        return put("" + endpoint, type);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default checklistTypeApi;
