import {
  Company,
  Classification,
  User,
  Vehicle,
  Program,
  DocumentConfig,
  GuidelineConfig,
  Editorial,
  Team,
  DocumentType,
  ReportageConfig,
  StoryConfig,
  RundownConfig,
  CustomMetadata,
} from '@anews/types'

export enum CompanyActionType {
  LIST_REQUEST = '@companies/LIST_REQUEST',
  LIST_SUCCESS = '@companies/LIST_SUCCESS',
  LIST_FAILURE = '@companies/LIST_FAILURE',
  // loadRemotePrograms()
  LIST_PROGRAMS_REQUEST = '@companies/LIST_PROGRAMS_REQUEST',
  LIST_PROGRAMS_SUCCESS = '@companies/LIST_PROGRAMS_SUCCESS',
  LIST_PROGRAMS_FAILURE = '@companies/LIST_PROGRAMS_FAILURE',
  // loadRemoteClassifications()
  LIST_CLASSIFICATIONS_REQUEST = '@companies/LIST_CLASSIFICATIONS_REQUEST',
  LIST_CLASSIFICATIONS_SUCCESS = '@companies/LIST_CLASSIFICATIONS_SUCCESS',
  LIST_CLASSIFICATIONS_FAILURE = '@companies/LIST_CLASSIFICATIONS_FAILURE',
  // loadRemoteUsers()
  LIST_USERS_REQUEST = '@companies/LIST_USERS_REQUEST',
  LIST_USERS_SUCCESS = '@companies/LIST_USERS_SUCCESS',
  LIST_USERS_FAILURE = '@companies/LIST_USERS_FAILURE',
  // loadRemoteEditors()
  LIST_EDITORS_REQUEST = '@companies/LIST_EDITORS_REQUEST',
  LIST_EDITORS_SUCCESS = '@companies/LIST_EDITORS_SUCCESS',
  LIST_EDITORS_FAILURE = '@companies/LIST_EDITORS_FAILURE',
  // loadRemoteMetadata()
  LIST_METADATA_REQUEST = '@companies/LIST_METADATA_REQUEST',
  LIST_METADATA_SUCCESS = '@companies/LIST_METADATA_SUCCESS',
  LIST_METADATA_FAILURE = '@companies/LIST_METADATA_FAILURE',
  // loadRemoteProducers()
  LIST_PRODUCERS_REQUEST = '@companies/LIST_PRODUCERS_REQUEST',
  LIST_PRODUCERS_SUCCESS = '@companies/LIST_PRODUCERS_SUCCESS',
  LIST_PRODUCERS_FAILURE = '@companies/LIST_PRODUCERS_FAILURE',
  // loadRemoteReporters()
  LIST_REPORTERS_REQUEST = '@companies/LIST_REPORTERS_REQUEST',
  LIST_REPORTERS_SUCCESS = '@companies/LIST_REPORTERS_SUCCESS',
  LIST_REPORTERS_FAILURE = '@companies/LIST_REPORTERS_FAILURE',
  // loadRemoteVehicles()
  LIST_VEHICLES_REQUEST = '@companies/LIST_VEHICLES_REQUEST',
  LIST_VEHICLES_SUCCESS = '@companies/LIST_VEHICLES_SUCCESS',
  LIST_VEHICLES_FAILURE = '@companies/LIST_VEHICLES_FAILURE',
  // loadRemoteDocumentConfig()
  LIST_DOCUMENT_CONFIG_REQUEST = '@companies/LIST_DOCUMENT_CONFIG_REQUEST',
  LIST_DOCUMENT_CONFIG_SUCCESS = '@companies/LIST_DOCUMENT_CONFIG_SUCCESS',
  LIST_DOCUMENT_CONFIG_FAILURE = '@companies/LIST_DOCUMENT_CONFIG_FAILURE',
  // loadRemoteGuidelineConfig()
  LIST_GUIDELINE_CONFIG_REQUEST = '@companies/LIST_GUIDELINE_CONFIG_REQUEST',
  LIST_GUIDELINE_CONFIG_SUCCESS = '@companies/LIST_GUIDELINE_CONFIG_SUCCESS',
  LIST_GUIDELINE_CONFIG_FAILURE = '@companies/LIST_GUIDELINE_CONFIG_FAILURE',
  // loadRemoteReportageConfig()
  LIST_REPORTAGE_CONFIG_REQUEST = '@companies/LIST_REPORTAGE_CONFIG_REQUEST',
  LIST_REPORTAGE_CONFIG_SUCCESS = '@companies/LIST_REPORTAGE_CONFIG_SUCCESS',
  LIST_REPORTAGE_CONFIG_FAILURE = '@companies/LIST_REPORTAGE_CONFIG_FAILURE',
  // loadRemoteStoryConfig()
  LIST_STORY_CONFIG_REQUEST = '@companies/LIST_STORY_CONFIG_REQUEST',
  LIST_STORY_CONFIG_SUCCESS = '@companies/LIST_STORY_CONFIG_SUCCESS',
  LIST_STORY_CONFIG_FAILURE = '@companies/LIST_STORY_CONFIG_FAILURE',
  // loadRemoteRundownConfig()
  LIST_RUNDOWN_CONFIG_REQUEST = '@companies/LIST_RUNDOWN_CONFIG_REQUEST',
  LIST_RUNDOWN_CONFIG_SUCCESS = '@companies/LIST_RUNDOWN_CONFIG_SUCCESS',
  LIST_RUNDOWN_CONFIG_FAILURE = '@companies/LIST_RUNDOWN_CONFIG_FAILURE',
  // loadRemoteCompanies()
  LIST_REMOTE_REQUEST = '@companies/LIST_REMOTE_REQUEST',
  LIST_REMOTE_SUCCESS = '@companies/LIST_REMOTE_SUCCESS',
  LIST_REMOTE_FAILURE = '@companies/LIST_REMOTE_FAILURE',
  // loadRemoteEditorials()
  LIST_EDITORIALS_REQUEST = '@companies/LIST_EDITORIALS_REQUEST',
  LIST_EDITORIALS_SUCCESS = '@companies/LIST_EDITORIALS_SUCCESS',
  LIST_EDITORIALS_FAILURE = '@companies/LIST_EDITORIALS_FAILURE',
  // loadRemoteTeams()
  LIST_TEAMS_REQUEST = '@companies/LIST_TEAMS_REQUEST',
  LIST_TEAMS_SUCCESS = '@companies/LIST_TEAMS_SUCCESS',
  LIST_TEAMS_FAILURE = '@companies/LIST_TEAMS_FAILURE',
  // loadRemoteTypes()
  LIST_TYPES_REQUEST = '@companies/LIST_TYPES_REQUEST',
  LIST_TYPES_SUCCESS = '@companies/LIST_TYPES_SUCCESS',
  LIST_TYPES_FAILURE = '@companies/LIST_TYPES_FAILURE',
}

export type CompanyActionMap<T extends CompanyActionType> = { type: T } & CompanyAction

export type CompanyAction = ReturnType<typeof CompanyActions[keyof typeof CompanyActions]>

export const CompanyActions = {
  //
  //	listCompanies()
  //

  listCompanies: () => <const>{ type: CompanyActionType.LIST_REQUEST },
  listCompaniesSuccess: (companies: Company[]) =>
    <const>{ type: CompanyActionType.LIST_SUCCESS, companies },
  listCompaniesFailure: (error: Error) => <const>{ type: CompanyActionType.LIST_FAILURE, error },

  //
  //	listPrograms()
  //

  listRemotePrograms: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_PROGRAMS_REQUEST, companyId },
  listRemoteProgramsSuccess: (companyId: number, programs: Program[]) =>
    <const>{ type: CompanyActionType.LIST_PROGRAMS_SUCCESS, companyId, programs },
  listRemoteProgramsFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_PROGRAMS_FAILURE, error, companyId },

  //
  //	listClassifications()
  //

  listRemoteClassifications: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_CLASSIFICATIONS_REQUEST, companyId },
  listRemoteClassificationsSuccess: (companyId: number, classifications: Classification[]) =>
    <const>{ type: CompanyActionType.LIST_CLASSIFICATIONS_SUCCESS, companyId, classifications },
  listRemoteClassificationsFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_CLASSIFICATIONS_FAILURE, error, companyId },

  //
  //	listUsers()
  //

  listRemoteUsers: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_USERS_REQUEST, companyId },
  listRemoteUsersSuccess: (companyId: number, users: User[]) =>
    <const>{ type: CompanyActionType.LIST_USERS_SUCCESS, companyId, users },
  listRemoteUsersFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_USERS_FAILURE, error, companyId },

  //
  //	listEditors()
  //

  listRemoteEditors: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_EDITORS_REQUEST, companyId },
  listRemoteEditorsSuccess: (companyId: number, editors: User[]) =>
    <const>{ type: CompanyActionType.LIST_EDITORS_SUCCESS, companyId, editors },
  listRemoteEditorsFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_EDITORS_FAILURE, error, companyId },

  //
  //  listMetadata()
  //

  listRemoteMetadata: (companyId: number) =>
    ({ type: CompanyActionType.LIST_METADATA_REQUEST, companyId } as const),
  listRemoteMetadataSuccess: (companyId: number, metadata: CustomMetadata[]) =>
    ({ type: CompanyActionType.LIST_METADATA_SUCCESS, companyId, metadata } as const),
  listRemoteMetadataFailure: (companyId: number, error: unknown) =>
    ({ type: CompanyActionType.LIST_METADATA_FAILURE, error, companyId } as const),

  //
  //	listProducers()
  //

  listRemoteProducers: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_PRODUCERS_REQUEST, companyId },
  listRemoteProducersSuccess: (companyId: number, producers: User[]) =>
    <const>{ type: CompanyActionType.LIST_PRODUCERS_SUCCESS, companyId, producers },
  listRemoteProducersFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_PRODUCERS_FAILURE, error, companyId },

  //
  //	listReporters()
  //

  listRemoteReporters: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_REPORTERS_REQUEST, companyId },
  listRemoteReportersSuccess: (companyId: number, reporters: User[]) =>
    <const>{ type: CompanyActionType.LIST_REPORTERS_SUCCESS, companyId, reporters },
  listRemoteReportersFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_REPORTERS_FAILURE, error, companyId },

  //
  //	listVehicles()
  //

  listRemoteVehicles: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_VEHICLES_REQUEST, companyId },
  listRemoteVehiclesSuccess: (companyId: number, vehicles: Vehicle[]) =>
    <const>{ type: CompanyActionType.LIST_VEHICLES_SUCCESS, companyId, vehicles },
  listRemoteVehiclesFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_VEHICLES_FAILURE, error, companyId },

  //
  //	listDocumentConfig()
  //

  listRemoteDocumentConfig: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_DOCUMENT_CONFIG_REQUEST, companyId },
  listRemoteDocumentConfigSuccess: (companyId: number, documentConfig: DocumentConfig) =>
    <const>{ type: CompanyActionType.LIST_DOCUMENT_CONFIG_SUCCESS, companyId, documentConfig },
  listRemoteDocumentConfigFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_DOCUMENT_CONFIG_FAILURE, error, companyId },

  //
  //	listGuidelineConfig()
  //

  listRemoteGuidelineConfig: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_GUIDELINE_CONFIG_REQUEST, companyId },
  listRemoteGuidelineConfigSuccess: (companyId: number, guidelineConfig: GuidelineConfig) =>
    <const>{ type: CompanyActionType.LIST_GUIDELINE_CONFIG_SUCCESS, companyId, guidelineConfig },
  listRemoteGuidelineConfigFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_GUIDELINE_CONFIG_FAILURE, error, companyId },

  //
  //	listReportageConfig()
  //

  listRemoteReportageConfig: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_REPORTAGE_CONFIG_REQUEST, companyId },
  listRemoteReportageConfigSuccess: (companyId: number, reportageConfig: ReportageConfig) =>
    <const>{ type: CompanyActionType.LIST_REPORTAGE_CONFIG_SUCCESS, companyId, reportageConfig },
  listRemoteReportageConfigFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_REPORTAGE_CONFIG_FAILURE, error, companyId },

  //
  //	listStoryConfig()
  //

  listRemoteStoryConfig: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_STORY_CONFIG_REQUEST, companyId },
  listRemoteStoryConfigSuccess: (companyId: number, storyConfig: StoryConfig) =>
    <const>{ type: CompanyActionType.LIST_STORY_CONFIG_SUCCESS, companyId, storyConfig },
  listRemoteRundownConfigFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_RUNDOWN_CONFIG_FAILURE, error, companyId },

  //
  //	listRundownConfig()
  //

  listRemoteRundownConfig: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_RUNDOWN_CONFIG_REQUEST, companyId },
  listRemoteRundownConfigSuccess: (companyId: number, rundownConfig: RundownConfig) =>
    <const>{ type: CompanyActionType.LIST_RUNDOWN_CONFIG_SUCCESS, companyId, rundownConfig },
  listRemoteStoryConfigFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_STORY_CONFIG_FAILURE, error, companyId },

  //
  //	listRemoteCompanies()
  //

  listRemoteCompanies: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_REMOTE_REQUEST, companyId },
  listRemoteCompaniesSuccess: (companyId: number, remoteCompanies: Company[]) =>
    <const>{ type: CompanyActionType.LIST_REMOTE_SUCCESS, companyId, remoteCompanies },
  listRemoteCompaniesFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_REMOTE_FAILURE, error, companyId },

  //
  //	listRemoteEditorials()
  //

  listRemoteEditorials: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_EDITORIALS_REQUEST, companyId },
  listRemoteEditorialsSuccess: (companyId: number, editorials: Editorial[]) =>
    <const>{ type: CompanyActionType.LIST_EDITORIALS_SUCCESS, companyId, editorials },
  listRemoteEditorialsFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_EDITORIALS_FAILURE, error, companyId },

  //
  //	listRemoteTeams()
  //

  listRemoteTeams: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_TEAMS_REQUEST, companyId },
  listRemoteTeamsSuccess: (companyId: number, teams: Team[]) =>
    <const>{ type: CompanyActionType.LIST_TEAMS_SUCCESS, companyId, teams },
  listRemoteTeamsFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_TEAMS_FAILURE, error, companyId },

  //
  //	listRemoteTypes()
  //

  listRemoteTypes: (companyId: number) =>
    <const>{ type: CompanyActionType.LIST_TYPES_REQUEST, companyId },
  listRemoteTypesSuccess: (companyId: number, types: DocumentType[]) =>
    <const>{ type: CompanyActionType.LIST_TYPES_SUCCESS, companyId, types },
  listRemoteTypesFailure: (companyId: number, error: Error) =>
    <const>{ type: CompanyActionType.LIST_TYPES_FAILURE, error, companyId },
}
