import { ReportParams, Reports } from '@anews/types'
import { toQueryString } from '@anews/utils'

// Mantendo export para nao quebrar ja implementados
// Usar enum Reports
export const {
  GUIDELINE_ARCHIVE,
  GUIDELINE_CONTENT,
  GUIDELINE_GRID,
  NEWS_CONTENT,
  REPORTAGE_ARCHIVE,
  REPORTAGE_CONTENT,
  ROUND_CONTENT,
  STORY_CONTENT,
  STORY_VO,
  STORY_GRID,
  TEAM_GRID,
} = Reports
// ///////

export function print<T extends Reports, K extends ReportParams>(report: T, params: K[T]) {
  const r = Math.round(Math.random() * 9999)
  const query = toQueryString({ r, ...params })
  const encoded = encodeURIComponent(btoa(query))

  window.open(`/print/${encoded}/${report}`, '_blank')
}

export function printRemote<T extends Reports, K extends ReportParams>(
  report: T,
  params: K[T],
  companyId: number,
) {
  const r = Math.round(Math.random() * 9999)
  const query = toQueryString({ r, ...params })
  const encoded = encodeURIComponent(btoa(query))

  window.open(`/print/branches/${companyId}/${encoded}/${report}`, '_blank')
}
