import { FacebookConfig } from '@anews/types'

export enum FacebookActionType {
  LOAD_CONFIG_REQUEST = '@facebook/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@facebook/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@facebook/LOAD_CONFIG_FAILURE',
  CREATE_CONFIG_REQUEST = '@facebook/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@facebook/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@facebook/CREATE_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@facebook/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@facebook/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@facebook/UPDATE_CONFIG_FAILURE',
}

export type FacebookActionMap<T extends FacebookActionType> = { type: T } & FacebookAction

export type FacebookAction = ReturnType<typeof FacebookActions[keyof typeof FacebookActions]>

export const FacebookActions = {
  //
  //  loadConfig()
  //

  loadConfig: () => <const>{ type: FacebookActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: FacebookConfig) =>
    <const>{ type: FacebookActionType.LOAD_CONFIG_SUCCESS, config },

  loadConfigFailure: (error: Error) =>
    <const>{ type: FacebookActionType.LOAD_CONFIG_FAILURE, error },

  //
  //  createConfig()
  //

  createConfig: (config: FacebookConfig) =>
    <const>{ type: FacebookActionType.CREATE_CONFIG_REQUEST, config },

  createConfigSuccess: (config: FacebookConfig) =>
    <const>{ type: FacebookActionType.CREATE_CONFIG_SUCCESS, config },

  createConfigFailure: (error: Error) =>
    <const>{ type: FacebookActionType.CREATE_CONFIG_FAILURE, error },

  //
  //  updateConfig()
  //

  updateConfig: (config: FacebookConfig) =>
    <const>{ type: FacebookActionType.UPDATE_CONFIG_REQUEST, config },

  updateConfigSuccess: (config: FacebookConfig) =>
    <const>{ type: FacebookActionType.UPDATE_CONFIG_SUCCESS, config },

  updateConfigFailure: (error: Error) =>
    <const>{ type: FacebookActionType.UPDATE_CONFIG_FAILURE, error },
}
