import React, { PureComponent } from 'react'
import { Moment } from 'moment'

import AntDatePicker from 'antd/es/date-picker'
import { PickerDateProps } from 'antd/es/date-picker/generatePicker'

class DatePicker extends PureComponent<PickerDateProps<Moment>> {
  render() {
    const { showTime } = this.props
    return (
      <AntDatePicker
        format={showTime ? 'DD/MM/YYYY HH:mm' : 'L'}
        style={{ minWidth: 104, width: showTime ? 144 : 112 }}
        // css={css`
        //   min-width: 120px;
        // `}
        // disabledTime={disabledTime || defaultDisabledTime}
        {...this.props}
      />
    )
  }
}

export default DatePicker
