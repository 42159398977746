import { del, get, post, put } from './client';
var endpoint = '/api/rounds/institutions';
var institutionApi = {
    list: function () {
        return get(endpoint);
    },
    create: function (institution) {
        return post(endpoint, institution);
    },
    update: function (institution) {
        return put("" + endpoint, institution);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default institutionApi;
