import { License } from '@anews/types'

export enum ActionType {
  // check()
  INFO_LICENSE_REQUEST = '@license/INFO_LICENSE_REQUEST',
  INFO_LICENSE_SUCCESS = '@license/INFO_LICENSE_SUCCESS',
  INFO_LICENSE_FAILURE = '@license/INFO_LICENSE_FAILURE',
  // daysToExpire()
  DAYS_LICENSE_REQUEST = '@license/DAYS_LICENSE_REQUEST',
  // SetServerUrl()
  SET_SERVER_URL_REQUEST = '@license/SET_SERVER_URL_REQUEST',
  SET_SERVER_URL_SUCCESS = '@license/SET_SERVER_URL_SUCCESS',
  SET_SERVER_URL_FAILURE = '@license/SET_SERVER_URL_FAILURE',
  // register()
  REGISTER_REQUEST = '@license/REGISTER_REQUEST',
  REGISTER_SUCCESS = '@license/REGISTER_SUCCESS',
  REGISTER_FAILURE = '@license/REGISTER_FAILURE',
}

export type LicenseInfoAction = ReturnType<typeof createLicenseInfo>
export type LicenseDaysToExpireAction = ReturnType<typeof notifyDaysToExpire>
export type LicenseRegisterAction = ReturnType<typeof createLicenseRegister>
export type SetServerUrlLicenseAction = ReturnType<typeof createSetServerUrl>

export type LicenseAction =
  /* licenseInfo() */
  | LicenseInfoAction
  | ReturnType<typeof createLicenseInfoSuccess>
  | ReturnType<typeof createLicenseInfoFailure>

  /* setServerUrl() */
  | SetServerUrlLicenseAction
  | ReturnType<typeof createSetServerUrlSuccess>
  | ReturnType<typeof createSetServerUrlFailure>

  /* register() */
  | LicenseRegisterAction
  | ReturnType<typeof createLicenseRegisterSuccess>
  | ReturnType<typeof createLicenseRegisterFailure>

//
//  licenseInfo()
//

export const createLicenseInfo = () => ({
  type: ActionType.INFO_LICENSE_REQUEST as ActionType.INFO_LICENSE_REQUEST,
})

export const createLicenseInfoSuccess = (license: License) => ({
  type: ActionType.INFO_LICENSE_SUCCESS as ActionType.INFO_LICENSE_SUCCESS,
  license,
})

export const createLicenseInfoFailure = (error: Error) => ({
  type: ActionType.INFO_LICENSE_FAILURE as ActionType.INFO_LICENSE_FAILURE,
  error,
})

//
// daysToExpire()
//

export const notifyDaysToExpire = () => ({
  type: ActionType.DAYS_LICENSE_REQUEST as ActionType.DAYS_LICENSE_REQUEST,
})

//
//  setServerUrl()
//

export const createSetServerUrl = (url: string) => ({
  type: ActionType.SET_SERVER_URL_REQUEST as ActionType.SET_SERVER_URL_REQUEST,
  url,
})

export const createSetServerUrlSuccess = (license: License) => ({
  type: ActionType.SET_SERVER_URL_SUCCESS as ActionType.SET_SERVER_URL_SUCCESS,
  license,
})

export const createSetServerUrlFailure = (error: Error) => ({
  type: ActionType.SET_SERVER_URL_FAILURE as ActionType.SET_SERVER_URL_FAILURE,
  error,
})

//
//  register()
//

export const createLicenseRegister = (license: License) => ({
  type: ActionType.REGISTER_REQUEST as ActionType.REGISTER_REQUEST,
  license,
})

export const createLicenseRegisterSuccess = (license: License) => ({
  type: ActionType.REGISTER_SUCCESS as ActionType.REGISTER_SUCCESS,
  license,
})

export const createLicenseRegisterFailure = (error: Error) => ({
  type: ActionType.REGISTER_FAILURE as ActionType.REGISTER_FAILURE,
  error,
})
