import { call, put, takeLatest } from 'redux-saga/effects'

import { nominatimApi as api } from '@anews/api'
import { NominatimConfig } from '@anews/types'

import {
  NominatimActions,
  NominatimActionMap,
  NominatimActionType,
  NotificationActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  createConfigSuccess,
  createConfigFailure,
} = NominatimActions

const { notifyError } = NotificationActions

/* Watchers */

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as NominatimConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(
  action: NominatimActionMap<NominatimActionType.CREATE_CONFIG_REQUEST>,
): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as NominatimConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(
  action: NominatimActionMap<NominatimActionType.UPDATE_CONFIG_REQUEST>,
): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as NominatimConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(NominatimActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(NominatimActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(NominatimActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
