import { get, post } from './client';
var endpoint = '/api/2fa';
var twoFactorsApi = {
    generateSecretKey: function (id) {
        return post(endpoint + "/" + id + "/generate");
    },
    generatedQrCode: function (id, secretKey) {
        return get(endpoint + "/" + id + "/" + secretKey + "/qr_code");
    },
    recoveryCodes: function (id) {
        return get(endpoint + "/" + id + "/codes");
    },
    amountRecoveryCodes: function (id) {
        return get(endpoint + "/" + id + "/amountCodes");
    },
    reset2FaCodePassword: function (id) {
        return post(endpoint + "/" + id + "/reset");
    },
    first2FAValidade: function (id, secretKey, code) {
        return post("/api/2fa/" + id + "/" + secretKey + "/" + code + "/first2FAValidade");
    },
    validateCode: function (id, code) {
        return post("/api/2fa/" + id + "/" + code + "/validate");
    },
    validateRecoveryCode: function (id, code) {
        return post("/api/2fa/" + id + "/" + code + "/recover");
    },
};
export default twoFactorsApi;
