import React, { FC, memo } from 'react'

import { CustomMetadata, DocumentMetadata } from '@anews/types'

import { displayMetadataValues } from '@anews/utils'

import ViewerItem from '../ViewerItem'

type Props = {
  metadata: CustomMetadata[]
  values?: DocumentMetadata[]
}

const MetadataViewer: FC<Props> = ({ metadata, values }) => (
  <>
    {displayMetadataValues(metadata, values, (key, label, value) =>
      value ? (
        <ViewerItem key={key} label={label}>
          {value}
        </ViewerItem>
      ) : null,
    )}
  </>
)

export default memo(MetadataViewer)
