import { call, put, takeLatest } from 'redux-saga/effects'

import { customMetadataApi as api } from '@anews/api'
import { CustomMetadata } from '@anews/types'

import i18n from '../../i18n'
import {
  CustomMetadataActionMap as ActionMap,
  CustomMetadataActionType as ActionType,
  CustomMetadataActions,
  NotificationActions,
} from '../actions'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions
const {
  createMetadataFailure,
  createMetadataSuccess,
  listMetadataFailure,
  listMetadataSuccess,
  removeMetadataFailure,
  removeMetadataSuccess,
  updateMetadataFailure,
  updateMetadataSuccess,
} = CustomMetadataActions

function* listMetadataSaga(): Generator {
  try {
    const metadata = yield call(api.list)
    yield put(listMetadataSuccess(metadata as CustomMetadata[]))
  } catch (error) {
    yield put(listMetadataFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createMetadataSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const group = yield call(api.create, action.data)
    yield put(createMetadataSuccess(group as CustomMetadata))
  } catch (error) {
    yield put(createMetadataFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateGroupSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const group = yield call(api.update, action.data)
    yield put(updateMetadataSuccess(group as CustomMetadata))
  } catch (error) {
    yield put(updateMetadataFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeGroupSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeMetadataSuccess(action.id))
  } catch (error) {
    yield put(removeMetadataFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listMetadataSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createMetadataSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateGroupSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeGroupSaga)
  },
])
