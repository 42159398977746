var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useRef, useState } from 'react';
// https://usehooks.com/useLocalStorage/
/*
 * Essa chave é usada no nosso código e também pelo i18next, só que o último não faz o stringfy do valor,
 * então ele fica sem as aspas que o stringfy costuma colocar. Por isso tem regras especiais para essa chave.
 */
var i18nKey = 'i18nextLng';
export default function useLocalStorage(key, initialValue) {
    var _a = __read(useState(function () {
        try {
            var item = localStorage.getItem(key);
            return item ? (key === i18nKey ? item : JSON.parse(item)) : initialValue;
        }
        catch (error) {
            console.error(error);
            return initialValue;
        }
    }), 2), storedValue = _a[0], setStoredValue = _a[1];
    var valueRef = useRef(storedValue);
    useEffect(function () {
        valueRef.current = storedValue;
    }, [storedValue]);
    var setValue = useCallback(function (value) {
        try {
            // Permite que o valor seja uma função, assim como o useState
            var toStore = value instanceof Function ? value(valueRef.current) : value;
            setStoredValue(toStore);
            localStorage.setItem(key, key === i18nKey && typeof toStore === 'string' ? toStore : JSON.stringify(toStore));
        }
        catch (error) {
            console.warn(error);
        }
    }, [key]);
    return [storedValue, setValue];
}
