import { FacebookCategory } from '@anews/types'

export enum FacebookCategoryActionType {
  NEW = '@facebook_category/NEW',
  EDIT = '@facebook_category/EDIT',
  LIST_REQUEST = '@facebook_category/LIST_REQUEST',
  LIST_SUCCESS = '@facebook_category/LIST_SUCCESS',
  LIST_FAILURE = '@facebook_category/LIST_FAILURE',
  CREATE_REQUEST = '@facebook_category/CREATE_REQUEST',
  CREATE_SUCCESS = '@facebook_category/CREATE_SUCCESS',
  CREATE_FAILURE = '@facebook_category/CREATE_FAILURE',
  UPDATE_REQUEST = '@facebook_category/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@facebook_category/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@facebook_category/UPDATE_FAILURE',
  REMOVE_REQUEST = '@facebook_category/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@facebook_category/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@facebook_category/REMOVE_FAILURE',
}

export type FacebookCategoryAction = ReturnType<
  typeof FacebookCategoryActions[keyof typeof FacebookCategoryActions]
>

export type FacebookCategoryActionMap<T extends FacebookCategoryActionType> = {
  type: T
} & FacebookCategoryAction

export const FacebookCategoryActions = {
  //
  //	newFacebookCategory()
  //

  newFacebookCategory: (category?: FacebookCategory) =>
    <const>{ type: FacebookCategoryActionType.NEW, category },

  //
  //	listFacebookCategories()
  //

  listFacebookCategories: (pages: boolean) =>
    <const>{ type: FacebookCategoryActionType.LIST_REQUEST, pages },

  listFacebookCategoriesSuccess: (categories: FacebookCategory[]) =>
    <const>{ type: FacebookCategoryActionType.LIST_SUCCESS, categories },

  listFacebookCategoriesFailure: (error: Error) =>
    <const>{ type: FacebookCategoryActionType.LIST_FAILURE, error },

  //
  //	editFacebookCategory()
  //

  editFacebookCategory: (category: FacebookCategory) =>
    <const>{ type: FacebookCategoryActionType.EDIT, category },

  //
  //	createFacebookCategory()
  //

  createFacebookCategory: (category: FacebookCategory) =>
    <const>{ type: FacebookCategoryActionType.CREATE_REQUEST, category },

  createFacebookCategorySuccess: (category: FacebookCategory) =>
    <const>{ type: FacebookCategoryActionType.CREATE_SUCCESS, category },

  createFacebookCategoryFailure: (error: Error) =>
    <const>{ type: FacebookCategoryActionType.CREATE_FAILURE, error },

  //
  //	updateFacebookCategory()
  //

  updateFacebookCategory: (category: FacebookCategory) =>
    <const>{ type: FacebookCategoryActionType.UPDATE_REQUEST, category },

  updateFacebookCategorySuccess: (category: FacebookCategory) =>
    <const>{ type: FacebookCategoryActionType.UPDATE_SUCCESS, category },

  updateFacebookCategoryFailure: (error: Error) =>
    <const>{ type: FacebookCategoryActionType.UPDATE_FAILURE, error },

  //
  //	removeFacebookCategory()
  //

  removeFacebookCategory: (id: number) =>
    <const>{ type: FacebookCategoryActionType.REMOVE_REQUEST, id },

  removeFacebookCategorySuccess: (id: number) =>
    <const>{ type: FacebookCategoryActionType.REMOVE_SUCCESS, id },

  removeFacebookCategoryFailure: (error: Error) =>
    <const>{ type: FacebookCategoryActionType.REMOVE_FAILURE, error },
}
