import { Reducer } from 'redux'

import { News } from '@anews/types'

import { NewsAction, NewsActionType } from '../actions/news-actions'

export interface NewsPageState {
  data: News[]
  number: number
  size: number
  total: number
  from?: string
  to?: string
  filter?: string
  loading: boolean
}

export interface NewsState {
  page: NewsPageState
  editing?: News
  viewing?: News
  saving: boolean
  disabling: boolean
  sidebar: {
    visible: boolean
    loading: boolean
    data: News[]
  }
}

export const defaultState: NewsState = {
  page: {
    data: [],
    number: 0,
    size: 0,
    total: 0,
    from: undefined,
    to: undefined,
    filter: '',
    loading: true,
  },
  editing: undefined,
  viewing: undefined,
  saving: false,
  disabling: false,
  sidebar: {
    visible: false,
    loading: false,
    data: [],
  },
}

function sidebarReducer(state: NewsState['sidebar'], action: NewsAction): NewsState['sidebar'] {
  switch (action.type) {
    case NewsActionType.TOGGLE_SIDEBAR:
      return { ...state, visible: !state.visible }

    case NewsActionType.REFRESH_SIDEBAR_REQUEST:
      return { ...state, loading: true }

    case NewsActionType.REFRESH_SIDEBAR_FAILURE:
      return { ...state, loading: false }

    case NewsActionType.REFRESH_SIDEBAR_SUCCESS:
      return { ...state, loading: false, data: action.result.data }

    default:
      return state
  }
}

function pageReducer(page: NewsPageState, action: NewsAction): NewsPageState {
  switch (action.type) {
    case NewsActionType.FILTER_REQUEST:
      return { ...page, from: action.from, to: action.to, filter: action.filter, loading: true }

    case NewsActionType.FILTER_FAILURE:
      return { ...page, loading: false }

    case NewsActionType.FILTER_SUCCESS:
      return {
        ...page,
        data: action.result.data,
        total: action.result.total,
        number: action.result.page,
        size: action.result.size,
        loading: false,
      }

    case NewsActionType.CREATE_SUCCESS:
    case NewsActionType.WS_CREATE:
      // O filter serve para não duplicar (CREATE_SUCCESS x WS_CREATE)
      return {
        ...page,
        data:
          page.number === 0
            ? [action.news, ...page.data.filter(n => n.id !== action.news.id)]
            : page.data,
      }

    case NewsActionType.UPDATE_SUCCESS:
    case NewsActionType.WS_UPDATE:
      return {
        ...page,
        data: page.data.map(n => (n.id === action.news.id ? action.news : n)),
      }

    case NewsActionType.REMOVE_SUCCESS:
      return {
        ...page,
        data: page.data.filter(n => !action.ids.includes(n.id)),
      }

    case NewsActionType.WS_DELETE:
      return { ...page, data: page.data.filter(n => n.id !== action.id), total: page.total - 1 }

    case NewsActionType.CREATE_REQUEST:
    case NewsActionType.UPDATE_REQUEST:
    case NewsActionType.REMOVE_REQUEST:
      return { ...page, loading: true }

    default:
      return page
  }
}

const newsReducer: Reducer<NewsState, NewsAction> = (state = defaultState, action): NewsState => {
  switch (action.type) {
    case NewsActionType.FILTER_REQUEST:
    case NewsActionType.FILTER_FAILURE:
    case NewsActionType.FILTER_SUCCESS:
    case NewsActionType.WS_CREATE:
    case NewsActionType.WS_DELETE:
    case NewsActionType.WS_UPDATE:
      return { ...state, page: pageReducer(state.page, action) }

    case NewsActionType.UNLOAD:
      return { ...state, editing: undefined, viewing: undefined }

    case NewsActionType.NEW:
      return { ...state, editing: action.news }

    case NewsActionType.CREATE_REQUEST:
    case NewsActionType.UPDATE_REQUEST:
      return { ...state, editing: action.news, saving: true }

    case NewsActionType.LOAD_SUCCESS:
      if (action.edit) {
        return { ...state, editing: action.news }
      }
      return { ...state, viewing: action.news as News }

    case NewsActionType.REMOVE_REQUEST:
      return { ...state, disabling: true }

    case NewsActionType.REMOVE_FAILURE:
      return { ...state, disabling: false }

    case NewsActionType.CREATE_FAILURE:
    case NewsActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case NewsActionType.CREATE_SUCCESS:
    case NewsActionType.UPDATE_SUCCESS:
    case NewsActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        page: pageReducer(state.page, action),
        disabling: false,
        saving: false,
      }

    case NewsActionType.TOGGLE_SIDEBAR:
    case NewsActionType.REFRESH_SIDEBAR_REQUEST:
    case NewsActionType.REFRESH_SIDEBAR_SUCCESS:
    case NewsActionType.REFRESH_SIDEBAR_FAILURE:
      return { ...state, sidebar: sidebarReducer(state.sidebar, action) }

    default:
      return state
  }
}

export default newsReducer
