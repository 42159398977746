import { get, post, put } from './client';
var endpoint = '/api/streaming';
var streamingApi = {
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
    protocols: function () {
        return get(endpoint + "/protocols");
    },
};
export default streamingApi;
