/*
 * Pede confirmmaçao para usar dados precisos de localização ou usa chamada de
 * api fornecida para obter a localização.
 */
export function getLocation(cbLocationApi) {
    return new Promise(function (resolve, reject) {
        // check for Geolocation support
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (pos) { return resolve(pos.coords.latitude + "," + pos.coords.longitude); }, function (error) {
                if (cbLocationApi) {
                    cbLocationApi().then(resolve).catch(reject);
                }
                else {
                    reject(error);
                }
            });
        }
        else if (cbLocationApi) {
            cbLocationApi().then(resolve).catch(reject);
        }
        else {
            reject(Error('Geolocation is not supported for this Browser/OS.'));
        }
    });
}
