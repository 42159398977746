import React, { memo, useState } from 'react'

import Empty from 'antd/es/empty'
import Modal from 'antd/es/modal'
import Pagination from 'antd/es/pagination'

interface Props {
  content: any[]
  visible: boolean
  title: string
  onClose: () => void
}

const CarouselModal = ({ content, visible, title, onClose }: Props) => {
  const [currentPage, setCurrentPage] = useState(0)

  const renderCarousel = () =>
    content
      .slice(currentPage, currentPage + 1)
      .map((el, idx) => <div key={el?.id || idx}>{el}</div>)

  return (
    <Modal
      title={title}
      width="50%"
      visible={visible}
      footer={null}
      maskClosable={false}
      onCancel={onClose}
      destroyOnClose
      bodyStyle={{ padding: '0 24px 4px 24px', overflow: 'hidden' }}
    >
      {content?.length ? (
        <>
          {renderCarousel()}
          <Pagination
            style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
            current={currentPage + 1}
            onChange={page => setCurrentPage(page - 1)}
            pageSize={1}
            showSizeChanger={false}
            total={content.length}
          />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Modal>
  )
}

export default memo(CarouselModal)
