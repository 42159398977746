import { LdapConfig } from '@anews/types'

export enum ActionType {
  LOAD_CONFIG_REQUEST = '@ldap/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@ldap/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@ldap/LOAD_CONFIG_FAILURE',
  CREATE_CONFIG_REQUEST = '@ldap/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@ldap/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@ldap/CREATE_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@ldap/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@ldap/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@ldap/UPDATE_CONFIG_FAILURE',
  SYNC_USERS_REQUEST = '@ldap/SYNC_USERS_REQUEST',
  SYNC_STATE_REQUEST = '@ldap/SYNC_STATE_REQUEST',
  SYNC_STATE_SUCCESS = '@ldap/SYNC_STATE_SUCCESS',
  SYNC_STATE_FAILURE = '@ldap/SYNC_STATE_FAILURE',
}

export interface CreateLdapConfigAction {
  type: ActionType.CREATE_CONFIG_REQUEST
  config: LdapConfig
}

export interface UpdateLdapConfigAction {
  type: ActionType.UPDATE_CONFIG_REQUEST
  config: LdapConfig
}

export type LdapAction =
  | { type: ActionType.LOAD_CONFIG_REQUEST }
  | { type: ActionType.LOAD_CONFIG_SUCCESS; config: LdapConfig }
  | { type: ActionType.LOAD_CONFIG_FAILURE; error: Error }
  | CreateLdapConfigAction
  | { type: ActionType.CREATE_CONFIG_SUCCESS; config: LdapConfig }
  | { type: ActionType.CREATE_CONFIG_FAILURE; error: Error }
  | UpdateLdapConfigAction
  | { type: ActionType.UPDATE_CONFIG_SUCCESS; config: LdapConfig }
  | { type: ActionType.UPDATE_CONFIG_FAILURE; error: Error }
  // Sync
  | { type: ActionType.SYNC_USERS_REQUEST }
  | { type: ActionType.SYNC_STATE_REQUEST }
  | { type: ActionType.SYNC_STATE_SUCCESS; isSyncing: boolean }
  | { type: ActionType.SYNC_STATE_FAILURE; error: Error }

//
//  loadConfig()
//

export const loadConfig = (): LdapAction => ({ type: ActionType.LOAD_CONFIG_REQUEST })

export const loadConfigSuccess = (config: LdapConfig): LdapAction => ({
  type: ActionType.LOAD_CONFIG_SUCCESS,
  config,
})

export const loadConfigFailure = (error: Error): LdapAction => ({
  type: ActionType.LOAD_CONFIG_FAILURE,
  error,
})

//
//  createConfig()
//

export const createConfig = (config: LdapConfig): LdapAction => ({
  type: ActionType.CREATE_CONFIG_REQUEST,
  config,
})

export const createConfigSuccess = (config: LdapConfig): LdapAction => ({
  type: ActionType.CREATE_CONFIG_SUCCESS,
  config,
})

export const createConfigFailure = (error: Error): LdapAction => ({
  type: ActionType.CREATE_CONFIG_FAILURE,
  error,
})

//
//  updateConfig()
//

export const updateConfig = (config: LdapConfig): LdapAction => ({
  type: ActionType.UPDATE_CONFIG_REQUEST,
  config,
})

export const updateConfigSuccess = (config: LdapConfig): LdapAction => ({
  type: ActionType.UPDATE_CONFIG_SUCCESS,
  config,
})

export const updateConfigFailure = (error: Error): LdapAction => ({
  type: ActionType.UPDATE_CONFIG_FAILURE,
  error,
})

//
//  synchronizeUsers()
//

export const synchronizeUsers = (): LdapAction => ({
  type: ActionType.SYNC_USERS_REQUEST,
})

//
//  syncState()
//

export const syncState = (): LdapAction => ({
  type: ActionType.SYNC_STATE_REQUEST,
})

export const syncStateSuccess = (isSyncing: boolean): LdapAction => ({
  type: ActionType.SYNC_STATE_SUCCESS,
  isSyncing,
})

export const syncStateFailure = (error: Error): LdapAction => ({
  type: ActionType.SYNC_STATE_FAILURE,
  error,
})
