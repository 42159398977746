import React, { memo, useCallback, useMemo, useState } from 'react'

import Button from 'antd/es/button'

import Col from 'antd/es/col'
import Row from 'antd/es/row'
import PageHeader from 'antd/es/page-header'
import Tooltip from 'antd/es/tooltip'
import message from 'antd/es/message'

import { CheckCircleOutlined, DiffOutlined } from '@ant-design/icons'

import { displayDate, displayTime, mapJoin, highlight, isNotBlank, richToPlain } from '@anews/utils'
import { Checklist, ChecklistRevision, ChecklistTask, CustomMetadata } from '@anews/types'
import { checklistApi } from '@anews/api'

import { SelectorMap } from '../../../redux/selectors/helpers'

import { CarouselModal, ColumnProps, CompactTable, ViewerItem } from '../../global'
import { useTranslation } from '../../../i18n'
import RevisionCard from '../revision/RevisionCard'
import RichTextViewer from '../../global/RichText/RichTextViewer'
import { MetadataViewer } from '../../global/CustomMetadata'

function countNotBlank(values: (string | undefined)[], weights?: number[]) {
  if (!weights) {
    return values.filter(v => !!v).length
  }

  let sum = 0

  for (let i = 0; i < values.length; i += 1) {
    if (values[i]) {
      sum += weights[i]
    }
  }

  return sum
}

interface Props {
  checklist?: Checklist
  editable: boolean
  resources: SelectorMap<string>
  types: SelectorMap<string>
  users: SelectorMap<string>
  vehicles: SelectorMap<string>
  customMetadata: CustomMetadata[]
  onEdit?: (id: number) => void
  onClose: () => void
}

function ChecklistViewer({
  checklist,
  editable,
  resources,
  types,
  users,
  vehicles,
  customMetadata,
  onEdit,
  onClose,
}: Props) {
  const { t } = useTranslation()

  //
  // Versões
  //

  const [versionsVisible, setVersionsVisible] = useState(false)
  const [revisions, setRevisions] = useState<ChecklistRevision[]>([])

  const versionsHandler = useCallback(async () => {
    if (checklist) {
      try {
        const result = await checklistApi.loadChangesHistory(checklist.id)
        setRevisions(result)
        setVersionsVisible(true)
      } catch (error) {
        console.error(error)
        message.error(t('error:loadFailed'))
      }
    }
  }, [checklist, t])

  const revisionCards = useMemo(
    () =>
      revisions.map(rev => (
        <RevisionCard
          date={rev.revisionDate}
          ip={rev.revisorIP}
          nickname={rev.revisorNickname}
          slug={rev.slug}
          content={rev}
        />
      )),
    [revisions],
  )

  if (!checklist) {
    return null
  }

  const actions = [
    <Tooltip key="version" placement="bottom" title={t('words:versions')}>
      <Button onClick={versionsHandler} icon={<DiffOutlined />} />
    </Tooltip>,
  ]

  editable &&
    actions.push(
      <Button key={1} onClick={() => onEdit && onEdit(checklist.id)}>
        {t('words:edit')}
      </Button>,
    )

  actions.push(
    <Button key={2} onClick={onClose} type="primary">
      {t('words:close')}
    </Button>,
  )

  const date = displayDate(checklist.date)
  const schedule = displayTime(checklist.schedule)
  const departure = displayTime(checklist.departure)
  const arrival = displayTime(checklist.arrival)

  const type = types[checklist.typeId || 0]
  const editors = mapJoin(users, checklist.editorsIds)
  const producers = mapJoin(users, checklist.producersIds)
  const resourcesNames = mapJoin(resources, checklist.resourcesIds)
  const vehiclesNames = mapJoin(vehicles, checklist.vehiclesIds)

  const firstRowCount = countNotBlank([date, schedule, departure, arrival]) || 1
  const secondRowCount = countNotBlank([type, checklist.local, checklist.team]) || 1

  const columns: ColumnProps<ChecklistTask>[] = [
    { key: 'date', dataIndex: 'date', title: t('words:date'), render: text => displayDate(text) },
    { key: 'time', dataIndex: 'time', title: t('words:time'), render: text => displayTime(text) },
    {
      key: 'label',
      dataIndex: 'label',
      title: t('checklist:task'),
      render: text => highlight(text),
    },
    {
      key: 'done',
      dataIndex: 'done',
      title: t('checklist:done'),
      align: 'center',
      render: done => (done ? <CheckCircleOutlined /> : ''),
    },
  ]

  return (
    <PageHeader
      title={
        <Tooltip title={highlight(checklist.slug)} placement="left">
          {highlight(checklist.slug)}
        </Tooltip>
      }
      extra={actions}
    >
      <Row>
        {/* Data */}
        <Col span={24 / firstRowCount}>
          <ViewerItem label={t('words:date')}>{date}</ViewerItem>
        </Col>
        {/* Hora */}
        {schedule && (
          <Col span={24 / firstRowCount}>
            <ViewerItem label={t('checklist:schedule')}>{schedule}</ViewerItem>
          </Col>
        )}
        {/* Saída */}
        {departure && (
          <Col span={24 / firstRowCount}>
            <ViewerItem label={t('checklist:departure')}>{departure}</ViewerItem>
          </Col>
        )}
        {/* Chegada */}
        {arrival && (
          <Col span={24 / firstRowCount}>
            <ViewerItem label={t('checklist:arrival')}>{arrival}</ViewerItem>
          </Col>
        )}
      </Row>

      <Row>
        {/* Tipo */}
        {type && (
          <Col span={24 / secondRowCount}>
            <ViewerItem label={t('words:type')}>{type}</ViewerItem>
          </Col>
        )}
        {/* Local */}
        {checklist.local && (
          <Col span={24 / secondRowCount}>
            <ViewerItem label={t('words:local')}>{highlight(checklist.local)}</ViewerItem>
          </Col>
        )}
        {/* Equipe */}
        {checklist.team && (
          <Col span={24 / secondRowCount}>
            <ViewerItem label={t('words:team')}>{highlight(checklist.team)}</ViewerItem>
          </Col>
        )}
      </Row>

      {/* Mídias */}
      <ViewerItem label={t('words:medias')}>{checklist.medias}</ViewerItem>

      {/* Produtores */}
      <ViewerItem label={t('checklist:producers')}>{producers}</ViewerItem>

      {/* Editores */}
      <ViewerItem label={t('words:editors')}>{editors}</ViewerItem>

      {/* Recursos */}
      <ViewerItem label={t('checklist:resources')}>{resourcesNames}</ViewerItem>

      {/* Veículos */}
      <ViewerItem label={t('words:vehicles')}>{vehiclesNames}</ViewerItem>

      <MetadataViewer metadata={customMetadata} values={checklist.metadata} />

      <CompactTable
        rowKey="id"
        dataSource={checklist?.tasks || []}
        columns={columns}
        expandable={{
          expandedRowRender: ({ information }) => (
            <p style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
              <RichTextViewer content={information} />
            </p>
          ),
          rowExpandable: ({ information }) => !!information,
        }}
        pagination={false}
        style={{ marginTop: 8, marginBottom: 16 }}
      />

      {/* Informações */}

      {isNotBlank(richToPlain(checklist.information)) ? (
        <ViewerItem label={t('words:info')}>
          <RichTextViewer content={checklist.information} />
        </ViewerItem>
      ) : null}

      {/* Versões */}
      <CarouselModal
        content={revisionCards}
        visible={versionsVisible}
        title={t('words:versions')}
        onClose={() => setVersionsVisible(false)}
      />
    </PageHeader>
  )
}

export default memo(ChecklistViewer)
