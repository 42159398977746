import { Reducer } from 'redux'

import { BranchConfig } from '@anews/types'

import { BranchActionType, BranchAction } from '../actions/branch-actions'

import { validateThemeColor } from '../../theme'

import { ConfigState } from './types'

export interface BranchState {
  config: ConfigState<BranchConfig>
}

export const initialState: BranchState = {
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
}

function configReducer(
  config: ConfigState<BranchConfig>,
  action: BranchAction,
): ConfigState<BranchConfig> {
  switch (action.type) {
    case BranchActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case BranchActionType.UPDATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case BranchActionType.LOAD_CONFIG_FAILURE:
    case BranchActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case BranchActionType.LOAD_CONFIG_SUCCESS:
    case BranchActionType.UPDATE_CONFIG_SUCCESS:
      return {
        ...config,
        loading: false,
        saving: false,
        data: { ...action.config, themeColor: validateThemeColor(action.config.themeColor) },
      }

    default:
      return config
  }
}

const branchReducer: Reducer<BranchState, BranchAction> = (
  state = initialState,
  action,
): BranchState => {
  switch (action.type) {
    case BranchActionType.LOAD_CONFIG_REQUEST:
    case BranchActionType.LOAD_CONFIG_SUCCESS:
    case BranchActionType.LOAD_CONFIG_FAILURE:
    case BranchActionType.UPDATE_CONFIG_REQUEST:
    case BranchActionType.UPDATE_CONFIG_SUCCESS:
    case BranchActionType.UPDATE_CONFIG_FAILURE:
      return { ...state, config: configReducer(state.config, action) }

    default:
      return state
  }
}

export default branchReducer
