import { AdminLog, Page } from '@anews/types'

import { AdminLogsParams } from '@anews/api/build/adminlogs-api'

import { PAGE_SIZE } from '../../consts/pagination'

export enum AdminLogActionType {
  FILTER_ADMIN_REQUEST = 'FILTER_ADMIN_REQUEST',
  FILTER_ADMIN_SUCCESS = 'FILTER_ADMIN_SUCCESS',
  FILTER_ADMIN_FAILURE = 'FILTER_ADMIN_FAILURE',
}

export type AdminLogActionMap<T extends AdminLogActionType> = {
  type: T
} & AdminLogsAction

export type AdminLogsAction = ReturnType<typeof AdminLogActions[keyof typeof AdminLogActions]>

export const AdminLogActions = {
  //
  //	filterAdminLogs()
  //

  filterAdminLogs: (filter?: AdminLogsParams, page: number = 0, size: number = PAGE_SIZE) =>
    <const>{
      type: AdminLogActionType.FILTER_ADMIN_REQUEST,
      filter,
      page,
      size,
    },

  filterAdminLogsSuccess: (result: Page<AdminLog>) =>
    <const>{ type: AdminLogActionType.FILTER_ADMIN_SUCCESS, result },

  filterAdminLogsFailure: (error: Error) =>
    <const>{ type: AdminLogActionType.FILTER_ADMIN_FAILURE, error },
}
