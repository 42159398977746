import { GoogleApiConfig } from '@anews/types'

export enum GoogleActionType {
  LOAD_CONFIG_REQUEST = '@google/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@google/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@google/LOAD_CONFIG_FAILURE',
  CREATE_CONFIG_REQUEST = '@google/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@google/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@google/CREATE_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@google/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@google/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@google/UPDATE_CONFIG_FAILURE',
}

export type GoogleActionMap<T extends GoogleActionType> = { type: T } & GoogleAction

export type GoogleAction = ReturnType<typeof GoogleActions[keyof typeof GoogleActions]>

export const GoogleActions = {
  //
  //  loadConfig()
  //

  loadConfig: () => <const>{ type: GoogleActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: GoogleApiConfig) =>
    <const>{ type: GoogleActionType.LOAD_CONFIG_SUCCESS, config },

  loadConfigFailure: (error: Error) => <const>{ type: GoogleActionType.LOAD_CONFIG_FAILURE, error },

  //
  //  createConfig()
  //

  createConfig: (config: GoogleApiConfig) =>
    <const>{ type: GoogleActionType.CREATE_CONFIG_REQUEST, config },

  createConfigSuccess: (config: GoogleApiConfig) =>
    <const>{ type: GoogleActionType.CREATE_CONFIG_SUCCESS, config },

  createConfigFailure: (error: Error) =>
    <const>{ type: GoogleActionType.CREATE_CONFIG_FAILURE, error },

  //
  //  updateConfig()
  //

  updateConfig: (config: GoogleApiConfig) =>
    <const>{ type: GoogleActionType.UPDATE_CONFIG_REQUEST, config },

  updateConfigSuccess: (config: GoogleApiConfig) =>
    <const>{ type: GoogleActionType.UPDATE_CONFIG_SUCCESS, config },

  updateConfigFailure: (error: Error) =>
    <const>{ type: GoogleActionType.UPDATE_CONFIG_FAILURE, error },
}
