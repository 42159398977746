import { useEffect, useRef } from 'react';
function useUnload(fn) {
    var cb = useRef(fn);
    useEffect(function () {
        cb.current = fn;
    }, [fn]);
    useEffect(function () {
        var unloadHandler = cb.current;
        return function () {
            if (unloadHandler) {
                unloadHandler();
            }
        };
    }, []);
}
export default useUnload;
