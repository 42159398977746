import { call, put, takeLatest } from 'redux-saga/effects'

import { segmentApi as api } from '@anews/api'
import { Segment } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  listSegmentsSuccess,
  listSegmentsFailure,
  CreateSegmentAction,
  createSegmentSuccess,
  createSegmentFailure,
  UpdateSegmentAction,
  updateSegmentSuccess,
  updateSegmentFailure,
  RemoveSegmentAction,
  removeSegmentSuccess,
  removeSegmentFailure,
  ListSegmentsAction,
} from '../actions/segment-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listSegmentsSaga(action: ListSegmentsAction): Generator {
  try {
    const categories = yield call(api.list, action.institutions)
    yield put(listSegmentsSuccess(categories as Segment[]))
  } catch (error) {
    yield put(listSegmentsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createSegmentSaga(action: CreateSegmentAction): Generator {
  try {
    const segment = yield call(api.create, action.segment)
    yield put(createSegmentSuccess(segment as Segment))
  } catch (error) {
    yield put(createSegmentFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateSegmentSaga(action: UpdateSegmentAction): Generator {
  try {
    const segment = yield call(api.update, action.segment)
    yield put(updateSegmentSuccess(segment as Segment))
  } catch (error) {
    yield put(updateSegmentFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeSegmentSaga(action: RemoveSegmentAction): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeSegmentSuccess(action.id))
  } catch (error) {
    yield put(removeSegmentFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listSegmentsSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createSegmentSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateSegmentSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeSegmentSaga)
  },
])
