import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { rundownTemplateApi as api } from '@anews/api'
import { RundownTemplate, Block, Story } from '@anews/types'

import {
  RundownTemplateActionType as ActionType,
  RundownTemplateActionMap as ActionMap,
  RundownTemplateActions,
} from '../actions/rundowntemplate-actions'
import { NotificationActions } from '../actions/notification-actions'

import { RootState } from '../reducers'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyWarning, notifyError, notifySuccess } = NotificationActions
const {
  loadTemplateSuccess,
  loadTemplateFailure,
  patchTemplateSuccess,
  patchTemplateFailure,
  patchBlockSuccess,
  patchBlockFailure,
  listTemplatesSuccess,
  listTemplatesFailure,
  moveStorySuccess,
  moveStoryFailure,
  loadTemplate,
  createBlockSuccess,
  createBlockFailure,
  removeBlockSuccess,
  removeBlockFailure,
  createStorySuccess,
  createStoryFailure,
  copyStoriesSuccess,
  copyStoriesFailure,
  repaginateSuccess,
  repaginateFailure,
  createTemplateSuccess,
  createTemplateFailure,
  removeTemplateSuccess,
  removeTemplateFailure,
  copyTemplateSuccess,
  copyTemplateFailure,
} = RundownTemplateActions

/* Selectors */

const selectedTemplate = (state: RootState) => state.rundownTemplates.selectedTemplate

/* Watchers */

//
//  Rundown Templates
//

function* listTemplatesSaga(action: ActionMap<ActionType.LIST_REQUEST>): Generator {
  try {
    const templates = yield call(api.listTemplates, action.programId)
    yield put(listTemplatesSuccess(templates as RundownTemplate[]))
  } catch (error) {
    yield put(listTemplatesFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadTemplateSaga(action: ActionMap<ActionType.LOAD_REQUEST>): Generator {
  try {
    if (action.id) {
      const template = yield call(api.loadTemplate, action.id)
      yield put(loadTemplateSuccess(template as RundownTemplate))
    } else {
      yield put(loadTemplateSuccess(undefined))
    }
  } catch (error) {
    yield put(loadTemplateFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createTemplateSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const template = yield call(api.create, action.name, action.programId)
    yield put(createTemplateSuccess(template as RundownTemplate))
  } catch (error) {
    yield put(createTemplateFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* copyTemplateSaga(action: ActionMap<ActionType.COPY_REQUEST>): Generator {
  try {
    const template = yield call(api.createCopy, action.templateId, action.name, action.programId)
    yield put(copyTemplateSuccess(template as RundownTemplate))
  } catch (error) {
    yield put(copyTemplateFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* patchRundownSaga(action: ActionMap<ActionType.PATCH_REQUEST>): Generator {
  try {
    yield call(api.patchTemplate, action.templateId, action.field, action.newValue)
    yield put(patchTemplateSuccess())
  } catch (error) {
    yield put(
      patchTemplateFailure(
        error,
        action.templateId,
        action.field,
        action.newValue,
        action.oldValue,
      ),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeTemplateSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.removeTemplate, action.templateId)
    yield put(removeTemplateSuccess(action.templateId))
  } catch (error) {
    yield put(removeTemplateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

//
//  Block
//

function* createBlockSaga(action: ActionMap<ActionType.CREATE_BLOCK_REQUEST>): Generator {
  try {
    const block = yield call(api.createBlock, action.templateId)
    yield put(createBlockSuccess(block as Block))
  } catch (error) {
    yield put(createBlockFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* patchBlockSaga(action: ActionMap<ActionType.PATCH_BLOCK_REQUEST>): Generator {
  try {
    yield call(api.patchBlock, action.blockId, action.field, action.newValue)
    yield put(patchBlockSuccess())
  } catch (error) {
    yield put(
      patchBlockFailure(error, action.blockId, action.field, action.newValue, action.oldValue),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeBlockSaga(action: ActionMap<ActionType.REMOVE_BLOCK_REQUEST>): Generator {
  try {
    yield call(api.removeBlock, action.blockId)
    yield put(removeBlockSuccess(action.blockId))
  } catch (error) {
    yield put(removeBlockFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

//
//  Stories
//

function* createStorySaga(action: ActionMap<ActionType.CREATE_STORY_REQUEST>): Generator {
  try {
    const story = yield call(api.createStory, action.blockId)
    yield put(createStorySuccess(story as Story))
  } catch (error) {
    yield put(createStoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* copyStoriesSaga(action: ActionMap<ActionType.COPY_STORIES_REQUEST>): Generator {
  try {
    const story = yield call(api.copyStoriesToTemplate, action.id, action.source, action.storiesIds)
    yield put(copyStoriesSuccess(story as Story))
    yield put(
      notifySuccess({
        message: i18n.t('story:copySuccess', { count: action.storiesIds.length }),
      }),
    )
  } catch (error) {
    yield put(copyStoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* moveStorySaga(action: ActionMap<ActionType.MOVE_STORY_REQUEST>): Generator {
  try {
    yield call(api.moveStory, action.targetBlockId, action.storyId, action.targetStoryId)
    yield put(moveStorySuccess())
  } catch (error) {
    yield put(moveStoryFailure(error, action.storyId, action.targetBlockId, action.targetStoryId))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )

    // Para desfazer a mudança otimista, recarrega o template
    const templateId = (yield select(selectedTemplate)) as number | undefined
    if (templateId) {
      yield loadTemplate(templateId)
    }
  }
}

function* repaginateSaga(action: ActionMap<ActionType.REPAGINATE_REQUEST>): Generator {
  try {
    yield call(api.repaginateStories, action.templateId)
    yield put(repaginateSuccess())
  } catch (error) {
    yield put(repaginateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listTemplatesSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_REQUEST, loadTemplateSaga)
  },
  function* () {
    yield takeEvery(ActionType.CREATE_REQUEST, createTemplateSaga)
  },
  function* () {
    yield takeEvery(ActionType.COPY_REQUEST, copyTemplateSaga)
  },
  function* () {
    yield takeEvery(ActionType.PATCH_REQUEST, patchRundownSaga)
  },
  function* () {
    yield takeEvery(ActionType.REMOVE_REQUEST, removeTemplateSaga)
  },
  function* () {
    yield takeEvery(ActionType.CREATE_BLOCK_REQUEST, createBlockSaga)
  },
  function* () {
    yield takeEvery(ActionType.REMOVE_BLOCK_REQUEST, removeBlockSaga)
  },
  function* () {
    yield takeEvery(ActionType.PATCH_BLOCK_REQUEST, patchBlockSaga)
  },
  function* () {
    yield takeEvery(ActionType.CREATE_STORY_REQUEST, createStorySaga)
  },
  function* () {
    yield takeEvery(ActionType.COPY_STORIES_REQUEST, copyStoriesSaga)
  },
  function* () {
    yield takeEvery(ActionType.MOVE_STORY_REQUEST, moveStorySaga)
  },
  function* () {
    yield takeLatest(ActionType.REPAGINATE_REQUEST, repaginateSaga)
  },
])
