import React, { lazy, Suspense } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

type LazyComponent = ReturnType<typeof lazy>

// Isso na verdade não é exatamente um componente
export default function Loading(Component: LazyComponent) {
  return function Loader(props: any) {
    return (
      <Suspense fallback={<LoadingOutlined />}>
        <Component {...props} />
      </Suspense>
    )
  }
}
