import { CalendarEvent } from '@anews/types'

export enum NotificationActionType {
  SUCCESS = '@notification/SUCCESS',
  INFORMATION = '@notification/INFORMATION',
  EVENT = '@notification/EVENT',
  WARNING = '@notification/WARNING',
  ERROR = '@notification/ERROR',
}

export interface Payload {
  message?: string
  description?: string
  error?: any
  manualDismiss?: boolean
}

export type NotificationActionMap<T extends NotificationActionType> = {
  type: T
} & NotificationAction

export type NotificationAction = ReturnType<
  typeof NotificationActions[keyof typeof NotificationActions]
>

export const NotificationActions = {
  notifySuccess: (payload: Payload) =>
    <const>{
      type: NotificationActionType.SUCCESS,
      payload,
    },
  notifyInfo: (payload: Payload) =>
    <const>{
      type: NotificationActionType.INFORMATION,
      payload,
    },
  notifyEvent: (payload: { event: CalendarEvent; manualDismiss?: boolean }) =>
    <const>{
      type: NotificationActionType.EVENT,
      payload,
    },
  notifyWarning: (payload: Payload) =>
    <const>{
      type: NotificationActionType.WARNING,
      payload,
    },
  notifyError: (payload: Payload) => {
    if (payload.error) {
      console.error(payload.error)
    }
    return <const>{
      type: NotificationActionType.ERROR,
      payload,
    }
  },
}
