import { useCallback, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'

// import { diff } from 'deep-object-diff'

import { isTimestamp } from '@anews/utils'

import { AllowedType, Section } from './types'
import useUnsavedSection from './useUnsavedSection'

/*
 * isEqual() estava diferenciando propriedades inexistentes de propriedades com
 * valor undefined, então esse método remove todas as propriedades undefined.
 *
 * id e uuid também estava dando problema, então também ignora eles, mas é
 * importante não salvar o resultado desse método no cache e usar o resultado
 * apenas para comparação.
 */
function removeValuesToBeIgnored(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => removeValuesToBeIgnored(item))
  }
  if (typeof obj !== 'object') {
    return obj
  }

  let result = obj

  try {
    Object.entries(obj).forEach(([key, value]) => {
      // Ignora valores undefined, IDs, UUIDs e timestamps
      if (
        value === undefined ||
        key === 'id' ||
        key === 'uuid' ||
        (typeof value === 'string' && isTimestamp(value))
      ) {
        const { [key]: ignore, ...rest } = result
        result = rest
      }
      if (typeof value === 'object') {
        result = { ...result, [key]: removeValuesToBeIgnored(value) }
      }
    })
  } catch (error) {
    console.error(error)
  }
  return result
}

// PARA DEBUG
// function printDiff(initial: any = {}, updated: any = {}) {
//   const changes = diff(initial, updated)
//   const keys = Object.keys(changes)
//   if (keys.length === 0) {
//     console.log('not changed')
//   } else {
//     console.log('changed')
//     keys.forEach(key => console.log('  ', key, 'from', initial[key], 'to', updated[key]))
//   }
// }

export default function useUnsavedDocument<T extends AllowedType>(
  sectionKey: Section,
  uuid: string,
) {
  const { unsavedData, clearUnsavedEntry, updateUnsavedEntry } = useUnsavedSection(sectionKey)
  const [changed, setChanged] = useState(false)

  const clearCache = useCallback(() => {
    clearUnsavedEntry(uuid)
    setChanged(false)
  }, [clearUnsavedEntry, uuid])

  const checkChanged = useCallback(
    (newData: T, initial?: T, changedChecker?: (a?: T, b?: T) => boolean) => {
      let equal: boolean | undefined

      if (changedChecker) {
        equal = !changedChecker(initial, newData)
        // printDiff(initial, newData)
      } else {
        const cleanNew = removeValuesToBeIgnored(newData)
        const cleanInitial = removeValuesToBeIgnored(initial)
        // printDiff(cleanInitial, cleanNew)

        equal = isEqual(cleanInitial, cleanNew)
      }

      if (equal) {
        clearCache()
      } else {
        updateUnsavedEntry(uuid, newData)
        setChanged(true)
      }

      return !equal
    },
    [uuid, clearCache, updateUnsavedEntry],
  )

  return useMemo(() => ({ changed, clearCache, checkChanged, unsavedData }), [
    changed,
    clearCache,
    checkChanged,
    unsavedData,
  ])
}
