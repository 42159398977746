import { call, put, takeLatest } from 'redux-saga/effects'
import { StatusCodes } from 'http-status-codes'

import { checklistApi as api, guidelineApi } from '@anews/api'
import { ChecklistConfig, Checklist, Guideline } from '@anews/types'

import { isNew } from '@anews/utils'

import {
  ChecklistActionMap,
  ChecklistActions,
  ChecklistActionType as ActionType,
  EntityLockActions,
  NotificationActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError, notifySuccess, notifyWarning } = NotificationActions
const {
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  createConfigSuccess,
  createConfigFailure,
  dateListChecklistsSuccess,
  dateListChecklistsFailure,
  loadChecklistSuccess,
  loadChecklistFailure,
  createChecklistSuccess,
  createChecklistFailure,
  createChecklistFromGuidelineSuccess,
  createChecklistFromGuidelineFailure,
  updateChecklistSuccess,
  updateChecklistFailure,
  removeChecklistsSuccess,
  removeChecklistsFailure,
  patchChecklistSuccess,
  patchChecklistFailure,
  createChecklistFromGuideline,
  loadGuidelineSuccess,
  loadGuidelineFailure,
} = ChecklistActions

/* Watchers */

function* dateListChecklistsSaga(
  action: ChecklistActionMap<ActionType.DATE_LIST_REQUEST>,
): Generator {
  try {
    const checklists = yield call(api.dateList, action.date, action.onlyNotDone)
    yield put(dateListChecklistsSuccess(checklists as Checklist[], action.date, action.onlyNotDone))
  } catch (error) {
    yield put(dateListChecklistsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadChecklistSaga(action: ChecklistActionMap<ActionType.LOAD_REQUEST>): Generator {
  try {
    if (typeof action.target === 'number') {
      const checklist = yield call(api.load, action.target, action.edit)
      yield put(loadChecklistSuccess(checklist as Checklist, action.edit))
    } else {
      const checklist = action.target
      if (!isNew(checklist)) {
        yield put(EntityLockActions.renewLock(checklist.uuid))
      }
      yield put(loadChecklistSuccess(checklist, action.edit))
    }
  } catch (error) {
    yield put(loadChecklistFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadByGuidelineSaga(
  action: ChecklistActionMap<ActionType.LOAD_BY_GUIDELINE_REQUEST>,
): Generator {
  /*
   * O seguintes casos são tratados aqui:
   * - ainda não foi criada nenhuma produção para a pauta (manda criar)
   * - já existe uma produção e ela está em edição por outra pessoa (exibe aviso)
   * - já existe uma produção e ela está livre ou excluída (abre ela - desfaz exclusão ao salvar)
   */
  try {
    const checklist = yield call(api.loadByGuideline, action.guidelineId, action.edit)
    yield put(loadChecklistSuccess(checklist as Checklist, action.edit))
  } catch (error) {
    const status = error.response?.status

    if (status === StatusCodes.UNPROCESSABLE_ENTITY) {
      // Já existe uma produção criada para a pauta selecionada e ela está em edição
      yield put(notifyWarning({ message: i18n.t('checklist:fromGuidelineEditing') }))
    } else if (status === StatusCodes.NOT_FOUND) {
      // Nenhuma produção já criada para a pauta. Manda criar.
      if (!action.date) {
        console.error('A date is required to create a new checklist from the guideline')
      }
      yield put(createChecklistFromGuideline(action.guidelineId))
    } else {
      yield put(
        notifyError({
          message: i18n.t('error:operation'),
          description: i18n.t('error:createFailed'),
          error,
        }),
      )
    }
  }
}

function* loadGuidelineSaga(
  action: ChecklistActionMap<ActionType.LOAD_GUIDELINE_REQUEST>,
): Generator {
  try {
    const guideline = yield call(guidelineApi.load, action.id, false)
    yield put(loadGuidelineSuccess(guideline as Guideline))
  } catch (error) {
    yield put(loadGuidelineFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createChecklistSaga(action: ChecklistActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const checklist = yield call(api.create, action.checklist, action.lock)
    yield put(createChecklistSuccess(checklist as Checklist))
  } catch (error) {
    yield put(createChecklistFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* createChecklistFromGuidelineSaga(
  action: ChecklistActionMap<ActionType.CREATE_FROM_GUIDELINE_REQUEST>,
): Generator {
  try {
    const checklist = yield call(api.createFromGuideline, action.guidelineId)
    yield put(createChecklistFromGuidelineSuccess(checklist as Checklist))
  } catch (error) {
    yield put(createChecklistFromGuidelineFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateChecklistSaga(action: ChecklistActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const checklist = yield call(api.update, action.checklist)
    yield put(updateChecklistSuccess(checklist as Checklist))
  } catch (error) {
    yield put(updateChecklistFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeChecklistsSaga(action: ChecklistActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    const ids = yield call(api.remove, action.ids)
    yield put(removeChecklistsSuccess(ids as number[]))
  } catch (error) {
    yield put(removeChecklistsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

function* patchChecklistSaga(action: ChecklistActionMap<ActionType.PATCH_REQUEST>): Generator {
  try {
    yield call(api.updateField, action.checklistId, action.field, action.newValue)
    yield put(patchChecklistSuccess())
  } catch (error) {
    yield put(
      patchChecklistFailure(
        error,
        action.checklistId,
        action.field,
        action.newValue,
        action.oldValue,
      ),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

//
//  Config
//

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as ChecklistConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(
  action: ChecklistActionMap<ActionType.CREATE_CONFIG_REQUEST>,
): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as ChecklistConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(
  action: ChecklistActionMap<ActionType.UPDATE_CONFIG_REQUEST>,
): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as ChecklistConfig))
    yield put(
      notifySuccess({
        message: i18n.t('words:success'),
        description: i18n.t('config:updateSuccess'),
      }),
    )
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.DATE_LIST_REQUEST, dateListChecklistsSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_REQUEST, loadChecklistSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_BY_GUIDELINE_REQUEST, loadByGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_GUIDELINE_REQUEST, loadGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createChecklistSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_FROM_GUIDELINE_REQUEST, createChecklistFromGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateChecklistSaga)
  },
  function* () {
    yield takeLatest(ActionType.PATCH_REQUEST, patchChecklistSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeChecklistsSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
