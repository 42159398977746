export function removeEmpty(obj: any) {
  /*
   * Esse método altera o objeto recebido! Não usar em state!
   */
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key])
    } else if (obj[key] === undefined || obj[key] === '') {
      delete obj[key]
    }
  })
  return obj
}

export function convertToArray(obj: any): string[][] {
  const array: string[][] = []
  removeEmpty(obj)

  Object.keys(obj).forEach(key => {
    array.push([key, obj[key]])
  })

  return array
}
