import styled from '@emotion/styled'
import AntTextArea from 'antd/es/input/TextArea'

/*
 * ATENÇÃO! Esse componente é usado globalmente, então não faça dada específico aqui. Somente o que
 * deve ser realmente aplicado para todo o sistema.
 */

const TextArea = styled(AntTextArea)`
  overflow: ${props => (props.autoSize ? 'hidden' : 'inherit')};
`

export default TextArea
