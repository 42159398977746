export var AssetFieldType;
(function (AssetFieldType) {
    AssetFieldType["BOOLEAN"] = "BOOLEAN";
    AssetFieldType["CUSTOM"] = "CUSTOM";
    AssetFieldType["DATE"] = "DATE";
    AssetFieldType["DATETIME"] = "DATETIME";
    AssetFieldType["DOUBLE"] = "DOUBLE";
    AssetFieldType["ENUMERATION"] = "ENUMERATION";
    AssetFieldType["FLOAT"] = "FLOAT";
    AssetFieldType["INTEGER"] = "INTEGER";
    AssetFieldType["LONG"] = "LONG";
    AssetFieldType["STRING"] = "STRING";
    AssetFieldType["TIME"] = "TIME";
})(AssetFieldType || (AssetFieldType = {}));
