import { call, put, takeLatest } from 'redux-saga/effects'

import { storyTemplateApi as api } from '@anews/api'

import { Story } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  PatchStoryAction,
  patchStorySuccess,
  patchStoryFailure,
  RemoveStoriesAction,
  removeStoriesSuccess,
  removeStoriesFailure,
  UpdateStoryAction,
  updateStorySuccess,
  updateStoryFailure,
} from '../actions/storytemplate-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* updateStorySaga(action: UpdateStoryAction): Generator {
  try {
    const story = yield call(api.update, action.story)
    yield put(updateStorySuccess(story as Story))
  } catch (error) {
    yield put(updateStoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* patchStorySaga(action: PatchStoryAction): Generator {
  try {
    yield call(api.updateField, action.storyId, action.field, action.newValue)
    yield put(patchStorySuccess())
  } catch (error) {
    yield put(
      patchStoryFailure(error, action.storyId, action.field, action.newValue, action.oldValue),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* deleteStoriesSaga(action: RemoveStoriesAction): Generator {
  try {
    const ids = yield call(api.remove, action.ids)
    yield put(removeStoriesSuccess(ids as number[]))
  } catch (error) {
    yield put(removeStoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateStorySaga)
  },
  function* () {
    yield takeLatest(ActionType.PATCH_REQUEST, patchStorySaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, deleteStoriesSaga)
  },
])
