import { Reducer } from 'redux'

import { Device, AssetField, Media } from '@anews/types'

import { MediaAction, MediaActionType } from '../actions/media-actions'
import { DeviceAction, DeviceActionType } from '../actions/device-actions'

export interface MediasState {
  search: {
    mams: {
      loading: boolean
      data: Device[]
    }
    fields: {
      loading: boolean
      data: AssetField[]
    }
    mamsEmbedded: {
      loading: boolean
      data: Device[]
    }
    fieldsEmbedded: {
      loading: boolean
      data: AssetField[]
    }
  }
  page: {
    loading: boolean
    number: number
    size: number
    total: number
    medias?: Media[]
  }
  pageEmbedded: {
    loading: boolean
    number: number
    size: number
    total: number
    medias?: Media[]
  }
}

export const initialState: MediasState = {
  search: {
    mams: {
      loading: false,
      data: [],
    },
    fields: {
      loading: false,
      data: [],
    },
    mamsEmbedded: {
      loading: false,
      data: [],
    },
    fieldsEmbedded: {
      loading: false,
      data: [],
    },
  },
  page: {
    loading: false,
    number: 0,
    size: 0,
    total: 0,
    medias: undefined,
  },
  pageEmbedded: {
    loading: false,
    number: 0,
    size: 0,
    total: 0,
    medias: undefined,
  },
}

function searchReducer(
  state: MediasState['search'],
  action: MediaAction | DeviceAction,
): MediasState['search'] {
  switch (action.type) {
    // mams list
    case DeviceActionType.LIST_MAMS_REQUEST: {
      const mamsKey = action.embedded ? 'mamsEmbedded' : 'mams'
      const fieldsKey = action.embedded ? 'fieldsEmbedded' : 'fields'

      return {
        ...state,
        [mamsKey]: { ...state[mamsKey], loading: true },
        [fieldsKey]: initialState.search[fieldsKey],
      }
    }

    case DeviceActionType.LIST_MAMS_FAILURE: {
      const key = action.embedded ? 'mamsEmbedded' : 'mams'
      return { ...state, [key]: { ...state[key], loading: false } }
    }

    case DeviceActionType.LIST_MAMS_SUCCESS: {
      const key = action.embedded ? 'mamsEmbedded' : 'mams'
      return { ...state, [key]: { loading: false, data: action.devices } }
    }

    case MediaActionType.SEARCHABLE_FIELDS_REQUEST: {
      const key = action.embedded ? 'fieldsEmbedded' : 'fields'
      return { ...state, [key]: { ...state.fields, loading: true } }
    }

    case MediaActionType.SEARCHABLE_FIELDS_FAILURE: {
      const key = action.embedded ? 'fieldsEmbedded' : 'fields'
      return { ...state, [key]: { ...state[key], loading: false } }
    }

    case MediaActionType.SEARCHABLE_FIELDS_SUCCESS: {
      const key = action.embedded ? 'fieldsEmbedded' : 'fields'
      return { ...state, [key]: { loading: false, data: action.fields } }
    }

    case MediaActionType.CLEAR_RESULTS: {
      // Quando apertava no campo 'limpar' <MediasFilterForm>, estava excluindo os fields
      // Porque envia a mesma action quando fecha a <MediasScreen>
      // Tive que botar uma flag 'clearFields' para saber quando é para limpar os fields
      if (action.clearFields) {
        const key = action.embedded ? 'fieldsEmbedded' : 'fields'
        return { ...state, [key]: { loading: false, data: [] } }
      }

      return state
    }

    default:
      return state
  }
}

function pageReducer(
  state: MediasState['page'],
  action: MediaAction | DeviceAction,
): MediasState['page'] {
  // Não executa essas actions quando for "embedded"
  switch (action.type) {
    case MediaActionType.CLEAR_RESULTS:
    case MediaActionType.FILTER_MEDIAS_REQUEST:
    case MediaActionType.FILTER_MEDIAS_FAILURE:
    case MediaActionType.FILTER_MEDIAS_SUCCESS:
    case MediaActionType.SEARCHABLE_FIELDS_REQUEST:
      if (action.embedded) {
        return state
      }
  }

  switch (action.type) {
    case MediaActionType.SEARCHABLE_FIELDS_REQUEST:
      return { ...state, medias: [], loading: false, number: 0, size: 0, total: 0 }

    case MediaActionType.CLEAR_RESULTS:
      return {
        ...state,
        medias: state.medias && [],
        loading: false,
        number: 0,
        size: 0,
        total: 0,
      }

    case MediaActionType.FILTER_MEDIAS_REQUEST:
      return { ...state, loading: true }

    case MediaActionType.FILTER_MEDIAS_FAILURE:
      return { ...state, loading: false }

    case MediaActionType.FILTER_MEDIAS_SUCCESS: {
      const { page, size, total, data } = action.result
      return { ...state, loading: false, medias: data, number: page, size, total }
    }

    case MediaActionType.WS_UPDATE:
      return {
        ...state,
        medias: state.medias?.map(m => (m.objectId === action.media.objectId ? action.media : m)),
      }

    case MediaActionType.WS_DELETE:
      return { ...state, medias: state.medias?.filter(m => m.objectId !== action.id) }

    default:
      return state
  }
}

function pageEmbeddedReducer(
  state: MediasState['pageEmbedded'],
  action: MediaAction | DeviceAction,
): MediasState['pageEmbedded'] {
  // Não executa essas actions quando não for "embedded"
  switch (action.type) {
    case MediaActionType.SEARCHABLE_FIELDS_REQUEST:
    case MediaActionType.CLEAR_RESULTS:
    case MediaActionType.FILTER_MEDIAS_REQUEST:
    case MediaActionType.FILTER_MEDIAS_FAILURE:
    case MediaActionType.FILTER_MEDIAS_SUCCESS:
      if (!action.embedded) {
        return state
      }
  }

  switch (action.type) {
    case MediaActionType.SEARCHABLE_FIELDS_REQUEST:
      return { ...state, medias: [], loading: false, number: 0, size: 0, total: 0 }

    case MediaActionType.CLEAR_RESULTS:
      return {
        ...state,
        medias: state.medias && [],
        loading: false,
        number: 0,
        size: 0,
        total: 0,
      }

    case MediaActionType.FILTER_MEDIAS_REQUEST:
      return { ...state, loading: true }

    case MediaActionType.FILTER_MEDIAS_FAILURE:
      return { ...state, loading: false }

    case MediaActionType.FILTER_MEDIAS_SUCCESS: {
      const { page, size, total, data } = action.result
      return { ...state, loading: false, medias: data, number: page, size, total }
    }

    case MediaActionType.WS_UPDATE:
      return {
        ...state,
        medias: state.medias?.map(m => (m.objectId === action.media.objectId ? action.media : m)),
      }

    case MediaActionType.WS_DELETE:
      return { ...state, medias: state.medias?.filter(m => m.objectId !== action.id) }

    default:
      return state
  }
}

const targetTypes = [...Object.values(MediaActionType), ...Object.values(DeviceActionType)]

const mediasReducer: Reducer<MediasState, MediaAction | DeviceAction> = (
  state = initialState,
  action,
): MediasState => {
  if (action.type === MediaActionType.CLEAR) {
    return initialState
  }
  if (targetTypes.includes(action.type)) {
    return {
      ...state,
      search: searchReducer(state.search, action),
      page: pageReducer(state.page, action),
      pageEmbedded: pageEmbeddedReducer(state.pageEmbedded, action),
    }
  }
  return state
}

export default mediasReducer
