import { del, get, post, put, query } from './client';
var endpoint = '/api/groups';
var groupApi = {
    list: function () {
        return get(endpoint);
    },
    load: function (id) {
        return get(endpoint + "/" + id);
    },
    create: function (group) {
        return post(endpoint, group);
    },
    update: function (group) {
        return put("" + endpoint, group);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
};
export default groupApi;
