import { ContactGroup } from '@anews/types'

export enum ActionType {
  LIST_REQUEST = '@contactgroup/LIST_REQUEST',
  LIST_SUCCESS = '@contactgroup/LIST_SUCCESS',
  LIST_FAILURE = '@contactgroup/LIST_FAILURE',
}

export type ContactGroupAction =
  | { type: ActionType.LIST_REQUEST }
  | { type: ActionType.LIST_SUCCESS; groups: ContactGroup[] }
  | { type: ActionType.LIST_FAILURE; error: Error }

//
//	listGroups()
//

export const listGroups = (): ContactGroupAction => ({ type: ActionType.LIST_REQUEST })

export const listGroupsSuccess = (groups: ContactGroup[]): ContactGroupAction => ({
  type: ActionType.LIST_SUCCESS,
  groups,
})

export const listGroupsFailure = (error: Error): ContactGroupAction => ({
  type: ActionType.LIST_FAILURE,
  error,
})
