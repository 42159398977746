export var APPEARANCE = 'ON_CAMERA';
export var ART = 'ART';
export var HEADING = 'HEADING';
export var INFORMATION = 'INFORMATION';
export var INTERVIEW = 'PLAYBACK';
export var NOTE = 'NOTE';
export var OFF = 'VO';
export var SOUND_UP = 'SOUNDUP';
export var TEASER = 'TEASER';
export var TEXT = 'TEXT';
