import { Reducer } from 'redux'

import { WordpressConfig } from '@anews/types'

import { ActionType, WordpressAction } from '../actions/wordpress-actions'

export interface WordpressConfigListState {
  data: WordpressConfig[]
  loading: boolean
}

export interface WordpressConfigState {
  list: WordpressConfigListState
  editing?: WordpressConfig
  saving: boolean
  removing: boolean
}

export const initialState: WordpressConfigState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(
  list: WordpressConfigListState,
  action: WordpressAction,
): WordpressConfigListState {
  switch (action.type) {
    case ActionType.LIST_CONFIGS_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_CONFIGS_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_CONFIGS_SUCCESS:
      return {
        ...list,
        data: action.configs,
        loading: false,
      }

    case ActionType.CREATE_CONFIG_SUCCESS:
      return { ...list, data: [action.config, ...list.data] }

    case ActionType.UPDATE_CONFIG_SUCCESS:
      return {
        ...list,
        data: list.data.map(config => (config.id === action.config.id ? action.config : config)),
      }

    case ActionType.REMOVE_CONFIG_SUCCESS:
      return { ...list, data: list.data.filter(config => !action.ids.includes(config.id)) }

    default:
      return list
  }
}

const wordpressConfigsReducer: Reducer<WordpressConfigState, WordpressAction> = (
  state = initialState,
  action,
): WordpressConfigState => {
  switch (action.type) {
    case ActionType.LIST_CONFIGS_REQUEST:
    case ActionType.LIST_CONFIGS_FAILURE:
    case ActionType.LIST_CONFIGS_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW_CONFIG:
    case ActionType.EDIT_CONFIG_SUCCESS:
      return { ...state, editing: action.config }

    case ActionType.CREATE_CONFIG_REQUEST:
    case ActionType.UPDATE_CONFIG_REQUEST:
      return { ...state, editing: action.config, saving: true }

    case ActionType.REMOVE_CONFIG_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_CONFIG_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
    case ActionType.REMOVE_CONFIG_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default wordpressConfigsReducer
