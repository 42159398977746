import { Reducer } from 'redux'

import { ChecklistType } from '@anews/types'

import { ChecklistTypeActionType as ActionType, ChecklistTypeAction } from '../actions'

export interface ChecklistTypesListState {
  data: ChecklistType[]
  loading: boolean
}

export interface ChecklistTypesState {
  list: ChecklistTypesListState
}

export const defaultState = {
  list: {
    data: [],
    loading: true,
  },
}

function listReducer(
  list: ChecklistTypesListState,
  action: ChecklistTypeAction,
): ChecklistTypesListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }
    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }
    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.types,
        loading: false,
      }

    default:
      return list
  }
}

const typesReducer: Reducer<ChecklistTypesState, ChecklistTypeAction> = (
  state = defaultState,
  action,
): ChecklistTypesState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    default:
      return state
  }
}

export default typesReducer
