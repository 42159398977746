import { call, put, takeLatest } from 'redux-saga/effects'

import { twitterCategoryApi as api } from '@anews/api'
import { TwitterCategory } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  TwitterCategoryActions,
  TwitterCategoryActionMap as ActionMap,
  TwitterCategoryActionType as ActionType,
} from '../actions/twittercategory-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions
const {
  listTwitterCategoriesSuccess,
  listTwitterCategoriesFailure,
  createTwitterCategorySuccess,
  createTwitterCategoryFailure,
  updateTwitterCategorySuccess,
  updateTwitterCategoryFailure,
  removeTwitterCategorySuccess,
  removeTwitterCategoryFailure,
} = TwitterCategoryActions

/* Watchers */

function* listTwitterCategoriesSaga(action: ActionMap<ActionType.LIST_REQUEST>): Generator {
  try {
    const categories = yield call(api.list, action.users)
    yield put(listTwitterCategoriesSuccess(categories as TwitterCategory[]))
  } catch (error) {
    yield put(listTwitterCategoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createTwitterCategorySaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const category = yield call(api.create, action.category)
    yield put(createTwitterCategorySuccess(category as TwitterCategory))
  } catch (error) {
    yield put(createTwitterCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateTwitterCategorySaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const category = yield call(api.update, action.category)
    yield put(updateTwitterCategorySuccess(category as TwitterCategory))
  } catch (error) {
    yield put(updateTwitterCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeTwitterCategorySaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeTwitterCategorySuccess(action.id))
  } catch (error) {
    yield put(removeTwitterCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listTwitterCategoriesSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createTwitterCategorySaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateTwitterCategorySaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeTwitterCategorySaga)
  },
])
