var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from 'axios';
import { toQueryString } from '@anews/utils';
var listeners = [];
export function addApiListener(listener) {
    listeners.push(listener);
}
export function removeApiListener(listener) {
    listeners = listeners.filter(function (it) { return it !== listener; });
}
function dispatchInit() {
    listeners.forEach(function (listener) {
        try {
            listener.onInit();
        }
        catch (err) {
            console.error(err);
        }
    });
}
function dispatchSuccess(data) {
    listeners.forEach(function (listener) {
        try {
            listener.onSuccess(data);
        }
        catch (err) {
            console.error(err);
        }
    });
}
function dispatchFailure(reason) {
    listeners.forEach(function (listener) {
        try {
            listener.onFailure(reason);
        }
        catch (err) {
            console.error(err);
        }
    });
}
//
//  Client Helper Methods
//
function call(_a) {
    var method = _a.method, url = _a.url, data = _a.data, config = __rest(_a, ["method", "url", "data"]);
    dispatchInit();
    return axios(__assign({ method: method, url: url, data: data }, config))
        .then(function (resp) {
        dispatchSuccess(resp.data);
        return resp.data;
    })
        .catch(function (reason) {
        dispatchFailure(reason);
        throw reason;
    });
}
export function ping(url) {
    return axios.get(url, { timeout: 2000, withCredentials: false });
}
export function get(url, config) {
    return call(__assign({ method: 'get', url: url }, config));
}
export function del(url, config) {
    return call(__assign({ method: 'delete', url: url }, config));
}
export function post(url, data, config) {
    return call(__assign({ method: 'post', url: url, data: data }, config));
}
export function put(url, data, config) {
    return call(__assign({ method: 'put', url: url, data: data }, config));
}
export function patch(url, data, config) {
    return call(__assign({ method: 'patch', url: url, data: data }, config));
}
export function query(url, params) {
    var queryStr = toQueryString(params);
    return url.includes('?') ? url + "&" + queryStr : url + "?" + queryStr;
}
export function upload(url, data, onUploadProgress, onCancelToken) {
    var cancelSource = axios.CancelToken.source();
    if (onCancelToken) {
        onCancelToken(cancelSource);
    }
    return call({
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: onUploadProgress,
        cancelToken: cancelSource.token,
    });
}
