import { DocumentType } from '@anews/types'

export enum TypeActionType {
  LIST_REQUEST = '@type/LIST_REQUEST',
  LIST_SUCCESS = '@type/LIST_SUCCESS',
  LIST_FAILURE = '@type/LIST_FAILURE',
}

export type TypeActionMap<T extends TypeActionType> = { type: T } & TypeAction

export type TypeAction = ReturnType<typeof TypeActions[keyof typeof TypeActions]>

export const TypeActions = {
  //
  //	listTypes()
  //

  listTypes: () => <const>{ type: TypeActionType.LIST_REQUEST },
  listTypesSuccess: (types: DocumentType[]) =>
    <const>{
      type: TypeActionType.LIST_SUCCESS,
      types,
    },
  listTypesFailure: (error: Error) =>
    <const>{
      type: TypeActionType.LIST_FAILURE,
      error,
    },
}
