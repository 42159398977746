import { call, put, takeLatest } from 'redux-saga/effects'

import { rssCategoryApi as api } from '@anews/api'
import { RssCategory } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  RssCategoryActions,
  RssCategoryActionMap as ActionMap,
  RssCategoryActionType as ActionType,
} from '../actions/rsscategory-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  listRssCategoriesSuccess,
  listRssCategoriesFailure,
  createRssCategorySuccess,
  createRssCategoryFailure,
  updateRssCategorySuccess,
  updateRssCategoryFailure,
  removeRssCategorySuccess,
  removeRssCategoryFailure,
} = RssCategoryActions

const { notifyError } = NotificationActions

/* Watchers */

function* listRssCategoriesSaga(action: ActionMap<ActionType.LIST_REQUEST>): Generator {
  try {
    const categories = yield call(api.list, action.feeds)
    yield put(listRssCategoriesSuccess(categories as RssCategory[]))
  } catch (error) {
    yield put(listRssCategoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createRssCategorySaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const category = yield call(api.create, action.category)
    yield put(createRssCategorySuccess(category as RssCategory))
  } catch (error) {
    yield put(createRssCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateRssCategorySaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const category = yield call(api.update, action.category)
    yield put(updateRssCategorySuccess(category as RssCategory))
  } catch (error) {
    yield put(updateRssCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeRssCategorySaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeRssCategorySuccess(action.id))
  } catch (error) {
    yield put(removeRssCategoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listRssCategoriesSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createRssCategorySaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateRssCategorySaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeRssCategorySaga)
  },
])
