import React, { memo, useCallback, useEffect, ReactNode } from 'react'
import { connect } from 'react-redux'

import Tabs from 'antd/es/tabs'

import { TableOutlined, AppstoreOutlined } from '@ant-design/icons'

import Modal from 'antd/es/modal'

import { useInterval, useOnce } from '@anews/hooks'
import { EntityLock, Reportage } from '@anews/types'
import { isNew } from '@anews/utils'

import { EntityLockActions, UserActions, ReportageActions } from '../../../redux/actions'
import { RootState, PropsMapper } from '../../../redux/reducers'
import { createRolesChecker } from '../../../redux/selectors/auth-selectors'

import { Section, useUnsavedSection } from '../../../unsaved'

import { InnerTabsContainer } from '../../global'

import { useTranslation } from '../../../i18n'

import ReportageForm from './ReportageForm'

export const REPORTAGES_LIST_TAB = 'reportages'

interface OwnProps {
  children: ReactNode
  locks: EntityLock[]
  mode: 'board' | 'list'
  isActiveScreen?: boolean
}

const mapStateToProps = (state: RootState) => ({
  saving: state.reportages.saving,
  classifications: state.classifications.list,
  companies: state.companies.list,
  config: state.reportages.config.data,
  documentConfig: state.documents.config.data,
  editing: state.reportages.editing,
  editors: state.users.lists.editors,
  editorials: state.editorials.list,
  accessiblePrograms: state.programs.accessible,
  reporters: state.users.lists.reporters,
  vehicles: state.vehicles.list,
  userNickname: state.auth.user ? state.auth.user.nickname : undefined,
  hasRoles: createRolesChecker(state),
})

const dispatchProps = {
  changeReportageTab: ReportageActions.changeReportageTab,
  closeReportageTab: ReportageActions.closeReportageTab,
  createReportage: ReportageActions.createReportage,
  updateReportage: ReportageActions.updateReportage,
  storeTransient: ReportageActions.storeTransient,
  listEditors: UserActions.listEditors,
  listReporters: UserActions.listReporters,
  renewLock: EntityLockActions.renewLock,
  unlock: EntityLockActions.unlock,
  createFromGuideline: ReportageActions.createReportageFromGuideline,
}

type Props = PropsMapper<typeof mapStateToProps, typeof dispatchProps, OwnProps>

function ReportagesTabs({
  mode,
  saving,
  children,
  classifications,
  companies,
  config,
  documentConfig,
  editing,
  editors,
  editorials,
  locks,
  accessiblePrograms,
  reporters,
  vehicles,
  isActiveScreen,
  createReportage,
  updateReportage,
  changeReportageTab,
  closeReportageTab,
  storeTransient,
  listEditors,
  listReporters,
  renewLock,
  unlock,
  userNickname,
  hasRoles,
  createFromGuideline,
}: Props) {
  useOnce(listReporters)

  const { unsavedData, clearUnsavedEntry } = useUnsavedSection(Section.REPORTAGES)

  // Só carrega os editores se for preciso
  useEffect(() => {
    if (config && config.editorEnabled) {
      listEditors()
    }
  }, [config, listEditors])

  // No servidor os locks são criado com a duração máxima de 5 minutos
  // Renova os locks a cada 2 minutos para manter ativo
  useInterval(() => {
    editing.reportages.forEach(reportage => {
      if (!isNew(reportage)) {
        renewLock(reportage.uuid)
      }
    })
  }, 2 * 60 * 1000)

  const { t } = useTranslation()

  const close = useCallback(
    uuid => {
      closeReportageTab(uuid)
      unlock(uuid)
    },
    [closeReportageTab, unlock],
  )

  const onEditTabs = useCallback(
    (key, action) => {
      if (action === 'remove') {
        if (unsavedData[key]) {
          Modal.confirm({
            title: t('words:warning'),
            content: t('unsaved:discardConfirm'),
            okText: t('words:yes'),
            cancelText: t('words:no'),
            okButtonProps: { danger: true },
            onOk() {
              clearUnsavedEntry(key)
              close(key)
            },
            onCancel() {},
          })
        } else {
          close(key)
        }
      }
    },
    [clearUnsavedEntry, close, unsavedData, t],
  )

  const onSave = useCallback(
    (reportage: Reportage, lock: boolean) => {
      if (isNew(reportage)) {
        createReportage(reportage, lock)
      } else {
        updateReportage(reportage)
      }
    },
    [createReportage, updateReportage],
  )

  return (
    <InnerTabsContainer>
      <Tabs
        activeKey={editing.activeTab}
        onChange={changeReportageTab}
        onEdit={onEditTabs}
        type="editable-card"
        hideAdd
      >
        <Tabs.TabPane
          tab={
            mode === 'list' ? (
              <span>
                <TableOutlined /> {t('words:list')}
              </span>
            ) : (
              <span>
                <AppstoreOutlined /> {t('words:board')}
              </span>
            )
          }
          key={REPORTAGES_LIST_TAB}
          closable={false}
        >
          {children}
        </Tabs.TabPane>

        {editing.reportages.map(reportage => (
          <Tabs.TabPane
            tab={`${unsavedData[reportage.uuid] ? '*' : ''} ${
              reportage.slug || t('reportage:new')
            }`}
            key={reportage.uuid}
            closable={!saving}
          >
            <ReportageForm
              reportage={reportage}
              lock={locks.find(lock => lock.uuid === reportage.uuid)}
              userNickname={userNickname}
              documentConfig={documentConfig}
              reportageConfig={config}
              classifications={classifications}
              companies={companies}
              editors={editors}
              editorials={editorials}
              accessiblePrograms={accessiblePrograms}
              reporters={reporters}
              vehicles={vehicles}
              saving={saving}
              listReporters={listReporters}
              onSave={onSave}
              onClose={close}
              onChange={storeTransient}
              activeTab={isActiveScreen ? editing.activeTab : ''}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </InnerTabsContainer>
  )
}

export default connect(mapStateToProps, dispatchProps)(memo(ReportagesTabs))
