import { call, put, takeLatest } from 'redux-saga/effects'

import { editorialApi as api } from '@anews/api'
import { Editorial } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  listEditorialsSuccess,
  listEditorialsFailure,
} from '../actions/editorial-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listEditorialsSaga(): Generator {
  try {
    const editorials = yield call(api.list)
    yield put(listEditorialsSuccess(editorials as Editorial[]))
  } catch (error) {
    yield put(listEditorialsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listEditorialsSaga)
  },
])
