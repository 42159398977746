import { del, get, post, put } from './client';
var endpoint = '/api/custom-metadata';
var customMetadataApi = {
    list: function () { return get(endpoint); },
    load: function (id) { return get(endpoint + "/" + id); },
    create: function (metadata) { return post(endpoint, metadata); },
    update: function (metadata) { return put(endpoint, metadata); },
    remove: function (id) { return del(endpoint + "/" + id); },
    usages: function (id) { return get(endpoint + "/" + id + "/usages"); },
    optionUsages: function (metadataId, optionId) {
        return get(endpoint + "/" + metadataId + "/options/" + optionId + "/usages");
    },
};
export default customMetadataApi;
