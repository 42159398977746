export var AdminLogCategory;
(function (AdminLogCategory) {
    AdminLogCategory["GROUPS"] = "GROUPS";
    AdminLogCategory["USERS"] = "USERS";
    AdminLogCategory["PROGRAMS"] = "PROGRAMS";
    AdminLogCategory["METADATA"] = "METADATA";
    AdminLogCategory["BRANCHES"] = "BRANCHES";
    AdminLogCategory["BRANCH_CONFIG"] = "BRANCH_CONFIG";
    AdminLogCategory["STREAMING_CONFIG"] = "STREAMING_CONFIG";
    AdminLogCategory["SIZE_FONTS"] = "SIZE_FONTS";
    AdminLogCategory["HISTORY_CLEAN"] = "HISTORY_CLEAN";
    AdminLogCategory["MAIL_CONFIG"] = "MAIL_CONFIG";
    AdminLogCategory["LDAP_CONFIG"] = "LDAP_CONFIG";
    AdminLogCategory["DEVICES"] = "DEVICES";
    AdminLogCategory["FACEBOOK_CONFIG"] = "FACEBOOK_CONFIG";
    AdminLogCategory["MAPS_CONFIG"] = "MAPS_CONFIG";
    AdminLogCategory["SOUV_CONFIG"] = "SOUV_CONFIG";
    AdminLogCategory["TWITTER_CONFIG"] = "TWITTER_CONFIG";
    AdminLogCategory["WORDPRESS_CONFIG"] = "WORDPRESS_CONFIG";
})(AdminLogCategory || (AdminLogCategory = {}));
export var AdminLogAction;
(function (AdminLogAction) {
    AdminLogAction["CREATED"] = "CREATED";
    AdminLogAction["UPDATED"] = "UPDATED";
    AdminLogAction["DELETED"] = "DELETED";
})(AdminLogAction || (AdminLogAction = {}));
