import { ReportFilterParams } from '@anews/api'
import { Page, Report, ReportConfig, ReportRevision } from '@anews/types'

import { PAGE_SIZE } from '../../consts/pagination'

export enum ReportActionType {
  NEW = '@report/NEW',
  CLEAR = '@report/CLEAR',
  UNLOAD = '@report/UNLOAD',
  UNLOAD_REVISIONS = '@report/UNLOAD_REVISIONS',
  CLOSE_TAB = '@report/CLOSE_TAB',
  CHANGE_TAB = '@report/CHANGE_TAB',
  UPDATE_FORM = '@report/UPDATE_FORM',
  // filterReports()
  FILTER_REQUEST = '@report/FILTER_REQUEST',
  FILTER_SUCCESS = '@report/FILTER_SUCCESS',
  FILTER_FAILURE = '@report/FILTER_FAILURE',
  // loadReport()
  LOAD_REQUEST = '@report/LOAD_REQUEST',
  LOAD_SUCCESS = '@report/LOAD_SUCCESS',
  LOAD_FAILURE = '@report/LOAD_FAILURE',
  // loadReportRevisions()
  LOAD_REVISIONS_REQUEST = '@report/LOAD_REVISIONS_REQUEST',
  LOAD_REVISIONS_SUCCESS = '@report/LOAD_REVISIONS_SUCCESS',
  LOAD_REVISIONS_FAILURE = '@report/LOAD_REVISIONS_FAILURE',
  // createReport()
  CREATE_REQUEST = '@report/CREATE_REQUEST',
  CREATE_SUCCESS = '@report/CREATE_SUCCESS',
  CREATE_FAILURE = '@report/CREATE_FAILURE',
  // updateReport()
  UPDATE_REQUEST = '@report/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@report/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@report/UPDATE_FAILURE',
  // removeReport()
  REMOVE_REQUEST = '@report/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@report/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@report/REMOVE_FAILURE',
  // websocket
  WS_CREATE = '@report/WS_CREATE',
  WS_DELETE = '@report/WS_DELETE',
  WS_UPDATE = '@report/WS_UPDATE',
  WS_PATCH = '@report/WS_PATCH',
  // config load
  LOAD_CONFIG_REQUEST = '@report/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@report/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@report/LOAD_CONFIG_FAILURE',
  // config create
  CREATE_CONFIG_REQUEST = '@report/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@report/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@report/CREATE_CONFIG_FAILURE',
  // config update
  UPDATE_CONFIG_REQUEST = '@report/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@report/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@report/UPDATE_CONFIG_FAILURE',
}

export type ReportActionMap<T extends ReportActionType> = {
  type: T
} & ReportAction

export type ReportAction = ReturnType<typeof ReportActions[keyof typeof ReportActions]>

export const ReportActions = {
  //
  //	filterReports()
  //

  filterReports: (params: ReportFilterParams, page: number = 0, size: number = PAGE_SIZE) =>
    <const>{ type: ReportActionType.FILTER_REQUEST, params, page, size },

  filterReportsSuccess: (result: Page<Report>) =>
    <const>{ type: ReportActionType.FILTER_SUCCESS, result },
  filterReportsFailure: (error: Error) => <const>{ type: ReportActionType.FILTER_FAILURE, error },

  //
  //	loadReport()
  //
  loadReport: (target: number | Report, edit: boolean) =>
    <const>{ type: ReportActionType.LOAD_REQUEST, target, edit },
  loadReportSuccess: (report: Report, edit: boolean) =>
    <const>{ type: ReportActionType.LOAD_SUCCESS, report, edit },
  loadReportFailure: (error: Error) => <const>{ type: ReportActionType.LOAD_FAILURE, error },

  //
  //	loadReportRevisions()
  //
  loadReportRevisions: (reportId: number) =>
    <const>{ type: ReportActionType.LOAD_REVISIONS_REQUEST, reportId },
  loadReportRevisionsSuccess: (revisions: ReportRevision[]) =>
    <const>{ type: ReportActionType.LOAD_REVISIONS_SUCCESS, revisions },
  loadReportRevisionsFailure: (error: Error) =>
    <const>{ type: ReportActionType.LOAD_REVISIONS_FAILURE, error },

  //
  //	createReport()
  //
  createReport: (report: Report, lock: boolean) =>
    <const>{ type: ReportActionType.CREATE_REQUEST, report, lock },
  createReportSuccess: (report: Report) => <const>{ type: ReportActionType.CREATE_SUCCESS, report },
  createReportFailure: (error: Error) => <const>{ type: ReportActionType.CREATE_FAILURE, error },

  //
  //	updateReport()
  //
  updateReport: (report: Report) => <const>{ type: ReportActionType.UPDATE_REQUEST, report },
  updateReportSuccess: (report: Report) => <const>{ type: ReportActionType.UPDATE_SUCCESS, report },
  updateReportFailure: (error: Error) => <const>{ type: ReportActionType.UPDATE_FAILURE, error },

  //
  //	removeReports()
  //
  removeReports: (ids: number[]) => <const>{ type: ReportActionType.REMOVE_REQUEST, ids },
  removeReportsSuccess: (ids: number[]) => <const>{ type: ReportActionType.REMOVE_SUCCESS, ids },
  removeReportsFailure: (error: Error) => <const>{ type: ReportActionType.REMOVE_FAILURE, error },

  //
  //	plain actions
  //

  newReport: (report: Report) => <const>{ type: ReportActionType.NEW, report },

  unloadReport: () => <const>{ type: ReportActionType.UNLOAD },

  unloadReportRevisions: () => <const>{ type: ReportActionType.UNLOAD_REVISIONS },

  clearReports: () => <const>{ type: ReportActionType.CLEAR },

  changeReportTab: (key: string) => <const>{ type: ReportActionType.CHANGE_TAB, key },

  closeReportTab: (key: string) => <const>{ type: ReportActionType.CLOSE_TAB, key },

  updateForm: (uuid: string, changes: Partial<Report>) =>
    <const>{ type: ReportActionType.UPDATE_FORM, uuid, changes },

  //
  //  websocket actions
  //

  wsCreateReport: (report: Report) => <const>{ type: ReportActionType.WS_CREATE, report },

  wsUpdateReport: (report: Report) => <const>{ type: ReportActionType.WS_UPDATE, report },

  wsDeleteReport: (ids: number[]) => <const>{ type: ReportActionType.WS_DELETE, ids },

  wsPatchReport: (id: number, changes: Partial<Report>) =>
    <const>{ type: ReportActionType.WS_PATCH, id, changes },

  //  loadConfig()

  loadConfig: () => <const>{ type: ReportActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: ReportConfig) =>
    <const>{ type: ReportActionType.LOAD_CONFIG_SUCCESS, config },

  loadConfigFailure: (error: Error) => <const>{ type: ReportActionType.LOAD_CONFIG_FAILURE, error },

  //  createConfig()

  createConfig: (config: ReportConfig) =>
    <const>{ type: ReportActionType.CREATE_CONFIG_REQUEST, config },

  createConfigSuccess: (config: ReportConfig) =>
    <const>{ type: ReportActionType.CREATE_CONFIG_SUCCESS, config },

  createConfigFailure: (error: Error) =>
    <const>{ type: ReportActionType.CREATE_CONFIG_FAILURE, error },

  //  updateConfig()

  updateConfig: (config: ReportConfig) =>
    <const>{ type: ReportActionType.UPDATE_CONFIG_REQUEST, config },

  updateConfigSuccess: (config: ReportConfig) =>
    <const>{ type: ReportActionType.UPDATE_CONFIG_SUCCESS, config },

  updateConfigFailure: (error: Error) =>
    <const>{ type: ReportActionType.UPDATE_CONFIG_FAILURE, error },
}
