import { User } from '@anews/types'

export enum AuthActionType {
  LIST_AUTHORITIES_REQUEST = '@auth/LIST_AUTHORITIES_REQUEST',
  LIST_AUTHORITIES_SUCCESS = '@auth/LIST_AUTHORITIES_SUCCESS',
  LIST_AUTHORITIES_FAILURE = '@auth/LIST_AUTHORITIES_FAILURE',
  LOAD_AUTH_REQUEST = '@auth/LOAD_AUTH_REQUEST',
  LOAD_AUTH_SUCCESS = '@auth/LOAD_AUTH_SUCCESS',
  LOAD_AUTH_FAILURE = '@auth/LOAD_AUTH_FAILURE',
  LOGIN_REQUEST = '@auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@auth/LOGIN_FAILURE',
  LOGIN_2FA_REQUEST = '@auth/LOGIN_2FA_REQUEST',
  LOGIN_2FA_SUCCESS = '@auth/LOGIN_2FA_SUCCESS',
  LOGIN_2FA_FAILURE = '@auth/LOGIN_2FA_FAILURE',
  VALIDATE_RECOVERY_CODE_REQUEST = '@auth/VALIDATE_RECOVERY_CODE_REQUEST',
  VALIDATE_RECOVERY_CODE_SUCCESS = '@auth/VALIDATE_RECOVERY_CODE_SUCCESS',
  VALIDATE_RECOVERY_CODE_FAILURE = '@auth/VALIDATE_RECOVERY_CODE_FAILURE',
  LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = '@auth/LOGOUT_FAILURE',
  KICKOUT_REQUEST = '@auth/KICKOUT_REQUEST',
  KICKOUT_SUCCESS = '@auth/KICKOUT_SUCCESS',
  KICKOUT_FAILURE = '@auth/KICKOUT_FAILURE',
  FIRST_LOGIN = '@auth/FIRST_LOGIN',
}

export interface Credentials {
  username: string
  password: string
  isResetPassword?: boolean
}

export type AuthActionMap<T extends AuthActionType> = { type: T } & AuthAction

export type AuthAction = ReturnType<typeof AuthActions[keyof typeof AuthActions]>

export const AuthActions = {
  //
  //	listAuthorities()
  //
  listAuthorities: () => <const>{ type: AuthActionType.LIST_AUTHORITIES_REQUEST },

  listAuthoritiesSuccess: (authorities: string[]) =>
    <const>{ type: AuthActionType.LIST_AUTHORITIES_SUCCESS, authorities },

  listAuthoritiesFailure: (error: Error) =>
    <const>{ type: AuthActionType.LIST_AUTHORITIES_FAILURE, error },

  //
  //	loadAuth()
  //
  loadAuth: () => <const>{ type: AuthActionType.LOAD_AUTH_REQUEST },
  loadAuthSuccess: (user: User) => <const>{ type: AuthActionType.LOAD_AUTH_SUCCESS, user },
  loadAuthFailure: (error: Error) => <const>{ type: AuthActionType.LOAD_AUTH_FAILURE, error },

  //
  //	login()
  //
  login: (credentials: Credentials) => <const>{ type: AuthActionType.LOGIN_REQUEST, credentials },
  loginSuccess: () => <const>{ type: AuthActionType.LOGIN_SUCCESS },
  loginFailure: (error: Error) => <const>{ type: AuthActionType.LOGIN_FAILURE, error },

  //
  //  login2Fa()
  //
  login2Fa: (id?: number, code?: string) =>
    <const>{ type: AuthActionType.LOGIN_2FA_REQUEST, id, code },
  login2FaSuccess: (validCode: boolean) =>
    <const>{ type: AuthActionType.LOGIN_2FA_SUCCESS, validCode },
  login2FaFailure: (error: Error) => <const>{ type: AuthActionType.LOGIN_2FA_FAILURE, error },

  //
  // validateRecoveryCode
  //
  validateRecoveryCode: (id?: number, code?: string) =>
    <const>{ type: AuthActionType.VALIDATE_RECOVERY_CODE_REQUEST, id, code },
  validateRecoveryCodeSuccess: (validCode: boolean) =>
    <const>{ type: AuthActionType.VALIDATE_RECOVERY_CODE_SUCCESS, validCode },
  validateRecoveryCodeFailure: (error: Error) =>
    <const>{ type: AuthActionType.VALIDATE_RECOVERY_CODE_FAILURE, error },

  //
  //	logout()
  //
  logout: () => <const>{ type: AuthActionType.LOGOUT_REQUEST },
  logoutSuccess: () => <const>{ type: AuthActionType.LOGOUT_SUCCESS },
  logoutFailure: (error: Error) => <const>{ type: AuthActionType.LOGOUT_FAILURE, error },

  //
  //	kickOut()
  //
  kickOut: (id: number) => <const>{ type: AuthActionType.KICKOUT_REQUEST, id },
  kickOutSuccess: (id: number) => <const>{ type: AuthActionType.KICKOUT_SUCCESS, id },
  kickOutFailure: (error: Error) => <const>{ type: AuthActionType.KICKOUT_FAILURE, error },

  //
  // isFirstLogin()
  //
  isFirstLogin: (firstLogin: boolean) => <const>{ type: AuthActionType.FIRST_LOGIN, firstLogin },
}
