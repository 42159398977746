import React, { FC, memo, ReactNode } from 'react'

import Form, { Rule } from 'antd/es/form'
import Input from 'antd/es/input'
import InputNumber from 'antd/es/input-number'
import TimePicker from 'antd/es/time-picker'
import Tooltip from 'antd/es/tooltip'

import { CustomMetadata, CustomMetadataType } from '@anews/types'

import { generateMetadataInputName } from '@anews/utils'

import { useTranslation } from '../../../i18n'

import SelectWithSearch from '../SelectWithSearch'

import { DatePicker } from '..'

function flexSize(meta: CustomMetadata) {
  switch (meta.type) {
    case CustomMetadataType.DATE:
    case CustomMetadataType.NUMBER:
    case CustomMetadataType.TIME:
    case CustomMetadataType.TIMESTAMP:
      return undefined
    case CustomMetadataType.SELECT:
      return meta.multiple ? 2 : 1
    case CustomMetadataType.TEXT:
      return meta.multiple ? undefined : 2
    default:
      throw new Error('Invalid metadata type')
  }
}

type Props = {
  metadata: CustomMetadata
  placeholderAsLabel?: boolean
}

/**
 * Componente que representa um metadado customizado em um formulário.
 */
const CustomMetadataFormItem: FC<Props> = ({ metadata, placeholderAsLabel }) => {
  const { t } = useTranslation()

  const inputName = generateMetadataInputName(metadata.name)
  const placeholder = placeholderAsLabel ? metadata.name : undefined
  const rules: Rule[] = [{ required: metadata.required, message: t('validation:required') }]
  let control: ReactNode = null

  // Data
  if (metadata.type === CustomMetadataType.DATE) {
    control = <DatePicker placeholder={placeholder} />
  }
  // Horário
  if (metadata.type === CustomMetadataType.TIME) {
    control = <TimePicker format="HH:mm" placeholder={placeholder} />
  }
  // Data e horário
  if (metadata.type === CustomMetadataType.TIMESTAMP) {
    control = <DatePicker showTime placeholder={placeholder} />
  }
  // Número
  if (metadata.type === CustomMetadataType.NUMBER) {
    control = (
      <InputNumber min={metadata.minimum} max={metadata.maximum} placeholder={placeholder} />
    )
    rules.push({ type: 'number' })

    /*
    if (metadata.minimum) {
      rules.push({
        min: metadata.minimum,
        message: t('validation:minNum', { count: metadata.minimum }),
      })
    }
    if (metadata.maximum) {
      rules.push({
        max: metadata.maximum,
        message: t('validation:maxNum', { count: metadata.maximum }),
      })
    }
    */
  }
  // Texto
  if (metadata.type === CustomMetadataType.TEXT) {
    control = metadata.multiple ? (
      <Input.TextArea maxLength={metadata.maximum} placeholder={placeholder} />
    ) : (
      <Input maxLength={metadata.maximum} placeholder={placeholder} />
    )
    rules.push({
      max: metadata.maximum,
      message: t('validation:maxChars', { count: metadata.maximum }),
    })
  }
  // Seleção
  if (metadata.type === CustomMetadataType.SELECT) {
    control = (
      <SelectWithSearch
        options={metadata.options
          ?.sort((a, b) => a.label.localeCompare(b.label))
          .map(({ id, label }) => ({ label, value: id }))}
        mode={metadata.multiple ? 'multiple' : undefined}
        placeholder={placeholder}
      />
    )
  }

  const formItem = (
    <Form.Item
      name={inputName}
      label={placeholderAsLabel ? undefined : metadata.name}
      rules={rules}
      style={{ flex: flexSize(metadata) }}
    >
      {control}
    </Form.Item>
  )

  return placeholderAsLabel ? <Tooltip title={metadata.name}>{formItem}</Tooltip> : formItem
}

export default memo(CustomMetadataFormItem)
