import { TwitterCategory } from '@anews/types'

export enum TwitterCategoryActionType {
  NEW = '@twitter_category/NEW',
  EDIT = '@twitter_category/EDIT',
  LIST_REQUEST = '@twitter_category/LIST_REQUEST',
  LIST_SUCCESS = '@twitter_category/LIST_SUCCESS',
  LIST_FAILURE = '@twitter_category/LIST_FAILURE',
  CREATE_REQUEST = '@twitter_category/CREATE_REQUEST',
  CREATE_SUCCESS = '@twitter_category/CREATE_SUCCESS',
  CREATE_FAILURE = '@twitter_category/CREATE_FAILURE',
  UPDATE_REQUEST = '@twitter_category/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@twitter_category/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@twitter_category/UPDATE_FAILURE',
  REMOVE_REQUEST = '@twitter_category/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@twitter_category/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@twitter_category/REMOVE_FAILURE',
}

export type TwitterCategoryActionMap<T extends TwitterCategoryActionType> = {
  type: T
} & TwitterCategoryAction

export type TwitterCategoryAction = ReturnType<
  typeof TwitterCategoryActions[keyof typeof TwitterCategoryActions]
>

export const TwitterCategoryActions = {
  //
  //	newTwitterCategory()
  //

  newTwitterCategory: (category?: TwitterCategory) =>
    <const>{ type: TwitterCategoryActionType.NEW, category },

  //
  //	listTwitterCategories()
  //

  listTwitterCategories: (users: boolean) =>
    <const>{ type: TwitterCategoryActionType.LIST_REQUEST, users },

  listTwitterCategoriesSuccess: (categories: TwitterCategory[]) =>
    <const>{ type: TwitterCategoryActionType.LIST_SUCCESS, categories },

  listTwitterCategoriesFailure: (error: Error) =>
    <const>{ type: TwitterCategoryActionType.LIST_FAILURE, error },

  //
  //	editTwitterCategory()
  //

  editTwitterCategory: (category: TwitterCategory) =>
    <const>{ type: TwitterCategoryActionType.EDIT, category },

  //
  //	createTwitterCategory()
  //

  createTwitterCategory: (category: TwitterCategory) =>
    <const>{ type: TwitterCategoryActionType.CREATE_REQUEST, category },

  createTwitterCategorySuccess: (category: TwitterCategory) =>
    <const>{ type: TwitterCategoryActionType.CREATE_SUCCESS, category },

  createTwitterCategoryFailure: (error: Error) =>
    <const>{ type: TwitterCategoryActionType.CREATE_FAILURE, error },

  //
  //	updateTwitterCategory()
  //

  updateTwitterCategory: (category: TwitterCategory) =>
    <const>{ type: TwitterCategoryActionType.UPDATE_REQUEST, category },

  updateTwitterCategorySuccess: (category: TwitterCategory) =>
    <const>{ type: TwitterCategoryActionType.UPDATE_SUCCESS, category },

  updateTwitterCategoryFailure: (error: Error) =>
    <const>{ type: TwitterCategoryActionType.UPDATE_FAILURE, error },

  //
  //	removeTwitterCategory()
  //

  removeTwitterCategory: (id: number) =>
    <const>{ type: TwitterCategoryActionType.REMOVE_REQUEST, id },

  removeTwitterCategorySuccess: (id: number) =>
    <const>{ type: TwitterCategoryActionType.REMOVE_SUCCESS, id },

  removeTwitterCategoryFailure: (error: Error) =>
    <const>{ type: TwitterCategoryActionType.REMOVE_FAILURE, error },
}
