import { StreamingConfig } from '@anews/types'

export enum ActionType {
  LOAD_CONFIG_REQUEST = '@streaming/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@streaming/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@streaming/LOAD_CONFIG_FAILURE',
  CREATE_CONFIG_REQUEST = '@streaming/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@streaming/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@streaming/CREATE_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@streaming/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@streaming/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@streaming/UPDATE_CONFIG_FAILURE',
}

export interface CreateStreamingConfigAction {
  type: ActionType.CREATE_CONFIG_REQUEST
  config: StreamingConfig
}

export interface UpdateStreamingConfigAction {
  type: ActionType.UPDATE_CONFIG_REQUEST
  config: StreamingConfig
}

export type StreamingAction =
  | { type: ActionType.LOAD_CONFIG_REQUEST }
  | { type: ActionType.LOAD_CONFIG_SUCCESS; config: StreamingConfig }
  | { type: ActionType.LOAD_CONFIG_FAILURE; error: Error }
  | CreateStreamingConfigAction
  | { type: ActionType.CREATE_CONFIG_SUCCESS; config: StreamingConfig }
  | { type: ActionType.CREATE_CONFIG_FAILURE; error: Error }
  | UpdateStreamingConfigAction
  | { type: ActionType.UPDATE_CONFIG_SUCCESS; config: StreamingConfig }
  | { type: ActionType.UPDATE_CONFIG_FAILURE; error: Error }

//
//  loadConfig()
//

export const loadConfig = (): StreamingAction => ({ type: ActionType.LOAD_CONFIG_REQUEST })

export const loadConfigSuccess = (config: StreamingConfig): StreamingAction => ({
  type: ActionType.LOAD_CONFIG_SUCCESS,
  config,
})

export const loadConfigFailure = (error: Error): StreamingAction => ({
  type: ActionType.LOAD_CONFIG_FAILURE,
  error,
})

//
//  createConfig()
//

export const createConfig = (config: StreamingConfig): StreamingAction => ({
  type: ActionType.CREATE_CONFIG_REQUEST,
  config,
})

export const createConfigSuccess = (config: StreamingConfig): StreamingAction => ({
  type: ActionType.CREATE_CONFIG_SUCCESS,
  config,
})

export const createConfigFailure = (error: Error): StreamingAction => ({
  type: ActionType.CREATE_CONFIG_FAILURE,
  error,
})

//
//  updateConfig()
//

export const updateConfig = (config: StreamingConfig): StreamingAction => ({
  type: ActionType.UPDATE_CONFIG_REQUEST,
  config,
})

export const updateConfigSuccess = (config: StreamingConfig): StreamingAction => ({
  type: ActionType.UPDATE_CONFIG_SUCCESS,
  config,
})

export const updateConfigFailure = (error: Error): StreamingAction => ({
  type: ActionType.UPDATE_CONFIG_FAILURE,
  error,
})
