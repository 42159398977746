import { Reducer } from 'redux'

import { RssFeed } from '@anews/types'

import { RssFeedActionType as ActionType, RssFeedAction } from '../actions/rssfeed-actions'

export interface RssFeedsListState {
  data: RssFeed[]
  loading: boolean
}

export interface RssFeedsState {
  list: RssFeedsListState
  editing?: RssFeed
  saving: boolean
  removing: boolean
}

export const initialState: RssFeedsState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(list: RssFeedsListState, action: RssFeedAction): RssFeedsListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.feeds,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.feed, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(feed => (feed.id === action.feed.id ? action.feed : feed)),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(feed => feed.id !== action.id) }

    default:
      return list
  }
}

const feedsReducer: Reducer<RssFeedsState, RssFeedAction> = (
  state = initialState,
  action,
): RssFeedsState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT:
      return { ...state, editing: action.feed }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.feed, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default feedsReducer
