import { call, put, takeLatest } from 'redux-saga/effects'

import { authLogApi as api } from '@anews/api'

import { AuthenticationLog, Page } from '@anews/types'

import {
  AuthenticationLogActionType as ActionType,
  AuthenticationLogActions,
  AuthenticationLogActionMap,
} from '../actions/authlog-actions'

import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { filterLogsSuccess, filterLogsFailure } = AuthenticationLogActions
const { notifyError } = NotificationActions

function* filterLogsSaga(action: AuthenticationLogActionMap<ActionType.FILTER_REQUEST>): Generator {
  try {
    const result = yield call(
      api.logs,
      action.filter,
      action.page,
      action.size,
      action.success,
      action.from,
      action.to,
    )
    yield put(filterLogsSuccess(result as Page<AuthenticationLog>))
  } catch (error) {
    yield put(filterLogsFailure(error as Error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.FILTER_REQUEST, filterLogsSaga)
  },
])
