/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import {
  DetailedHTMLProps,
  HTMLAttributes,
  MutableRefObject,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { ADMIN_CARD_PADDING, LAYOUT_CONTENT_MARGIN, TAB_PADDING } from './CompactTable'

const MAIN = LAYOUT_CONTENT_MARGIN + TAB_PADDING
const ADMIN = MAIN + ADMIN_CARD_PADDING

const overScrollCss = `
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      display: block;
    }
  }
`

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  admin?: boolean
  disabled?: boolean
  overScroll?: boolean
  fixedHeight?: string | number
  extraHeight?: number | (number | undefined)[]
  extraHeightRef?: MutableRefObject<any>
}

function ScrollContainer({
  admin,
  disabled,
  overScroll,
  fixedHeight,
  extraHeight,
  extraHeightRef,
  ...props
}: Props) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null) // usRef não dispara re-render
  const [fix, setFix] = useState(0)

  const { height } = useResizeDetector({ targetRef: extraHeightRef })

  const extraHeightSum = useMemo(
    () =>
      Array.isArray(extraHeight)
        ? extraHeight.reduce((total, curr) => (total || 0) + (curr || 0), 0)
        : extraHeight || height,
    [extraHeight, height],
  )

  useLayoutEffect(() => {
    if (!disabled && !fixedHeight && ref) {
      const { top } = ref.getBoundingClientRect()

      // top fica zero quando a aba é trocada, então deve ignorar
      if (top === 0) {
        return
      }

      const calc = top + (admin ? ADMIN : MAIN) + (extraHeightSum || 0)

      setFix(calc)
    }
  }, [admin, disabled, extraHeightSum, fixedHeight, ref])

  return (
    <div
      ref={divRef => setRef(divRef)}
      css={
        !disabled
          ? css`
              overflow: auto;
              max-height: ${fixedHeight || `calc(100vh - ${fix}px)`};
              ${overScroll ? overScrollCss : undefined}
            `
          : overScroll
          ? css`
              ${overScrollCss}
            `
          : undefined
      }
      {...props}
    />
  )
}

export default ScrollContainer
