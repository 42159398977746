import { BranchConfig } from '@anews/types'

export enum BranchActionType {
  LOAD_CONFIG_REQUEST = '@branch/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@branch/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@branch/LOAD_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@branch/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@branch/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@branch/UPDATE_CONFIG_FAILURE',
}

export type BranchActionMap<T extends BranchActionType> = {
  type: T
} & BranchAction

export type BranchAction = ReturnType<typeof BranchActions[keyof typeof BranchActions]>

export const BranchActions = {
  //
  //  loadConfig()
  //
  loadConfig: () => <const>{ type: BranchActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: BranchConfig) =>
    <const>{ type: BranchActionType.LOAD_CONFIG_SUCCESS, config },

  loadConfigFailure: (error: Error) => <const>{ type: BranchActionType.LOAD_CONFIG_FAILURE, error },

  //
  //  updateConfig()
  //
  updateConfig: (config: BranchConfig) =>
    <const>{ type: BranchActionType.UPDATE_CONFIG_REQUEST, config },

  updateConfigSuccess: (config: BranchConfig) =>
    <const>{ type: BranchActionType.UPDATE_CONFIG_SUCCESS, config },

  updateConfigFailure: (error: Error) =>
    <const>{ type: BranchActionType.UPDATE_CONFIG_FAILURE, error },
}
