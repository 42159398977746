export var EntityType;
(function (EntityType) {
    EntityType[EntityType["ASSIGNMENT"] = 0] = "ASSIGNMENT";
    EntityType[EntityType["ASSIGNMENT_PROPOSAL"] = 1] = "ASSIGNMENT_PROPOSAL";
    EntityType[EntityType["CONTACT"] = 2] = "CONTACT";
    EntityType[EntityType["DOCUMENT"] = 3] = "DOCUMENT";
    EntityType[EntityType["FACEBOOK_POST"] = 4] = "FACEBOOK_POST";
    EntityType[EntityType["POST"] = 5] = "POST";
    EntityType[EntityType["REPORT"] = 6] = "REPORT";
    EntityType[EntityType["REPORTAGE"] = 7] = "REPORTAGE";
    EntityType[EntityType["ROUND"] = 8] = "ROUND";
    EntityType[EntityType["RSS_ITEM"] = 9] = "RSS_ITEM";
    EntityType[EntityType["STORY"] = 10] = "STORY";
    EntityType[EntityType["TWEET"] = 11] = "TWEET";
})(EntityType || (EntityType = {}));
export var SearchMode;
(function (SearchMode) {
    // Aproximação
    SearchMode["FUZZY"] = "FUZZY";
    // Deve conter todos os termos independente da ordem
    SearchMode["MUST"] = "MUST";
    // Deve conter os termos na ordem
    SearchMode["PHRASE"] = "PHRASE";
    // Deve conter algum dos termos
    SearchMode["SHOULD"] = "SHOULD";
})(SearchMode || (SearchMode = {}));
