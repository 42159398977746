import { call, put, takeLatest } from 'redux-saga/effects'

import { rssFeedApi as api } from '@anews/api'
import { RssFeed } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  RssFeedActions,
  RssFeedActionMap as ActionMap,
  RssFeedActionType as ActionType,
} from '../actions/rssfeed-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

const {
  listRssFeedsSuccess,
  listRssFeedsFailure,
  createRssFeedSuccess,
  createRssFeedFailure,
  updateRssFeedSuccess,
  updateRssFeedFailure,
  removeRssFeedSuccess,
  removeRssFeedFailure,
} = RssFeedActions

/* Watchers */

function* listRssFeedsSaga(): Generator {
  try {
    const feeds = yield call(api.list)
    yield put(listRssFeedsSuccess(feeds as RssFeed[]))
  } catch (error) {
    yield put(listRssFeedsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createRssFeedSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const feed = yield call(api.create, action.feed)
    yield put(createRssFeedSuccess(feed as RssFeed))
  } catch (error) {
    yield put(createRssFeedFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:createFailed')} ${i18n.t('agency:invalidFeed')}`,
        error,
      }),
    )
  }
}

function* updateRssFeedSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const feed = yield call(api.update, action.feed)
    yield put(updateRssFeedSuccess(feed as RssFeed))
  } catch (error) {
    yield put(updateRssFeedFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:updateFailed')} ${i18n.t('agency:invalidFeed')}`,
        error,
      }),
    )
  }
}

function* removeRssFeedSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeRssFeedSuccess(action.id))
  } catch (error) {
    yield put(removeRssFeedFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listRssFeedsSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createRssFeedSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateRssFeedSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeRssFeedSaga)
  },
])
