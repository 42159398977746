import { useEffect, useRef } from 'react';
/*
 * Executa uma função uma única vez, não importa se mudar os parâmetros ou até
 * mesmo a própria função.
 */
export default function useOnce(callback, params) {
    if (params === void 0) { params = []; }
    var callbackRef = useRef(callback);
    var paramsRef = useRef(params);
    useEffect(function () {
        callbackRef.current(paramsRef.current);
    }, []);
}
