import { call, put, takeLatest } from 'redux-saga/effects'

import { wordpressApi as api } from '@anews/api'
import { WordpressConfig } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  listConfigsSuccess,
  listConfigsFailure,
  EditConfigAction,
  editConfigSuccess,
  editConfigFailure,
  UpdateWordpressConfigAction,
  updateConfigSuccess,
  updateConfigFailure,
  CreateWordpressConfigAction,
  createConfigSuccess,
  createConfigFailure,
  RemoveConfigAction,
  removeConfigSuccess,
  removeConfigFailure,
} from '../actions/wordpress-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listConfigsSaga(): Generator {
  try {
    const configs = yield call(api.listConfigs)
    yield put(listConfigsSuccess(configs as WordpressConfig[]))
  } catch (error) {
    yield put(listConfigsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* editConfigSaga(action: EditConfigAction): Generator {
  try {
    const config = yield call(api.loadConfig, action.id)
    yield put(editConfigSuccess(config as WordpressConfig))
  } catch (error) {
    yield put(editConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: CreateWordpressConfigAction): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as WordpressConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: UpdateWordpressConfigAction): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as WordpressConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeConfigSaga(action: RemoveConfigAction): Generator {
  try {
    yield call(api.remove, action.ids)
    yield put(removeConfigSuccess(action.ids))
  } catch (error) {
    yield put(removeConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_CONFIGS_REQUEST, listConfigsSaga)
  },
  function* () {
    yield takeLatest(ActionType.EDIT_CONFIG_REQUEST, editConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_CONFIG_REQUEST, removeConfigSaga)
  },
])
