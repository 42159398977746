import { StorageFile } from '@anews/types'

export enum StorageFileActionType {
  LIST_REQUEST = '@storageFile/LIST_REQUEST',
  LIST_SUCCESS = '@storageFile/LIST_SUCCESS',
  LIST_FAILURE = '@storageFile/LIST_FAILURE',
}

export type StorageFileActonMap<T extends StorageFileActionType> = { type: T } & StorageFileAction

export type StorageFileAction = ReturnType<
  typeof StorageFileActions[keyof typeof StorageFileActions]
>

export const StorageFileActions = {
  //
  // listStorageFiles(
  //

  listStorageFiles: () => <const>{ type: StorageFileActionType.LIST_REQUEST },

  listStorageFileSuccess: (storageFile: StorageFile[]) =>
    <const>{ type: StorageFileActionType.LIST_SUCCESS, storageFile },

  listStorageFileFailure: (error: Error) =>
    <const>{ type: StorageFileActionType.LIST_FAILURE, error },
}
