import { CustomMetadata } from '@anews/types'

export enum CustomMetadataActionType {
  // listMetadata()
  LIST_REQUEST = '@custom-metadata/LIST_REQUEST',
  LIST_SUCCESS = '@custom-metadata/LIST_SUCCESS',
  LIST_FAILURE = '@custom-metadata/LIST_FAILURE',
  // createMetadata()
  CREATE_REQUEST = '@custom-metadata/CREATE_REQUEST',
  CREATE_SUCCESS = '@custom-metadata/CREATE_SUCCESS',
  CREATE_FAILURE = '@custom-metadata/CREATE_FAILURE',
  // updateMetadata()
  UPDATE_REQUEST = '@custom-metadata/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@custom-metadata/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@custom-metadata/UPDATE_FAILURE',
  // removeMetadata()
  REMOVE_REQUEST = '@custom-metadata/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@custom-metadata/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@custom-metadata/REMOVE_FAILURE',
  // websocket
  WS_CREATE = '@custom-metadata/WS_CREATE',
  WS_DELETE = '@custom-metadata/WS_DELETE',
  WS_UPDATE = '@custom-metadata/WS_UPDATE',
}

export type CustomMetadataActionMap<T extends CustomMetadataActionType> = {
  type: T
} & CustomMetadataAction

export type CustomMetadataAction = ReturnType<
  typeof CustomMetadataActions[keyof typeof CustomMetadataActions]
>

export const CustomMetadataActions = {
  //
  // listMetadata()
  //

  listMetadata: () => ({ type: CustomMetadataActionType.LIST_REQUEST } as const),
  listMetadataSuccess: (data: CustomMetadata[]) =>
    ({ type: CustomMetadataActionType.LIST_SUCCESS, data } as const),
  listMetadataFailure: (error: unknown) =>
    ({ type: CustomMetadataActionType.LIST_FAILURE, error } as const),

  //
  // createMetadata()
  //

  createMetadata: (data: Partial<CustomMetadata>) =>
    ({ type: CustomMetadataActionType.CREATE_REQUEST, data } as const),
  createMetadataSuccess: (data: CustomMetadata) =>
    ({ type: CustomMetadataActionType.CREATE_SUCCESS, data } as const),
  createMetadataFailure: (error: unknown) =>
    ({ type: CustomMetadataActionType.CREATE_FAILURE, error } as const),

  //
  // updateMetadata()
  //

  updateMetadata: (data: CustomMetadata) =>
    ({ type: CustomMetadataActionType.UPDATE_REQUEST, data } as const),
  updateMetadataSuccess: (data: CustomMetadata) =>
    ({ type: CustomMetadataActionType.UPDATE_SUCCESS, data } as const),
  updateMetadataFailure: (error: unknown) =>
    ({ type: CustomMetadataActionType.UPDATE_FAILURE, error } as const),

  //
  // removeMetadata()
  //

  removeMetadata: (id: number) => ({ type: CustomMetadataActionType.REMOVE_REQUEST, id } as const),
  removeMetadataSuccess: (id: number) =>
    ({ type: CustomMetadataActionType.REMOVE_SUCCESS, id } as const),
  removeMetadataFailure: (error: unknown) =>
    ({ type: CustomMetadataActionType.REMOVE_FAILURE, error } as const),

  //
  // websocket
  //

  wsCreateMetadata: (data: CustomMetadata) =>
    ({ type: CustomMetadataActionType.WS_CREATE, data } as const),
  wsUpdateMetadata: (data: CustomMetadata) =>
    ({ type: CustomMetadataActionType.WS_UPDATE, data } as const),
  wsDeleteMetadata: (id: number) => ({ type: CustomMetadataActionType.WS_DELETE, id } as const),
}
