import { Institution } from '@anews/types'

export enum ActionType {
  NEW = '@round_institution/NEW',
  EDIT = '@round_institution/EDIT',
  LIST_REQUEST = '@round_institution/LIST_REQUEST',
  LIST_SUCCESS = '@round_institution/LIST_SUCCESS',
  LIST_FAILURE = '@round_institution/LIST_FAILURE',
  CREATE_REQUEST = '@round_institution/CREATE_REQUEST',
  CREATE_SUCCESS = '@round_institution/CREATE_SUCCESS',
  CREATE_FAILURE = '@round_institution/CREATE_FAILURE',
  UPDATE_REQUEST = '@round_institution/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@round_institution/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@round_institution/UPDATE_FAILURE',
  REMOVE_REQUEST = '@round_institution/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@round_institution/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@round_institution/REMOVE_FAILURE',
}

export interface CreateInstitutionAction {
  type: ActionType.CREATE_REQUEST
  institution: Institution
}

export interface UpdateInstitutionAction {
  type: ActionType.UPDATE_REQUEST
  institution: Institution
}

export interface RemoveInstitutionAction {
  type: ActionType.REMOVE_REQUEST
  id: number
}

export type InstitutionAction =
  /* newInstitution() */
  | { type: ActionType.NEW; institution?: Institution }
  /* listInstitutions() */
  | { type: ActionType.LIST_REQUEST }
  | { type: ActionType.LIST_SUCCESS; institutions: Institution[] }
  | { type: ActionType.LIST_FAILURE; error: Error }
  /* editInstitution() */
  | { type: ActionType.EDIT; institution: Institution }
  /* createInstitution() */
  | CreateInstitutionAction
  | { type: ActionType.CREATE_SUCCESS; institution: Institution }
  | { type: ActionType.CREATE_FAILURE; error: Error }
  /* updateInstitution() */
  | UpdateInstitutionAction
  | { type: ActionType.UPDATE_SUCCESS; institution: Institution }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* removeInstitution() */
  | RemoveInstitutionAction
  | { type: ActionType.REMOVE_SUCCESS; id: number }
  | { type: ActionType.REMOVE_FAILURE; error: Error }

//
//	newInstitution()
//

export const newInstitution = (institution?: Institution): InstitutionAction => ({
  type: ActionType.NEW,
  institution,
})

//
//	listInstitutions()
//

export const listInstitutions = (): InstitutionAction => ({ type: ActionType.LIST_REQUEST })

export const listInstitutionsSuccess = (institutions: Institution[]): InstitutionAction => ({
  type: ActionType.LIST_SUCCESS,
  institutions,
})

export const listInstitutionsFailure = (error: Error): InstitutionAction => ({
  type: ActionType.LIST_FAILURE,
  error,
})

//
//	editInstitution()
//

export const editInstitution = (institution: Institution): InstitutionAction => ({
  type: ActionType.EDIT,
  institution,
})

//
//	createInstitution()
//

export const createInstitution = (institution: Institution): InstitutionAction => ({
  type: ActionType.CREATE_REQUEST,
  institution,
})

export const createInstitutionSuccess = (institution: Institution): InstitutionAction => ({
  type: ActionType.CREATE_SUCCESS,
  institution,
})

export const createInstitutionFailure = (error: Error): InstitutionAction => ({
  type: ActionType.CREATE_FAILURE,
  error,
})

//
//	updateInstitution()
//

export const updateInstitution = (institution: Institution): InstitutionAction => ({
  type: ActionType.UPDATE_REQUEST,
  institution,
})

export const updateInstitutionSuccess = (institution: Institution): InstitutionAction => ({
  type: ActionType.UPDATE_SUCCESS,
  institution,
})

export const updateInstitutionFailure = (error: Error): InstitutionAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//	removeInstitution()
//

export const removeInstitution = (id: number): InstitutionAction => ({
  type: ActionType.REMOVE_REQUEST,
  id,
})

export const removeInstitutionSuccess = (id: number): InstitutionAction => ({
  type: ActionType.REMOVE_SUCCESS,
  id,
})

export const removeInstitutionFailure = (error: Error): InstitutionAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})
