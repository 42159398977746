import { User, Page } from '@anews/types'

import { PAGE_SIZE } from '../../consts/pagination'
import { UserCategory } from '../reducers/users-reducer'

export enum UserActionType {
  NEW = '@user/NEW',
  LIST_BY_CATEGORY_REQUEST = '@user/LIST_BY_CATEGORY_REQUEST',
  LIST_BY_CATEGORY_SUCCESS = '@user/LIST_BY_CATEGORY_SUCCESS',
  LIST_BY_CATEGORY_FAILURE = '@user/LIST_BY_CATEGORY_FAILURE',
  FILTER_REQUEST = '@user/FILTER_REQUEST',
  FILTER_SUCCESS = '@user/FILTER_SUCCESS',
  FILTER_FAILURE = '@user/FILTER_FAILURE',
  EDIT_REQUEST = '@user/EDIT_REQUEST',
  EDIT_SUCCESS = '@user/EDIT_SUCCESS',
  EDIT_FAILURE = '@user/EDIT_FAILURE',
  CREATE_REQUEST = '@user/CREATE_REQUEST',
  CREATE_SUCCESS = '@user/CREATE_SUCCESS',
  CREATE_FAILURE = '@user/CREATE_FAILURE',
  UPDATE_REQUEST = '@user/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@user/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@user/UPDATE_FAILURE',
  REMOVE_REQUEST = '@user/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@user/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@user/REMOVE_FAILURE',
  RESTORE_REQUEST = '@user/RESTORE_REQUEST',
  RESTORE_SUCCESS = '@user/RESTORE_SUCCESS',
  RESTORE_FAILURE = '@user/RESTORE_FAILURE',
  RESET_PASSWORD_REQUEST = '@user/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = '@user/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = '@user/RESET_PASSWORD_FAILURE',
  RESET_2FA_PASSWORD_REQUEST = '@user/RESET_2FA_REQUEST',
  RESET_2FA_PASSWORD_SUCCESS = '@user/RESET_2FA_SUCCESS',
  RESET_2FA_PASSWORD_FAILURE = '@user/RESET_2FA_FAILURE',
  WS_DELETE = '@user/WS_DELETE',
}

//
//  Helper para actions que carregam lista de usuários
//

const usersRequestAction = (category: UserCategory) => () =>
  <const>{ type: UserActionType.LIST_BY_CATEGORY_REQUEST, category }

//
//  UserActions
//

export type UserActionMap<T extends UserActionType> = { type: T } & UserAction

export type UserAction = ReturnType<typeof UserActions[keyof typeof UserActions]>

export const UserActions = {
  //
  //	newUser()
  //

  newUser: (user?: User) => <const>{ type: UserActionType.NEW, user },

  //
  //	Actions que listam usuários. Elas utilizam a mesma action para success e failure
  //

  listAllUsers: usersRequestAction('all'),
  listActiveUsers: usersRequestAction('active'),
  listEditors: usersRequestAction('editors'),
  listImageEditors: usersRequestAction('imageEditors'),
  listProducers: usersRequestAction('producers'),
  listReporters: usersRequestAction('reporters'),

  listUserByCategorySuccess: (users: User[], category: UserCategory) =>
    <const>{ type: UserActionType.LIST_BY_CATEGORY_SUCCESS, category, users },
  listUserByCategoryFailure: (error: Error, category: UserCategory) =>
    <const>{ type: UserActionType.LIST_BY_CATEGORY_FAILURE, category, error },

  //
  //	filterUsers()
  //

  filterUsers: (filter: string, activeOnly: boolean, page: number = 0, size: number = PAGE_SIZE) =>
    <const>{ type: UserActionType.FILTER_REQUEST, filter, activeOnly, page, size },
  filterUsersSuccess: (result: Page<User>) =>
    <const>{ type: UserActionType.FILTER_SUCCESS, result },
  filterUsersFailure: (error: Error) => <const>{ type: UserActionType.FILTER_FAILURE, error },

  //
  //	editUser()
  //

  editUser: (id: number) => <const>{ type: UserActionType.EDIT_REQUEST, id },
  editUserSuccess: (user: User) => <const>{ type: UserActionType.EDIT_SUCCESS, user },
  editUserFailure: (error: Error) => <const>{ type: UserActionType.EDIT_FAILURE, error },

  //
  //	createUser()
  //

  createUser: (user: User) => <const>{ type: UserActionType.CREATE_REQUEST, user },
  createUserSuccess: (user: User) => <const>{ type: UserActionType.CREATE_SUCCESS, user },
  createUserFailure: (error: Error) => <const>{ type: UserActionType.CREATE_FAILURE, error },

  //
  //	updateUser()
  //

  updateUser: (user: User) => <const>{ type: UserActionType.UPDATE_REQUEST, user },
  updateUserSuccess: (user: User) => <const>{ type: UserActionType.UPDATE_SUCCESS, user },
  updateUserFailure: (error: Error) => <const>{ type: UserActionType.UPDATE_FAILURE, error },

  //
  //	removeUser()
  //

  removeUser: (ids: number[]) => <const>{ type: UserActionType.REMOVE_REQUEST, ids },
  removeUserSuccess: (ids: number[]) => <const>{ type: UserActionType.REMOVE_SUCCESS, ids },
  removeUserFailure: (error: Error) => <const>{ type: UserActionType.REMOVE_FAILURE, error },

  //
  //	restoreUser()
  //

  restoreUser: (id: number) => <const>{ type: UserActionType.RESTORE_REQUEST, id },
  restoreUserSuccess: (user: User) => <const>{ type: UserActionType.RESTORE_SUCCESS, user },
  restoreUserFailure: (error: Error) => <const>{ type: UserActionType.RESTORE_FAILURE, error },

  //
  //	resetPassword()
  //

  resetPassword: (id: number) => <const>{ type: UserActionType.RESET_PASSWORD_REQUEST, id },
  resetPasswordSuccess: (user: User) =>
    <const>{ type: UserActionType.RESET_PASSWORD_SUCCESS, user },
  resetPasswordFailure: (error: Error) =>
    <const>{ type: UserActionType.RESET_PASSWORD_FAILURE, error },

  //
  // reset2FaPassword()
  //
  reset2FaPassword: (id: number) => <const>{ type: UserActionType.RESET_2FA_PASSWORD_REQUEST, id },
  reset2FaPasswordSuccess: (user: User) =>
    <const>{ type: UserActionType.RESET_2FA_PASSWORD_SUCCESS, user },
  reset2FaPasswordFailure: (error: Error) =>
    <const>{ type: UserActionType.RESET_2FA_PASSWORD_FAILURE, error },

  //
  //	resetPassword()
  //

  wsDeleteUser: (id: number) => <const>{ type: UserActionType.WS_DELETE, id },
}
