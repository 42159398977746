import { Classification } from '@anews/types'

import { RootState } from '../reducers'

import { createMapSelector } from './helpers'

const getClassifications = (state: RootState) => state.classifications.list.data

export const classificationsMap = createMapSelector<Classification, string>(
  getClassifications,
  'id',
  'name',
)
