import React, {
  PureComponent,
  cloneElement,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  ReactElement,
  ReactNode,
  FC,
} from 'react'

import Form, { FormInstance } from 'antd/es/form'
import Input from 'antd/es/input'
import InputNumber from 'antd/es/input-number'
import Table, { ColumnType, TableProps } from 'antd/es/table'

import { useTranslation } from '../../i18n'

import TimeInput from './TimeInput'

import './EditableTable.css'

/*
 * Baseado no exemplo "Editable Cells" da documentação do Ant Design
 * https://ant.design/components/table/#components-table-demo-edit-cell
 */

const EditableContext = React.createContext<FormInstance | undefined>(undefined)

interface EditableRowProps {
  index: number
}

const EditableRow: FC<EditableRowProps> = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

interface Record {
  [prop: string]: any
}

interface EditableCellProps<T extends Record> {
  title: ReactNode
  editable: boolean
  children: ReactNode
  dataIndex: string
  record: T
  handleSave: (record: T, previous: T) => void
  // Added
  required?: boolean
  fieldRender: (record: T) => ReactElement
  type?: 'number' | 'string' | 'time'
  timeFormat?: 'HH:mm' | 'HH:mm:ss' | 'mm:ss'
}

function EditableCell<T extends Record>({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  // Added
  required,
  fieldRender,
  type,
  timeFormat,
  ...restProps
}: EditableCellProps<T>) {
  const [editing, setEditing] = useState(false)
  const input = useRef<any>(null)
  const form = useContext(EditableContext)

  const { t } = useTranslation()

  useEffect(() => {
    if (editing && input && input.current) {
      input.current.focus()
    }
  }, [input, editing])

  const toggleEdit = useCallback(() => {
    setEditing(!editing)
    form!.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }, [dataIndex, editing, form, record])

  const save = useCallback(
    async e => {
      try {
        const values = await form!.validateFields()
        toggleEdit()
        handleSave({ ...record, ...values }, record)
      } catch (err) {
        console.warn(err)
      }
    },
    [form, handleSave, record, toggleEdit],
  )

  let childNode

  if (editable) {
    if (editing) {
      let el // : DetailedReactHTMLElement<any, any>

      if (fieldRender) {
        const origEl = fieldRender(record)
        const origProps = origEl.props
        const props = {
          ref: input,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            if (origProps.onBlur) {
              origProps.onBlur(e)
            }
            save(e)
          },
        }
        el = cloneElement(origEl, props)
      } else {
        const Editor = type === 'number' ? InputNumber : Input
        el =
          type === 'time' ? (
            <TimeInput
              format={timeFormat || 'HH:mm'}
              style={{ margin: 0 }}
              ref={input}
              onPressEnter={save}
              onBlur={save}
              size="small"
            />
          ) : (
            <Editor
              style={{ margin: 0 }}
              ref={input}
              onPressEnter={save}
              onBlur={save}
              size="small"
            />
          )
      }

      childNode = (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0, padding: 0 }}
          rules={[{ required: !!required, message: t('validation:required') }]}
        >
          {el}
        </Form.Item>
      )
    } else {
      childNode = (
        <div
          className="editable-cell-value-wrap"
          role="presentation"
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      )
    }
  } else {
    childNode = children
  }

  return <td {...restProps}>{childNode}</td>
}

export interface EditableColumnType<T> extends ColumnType<T> {
  editable?: boolean
  required?: boolean
  fieldRender?: (record: T) => ReactElement
  type?: 'number' | 'string' | 'time'
  timeFormat?: 'HH:mm' | 'HH:mm:ss' | 'mm:ss'
}

export interface EditableTableProps<T> extends TableProps<T> {
  columns: EditableColumnType<T>[]
  onSave: (record: T, previous: T) => void
}

// Tem ser um Class Component porque como Functional Component deu problema com o getFieldDecorator do AntD
class EditableTable<T extends object = any> extends PureComponent<EditableTableProps<T>> {
  private components: any

  constructor(props: EditableTableProps<T>) {
    super(props)

    this.components = {
      body: {
        row: memo(EditableRow),
        cell: memo(EditableCell),
      },
    }
  }

  render() {
    const { columns, onSave, ...props } = this.props

    const newColumns = columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record: T) => ({
          record,
          dataIndex: col.dataIndex,
          editable: col.editable,
          required: col.required,
          fieldRender: col.fieldRender,
          title: col.title,
          type: col.type,
          handleSave: onSave,
        }),
      } as EditableColumnType<T>
    })

    return (
      <Table
        columns={newColumns}
        components={this.components}
        rowClassName="editable-row"
        {...props}
      />
    )
  }
}

export default EditableTable
