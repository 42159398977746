import { Reducer } from 'redux'

import { NominatimConfig } from '@anews/types'

import { NominatimAction, NominatimActionType } from '../actions'

import { ConfigState } from './types'

export interface NominatimState {
  config: ConfigState<NominatimConfig>
}

export const initialState: NominatimState = {
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
}

function configReducer(
  config: ConfigState<NominatimConfig>,
  action: NominatimAction,
): ConfigState<NominatimConfig> {
  switch (action.type) {
    case NominatimActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case NominatimActionType.UPDATE_CONFIG_REQUEST:
    case NominatimActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case NominatimActionType.LOAD_CONFIG_FAILURE:
    case NominatimActionType.CREATE_CONFIG_FAILURE:
    case NominatimActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case NominatimActionType.LOAD_CONFIG_SUCCESS:
    case NominatimActionType.CREATE_CONFIG_SUCCESS:
    case NominatimActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

const nominatimReducer: Reducer<NominatimState, NominatimAction> = (
  state = initialState,
  action,
): NominatimState => {
  switch (action.type) {
    case NominatimActionType.LOAD_CONFIG_REQUEST:
    case NominatimActionType.LOAD_CONFIG_SUCCESS:
    case NominatimActionType.LOAD_CONFIG_FAILURE:
    case NominatimActionType.CREATE_CONFIG_REQUEST:
    case NominatimActionType.CREATE_CONFIG_SUCCESS:
    case NominatimActionType.CREATE_CONFIG_FAILURE:
    case NominatimActionType.UPDATE_CONFIG_REQUEST:
    case NominatimActionType.UPDATE_CONFIG_SUCCESS:
    case NominatimActionType.UPDATE_CONFIG_FAILURE:
      return { ...state, config: configReducer(state.config, action) }

    default:
      return state
  }
}

export default nominatimReducer
