import React, { memo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import AntIcon from '@ant-design/icons'
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon'

type IconProps = IconComponentProps & React.RefAttributes<HTMLSpanElement>

export interface DoubleIconProps extends IconProps {
  color?: string
  icon: IconProp
  customIcon?: JSX.Element
}

function DoubleIcon({ color, icon, customIcon, ...props }: DoubleIconProps) {
  return (
    <AntIcon
      component={
        customIcon
          ? () => customIcon
          : () => <FontAwesomeIcon icon={icon} style={{ verticalAlign: 0 }} color={color} />
      }
      {...props}
    />
  )
}

export default memo(DoubleIcon)
