import { Reducer } from 'redux'

import { ChecklistResource } from '@anews/types'

import { ChecklistResourceActionType as ActionType, ChecklistResourceAction } from '../actions'

export interface ChecklistResourcesListState {
  data: ChecklistResource[]
  loading: boolean
}

export interface ChecklistResourcesState {
  list: ChecklistResourcesListState
}

export const defaultState = {
  list: {
    data: [],
    loading: true,
  },
}

function listReducer(
  list: ChecklistResourcesListState,
  action: ChecklistResourceAction,
): ChecklistResourcesListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }
    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }
    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.resources,
        loading: false,
      }

    default:
      return list
  }
}

const resourcesReducer: Reducer<ChecklistResourcesState, ChecklistResourceAction> = (
  state = defaultState,
  action,
): ChecklistResourcesState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    default:
      return state
  }
}

export default resourcesReducer
