import { TwitterUser } from '@anews/types'

export enum TwitterUserActionType {
  NEW = '@twitter_user/NEW',
  EDIT = '@twitter_user/EDIT',
  LIST_REQUEST = '@twitter_user/LIST_REQUEST',
  LIST_SUCCESS = '@twitter_user/LIST_SUCCESS',
  LIST_FAILURE = '@twitter_user/LIST_FAILURE',
  CREATE_REQUEST = '@twitter_user/CREATE_REQUEST',
  CREATE_SUCCESS = '@twitter_user/CREATE_SUCCESS',
  CREATE_FAILURE = '@twitter_user/CREATE_FAILURE',
  UPDATE_REQUEST = '@twitter_user/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@twitter_user/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@twitter_user/UPDATE_FAILURE',
  REMOVE_REQUEST = '@twitter_user/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@twitter_user/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@twitter_user/REMOVE_FAILURE',
}

export type TwitterUserActionMap<T extends TwitterUserActionType> = { type: T } & TwitterUserAction

export type TwitterUserAction = ReturnType<
  typeof TwitterUserActions[keyof typeof TwitterUserActions]
>

export const TwitterUserActions = {
  //
  //	newTwitterUser()
  //

  newTwitterUser: (user?: TwitterUser) => <const>{ type: TwitterUserActionType.NEW, user },

  //
  //	listTwitterUsers()
  //

  listTwitterUsers: () => <const>{ type: TwitterUserActionType.LIST_REQUEST },

  listTwitterUsersSuccess: (users: TwitterUser[]) =>
    <const>{ type: TwitterUserActionType.LIST_SUCCESS, users },

  listTwitterUsersFailure: (error: Error) =>
    <const>{ type: TwitterUserActionType.LIST_FAILURE, error },

  //
  //	editTwitterUser()
  //

  editTwitterUser: (user: TwitterUser) => <const>{ type: TwitterUserActionType.EDIT, user },

  //
  //	createTwitterUser()
  //

  createTwitterUser: (user: TwitterUser) =>
    <const>{ type: TwitterUserActionType.CREATE_REQUEST, user },

  createTwitterUserSuccess: (user: TwitterUser) =>
    <const>{ type: TwitterUserActionType.CREATE_SUCCESS, user },

  createTwitterUserFailure: (error: Error) =>
    <const>{ type: TwitterUserActionType.CREATE_FAILURE, error },

  //
  //	updateTwitterUser()
  //

  updateTwitterUser: (user: TwitterUser) =>
    <const>{ type: TwitterUserActionType.UPDATE_REQUEST, user },

  updateTwitterUserSuccess: (user: TwitterUser) =>
    <const>{ type: TwitterUserActionType.UPDATE_SUCCESS, user },

  updateTwitterUserFailure: (error: Error) =>
    <const>{ type: TwitterUserActionType.UPDATE_FAILURE, error },

  //
  //	removeTwitterUser()
  //

  removeTwitterUser: (id: number) => <const>{ type: TwitterUserActionType.REMOVE_REQUEST, id },

  removeTwitterUserSuccess: (id: number) =>
    <const>{ type: TwitterUserActionType.REMOVE_SUCCESS, id },

  removeTwitterUserFailure: (error: Error) =>
    <const>{ type: TwitterUserActionType.REMOVE_FAILURE, error },
}
