import { Reducer } from 'redux'

import { Post } from '@anews/types'

import { ActionType, PostAction } from '../actions/post-actions'

export interface PostsPageState {
  data: Post[]
  number: number
  size: number
  total: number
  from?: string
  to?: string
  filter?: string
  loading: boolean
}

export interface PostsState {
  page: PostsPageState
  editing?: Post
  viewing?: Post
  saving: boolean
  disabling: boolean
}

export const defaultState: PostsState = {
  page: {
    data: [],
    number: 0,
    size: 0,
    total: 0,
    from: undefined,
    to: undefined,
    filter: '',
    loading: true,
  },
  editing: undefined,
  viewing: undefined,
  saving: false,
  disabling: false,
}

function pageReducer(page: PostsPageState, action: PostAction): PostsPageState {
  switch (action.type) {
    case ActionType.FILTER_REQUEST:
      return { ...page, from: action.from, to: action.to, filter: action.filter, loading: true }

    case ActionType.FILTER_FAILURE:
      return { ...page, loading: false }

    case ActionType.FILTER_SUCCESS:
      return {
        ...page,
        data: action.result.data,
        total: action.result.total,
        number: action.result.page,
        size: action.result.size,
        loading: false,
      }
    case ActionType.UPDATE_SUCCESS:
    case ActionType.WS_UPDATE:
      return {
        ...page,
        data: page.data.map(post => (post.id === action.post.id ? (action.post as Post) : post)),
      }

    case ActionType.CREATE_SUCCESS:
    case ActionType.CREATE_COPY_SUCCESS:
    case ActionType.WS_CREATE:
      // O filter serve para não duplicar (CREATE_SUCCESS x WS_CREATE)
      // Somente se for primeira pagina
      if (page.number === 0) {
        page.data = [action.post, ...page.data.filter(n => n.id !== action.post.id)]
      }
      return {
        ...page,
      }
    case ActionType.REMOVE_SUCCESS:
      return {
        ...page,
        data: page.data.filter(n => !action.ids.includes(n.id)),
      }

    case ActionType.WS_DELETE:
      return {
        ...page,
        data: page.data.filter(n => n.id !== action.id),
        total: page.total - 1, // Subtrai do total, tentando evitar erro de ultima pagina vazia
      }

    case ActionType.CREATE_COPY_REQUEST:
    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
    case ActionType.REMOVE_REQUEST:
      return { ...page, loading: true }
    default:
      return { ...page }
  }
}

const postReducer: Reducer<PostsState, PostAction> = (state = defaultState, action): PostsState => {
  switch (action.type) {
    case ActionType.FILTER_REQUEST:
    case ActionType.FILTER_FAILURE:
    case ActionType.FILTER_SUCCESS:
    case ActionType.WS_CREATE:
    case ActionType.WS_DELETE:
    case ActionType.WS_UPDATE:
      return { ...state, page: pageReducer(state.page, action) }

    case ActionType.UNLOAD:
      return { ...state, editing: undefined, viewing: undefined }

    case ActionType.NEW:
      return { ...state, editing: action.post }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.post, saving: true }

    case ActionType.LOAD_SUCCESS:
      if (action.edit) {
        return { ...state, editing: action.post }
      }
      return { ...state, viewing: action.post as Post }

    case ActionType.REMOVE_REQUEST:
      return { ...state, disabling: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, disabling: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_COPY_SUCCESS:
    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        page: pageReducer(state.page, action),
        disabling: false,
        saving: false,
      }

    default:
      return state
  }
}

export default postReducer
