import styled from '@emotion/styled'
import Button from 'antd/es/button'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Editor } from 'slate'
import { useFocused, useSlate } from 'slate-react'

import { BlockPicker } from 'react-color'

import { faBrush, faItalic, faUnderline } from '@fortawesome/pro-regular-svg-icons'
import { faBold } from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Tooltip } from 'antd/es'

import theme from '../../../theme'

import { richTextPalette, toggleFormat } from './RichTextEditor'
import { toggleRichTextComment } from './toggleRichComment'

interface ToolbarProps {
  visible: boolean
  position: number[]
}

const Rect = styled.div<ToolbarProps>`
  transition: opacity 0.3s;
  position: absolute;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  top: ${({ position }) => position[0]}px;
  left: ${({ position }) => position[1]}px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 2.5em;
`

const Toolbar = styled.div`
  box-shadow: 0px 2px 6px #00334455;
  border-radius: 4px;
  background-color: ${theme.backgrounds.light};
  padding: 0;
`

const StyledPicker = styled(BlockPicker)<{ visible: boolean }>`
  position: relative;
  top: 36px;
  left: 24px;
  transition: opacity 0.3s;
  ${({ visible }) => (!visible ? 'opacity: 0; pointer-events: none' : 'opacity: 1;')}
`

const BarButton = styled(Button)`
  &.ant-btn {
    border: 0;
    border-radius: 0 !important;
    background-color: transparent;
  }
  &.ant-btn:hover {
    background-color: ${theme.backgrounds.base};
  }
`

export default function HoveringToolBar() {
  const ref = useRef<HTMLDivElement>(null)
  const editor = useSlate()
  const inFocus = useFocused()
  const [barPosition, setBarPosition] = useState<number[]>([0, 0])
  const [visible, setVisible] = useState<boolean>(false)
  const [colorPicker, setColorPicker] = useState(false)
  const [chosenColor, setColor] = useState('#000')

  const palette = useMemo((): string[] => Object.values(richTextPalette.colorPicker), [])

  const showToolbar = useCallback(bar => {
    const domSelection = window.getSelection()
    const domRange = domSelection?.getRangeAt(0)
    const rect = domRange?.getBoundingClientRect()
    setVisible(true)
    setColorPicker(false)
    setBarPosition([
      (rect?.top || 0) + window.pageYOffset - bar.offsetHeight - 4,
      (rect?.left || 0) + window.pageXOffset - bar.offsetWidth / 2 + (rect ? rect.width / 2 : 0),
    ])
  }, [])

  useEffect(() => {
    const bar = ref.current
    if (!bar) return

    const { selection } = editor
    let delayMovement: any
    let delayOpen: any
    let preventClose: any
    if (!selection || !inFocus || Editor.string(editor, selection) === '') {
      setVisible(false)
      delayMovement = setTimeout(() => setBarPosition([0, 0]), 300)
    } else {
      preventClose = (e: MouseEvent) => e.preventDefault()
      bar.addEventListener('mousedown', preventClose)
      delayOpen = setTimeout(() => showToolbar(bar), 200)
    }
    return function cleanup() {
      clearTimeout(delayOpen)
      clearTimeout(delayMovement)
      bar.removeEventListener('mousedown', preventClose)
    }
  }, [editor, editor.selection, inFocus, showToolbar])

  return ReactDOM.createPortal(
    <Rect visible={visible} position={barPosition} ref={ref}>
      <Toolbar>
        <Tooltip title="Ctrl+B">
          <BarButton onClick={() => toggleFormat(editor, 'bold')}>
            <FontAwesomeIcon icon={faBold} />
          </BarButton>
        </Tooltip>
        <Tooltip title="Ctrl+I">
          <BarButton onClick={() => toggleFormat(editor, 'italic')}>
            <FontAwesomeIcon icon={faItalic} />
          </BarButton>
        </Tooltip>
        <Tooltip title="Ctrl+U">
          <BarButton onClick={() => toggleFormat(editor, 'underline')}>
            <FontAwesomeIcon icon={faUnderline} />
          </BarButton>
        </Tooltip>
        <BarButton onClick={() => setColorPicker(!colorPicker)}>
          <FontAwesomeIcon icon={faBrush} />
        </BarButton>
        <BarButton onClick={() => toggleRichTextComment(editor)}>[]</BarButton>
      </Toolbar>
      <StyledPicker
        color={chosenColor}
        onChangeComplete={color => {
          setColor(color.hex)
          toggleFormat(editor, 'colored', color.hex)
        }}
        visible={colorPicker}
        colors={palette}
      />
    </Rect>,

    document.body,
  )
}
