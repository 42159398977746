import { call, put, takeLatest } from 'redux-saga/effects'

import { contactGroupApi as api } from '@anews/api'

import { ContactGroup } from '@anews/types'

import { ActionType, listGroupsSuccess, listGroupsFailure } from '../actions/contactgroup-actions'
import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listGroupsSaga(): Generator {
  try {
    const groups = yield call(api.list)
    yield put(listGroupsSuccess(groups as ContactGroup[]))
  } catch (error) {
    yield put(listGroupsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listGroupsSaga)
  },
])
