import { Options, useHotkeys } from 'react-hotkeys-hook'
import { HotkeysEvent, KeyHandler } from 'hotkeys-js'

export enum Shortcuts {
  COPY = 'ctrl+c,command+c',
  CUT = 'ctrl+x,command+x',
  PASTE = 'ctrl+v,command+v',
  CLOSE = 'ctrl+alt+c, command+alt+c',
  // limpar selecionados
  ESC = 'escape',
  // Mover lauda selecionada para final do Stand By
  STORY_MOVE_TO_END = 'ctrl+shift+u,command+shit+u',
  RUNDOWN_TOGGLE_STATUS = 'space',
  RUNDOWN_TOGGLE_SELECTED_STATUS = 'ctrl+space',
  RUNDOWN_TOGGLE_SELECTED_STATUS_CASCADE = 'ctrl+shift+space',
}

export const useShortcut = (
  key: Shortcuts,
  callback: KeyHandler,
  deps: any[],
  options?: CustomOpts,
) =>
  useHotkeys(
    key,
    (keyboardEvent: KeyboardEvent, hotkeysEvent: HotkeysEvent) => {
      if (options?.disabled) return
      keyboardEvent.preventDefault()
      return callback(keyboardEvent, hotkeysEvent)
    },
    {
      ...options,
      filter: (event: KeyboardEvent) => {
        if (event.target) {
          const { tagName } = event.target as HTMLElement
          if (tagName === 'INPUT') {
            const { type } = event.target as HTMLInputWithTypes

            if (options?.customInputTypes?.includes(type)) {
              return true // Permite atalho
            }

            switch (type) {
              case 'checkbox':
              case 'radio':
              case 'submit':
              case 'button':
              case 'reset':
              case 'hidden':
                return true // Permite atalho
              default:
                return false // Bloqueia atalho
            }
          }
          return tagName !== 'SELECT'
        }
        return true
      },
    },
    [...deps, options?.disabled],
  )

type CustomOpts =
  | (Omit<Omit<Options, 'enableOnTags'>, 'filter'> & {
      disabled?: boolean
      customInputTypes?: string[]
    })
  | undefined

type HTMLInputWithTypes = HTMLInputElement & {
  type:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
}
