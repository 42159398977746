import { Reducer } from 'redux'

import { Vehicle } from '@anews/types'

import { VehicleActionType as ActionType, VehicleAction } from '../actions'

export interface VehiclesListState {
  data: Vehicle[]
  loading: boolean
}

export interface VehiclesState {
  list: VehiclesListState
}

export const initialState: VehiclesState = {
  list: {
    data: [],
    loading: true,
  },
}

function listReducer(list: VehiclesListState, action: VehicleAction): VehiclesListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.vehicles,
        loading: false,
      }

    default:
      return list
  }
}

const vehiclesReducer: Reducer<VehiclesState, VehicleAction> = (
  state = initialState,
  action,
): VehiclesState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    default:
      return state
  }
}

export default vehiclesReducer
