import { del, get, post, put } from './client';
var endpoint = '/api/rss/feeds';
var rssFeedApi = {
    list: function () {
        return get(endpoint);
    },
    create: function (feed) {
        return post(endpoint, feed);
    },
    update: function (feed) {
        return put("" + endpoint, feed);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default rssFeedApi;
