import { call, put, takeLatest } from 'redux-saga/effects'

import { twitterListApi as api } from '@anews/api'
import { TwitterList } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  TwitterListActions,
  TwitterListActionMap as ActionMap,
  TwitterListActionType as ActionType,
} from '../actions/twitterlist-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions
const {
  listTwitterListsSuccess,
  listTwitterListsFailure,
  createTwitterListSuccess,
  createTwitterListFailure,
  updateTwitterListSuccess,
  updateTwitterListFailure,
  removeTwitterListSuccess,
  removeTwitterListFailure,
} = TwitterListActions

/* Watchers */

function* listTwitterListsSaga(): Generator {
  try {
    const lists = yield call(api.listAll)
    yield put(listTwitterListsSuccess(lists as TwitterList[]))
  } catch (error) {
    yield put(listTwitterListsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createTwitterListSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const list = yield call(api.create, action.list)
    yield put(createTwitterListSuccess(list as TwitterList))
  } catch (error) {
    yield put(createTwitterListFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:createFailed')}`,
        error,
      }),
    )
  }
}

function* updateTwitterListSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const list = yield call(api.update, action.list)
    yield put(updateTwitterListSuccess(list as TwitterList))
  } catch (error) {
    yield put(updateTwitterListFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:updateFailed')}`,
        error,
      }),
    )
  }
}

function* removeTwitterListSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeTwitterListSuccess(action.id))
  } catch (error) {
    yield put(removeTwitterListFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listTwitterListsSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createTwitterListSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateTwitterListSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeTwitterListSaga)
  },
])
