import { TwitterList } from '@anews/types'

export enum TwitterListActionType {
  NEW = '@twitter_list/NEW',
  EDIT = '@twitter_list/EDIT',
  LIST_REQUEST = '@twitter_list/LIST_REQUEST',
  LIST_SUCCESS = '@twitter_list/LIST_SUCCESS',
  LIST_FAILURE = '@twitter_list/LIST_FAILURE',
  CREATE_REQUEST = '@twitter_list/CREATE_REQUEST',
  CREATE_SUCCESS = '@twitter_list/CREATE_SUCCESS',
  CREATE_FAILURE = '@twitter_list/CREATE_FAILURE',
  UPDATE_REQUEST = '@twitter_list/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@twitter_list/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@twitter_list/UPDATE_FAILURE',
  REMOVE_REQUEST = '@twitter_list/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@twitter_list/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@twitter_list/REMOVE_FAILURE',
}

export type TwitterListActionMap<T extends TwitterListActionType> = { type: T } & TwitterListAction

export type TwitterListAction = ReturnType<
  typeof TwitterListActions[keyof typeof TwitterListActions]
>

export const TwitterListActions = {
  //
  //	newTwitterList()
  //

  newTwitterList: (list?: TwitterList) => <const>{ type: TwitterListActionType.NEW, list },

  //
  //	listTwitterLists()
  //

  listTwitterLists: () => <const>{ type: TwitterListActionType.LIST_REQUEST },

  listTwitterListsSuccess: (lists: TwitterList[]) =>
    <const>{ type: TwitterListActionType.LIST_SUCCESS, lists },

  listTwitterListsFailure: (error: Error) =>
    <const>{ type: TwitterListActionType.LIST_FAILURE, error },

  //
  //	editTwitterList()
  //

  editTwitterList: (list: TwitterList) => <const>{ type: TwitterListActionType.EDIT, list },

  //
  //	createTwitterList()
  //

  createTwitterList: (list: TwitterList) =>
    <const>{ type: TwitterListActionType.CREATE_REQUEST, list },

  createTwitterListSuccess: (list: TwitterList) =>
    <const>{ type: TwitterListActionType.CREATE_SUCCESS, list },

  createTwitterListFailure: (error: Error) =>
    <const>{ type: TwitterListActionType.CREATE_FAILURE, error },

  //
  //	updateTwitterList()
  //

  updateTwitterList: (list: TwitterList) =>
    <const>{ type: TwitterListActionType.UPDATE_REQUEST, list },

  updateTwitterListSuccess: (list: TwitterList) =>
    <const>{ type: TwitterListActionType.UPDATE_SUCCESS, list },

  updateTwitterListFailure: (error: Error) =>
    <const>{ type: TwitterListActionType.UPDATE_FAILURE, error },

  //
  //	removeTwitterList()
  //

  removeTwitterList: (id: number) => <const>{ type: TwitterListActionType.REMOVE_REQUEST, id },

  removeTwitterListSuccess: (id: number) =>
    <const>{ type: TwitterListActionType.REMOVE_SUCCESS, id },

  removeTwitterListFailure: (error: Error) =>
    <const>{ type: TwitterListActionType.REMOVE_FAILURE, error },
}
