import { Editorial } from '@anews/types'

export enum ActionType {
  LIST_REQUEST = '@editorial/LIST_REQUEST',
  LIST_SUCCESS = '@editorial/LIST_SUCCESS',
  LIST_FAILURE = '@editorial/LIST_FAILURE',
}

export type EditorialAction =
  | { type: ActionType.LIST_REQUEST }
  | { type: ActionType.LIST_SUCCESS; editorials: Editorial[] }
  | { type: ActionType.LIST_FAILURE; error: Error }

//
//	listEditorials()
//

export const listEditorials = (): EditorialAction => ({ type: ActionType.LIST_REQUEST })

export const listEditorialsSuccess = (editorials: Editorial[]): EditorialAction => ({
  type: ActionType.LIST_SUCCESS,
  editorials,
})

export const listEditorialsFailure = (error: Error): EditorialAction => ({
  type: ActionType.LIST_FAILURE,
  error,
})
