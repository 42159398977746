import { Story } from '@anews/types'

export enum ActionType {
  // loadStories()
  LOAD_STORIES_REQUEST = '@preview/LOAD_STORIES_REQUEST',
  LOAD_STORIES_SUCCESS = '@preview/LOAD_STORIES_SUCCESS',
  LOAD_STORIES_FAILURE = '@preview/LOAD_STORIES_FAILURE',
}

export interface LoadStoriesAction {
  type: ActionType.LOAD_STORIES_REQUEST
  ids: [number | undefined, number | undefined]
}

export type PreviewAction =
  | LoadStoriesAction
  | { type: ActionType.LOAD_STORIES_SUCCESS; stories: [Story | undefined, Story | undefined] }
  | { type: ActionType.LOAD_STORIES_FAILURE; error: Error }

//
//  loadStories()
//

export const loadStories = (ids: [number | undefined, number | undefined]): PreviewAction => ({
  type: ActionType.LOAD_STORIES_REQUEST,
  ids,
})

export const loadStoriesSuccess = (
  stories: [Story | undefined, Story | undefined],
): PreviewAction => ({
  type: ActionType.LOAD_STORIES_SUCCESS,
  stories,
})

export const loadStoriesFailure = (error: Error): PreviewAction => ({
  type: ActionType.LOAD_STORIES_FAILURE,
  error,
})
