import reactHtmlParser from 'react-html-parser';
export function escapeTags(value, preserverMarks) {
    if (preserverMarks === void 0) { preserverMarks = true; }
    var escaped = value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return preserverMarks
        ? escaped.replace(/&lt;mark&gt;/g, '<mark>').replace(/&lt;\/mark&gt;/g, '</mark>')
        : escaped;
}
export function highlight(value) {
    return value ? reactHtmlParser(escapeTags(value)) : value;
}
