import { Checklist, Guideline, Report, Reportage, Story } from '@anews/types'

export enum Section {
  CHECKLISTS = 'checklists',
  GUIDELINES = 'guidelines',
  REPORTS = 'reports',
  REPORTAGES = 'reportages',
  STORIES = 'stories',
}

export type AllowedType = Checklist | Guideline | Report | Reportage | Story

export interface UnsavedDataSection<T extends AllowedType> {
  [uuid: string]: {
    document: T
    date: string
  }
}

export interface UnsavedDataCache {
  [Section.CHECKLISTS]: UnsavedDataSection<Checklist>
  [Section.GUIDELINES]: UnsavedDataSection<Guideline>
  [Section.REPORTS]: UnsavedDataSection<Report>
  [Section.REPORTAGES]: UnsavedDataSection<Reportage>
  [Section.STORIES]: UnsavedDataSection<Story>
}
