import message from 'antd/es/message'

// Método do textArea:  cria um textarea e usa o exeCommand('copy')
function copyUsingDocumentCommand(value: string) {
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.value = value

    // Não mostra o textarea na tela
    textarea.style.position = 'fixed'
    textarea.style.left = '-999999px'
    textarea.style.top = '-999999px'

    document.body.appendChild(textarea)
    textarea.focus()
    textarea.select()

    try {
      const success = document.execCommand('copy')
      if (!success) {
        console.error('document.execCommand() returned false')
      }
      return success
    } catch (error) {
      console.error('Exception on document.execCommand()', error)
      return false
    } finally {
      textarea.remove()
    }
  } else {
    console.error(`queryCommandSupported or queryCommandSupported('copy') not supported`)
    return false
  }
}

// Método da API clipboard
function copyUsingClipboardApi(value: string) {
  if (navigator.clipboard && window.isSecureContext) {
    document.body.focus()
    return navigator.clipboard.writeText(value)
  }
  return Promise.reject(
    new Error('navigator.clipboard is undefined or window.isSecureContext is false'),
  )
}

export function copyToClipboard(value: string, successMsg: string, errorMsg: string) {
  return copyUsingClipboardApi(value)
    .then(() => {
      message.info(successMsg)
    })
    .catch(error => {
      if (copyUsingDocumentCommand(value)) {
        message.info(successMsg)
      } else {
        console.error(error)
        message.error(errorMsg)
      }
    })
}
