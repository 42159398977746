import { call, put, takeLatest } from 'redux-saga/effects'

import { institutionApi as api } from '@anews/api'
import { Institution } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  listInstitutionsSuccess,
  listInstitutionsFailure,
  CreateInstitutionAction,
  createInstitutionSuccess,
  createInstitutionFailure,
  UpdateInstitutionAction,
  updateInstitutionSuccess,
  updateInstitutionFailure,
  RemoveInstitutionAction,
  removeInstitutionSuccess,
  removeInstitutionFailure,
} from '../actions/institution-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listInstitutionsSaga(): Generator {
  try {
    const institutions = yield call(api.list)
    yield put(listInstitutionsSuccess(institutions as Institution[]))
  } catch (error) {
    yield put(listInstitutionsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createInstitutionSaga(action: CreateInstitutionAction): Generator {
  try {
    const institution = yield call(api.create, action.institution)
    yield put(createInstitutionSuccess(institution as Institution))
  } catch (error) {
    yield put(createInstitutionFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:createFailed')}`,
        error,
      }),
    )
  }
}

function* updateInstitutionSaga(action: UpdateInstitutionAction): Generator {
  try {
    const institution = yield call(api.update, action.institution)
    yield put(updateInstitutionSuccess(institution as Institution))
  } catch (error) {
    yield put(updateInstitutionFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: `${i18n.t('error:updateFailed')}`,
        error,
      }),
    )
  }
}

function* removeInstitutionSaga(action: RemoveInstitutionAction): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeInstitutionSuccess(action.id))
  } catch (error) {
    yield put(removeInstitutionFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listInstitutionsSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createInstitutionSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateInstitutionSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeInstitutionSaga)
  },
])
