import { useEffect, useRef } from 'react';
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export default function useInterval(callback, delay) {
    var savedCallback = useRef();
    // Lembra do último callback
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Define o interval
    useEffect(function () {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null && delay > 0) {
            var id_1 = setInterval(tick, delay);
            return function () {
                clearInterval(id_1);
            };
        }
    }, [delay]);
}
