import { Reducer } from 'redux'

import { IITemplate } from '@anews/types'

import { ActionType, IITemplateAction } from '../actions/iitemplate-actions'

export interface IITemplatesListState {
  data: IITemplate[]
  loading: boolean
}

export interface IITemplatesState {
  list: IITemplatesListState
  editing?: IITemplate
  saving: boolean
  removing: boolean
}

export const initialState: IITemplatesState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(list: IITemplatesListState, action: IITemplateAction): IITemplatesListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.templates,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.template, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(template =>
          template.id === action.template.id ? action.template : template,
        ),
      }

    case ActionType.REMOVE_SUCCESS:
      return {
        ...list,
        data: list.data.filter(template => !action.templateIds.includes(template.id)),
      }

    default:
      return list
  }
}

const templatesReducer: Reducer<IITemplatesState, IITemplateAction> = (
  state = initialState,
  action,
): IITemplatesState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT_SUCCESS:
      return { ...state, editing: action.template }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.template, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default templatesReducer
