import { Page, SouvConfig, SouvDocument, SouvDocumentType, SouvWorkspace } from '@anews/types'

import { PAGE_SIZE } from '../../consts/pagination'

export enum SouvActionType {
  CLEAR_LIST = '@souv/CLEAR_LIST',
  UNLOAD_DOCUMENT = '@souv/UNLOAD_DOCUMENT',
  // listWorkspaces()
  LIST_WORKSPACES_REQUEST = '@souv/LIST_WORKSPACES_REQUEST',
  LIST_WORKSPACES_SUCCESS = '@souv/LIST_WORKSPACES_SUCCESS',
  LIST_WORKSPACES_FAILURE = '@souv/LIST_WORKSPACES_FAILURE',
  // filterDocuments()
  FILTER_DOCUMENT_REQUEST = '@souv/FILTER_REQUEST',
  FILTER_DOCUMENT_SUCCESS = '@souv/FILTER_SUCCESS',
  FILTER_DOCUMENT_FAILURE = '@souv/FILTER_FAILURE',
  // loadDocument()
  LOAD_DOCUMENT_REQUEST = '@souv/LOAD_REQUEST',
  LOAD_DOCUMENT_SUCCESS = '@souv/LOAD_SUCCESS',
  LOAD_DOCUMENT_FAILURE = '@souv/LOAD_FAILURE',
  // loadConfig()
  LOAD_CONFIG_REQUEST = '@souv/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@souv/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@souv/LOAD_CONFIG_FAILURE',
  // createConfig()
  CREATE_CONFIG_REQUEST = '@souv/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@souv/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@souv/CREATE_CONFIG_FAILURE',
  // updateConfig()
  UPDATE_CONFIG_REQUEST = '@souv/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@souv/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@souv/UPDATE_CONFIG_FAILURE',
}

export type SouvActionMap<T extends SouvActionType> = { type: T } & SouvAction

export type SouvAction = ReturnType<typeof SouvActions[keyof typeof SouvActions]>

export const SouvActions = {
  //
  //  listWorkspaces()
  //

  listWorkspaces: () => <const>{ type: SouvActionType.LIST_WORKSPACES_REQUEST },

  listWorkspacesSuccess: (workspaces: SouvWorkspace[]) =>
    <const>{ type: SouvActionType.LIST_WORKSPACES_SUCCESS, workspaces },

  listWorkspacesFailure: (error: Error) =>
    <const>{ type: SouvActionType.LIST_WORKSPACES_FAILURE, error },

  //
  //  loadDocument()
  //

  loadDocument: (workspaceId: string, documentId: string) =>
    <const>{ type: SouvActionType.LOAD_DOCUMENT_REQUEST, workspaceId, documentId },

  loadDocumentSuccess: (document: SouvDocument) =>
    <const>{ type: SouvActionType.LOAD_DOCUMENT_SUCCESS, document },

  loadDocumentFailure: (error: Error) =>
    <const>{ type: SouvActionType.LOAD_DOCUMENT_FAILURE, error },

  //
  //  unloadDocument()
  //

  unloadDocument: () => <const>{ type: SouvActionType.UNLOAD_DOCUMENT },

  //
  //	filterDocuments()
  //

  filterDocuments: (
    workspaceId: string,
    text?: string,
    docType?: SouvDocumentType,
    page = 0,
    limit = PAGE_SIZE,
  ) =>
    <const>{
      type: SouvActionType.FILTER_DOCUMENT_REQUEST,
      workspaceId,
      text,
      docType,
      page,
      limit,
    },

  filterDocumentsSuccess: (result: Page<SouvDocument>) =>
    <const>{ type: SouvActionType.FILTER_DOCUMENT_SUCCESS, result },

  filterDocumentsFailure: (error: Error) =>
    <const>{ type: SouvActionType.FILTER_DOCUMENT_FAILURE, error },

  //
  //  clearList()
  //

  clearList: () => <const>{ type: SouvActionType.CLEAR_LIST },

  //
  //  loadConfig()
  //

  loadConfig: () => <const>{ type: SouvActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: SouvConfig) =>
    <const>{
      type: SouvActionType.LOAD_CONFIG_SUCCESS,
      config,
    },

  loadConfigFailure: (error: Error) =>
    <const>{
      type: SouvActionType.LOAD_CONFIG_FAILURE,
      error,
    },

  //
  //  createConfig()
  //

  createConfig: (config: SouvConfig) =>
    <const>{
      type: SouvActionType.CREATE_CONFIG_REQUEST,
      config,
    },

  createConfigSuccess: (config: SouvConfig) =>
    <const>{
      type: SouvActionType.CREATE_CONFIG_SUCCESS,
      config,
    },

  createConfigFailure: (error: Error) =>
    <const>{
      type: SouvActionType.CREATE_CONFIG_FAILURE,
      error,
    },

  //
  //  updateConfig()
  //

  updateConfig: (config: SouvConfig) =>
    <const>{
      type: SouvActionType.UPDATE_CONFIG_REQUEST,
      config,
    },

  updateConfigSuccess: (config: SouvConfig) =>
    <const>{
      type: SouvActionType.UPDATE_CONFIG_SUCCESS,
      config,
    },

  updateConfigFailure: (error: Error) =>
    <const>{
      type: SouvActionType.UPDATE_CONFIG_FAILURE,
      error,
    },
}
