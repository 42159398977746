import React, { memo, useCallback, HTMLAttributes, useState } from 'react'

import Col from 'antd/es/col'
import Row from 'antd/es/row'

import Form from 'antd/es/form'

import {
  BeforeCapture,
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'

import {
  APPEARANCE,
  ART,
  HEADING,
  INFORMATION,
  INTERVIEW,
  NOTE,
  OFF,
  SOUND_UP,
  TEASER,
  ReportageSection,
  RichTextBlock,
} from '@anews/types'

import { useTranslation } from '../../../i18n'

import theme from '../../../theme'

import ReportageSectionInput from './ReportageSectionInput'
import ReportageText from './ReportageText'

import './StyleReportage.css'

//
//  <ReportageFormBody>
//

interface OwnProps {
  showHeading?: boolean
  showInformation?: boolean
  showNote?: boolean
  showTeaser?: boolean
  showText?: boolean
  tvType?: boolean
  sections: ReportageSection[]
  onRemoveText: (sectionType: string) => void
  onToggleTextCase: (sectionType: string) => void
  onSectionChange: (section: ReportageSection) => void
  onRemoveSection: (sectionConst: string) => void
  onToggleSectionCase: (uuid: string, prop: 'content' | 'observation') => void
  onDurationChange: (
    uuid: string,
    manual: boolean,
    duration: string,
    text?: RichTextBlock[],
  ) => void
  onMoveSectionUp: (uuid: string) => void
  onMoveSectionDown: (uuid: string) => void
  onDragSection: (uuid: string, sourceIndex: number, destinationIndex: number) => void
}

type Props = OwnProps & Omit<HTMLAttributes<HTMLDivElement>, 'onDurationChange'>

function ReportageFormBody({
  // texts props
  showHeading,
  showInformation,
  showNote,
  showTeaser,
  showText,
  tvType,
  onRemoveText,
  onToggleTextCase,
  // sections props
  sections,
  onSectionChange,
  onRemoveSection,
  onToggleSectionCase,
  onDurationChange,
  onMoveSectionUp,
  onMoveSectionDown,
  onDragSection,
  // rest
  ...props
}: Props) {
  const { t } = useTranslation()

  const getDraggableStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    background: theme.backgrounds.body,
    height: isDragging ? '110px' : 'auto',
    boxShadow: isDragging ? '4px 4px 2px #bfbfbf' : 'none',

    ...draggableStyle,
  })

  let appearanceCount = 0
  let artCount = 0
  let interviewCount = 0
  let offCount = 0
  let soundUpCount = 0

  const rmHead = useCallback(() => onRemoveText(HEADING), [onRemoveText])
  const rmInfo = useCallback(() => onRemoveText(INFORMATION), [onRemoveText])
  const rmNote = useCallback(() => onRemoveText(NOTE), [onRemoveText])
  const rmTeaser = useCallback(() => onRemoveText(TEASER), [onRemoveText])

  const [draggingId, setDraggingId] = useState<string>()

  /*
   * Tive que colocar essa função antes da renderização do componente que faz a contagem, pois o
   * componente de drag n drop estava incrementando a contagem a cada 'arraste' de um <Dragabble>
   */

  function resetNumber() {
    appearanceCount = 0
    artCount = 0
    interviewCount = 0
    offCount = 0
    soundUpCount = 0
  }

  const onDragEndHandler = useCallback(
    ({ source, destination, draggableId }: DropResult) => {
      // Soltou fora de uma lista
      setDraggingId(undefined)
      if (!destination) {
        return
      }

      // Soltou na posição
      if (destination.index === source.index) {
        return
      }

      onDragSection(draggableId, source.index, destination.index)
    },
    [onDragSection],
  )

  const onBeforeCaptureHandler = useCallback(({ draggableId }: BeforeCapture) => {
    setDraggingId(draggableId)
  }, [])

  return (
    <div {...props}>
      {showText && (
        <Form.Item getValueFromEvent={content => content} name="text" noStyle>
          <ReportageText header={t('reportage:text')} />
        </Form.Item>
      )}

      <Row gutter={24}>
        <Col span={9}>
          {showInformation && (
            <Form.Item getValueFromEvent={content => content} name="information" noStyle>
              <ReportageText header={t('reportage:info')} onRemove={rmInfo} />
            </Form.Item>
          )}
        </Col>
        <Col span={15}>
          {showTeaser && (
            <Form.Item getValueFromEvent={content => content} name="teaser" noStyle>
              <ReportageText header={t('reportage:teaser')} onRemove={rmTeaser} />
            </Form.Item>
          )}
          {showHeading && (
            <Form.Item getValueFromEvent={content => content} name="headingSuggestion" noStyle>
              <ReportageText header={t('reportage:heading')} onRemove={rmHead} />
            </Form.Item>
          )}
          {showNote && (
            <Form.Item getValueFromEvent={content => content} name="noteSuggestion" noStyle>
              <ReportageText header={t('reportage:footnote')} onRemove={rmNote} />
            </Form.Item>
          )}
        </Col>
      </Row>

      <DragDropContext onDragEnd={onDragEndHandler} onBeforeCapture={onBeforeCaptureHandler}>
        <Droppable droppableId="droppableSection">
          {(droppableProvided, dropSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              style={{
                background: dropSnapshot.isDraggingOver ? 'lightblue' : theme.backgrounds.body,
              }}
            >
              {resetNumber()}
              {sections.map((section, index) => {
                let number: any

                switch (section.type) {
                  case APPEARANCE:
                    appearanceCount += 1
                    number = appearanceCount
                    break
                  case ART:
                    artCount += 1
                    number = artCount
                    break
                  case INTERVIEW:
                    interviewCount += 1
                    number = interviewCount
                    break
                  case OFF:
                    offCount += 1
                    number = offCount
                    break
                  case SOUND_UP:
                    soundUpCount += 1
                    number = soundUpCount
                    break
                  default:
                    number = 0
                }

                number = (number < 10 ? '0' : '') + number

                return (
                  <Draggable key={section.uuid} draggableId={section.uuid} index={index}>
                    {(draggableProvided, dragSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getDraggableStyle(
                          dragSnapshot.isDragging || section.uuid === draggingId, // o redimensionamento só funciona quando coloca a segunda condição
                          draggableProvided.draggableProps.style,
                        )}
                        className={
                          dragSnapshot.isDragging || section.uuid === draggingId ? 'drag' : '' // Faz o redimensionamento do textarea
                        }
                      >
                        <ReportageSectionInput
                          tvType={tvType}
                          key={section.uuid}
                          number={number}
                          value={section}
                          onChange={onSectionChange}
                          onToggleCase={onToggleSectionCase}
                          onRemove={onRemoveSection}
                          onDurationChange={onDurationChange}
                          onMoveUp={section.index > 0 ? onMoveSectionUp : undefined}
                          onMoveDown={
                            section.index < sections.length - 1 ? onMoveSectionDown : undefined
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default memo(ReportageFormBody)
