var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { get, patch, post, query } from './client';
var endpoint = '/api/mam';
var mamApi = {
    searchableFields: function (id) {
        return get(endpoint + "/" + id + "/searchable_fields");
    },
    requestSync: function (id, clean) {
        return post(query(endpoint + "/" + id + "/request_sync", { clean: clean }));
    },
    filterMedias: function (id, params, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/" + id + "/medias", __assign(__assign({}, params), { page: page, size: size })));
    },
    getThumbSrc: function (deviceId, id) {
        return endpoint + "/" + deviceId + "/assets/" + id + "/thumbnail";
    },
    createPlaceholder: function (_a) {
        var mamId = _a.mamId, media = _a.media, rest = __rest(_a, ["mamId", "media"]);
        return post(query(endpoint + "/" + mamId + "/media", rest), __assign({}, media));
    },
    updateMedia: function (mamId, media, time) {
        return patch(query(endpoint + "/" + mamId + "/media", { time: time }), __assign({}, media));
    },
    getMedia: function (mamId, objId) {
        return get(endpoint + "/" + mamId + "/media/" + objId);
    },
};
export default mamApi;
