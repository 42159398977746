import { del, get, post, put, query } from './client';
var endpoint = '/api/rounds/segments';
var segmentApi = {
    list: function (institutions) {
        return get(query(endpoint, { institutions: institutions }));
    },
    create: function (segment) {
        return post(endpoint, segment);
    },
    update: function (segment) {
        return put("" + endpoint, segment);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default segmentApi;
