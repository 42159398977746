import { Reducer } from 'redux'

import { Page, SouvConfig, SouvDocument, SouvDocumentType, SouvWorkspace } from '@anews/types'

import { SouvAction, SouvActionType as ActionType } from '../actions/souv-actions'

import { ConfigState } from './types'

export interface SouvState {
  workspaces: {
    data: SouvWorkspace[]
    loading: boolean
  }
  list: {
    filter: {
      workspaceId?: string
      text?: string
      type?: SouvDocumentType
    }
    current: Page<SouvDocument>
    loading: boolean
  }
  viewing: {
    document?: SouvDocument
    loading: boolean
  }
  config: ConfigState<SouvConfig>
}

export const initialState: SouvState = {
  workspaces: {
    data: [],
    loading: false,
  },
  list: {
    filter: {},
    current: {
      data: [],
      page: 0,
      size: 0,
      total: 0,
    },
    loading: false,
  },
  viewing: {
    document: undefined,
    loading: false,
  },
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
}

function configReducer(
  config: ConfigState<SouvConfig>,
  action: SouvAction,
): ConfigState<SouvConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

function workspacesReducer(
  state: SouvState['workspaces'],
  action: SouvAction,
): SouvState['workspaces'] {
  switch (action.type) {
    case ActionType.LIST_WORKSPACES_REQUEST:
      return { ...state, loading: true }
    case ActionType.LIST_WORKSPACES_FAILURE:
      return { ...state, loading: false }
    case ActionType.LIST_WORKSPACES_SUCCESS:
      return { ...state, data: action.workspaces, loading: false }
    default:
      return state
  }
}

function listReducer(state: SouvState['list'], action: SouvAction): SouvState['list'] {
  switch (action.type) {
    case ActionType.FILTER_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        filter: { workspaceId: action.workspaceId, text: action.text, type: action.docType },
      }

    case ActionType.FILTER_DOCUMENT_SUCCESS:
      return { ...state, loading: false, current: action.result }

    case ActionType.FILTER_DOCUMENT_FAILURE:
      return { ...state, loading: false }

    case ActionType.CLEAR_LIST:
      return initialState.list

    default:
      return state
  }
}

function viewingReducer(state: SouvState['viewing'], action: SouvAction): SouvState['viewing'] {
  switch (action.type) {
    case ActionType.LOAD_DOCUMENT_REQUEST:
      return { ...state, loading: true }

    case ActionType.LOAD_DOCUMENT_FAILURE:
      return { ...state, loading: false }

    case ActionType.LOAD_DOCUMENT_SUCCESS:
      return { ...state, loading: false, document: action.document }

    case ActionType.UNLOAD_DOCUMENT:
      return initialState.viewing

    default:
      return state
  }
}

const souvReducer: Reducer<SouvState, SouvAction> = (state = initialState, action): SouvState => {
  if (Object.values(ActionType).includes(action.type)) {
    return {
      ...state,
      workspaces: workspacesReducer(state.workspaces, action),
      list: listReducer(state.list, action),
      viewing: viewingReducer(state.viewing, action),
      config: configReducer(state.config, action),
    }
  }
  return state
}

export default souvReducer
