export var ROLE_ROOT = 'ROLE_ROOT'; // role nao existe, usado para permitir somente acesso ao suporte no front
export var ROLE_ADMIN_READ = 'ROLE_ADMIN_READ';
export var ROLE_ADMIN_WRITE = 'ROLE_ADMIN_WRITE';
export var ROLE_AGENCY_ADMIN = 'ROLE_AGENCY_ADMIN';
export var ROLE_AGENCY_READ = 'ROLE_AGENCY_READ';
export var ROLE_ASSIGNMENT_ADMIN = 'ROLE_ASSIGNMENT_ADMIN';
export var ROLE_ASSIGNMENT_READ = 'ROLE_ASSIGNMENT_READ';
export var ROLE_ASSIGNMENT_WRITE = 'ROLE_ASSIGNMENT_WRITE';
export var ROLE_BRANCH_READ = 'ROLE_BRANCH_READ';
export var ROLE_CALENDAR_ADMIN = 'ROLE_CALENDAR_ADMIN';
export var ROLE_CALENDAR_READ = 'ROLE_CALENDAR_READ';
export var ROLE_CALENDAR_WRITE = 'ROLE_CALENDAR_WRITE';
export var ROLE_CHART_READ = 'ROLE_CHART_READ';
export var ROLE_CHAT_READ = 'ROLE_CHAT_READ';
export var ROLE_CONTACT_ADMIN = 'ROLE_CONTACT_ADMIN';
export var ROLE_CONTACT_READ = 'ROLE_CONTACT_READ';
export var ROLE_CONTACT_WRITE = 'ROLE_CONTACT_WRITE';
export var ROLE_DOCUMENT_ADMIN = 'ROLE_DOCUMENT_ADMIN';
export var ROLE_DOCUMENT_READ = 'ROLE_DOCUMENT_READ';
export var ROLE_ASSIGNMENT_PROPOSAL_READ = 'ROLE_ASSIGNMENT_PROPOSAL_READ';
export var ROLE_ASSIGNMENT_PROPOSAL_WRITE = 'ROLE_ASSIGNMENT_PROPOSAL_WRITE';
export var ROLE_MEDIA_READ = 'ROLE_MEDIA_READ';
export var ROLE_MEDIA_WRITE = 'ROLE_MEDIA_WRITE';
export var ROLE_MOBILE_READ = 'ROLE_MOBILE_READ';
export var ROLE_NEWS_REPORT_ADMIN = 'ROLE_NEWS_REPORT_ADMIN';
export var ROLE_NEWS_REPORT_READ = 'ROLE_NEWS_REPORT_READ';
export var ROLE_NEWS_REPORT_WRITE = 'ROLE_NEWS_REPORT_WRITE';
export var ROLE_NOTICE_ADMIN = 'ROLE_NOTICE_ADMIN';
export var ROLE_NOTICE_READ = 'ROLE_NOTICE_READ';
export var ROLE_NOTICE_WRITE = 'ROLE_NOTICE_WRITE';
export var ROLE_POST_ADMIN = 'ROLE_POST_ADMIN';
export var ROLE_POST_READ = 'ROLE_POST_READ';
export var ROLE_POST_WRITE = 'ROLE_POST_WRITE';
export var ROLE_PREVIEW_READ = 'ROLE_PREVIEW_READ';
export var ROLE_PRODUCTION_ADMIN = 'ROLE_PRODUCTION_ADMIN';
export var ROLE_PRODUCTION_READ = 'ROLE_PRODUCTION_READ';
export var ROLE_PRODUCTION_WRITE = 'ROLE_PRODUCTION_WRITE';
export var ROLE_PROGRAM_READ = 'ROLE_PROGRAM_READ';
export var ROLE_PROGRAM_WRITE = 'ROLE_PROGRAM_WRITE';
export var ROLE_REPORT_ADMIN = 'ROLE_REPORT_ADMIN';
export var ROLE_REPORT_READ = 'ROLE_REPORT_READ';
export var ROLE_REPORT_WRITE = 'ROLE_REPORT_WRITE';
export var ROLE_ROUND_ADMIN = 'ROLE_ROUND_ADMIN';
export var ROLE_ROUND_READ = 'ROLE_ROUND_READ';
export var ROLE_ROUND_WRITE = 'ROLE_ROUND_WRITE';
export var ROLE_RUNDOWN_ADMIN = 'ROLE_RUNDOWN_ADMIN';
export var ROLE_RUNDOWN_READ = 'ROLE_RUNDOWN_READ';
export var ROLE_RUNDOWN_WRITE = 'ROLE_RUNDOWN_WRITE';
export var ROLE_STORY_ADMIN = 'ROLE_STORY_ADMIN';
export var ROLE_STORY_READ = 'ROLE_STORY_READ';
export var ROLE_STORY_WRITE = 'ROLE_STORY_WRITE';
export var ROLE_TEAM_READ = 'ROLE_TEAM_READ';
export var ROLE_TEAM_WRITE = 'ROLE_TEAM_WRITE';
export var ROLE_USER_READ = 'ROLE_USER_READ';
export var ROLE_USER_WRITE = 'ROLE_USER_WRITE';
