import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Drawer from 'antd/es/drawer'

import { documentApi } from '@anews/api'
import { useOnce } from '@anews/hooks'
import { Checklist, Document, DocumentOrigin, Guideline, Reportage, Story } from '@anews/types'

import {
  UserActions,
  CompanyActions,
  ChecklistResourceActions,
  ProgramActions,
  TypeActions,
  VehicleActions,
} from '../../redux/actions'

import * as EditorialActions from '../../redux/actions/editorial-actions'
import * as ClassificationActions from '../../redux/actions/classification-actions'

import { typesMap as createTypesMap } from '../../redux/selectors/type-selectors'
import { programsMap as createProgramsMap } from '../../redux/selectors/program-selectors'
import { companiesMap as createCompaniesMap } from '../../redux/selectors/company-selectors'
import { editorialsMap as createEditorialsMap } from '../../redux/selectors/editorial-selectors'
import { classificationsMap as createClassificationMap } from '../../redux/selectors/classification-selectors'

import StoryViewer from '../main/stories/StoryViewer'
import { PropsMapper, RootState } from '../../redux/reducers'
import ReportageViewer from '../main/reportages/ReportageViewer'
import ChecklistViewer from '../main/checklists/ChecklistViewer'
import GuidelineViewer from '../main/guidelines/GuidelineViewer'
import { allUsersMap } from '../../redux/selectors/user-selectors'
import { vehiclesMap } from '../../redux/selectors/vehicle-selectors'
import { typesMap } from '../../redux/selectors/checklisttype-selectors'
import { createRolesChecker } from '../../redux/selectors/auth-selectors'
import { resourcesMap } from '../../redux/selectors/checklistresource-selectors'

interface OwnProps {
  document?: Document
  documentId?: number
  onClose: () => void
}

const mapStateToProps = (state: RootState) => ({
  customMetadata: state.customMetadata.list.data,
  programsMap: createProgramsMap(state),
  classifications: createClassificationMap(state),
  companies: createCompaniesMap(state),
  editorials: createEditorialsMap(state),
  types: createTypesMap(state),
  users: allUsersMap(state),
  vehicles: vehiclesMap(state),
  resources: resourcesMap(state),
  checklistTypes: typesMap(state),
  hasRoles: createRolesChecker(state),
})

const dispatchProps = {
  listAllUsers: UserActions.listAllUsers,
  listVehicles: VehicleActions.listVehicles,
  listAllPrograms: ProgramActions.listAllPrograms,
  listClassifications: ClassificationActions.listClassifications,
  listCompanies: CompanyActions.listCompanies,
  listEditorials: EditorialActions.listEditorials,
  listTypes: TypeActions.listTypes,
  listResources: ChecklistResourceActions.listResources,
}

type Props = PropsMapper<typeof mapStateToProps, typeof dispatchProps, OwnProps>

const DocumentViewDrawer = (props: Props) => {
  // State
  const {
    document,
    documentId,
    companies,
    editorials,
    types,
    vehicles,
    users,
    classifications,
    programsMap,
    resources,
    checklistTypes,
    customMetadata,
  } = props

  // Actions
  const {
    onClose,
    listAllPrograms,
    listAllUsers,
    listClassifications,
    listVehicles,
    listCompanies,
    listEditorials,
    listTypes,
    listResources,
  } = props

  useOnce(listAllPrograms)
  useOnce(listAllUsers)
  useOnce(listClassifications)
  useOnce(listVehicles)
  useOnce(listCompanies)
  useOnce(listEditorials)
  useOnce(listTypes)
  useOnce(listResources)

  const [documentToView, setDocumentToView] = useState<Document>()

  useEffect(() => {
    if (!document && documentId) {
      documentApi.loadById(documentId).then(setDocumentToView).catch(console.error)
    } else if (document) {
      setDocumentToView(document)
    }
  }, [documentId, document])

  return (
    <Drawer visible={!!documentToView} onClose={onClose} width={640} closable={false} mask={false}>
      {(documentToView?.origin === DocumentOrigin.GUIDELINE && (
        <GuidelineViewer
          guideline={documentToView as Guideline}
          editable={false}
          classifications={classifications}
          companies={companies}
          editorials={editorials}
          types={types}
          users={users}
          vehicles={vehicles}
          programs={programsMap}
          customMetadata={customMetadata}
          onClose={onClose}
        />
      )) ||
        (documentToView?.origin === DocumentOrigin.REPORTAGE && (
          <ReportageViewer
            reportage={documentToView as Reportage}
            customMetadata={customMetadata}
            editable={false}
            classifications={classifications}
            companies={companies}
            editorials={editorials}
            users={users}
            vehicles={vehicles}
            programs={programsMap}
            onClose={onClose}
          />
        )) ||
        (documentToView?.origin === DocumentOrigin.PRODUCTION && (
          <ChecklistViewer
            checklist={documentToView as Checklist}
            customMetadata={customMetadata}
            editable={false}
            users={users}
            resources={resources}
            types={checklistTypes}
            vehicles={vehicles}
            onClose={onClose}
          />
        )) ||
        (documentToView?.origin === DocumentOrigin.STORY && (
          <StoryViewer
            story={documentToView as Story}
            customMetadata={customMetadata}
            editable={false}
            companies={companies}
            types={types}
            users={users}
            programs={programsMap}
            onClose={onClose}
          />
        )) || <></>}
    </Drawer>
  )
}

export default connect(mapStateToProps, dispatchProps)(memo(DocumentViewDrawer))
