export var StorySubSectionType;
(function (StorySubSectionType) {
    StorySubSectionType["CAMERA_TEXT"] = "CAMERA_TEXT";
    StorySubSectionType["CG"] = "CG";
    StorySubSectionType["MOS_CG"] = "MOS_CG";
    StorySubSectionType["MOS_VIDEO"] = "MOS_VIDEO";
    StorySubSectionType["TEXT"] = "TEXT";
})(StorySubSectionType || (StorySubSectionType = {}));
export var StorySectionType;
(function (StorySectionType) {
    StorySectionType["FOOTER"] = "FOOTER";
    StorySectionType["HEADER"] = "HEADER";
    StorySectionType["LINK"] = "LINK";
    StorySectionType["LIVE_VO"] = "LIVE_VO";
    StorySectionType["RECORDED_VO"] = "RECORDED_VO";
    StorySectionType["VT"] = "VT";
})(StorySectionType || (StorySectionType = {}));
/*
 * Obtém a duração da nota coberta (NC)
 *
 * Além do valor de "duration" ela também tem "voDuration", onde apenas um dos
 * dois será considerado. Se tiver texto, será "voDuration", senão será "duration".
 */
export function liveVODuration(section) {
    if (!section) {
        return undefined;
    }
    var hasText = !!section.subSections.find(function (sub) { return sub.type === StorySubSectionType.TEXT; });
    return hasText ? section.voDuration : section.duration;
}
