import { get, post, put, del, query } from './client';
var endpoint = '/api/calendars/events';
var calendarEventApi = {
    list: function (calendarId, currentDate) {
        return get(query("" + endpoint, { calendarId: calendarId, currentDate: currentDate }));
    },
    find: function (id) {
        return get(endpoint + "/" + id);
    },
    create: function (event) {
        return post(endpoint, event);
    },
    update: function (event) {
        return put(endpoint, event);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default calendarEventApi;
