import { Classification } from '@anews/types'

export enum ActionType {
  LIST_REQUEST = '@classification/LIST_REQUEST',
  LIST_SUCCESS = '@classification/LIST_SUCCESS',
  LIST_FAILURE = '@classification/LIST_FAILURE',
}

export type ClassificationAction =
  | { type: ActionType.LIST_REQUEST }
  | { type: ActionType.LIST_SUCCESS; classifications: Classification[] }
  | { type: ActionType.LIST_FAILURE; error: Error }

//
//	listClassifcations()
//

export const listClassifications = (): ClassificationAction => ({ type: ActionType.LIST_REQUEST })

export const listClassificationsSuccess = (
  classifications: Classification[],
): ClassificationAction => ({ type: ActionType.LIST_SUCCESS, classifications })

export const listClassificationsFailure = (error: Error): ClassificationAction => ({
  type: ActionType.LIST_FAILURE,
  error,
})
