import React, { PureComponent } from 'react'

import AntTimePicker, { TimePickerProps } from 'antd/es/time-picker'

class TimePicker extends PureComponent<TimePickerProps> {
  render() {
    return (
      <AntTimePicker
        format="L"
        autoComplete="off"
        // css={css`
        //   min-width: 120px;
        // `}
        // disabledTime={disabledTime || defaultDisabledTime}
        {...this.props}
      />
    )
  }
}

export default TimePicker
