import { Indexes, IndexesState } from '@anews/types'

export enum IndexActionType {
  LOAD_STATE_REQUEST = '@index/LOAD_STATE_REQUEST',
  LOAD_STATE_SUCCESS = '@index/LOAD_STATE_SUCCESS',
  LOAD_STATE_FAILURE = '@index/LOAD_STATE_FAILURE',
  UPDATE_STATE = '@index/UPDATE_STATE',
  REBUILD_REQUEST = '@index/REBUILD_REQUEST',
  REBUILD_SUCCESS = '@index/REBUILD_SUCCESS',
  REBUILD_FAILURE = '@index/REBUILD_FAILURE',
}

export type IndexActionMap<T extends IndexActionType> = {
  type: T
} & IndexAction

export type IndexAction = ReturnType<typeof IndexActions[keyof typeof IndexActions]>

export const IndexActions = {
  //
  //	loadState()
  //

  loadState: () => <const>{ type: IndexActionType.LOAD_STATE_REQUEST },
  loadStateSuccess: (state: IndexesState) =>
    <const>{ type: IndexActionType.LOAD_STATE_SUCCESS, state },

  loadStateFailure: (error: Error) => <const>{ type: IndexActionType.LOAD_STATE_FAILURE, error },

  //
  //	updateState()
  //

  updateState: (state: IndexesState) => <const>{ type: IndexActionType.UPDATE_STATE, state },

  //
  //	rebuildIndex()
  //

  rebuildIndex: (targets: Indexes[]) => <const>{ type: IndexActionType.REBUILD_REQUEST, targets },
  rebuildIndexSuccess: () => <const>{ type: IndexActionType.REBUILD_SUCCESS },
  rebuildIndexFailure: (error: Error) => <const>{ type: IndexActionType.REBUILD_FAILURE, error },
}
