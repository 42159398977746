import { call, put, takeLatest } from 'redux-saga/effects'

import { branchApi } from '@anews/api'
import { BranchConfig } from '@anews/types'

import {
  BranchActionMap,
  BranchActions,
  BranchActionType as ActionType,
} from '../actions/branch-actions'

import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
} = BranchActions

const { notifyError } = NotificationActions

/* Watchers */

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(branchApi.loadConfig)
    yield put(loadConfigSuccess(config as BranchConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: BranchActionMap<ActionType.UPDATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(branchApi.updateConfig, action.config)
    yield put(updateConfigSuccess(config as BranchConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
