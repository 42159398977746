/* eslint-disable no-plusplus */
import { Editor, Transforms, Text, Path } from 'slate'

import { RichTextBlock } from '@anews/types'
import { isBlank } from '@anews/utils'

import { richTextPalette } from './RichTextEditor'

export function toggleRichTextComment(editor: Editor) {
  const { selection } = editor
  if (selection) {
    const fragment: RichTextBlock[] = JSON.parse(JSON.stringify(Editor.fragment(editor, selection)))

    // processa paragrafos individualmente
    fragment.forEach(paragraph => {
      const { children } = paragraph
      if (Array.isArray(children)) {
        if (children.length === 1) {
          if (isBlank(children[0].text)) return
          const isCommented = children[0].text.startsWith('[') && children[0].text.endsWith(']')
          children[0].text = isCommented ? children[0].text.slice(1, -1) : `[${children[0].text}]`
        } else {
          const firstNode = children[0]
          const lastNode = children[children.length - 1]
          if (!firstNode || !lastNode || isBlank(firstNode.text) || isBlank(lastNode.text)) return
          const isCommented = firstNode.text.startsWith('[') && lastNode.text.endsWith(']')
          firstNode.text = isCommented ? firstNode.text.substring(1) : `[${children[0].text}`
          lastNode.text = isCommented ? lastNode.text.slice(0, -1) : `${lastNode.text}]`
        }
      }
    })
    Transforms.insertFragment(editor, fragment, { hanging: true })
  }
}

/**
 * Este hook deve ser utilizado na criação do editor, juntamente com withReact e/ou withHistory
 * */

function extractComment(match: RegExpMatchArray) {
  const [searchMatch] = match
  const { index: matchStartIndex, input } = match
  const matchEndIndex = (matchStartIndex ?? 0) + searchMatch.length
  const excedent = input?.substring(matchEndIndex)
  return { searchMatch, matchEndIndex, matchStartIndex, excedent }
}

function toggleComment(editor: Editor, path: Path, start: number, end: number, isComment: boolean) {
  Transforms.setNodes(
    editor,
    { color: isComment ? richTextPalette.comment : 'inherit' },
    {
      at: {
        anchor: { path, offset: start },
        focus: { path, offset: end },
      },
      match: n => Text.isText(n),
      split: true,
    },
  )
}

export const withComment = (editor: Editor) => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Text.isText(node)) {
      const commentMatch = node.text.match(/(\[)(.+?)(\])/)
      const isComment = node.color === richTextPalette.comment

      if (commentMatch) {
        const { matchStartIndex, matchEndIndex, excedent } = extractComment(commentMatch)

        if (!isComment && !excedent) {
          toggleComment(editor, path, matchStartIndex || 0, matchEndIndex, true)
        } else if (excedent) {
          const multipleComments = excedent.match(/(\[)(.+?)(\])/g)
          if (!multipleComments) {
            toggleComment(editor, path, matchEndIndex, matchEndIndex + excedent.length, false)
          } else {
            const firstOcurrence = excedent.indexOf('[')
            const lastOcurrence = node.text.lastIndexOf(']') + 1
            if (lastOcurrence !== node.text.length) {
              toggleComment(editor, path, lastOcurrence, matchEndIndex, false)
            }
            if (firstOcurrence > 0) {
              toggleComment(editor, path, matchEndIndex, matchEndIndex + firstOcurrence, false)
            }
          }
          return
        }
      }
      if (!commentMatch && isComment) {
        Transforms.setNodes(
          editor,
          { color: 'inherit' },
          {
            match: n => Text.isText(n),
          },
        )
        return
      }
    }
    return normalizeNode([node, path])
  }

  return editor
}
