import styled from '@emotion/styled'

import theme from '../../theme'

const InnerTabsContainer = styled.div`
  & > .ant-tabs-card {
    & > .ant-tabs-nav .ant-tabs-nav-wrap {
      height: inherit;
    }
    & > .ant-tabs-nav .ant-tabs-nav-wrap {
      margin-bottom: -1px;
      overflow: hidden;
    }
    & > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
      font-size: 8px;
    }
    & > .ant-tabs-nav .ant-tabs-tab {
      background: none;
      border: none;
      height: 100%;
      line-height: 17px;
      margin: 0 16px 0 0 !important;
      padding: 0 8px 8px 8px;
    }
    & > .ant-tabs-nav .ant-tabs-tab-active {
      font-weight: 400;
    }
    & > .ant-tabs-nav .ant-tabs-ink-bar {
      visibility: visible;
    }
    & > .ant-tabs-nav .ant-tabs-extra-content {
      line-height: 16px;
    }
    & > .ant-tabs-nav .ant-tabs-nav-add {
      background-color: ${theme.backgrounds.body};
      border-radius: 2px;
      font-size: 8px;
      line-height: 8px;
      padding: 0;
      min-width: auto;
      width: 16px;
      height: 16px;
      text-align: center;
    }
    & > .ant-tabs-content-holder .ant-tabs-content {
      border-color: ${theme.backgrounds.body};

      & > .ant-tabs-tabpane {
        padding: 8px 0 0 0;
      }
    }
  }
`

export default InnerTabsContainer
