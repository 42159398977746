import React, { memo, ReactNode } from 'react'

import Button from 'antd/es/button'
import Modal, { ModalProps } from 'antd/es/modal'

import { useTranslation } from '../../i18n'

interface Props
  extends Omit<
    ModalProps,
    | 'cancelButtonProps'
    | 'cancelText'
    | 'confirmLoading'
    | 'footer'
    | 'okButtonProps'
    | 'okText'
    | 'okType'
    | 'onOk'
  > {
  children: ReactNode
  onNo?: (e: React.MouseEvent<HTMLElement>) => void
  onYes?: (e: React.MouseEvent<HTMLElement>) => void
}

function YesNoCancel({ children, onCancel, onNo, onYes, ...rest }: Props) {
  const { t } = useTranslation()

  return (
    <Modal
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('words:cancel')}
        </Button>,
        <Button key="no" type="primary" onClick={onNo} danger>
          {t('words:no')}
        </Button>,
        <Button key="yes" type="primary" onClick={onYes}>
          {t('words:yes')}
        </Button>,
      ]}
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default memo(YesNoCancel)
