import { get, post, put } from './client';
var endpoint = '/api/ldap';
var ldapApi = {
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
    filterUsers: function (filter) {
        return get(endpoint + "/filter?filter=" + filter);
    },
    synchronizeUsers: function () {
        return post(endpoint + "/sync");
    },
    syncState: function () {
        return get(endpoint + "/sync_state");
    },
};
export default ldapApi;
