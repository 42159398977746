/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useLayoutEffect, useMemo, useState } from 'react'
import Table, { TableProps } from 'antd/es/table'

import { normalizePagination } from '../../utils/pagination-utils'

export const LAYOUT_CONTENT_MARGIN = 8
export const TAB_PADDING = 12
export const ADMIN_CARD_PADDING = 8
export const TH_HEIGHT = 29
export const PAGINATION_HEIGHT = 28
export const PAGINATION_MARGIN = 8

// Quando não tem paginação, considera somente o height do header da tabela e o "bottom" do content geral

const MAIN_NOT_PAGINATED_EXTRA_HEIGHT = LAYOUT_CONTENT_MARGIN + TAB_PADDING + TH_HEIGHT
const MAIN_PAGINATED_EXTRA_HEIGHT =
  MAIN_NOT_PAGINATED_EXTRA_HEIGHT + PAGINATION_HEIGHT + PAGINATION_MARGIN

const ADMIN_NOT_PAGINATED_EXTRA_HEIGHT = MAIN_NOT_PAGINATED_EXTRA_HEIGHT + ADMIN_CARD_PADDING
const ADMIN_PAGINATED_EXTRA_HEIGHT =
  ADMIN_NOT_PAGINATED_EXTRA_HEIGHT + PAGINATION_HEIGHT + PAGINATION_MARGIN

// header preview: 47 de width + 14 de padding, não precisou considerar o header do anews nem footer
const PREVIEW_NOT_PAGINATED_EXTRA_HEIGHT = 61

interface Props<T extends object = any> extends TableProps<T> {
  admin?: boolean
  autoScroll?: boolean
  preview?: boolean
}

const CompactTable = <T extends object = any>({
  admin,
  autoScroll,
  pagination,
  preview,
  rowSelection,
  scroll,
  style,
  ...props
}: Props<T>) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null) // usRef não dispara re-render
  const [fix, setFix] = useState(0)

  const consts = useMemo(
    () =>
      admin
        ? [ADMIN_PAGINATED_EXTRA_HEIGHT, ADMIN_NOT_PAGINATED_EXTRA_HEIGHT]
        : preview
        ? [PREVIEW_NOT_PAGINATED_EXTRA_HEIGHT]
        : [MAIN_PAGINATED_EXTRA_HEIGHT, MAIN_NOT_PAGINATED_EXTRA_HEIGHT],
    [admin, preview],
  )

  useLayoutEffect(() => {
    if (autoScroll && ref) {
      const { top } = ref.getBoundingClientRect()

      // top fica zero quando a aba é trocada, então deve ignorar
      if (top === 0) {
        return
      }

      const calc = top + (preview ? consts[0] : consts[pagination ? 0 : 1])
      // Acontecia de aparecer um valor de top muito alto em re-render do componente,
      // então evita utilizar um calc maior do que o corrente
      setFix(curr => (curr === 0 || calc < curr ? calc : curr))
    }
  }, [autoScroll, consts, pagination, preview, ref])

  const dynamicScroll = useMemo(() => (fix > 0 ? { y: `calc(100vh - ${fix}px)` } : undefined), [
    fix,
  ])

  const ajustedPagination = normalizePagination(pagination)

  const ajustedRowSelection =
    !rowSelection || rowSelection.columnWidth ? rowSelection : { ...rowSelection, columnWidth: 28 }

  return (
    <div ref={divRef => setRef(divRef)} style={style}>
      <Table
        scroll={scroll || dynamicScroll}
        pagination={ajustedPagination}
        rowSelection={ajustedRowSelection}
        css={css`
          .ant-table-small .ant-table-selection-column {
            min-width: 28px;
            width: 28px;
          }
          .ant-pagination {
            margin-top: ${PAGINATION_MARGIN}px;
            margin-bottom: 0;
          }
        `}
        size="small"
        {...props}
      />
    </div>
  )
}

export default CompactTable
