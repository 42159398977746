import { createSelector, Selector } from 'reselect'

import { RootState } from '../reducers'

export interface SelectorMap<T> {
  [key: number]: T
  [key: string]: T
}

export function createMap<T, P, R extends SelectorMap<P> = SelectorMap<P>>(
  values: T[],
  keyProp: string,
  labelProp: string | ((value: T) => P),
): R {
  const map: R = {} as R

  values.forEach((p: any) => {
    if (typeof labelProp === 'function') {
      map[p[keyProp]] = labelProp(p)
    } else {
      map[p[keyProp]] = p[labelProp]
    }
  })

  return map
}

export function createMapSelector<T, P, R extends SelectorMap<P> = SelectorMap<P>>(
  stateSelector: (state: RootState) => T[],
  keyProp: string,
  labelProp: string | ((value: T) => P),
): Selector<RootState, R> {
  return createSelector([stateSelector], (values = []) => createMap(values, keyProp, labelProp))
}
