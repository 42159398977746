import { call, put, takeLatest } from 'redux-saga/effects'

import { contactApi as api } from '@anews/api'
import { AgendaContact, Contact, Page } from '@anews/types'

import {
  ActionType,
  filterContactsFailure,
  FilterContactsAction,
  filterContactsSuccess,
  LoadContactAction,
  loadContactSuccess,
  loadContactFailure,
  CreateContactAction,
  createContactSuccess,
  createContactFailure,
  UpdateContactAction,
  updateContactSuccess,
  updateContactFailure,
  RemoveContactAction,
  removeContactSuccess,
  removeContactFailure,
} from '../actions/contact-actions'
import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* filterContactsSaga(action: FilterContactsAction): Generator {
  try {
    const result = yield call(api.pageFilter, action.filter, action.page, action.size)
    yield put(filterContactsSuccess(result as Page<AgendaContact>))
  } catch (error) {
    yield put(filterContactsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadContactSaga(action: LoadContactAction): Generator {
  try {
    const contact = yield call(api.load, action.id)
    yield put(loadContactSuccess(contact as Contact, action.edit))
  } catch (error) {
    yield put(loadContactFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createContactSaga(action: CreateContactAction): Generator {
  try {
    const contact = yield call(api.create, action.contact, action.hidden)
    yield put(createContactSuccess(contact as Contact, action.hidden))
  } catch (error) {
    yield put(createContactFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateContactSaga(action: UpdateContactAction): Generator {
  try {
    const contact = yield call(api.update, action.contact, action.hidden)
    yield put(updateContactSuccess(contact as Contact, action.hidden))
  } catch (error) {
    yield put(updateContactFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeContactSaga(action: RemoveContactAction): Generator {
  try {
    yield call(api.remove, action.ids)
    yield put(removeContactSuccess(action.ids))
  } catch (error) {
    yield put(removeContactFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.FILTER_REQUEST, filterContactsSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_REQUEST, loadContactSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createContactSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateContactSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeContactSaga)
  },
])
