import React from 'react'
import copy from 'copy-text-to-clipboard'

import antMessage from 'antd/es/message'
import notification, { ArgsProps, IconType } from 'antd/es/notification'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import { memoizeDebounce } from '@anews/utils'

import i18n from '../i18n'

function calculateDuration(message?: string, description?: string) {
  // Calcula a duração da mensagem de acordo com a quantidade de texto dela
  // O tempo de leitura médio é de 0.07, então utiliza o dobro disso
  return ((message || '').length + (description || '').length) * 0.14
}

type NotifyArgs = ArgsProps & {
  category: IconType
  details: string
  icon?: IconDefinition
  manualDismiss?: boolean
}

export function notify({
  category,
  key,
  message,
  description,
  placement,
  duration,
  details,
  icon,
  manualDismiss,
  onClick,
}: NotifyArgs) {
  notification[category]({
    key,
    placement,
    message,

    icon: icon && <FontAwesomeIcon size="lg" icon={icon} style={{ margin: 'auto' }} />,

    duration: manualDismiss
      ? null
      : duration || calculateDuration(message as string, description as string),

    description: details ? (
      <>
        <span>{description}</span>
        <br />
        <br />
        <span>{i18n.t('error:copy')}</span>
      </>
    ) : (
      description
    ),

    onClick: () => {
      if (onClick) {
        onClick()
      }
      if (details) {
        copy(details)
        antMessage.info(i18n.t('error:copied'))

        if (key) {
          notification.close(key)
        }
      }
    },
  })
}

/*
 * Isso evita que mensagens com o mesmo conteúdo (message/description) sejam
 * mostradas uma após a outra dentro do intervalo de 500 millis.
 *
 * Isso era um problema principalmente quando a sessão do usuário era encerrada.
 * Ao tentar carregar uma área qualquer o sistema exibia uma mensagem para cada
 * requisição feita ao servidor.
 */
export const notifyOnce = memoizeDebounce<(args: NotifyArgs) => void>(notify, 500, {
  resolver: ({ message, description, placement }: NotifyArgs) =>
    JSON.stringify({ message, description, placement }),
})

export function closeNotification(key: string) {
  notification.close(key)
}

// //////// Notificação Chat
export const showNotification = (
  title: string,
  body: string,
  username: string,
  visible: boolean,
  onOpen?: () => void,
) => {
  if (Notification.permission === 'granted') {
    const notifWindow = new window.Notification(title, {
      body,
      tag: `${username}-messages`, // essa tag serve para não repetir a notificação várias vezes
      icon: `${process.env.PUBLIC_URL}/favicon.ico`,
    })

    // fecha a notificação depois de 5 segundos
    const timeoutId = setTimeout(() => notifWindow.close(), 5000)

    // foca a aba do anews e abre a conversa
    notifWindow.onclick = (event: Event) => {
      event.preventDefault()
      !visible && onOpen && onOpen()
      window.focus()
      notifWindow.close()
    }

    notifWindow.onclose = (event: Event) => {
      clearTimeout(timeoutId)
    }
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        showNotification(title, body, username, visible, onOpen)
      }
    })
  }
}

export const playNewMessageSound = async () => {
  try {
    await new Audio(`${process.env.PUBLIC_URL}/beep.mp3`).play()
  } catch (reason) {
    console.warn('Audio not supported', reason)
  }
}

// //////////
