import { del, get, post, put } from './client';
var endpoint = '/api/classifications';
var classificationApi = {
    list: function () {
        return get(endpoint);
    },
    usageCount: function (id) {
        return get(endpoint + "/" + id + "/usage_count");
    },
    create: function (category) {
        return post(endpoint, category);
    },
    update: function (category) {
        return put("" + endpoint, category);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default classificationApi;
