import { RundownConfig, Rundown, Block, Story } from '@anews/types'

export enum RundownActionType {
  CLEAR = '@rundown/CLEAR',
  // tabs
  CLOSE_TAB = '@rundown/CLOSE_TAB',
  CHANGE_TAB = '@rundown/CHANGE_TAB',
  NEW_TAB = '@rundown/NEW_TAB',
  COPY_CUT_SELECT = '@rundown/COPY_CUT_SELECT',
  COPY_CUT_CLEAR = '@rundown/COPY_CUT_CLEAR',
  PASTE = '@rundown/PASTE',
  SET_TAB_EXTRAS = '@rundown/SET_TAB_EXTRAS',
  // load rundown
  LOAD_REQUEST = '@rundown/LOAD_REQUEST',
  LOAD_SUCCESS = '@rundown/LOAD_SUCCESS',
  LOAD_FAILURE = '@rundown/LOAD_FAILURE',
  // create rundown
  CREATE_REQUEST = '@rundown/CREATE_REQUEST',
  CREATE_SUCCESS = '@rundown/CREATE_SUCCESS',
  CREATE_FAILURE = '@rundown/CREATE_FAILURE',
  // patch rundown
  PATCH_REQUEST = '@rundown/PATCH_REQUEST',
  PATCH_SUCCESS = '@rundown/PATCH_SUCCESS',
  PATCH_FAILURE = '@rundown/PATCH_FAILURE',
  // create block
  CREATE_BLOCK_REQUEST = '@rundown/CREATE_BLOCK_REQUEST',
  CREATE_BLOCK_SUCCESS = '@rundown/CREATE_BLOCK_SUCCESS',
  CREATE_BLOCK_FAILURE = '@rundown/CREATE_BLOCK_FAILURE',
  // patch block
  PATCH_BLOCK_REQUEST = '@rundown/PATCH_BLOCK_REQUEST',
  PATCH_BLOCK_SUCCESS = '@rundown/PATCH_BLOCK_SUCCESS',
  PATCH_BLOCK_FAILURE = '@rundown/PATCH_BLOCK_FAILURE',
  // remove block
  REMOVE_BLOCK_REQUEST = '@rundown/REMOVE_BLOCK_REQUEST',
  REMOVE_BLOCK_SUCCESS = '@rundown/REMOVE_BLOCK_SUCCESS',
  REMOVE_BLOCK_FAILURE = '@rundown/REMOVE_BLOCK_FAILURE',
  // toggleCurrentStatus()
  TOGGLE_CURRENT_STATUS_REQUEST = '@rundown/TOGGLE_CURRENT_STATUS_REQUEST',
  TOGGLE_CURRENT_STATUS_SUCCESS = '@rundown/TOGGLE_CURRENT_STATUS_SUCCESS',
  TOGGLE_CURRENT_STATUS_FAILURE = '@rundown/TOGGLE_CURRENT_STATUS_FAILURE',
  // toggleBlockStatus()
  TOGGLE_BLOCK_STATUS_REQUEST = '@rundown/TOGGLE_BLOCK_STATUS_REQUEST',
  TOGGLE_BLOCK_STATUS_SUCCESS = '@rundown/TOGGLE_BLOCK_STATUS_SUCCESS',
  TOGGLE_BLOCK_STATUS_FAILURE = '@rundown/TOGGLE_BLOCK_STATUS_FAILURE',
  // create story
  CREATE_STORY_REQUEST = '@rundown/CREATE_STORY_REQUEST',
  CREATE_STORY_SUCCESS = '@rundown/CREATE_STORY_SUCCESS',
  CREATE_STORY_FAILURE = '@rundown/CREATE_STORY_FAILURE',
  // create story
  CREATE_STORY_AFTER_REQUEST = '@rundown/CREATE_STORY_AFTER_REQUEST',
  CREATE_STORY_AFTER_SUCCESS = '@rundown/CREATE_STORY_AFTER_SUCCESS',
  CREATE_STORY_AFTER_FAILURE = '@rundown/CREATE_STORY_AFTER_FAILURE',
  // move stories
  MOVE_STORIES_REQUEST = '@rundown/MOVE_STORIES_REQUEST',
  MOVE_STORIES_SUCCESS = '@rundown/MOVE_STORIES_SUCCESS',
  MOVE_STORIES_FAILURE = '@rundown/MOVE_STORIES_FAILURE',
  // copy stories
  COPY_PASTE_STORIES_REQUEST = '@rundown/COPY_PASTE_STORIES_REQUEST',
  COPY_PASTE_STORIES_SUCCESS = '@rundown/COPY_PASTE_STORIES_SUCCESS',
  COPY_PASTE_STORIES_FAILURE = '@rundown/COPY_PASTE_STORIES_FAILURE',
  // repaginate stories
  REPAGINATE_REQUEST = '@rundown/REPAGINATE_REQUEST',
  REPAGINATE_SUCCESS = '@rundown/REPAGINATE_SUCCESS',
  REPAGINATE_FAILURE = '@rundown/REPAGINATE_FAILURE',
  // approve Rundown
  APPROVE_RUNDOWN_REQUEST = '@rundown/APPROVE_RUNDOWN_REQUEST',
  APPROVE_RUNDOWN_SUCCESS = '@rundown/APPROVE_RUNDOWN_SUCCESS',
  APPROVE_RUNDOWN_FAILURE = '@rundown/APPROVE_RUNDOWN_FAILURE',
  // approve Rundown Block
  APPROVE_RUNDOWN_BLOCK_REQUEST = '@rundown/APPROVE_RUNDOWN_BLOCK_REQUEST',
  APPROVE_RUNDOWN_BLOCK_SUCCESS = '@rundown/APPROVE_RUNDOWN_BLOCK_SUCCESS',
  APPROVE_RUNDOWN_BLOCK_FAILURE = '@rundown/APPROVE_RUNDOWN_BLOCK_FAILURE',
  // send credits
  SEND_CREDITS_REQUEST = '@rundown/SEND_CREDITS_REQUEST',
  SEND_CREDITS_SUCCESS = '@rundown/SEND_CREDITS_SUCCESS',
  SEND_CREDITS_FAILURE = '@rundown/SEND_CREDITS_FAILURE',
  // mos enable
  ENABLE_MOS_REQUEST = '@rundown/ENABLE_MOS_REQUEST',
  ENABLE_MOS_SUCCESS = '@rundown/ENABLE_MOS_SUCCESS',
  ENABLE_MOS_FAILURE = '@rundown/ENABLE_MOS_FAILURE',
  // mos disable
  DISABLE_MOS_REQUEST = '@rundown/DISABLE_MOS_REQUEST',
  DISABLE_MOS_SUCCESS = '@rundown/DISABLE_MOS_SUCCESS',
  DISABLE_MOS_FAILURE = '@rundown/DISABLE_MOS_FAILURE',
  // mos roReadyToAir
  RUN_MOS_ACTION_REQUEST = '@rundown/RUN_MOS_ACTION_REQUEST',
  RUN_MOS_ACTION_SUCCESS = '@rundown/RUN_MOS_ACTION_SUCCESS',
  RUN_MOS_ACTION_FAILURE = '@rundown/RUN_MOS_ACTION_FAILURE',
  // loadTpContent
  LOAD_TP_CONTENT_REQUEST = '@rundown/LOAD_TP_CONTENT_REQUEST',
  LOAD_TP_CONTENT_SUCCESS = '@rundown/LOAD_TP_CONTENT_SUCCESS',
  LOAD_TP_CONTENT_FAILURE = '@rundown/LOAD_TP_CONTENT_FAILURE',
  // websocket
  WS_PATCH = '@rundown/WS_PATCH',
  WS_UPDATE_META = '@rundown/WS_UPDATE_META',
  WS_CREATE_BLOCK = '@rundown/WS_CREATE_BLOCK',
  WS_DELETE_BLOCK = '@rundown/WS_DELETE_BLOCK',
  WS_PATCH_BLOCK = '@rundown/WS_PATCH_BLOCK',
  WS_UPDATE_BLOCK_META = '@rundown/WS_UPDATE_BLOCK_META',
  WS_CREATE_STORY = '@rundown/WS_CREATE_STORY',
  WS_MOVE_STORIES = '@rundown/WS_MOVE_STORIES',
  // load config
  LOAD_CONFIG_REQUEST = '@rundown/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@rundown/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@rundown/LOAD_CONFIG_FAILURE',
  // create config
  CREATE_CONFIG_REQUEST = '@rundown/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@rundown/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@rundown/CREATE_CONFIG_FAILURE',
  // update config
  UPDATE_CONFIG_REQUEST = '@rundown/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@rundown/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@rundown/UPDATE_CONFIG_FAILURE',
}

export type RundownActionMap<T extends RundownActionType> = { type: T } & RundownAction

export type RundownAction = ReturnType<typeof RundownActions[keyof typeof RundownActions]>

export type RundownMosAction = 'create' | 'ready' | 'replace'

export const RundownActions = {
  //
  //  clear
  //

  clearRundowns: () => <const>{ type: RundownActionType.CLEAR },

  //
  //  copy & cut
  //

  copySelect: (targets: number[]) =>
    <const>{ type: RundownActionType.COPY_CUT_SELECT, operation: 'copy', targets },
  cutSelect: (targets: number[]) =>
    <const>{ type: RundownActionType.COPY_CUT_SELECT, operation: 'cut', targets },
  clearCopyCut: () => <const>{ type: RundownActionType.COPY_CUT_CLEAR },

  //
  //  tabs
  //

  setTabExtras: (key: string, extras: { suffix?: string }) =>
    <const>{ type: RundownActionType.SET_TAB_EXTRAS, key, extras },
  changeTab: (key: string) => <const>{ type: RundownActionType.CHANGE_TAB, key },
  closeTab: (key: string) => <const>{ type: RundownActionType.CLOSE_TAB, key },
  newTab: () => <const>{ type: RundownActionType.NEW_TAB },

  //
  //	loadRundown()
  //

  loadRundown: (uuid: string, date: string, programId: number) =>
    <const>{ type: RundownActionType.LOAD_REQUEST, uuid, date, programId },
  loadRundownSuccess: (rundown: Rundown, uuid: string, date: string, programId: number) =>
    <const>{ type: RundownActionType.LOAD_SUCCESS, rundown, uuid, date, programId },
  loadRundownFailure: (error: Error) => <const>{ type: RundownActionType.LOAD_FAILURE, error },

  //
  //	createRundown()
  //

  createRundown: (uuid: string, date: string, programId: number, templateId: number | undefined) =>
    <const>{ type: RundownActionType.CREATE_REQUEST, uuid, date, programId, templateId },
  createRundownSuccess: (rundown: Rundown, uuid: string, date: string, programId: number) =>
    <const>{ type: RundownActionType.CREATE_SUCCESS, rundown, uuid, date, programId },
  createRundownFailure: (error: Error) => <const>{ type: RundownActionType.CREATE_FAILURE, error },

  //
  //	patchRundown()
  //

  patchRundown: (rundownId: number, field: keyof Rundown, newValue: any, oldValue: any) =>
    <const>{ type: RundownActionType.PATCH_REQUEST, rundownId, field, newValue, oldValue },
  patchRundownSuccess: () => <const>{ type: RundownActionType.PATCH_SUCCESS },
  patchRundownFailure: (
    error: Error,
    rundownId: number,
    field: keyof Rundown,
    newValue: any,
    oldValue: any,
  ) =>
    <const>{ type: RundownActionType.PATCH_FAILURE, error, rundownId, field, newValue, oldValue },

  //
  //	createBlock()
  //

  createBlock: (rundownId: number) =>
    <const>{ type: RundownActionType.CREATE_BLOCK_REQUEST, rundownId },
  createBlockSuccess: (block: Block) =>
    <const>{ type: RundownActionType.CREATE_BLOCK_SUCCESS, block },
  createBlockFailure: (error: Error) =>
    <const>{ type: RundownActionType.CREATE_BLOCK_FAILURE, error },

  //
  //	patchBlock()
  //

  patchBlock: (blockId: number, field: keyof Block, newValue: any, oldValue: any) =>
    <const>{ type: RundownActionType.PATCH_BLOCK_REQUEST, blockId, field, newValue, oldValue },
  patchBlockSuccess: () => <const>{ type: RundownActionType.PATCH_BLOCK_SUCCESS },
  patchBlockFailure: (
    error: Error,
    blockId: number,
    field: keyof Block,
    newValue: any,
    oldValue: any,
  ) =>
    <const>{
      type: RundownActionType.PATCH_BLOCK_FAILURE,
      error,
      blockId,
      field,
      newValue,
      oldValue,
    },

  //
  //  toggleCurrentStatus()
  //

  toggleCurrentStatus: (id: number) =>
    <const>{ type: RundownActionType.TOGGLE_CURRENT_STATUS_REQUEST, id },
  toggleCurrentStatusSuccess: () =>
    <const>{ type: RundownActionType.TOGGLE_CURRENT_STATUS_SUCCESS },
  toggleCurrentStatusFailure: (error: Error) =>
    <const>{ type: RundownActionType.TOGGLE_CURRENT_STATUS_FAILURE, error },

  //
  //  toggleBlockStatus()
  //

  toggleBlockStatus: (id: number) =>
    <const>{ type: RundownActionType.TOGGLE_BLOCK_STATUS_REQUEST, id },
  toggleBlockStatusSuccess: () => <const>{ type: RundownActionType.TOGGLE_BLOCK_STATUS_SUCCESS },
  toggleBlockStatusFailure: (error: Error) =>
    <const>{ type: RundownActionType.TOGGLE_BLOCK_STATUS_FAILURE, error },

  //
  //	removeBlock()
  //

  removeBlock: (blockId: number) =>
    <const>{ type: RundownActionType.REMOVE_BLOCK_REQUEST, blockId },
  removeBlockSuccess: (blockId: number) =>
    <const>{ type: RundownActionType.REMOVE_BLOCK_SUCCESS, blockId },
  removeBlockFailure: (error: Error) =>
    <const>{ type: RundownActionType.REMOVE_BLOCK_FAILURE, error },

  //
  //	createStory()
  //

  createStory: (blockId: number) =>
    <const>{ type: RundownActionType.CREATE_STORY_REQUEST, blockId },
  createStorySuccess: (story: Story) =>
    <const>{ type: RundownActionType.CREATE_STORY_SUCCESS, story },
  createStoryFailure: (error: Error) =>
    <const>{ type: RundownActionType.CREATE_STORY_FAILURE, error },

  //
  //	createStoryAfter()
  //

  createStoryAfter: (afterStory: number) =>
    <const>{ type: RundownActionType.CREATE_STORY_AFTER_REQUEST, afterStory },
  createStoryAfterSuccess: (story: Story) =>
    <const>{ type: RundownActionType.CREATE_STORY_AFTER_SUCCESS, story },
  createStoryAfterFailure: (error: Error) =>
    <const>{ type: RundownActionType.CREATE_STORY_AFTER_FAILURE, error },

  //
  //  pasteStories()
  //

  pasteStories: (targetBlockId: number, targetStoryId: number | undefined) =>
    <const>{ type: RundownActionType.PASTE, targetBlockId, targetStoryId },

  //
  //	moveStories()
  //

  moveStories: (storiesIds: number[], targetBlockId: number, targetStoryId: number | undefined) =>
    <const>{
      type: RundownActionType.MOVE_STORIES_REQUEST,
      storiesIds,
      targetBlockId,
      targetStoryId,
    },
  moveStoriesSuccess: () => <const>{ type: RundownActionType.MOVE_STORIES_SUCCESS },
  moveStoriesFailure: (
    error: Error,
    storiesIds: number[],
    targetBlockId: number,
    targetStoryId: number | undefined,
  ) =>
    <const>{
      type: RundownActionType.MOVE_STORIES_FAILURE,
      error,
      storiesIds,
      targetBlockId,
      targetStoryId,
    },

  //
  //	copyPasteStories()
  //

  copyPasteStories: (
    storiesIds: number[],
    targetBlockId: number,
    targetStoryId: number | undefined,
  ) =>
    <const>{
      type: RundownActionType.COPY_PASTE_STORIES_REQUEST,
      storiesIds,
      targetBlockId,
      targetStoryId,
    },
  copyPasteStoriesSuccess: (targetBlockId: number, targetStoryId: number | undefined) =>
    <const>{ type: RundownActionType.COPY_PASTE_STORIES_SUCCESS, targetBlockId, targetStoryId },
  copyPasteStoriesFailure: (error: Error) =>
    <const>{ type: RundownActionType.COPY_PASTE_STORIES_FAILURE, error },

  //
  //	repaginate()
  //

  repaginate: (rundownId: number) =>
    <const>{ type: RundownActionType.REPAGINATE_REQUEST, rundownId },
  repaginateSuccess: () => <const>{ type: RundownActionType.REPAGINATE_SUCCESS },
  repaginateFailure: (error: Error) => <const>{ type: RundownActionType.REPAGINATE_FAILURE, error },

  //
  //	approveRundown()
  //

  approveRundown: (rundownId: number, approve: boolean) =>
    <const>{ type: RundownActionType.APPROVE_RUNDOWN_REQUEST, rundownId, approve },
  approveRundownSuccess: () => <const>{ type: RundownActionType.APPROVE_RUNDOWN_SUCCESS },
  approveRundownFailure: (error: Error) =>
    <const>{ type: RundownActionType.APPROVE_RUNDOWN_FAILURE, error },

  //
  //	approveRundownblock()
  //

  approveRundownBlock: (blockId: number, approve: boolean) =>
    <const>{ type: RundownActionType.APPROVE_RUNDOWN_BLOCK_REQUEST, blockId, approve },
  approveRundownBlockSuccess: () =>
    <const>{ type: RundownActionType.APPROVE_RUNDOWN_BLOCK_SUCCESS },
  approveRundownBlockFailure: (error: Error) =>
    <const>{ type: RundownActionType.APPROVE_RUNDOWN_BLOCK_FAILURE, error },

  //
  //  loadTpContent()
  //

  loadTpContent: (id: number) => <const>{ type: RundownActionType.LOAD_TP_CONTENT_REQUEST, id },
  loadTpContentSuccess: (stories: Story[]) =>
    <const>{ type: RundownActionType.LOAD_TP_CONTENT_SUCCESS, stories },
  loadTpContentFailure: (error: Error) =>
    <const>{ type: RundownActionType.LOAD_TP_CONTENT_FAILURE, error },

  //
  //  sendRundownCredits()
  //

  sendRundownCredits: (rundownId: number) =>
    <const>{ type: RundownActionType.SEND_CREDITS_REQUEST, rundownId },
  sendRundownCreditsSuccess: (success: boolean) =>
    <const>{ type: RundownActionType.SEND_CREDITS_SUCCESS, success },
  sendRundownCreditsFailure: (error: Error) =>
    <const>{ type: RundownActionType.SEND_CREDITS_FAILURE, error },

  //
  //  enableMos()
  //

  enableMos: (rundownId: number) =>
    <const>{ type: RundownActionType.ENABLE_MOS_REQUEST, rundownId },
  enableMosSuccess: (devicesNames: string[]) =>
    <const>{ type: RundownActionType.ENABLE_MOS_SUCCESS, devicesNames },
  enableMosFailure: (error: Error) => <const>{ type: RundownActionType.ENABLE_MOS_FAILURE, error },

  //
  //  disableMos()
  //

  disableMos: (rundownId: number) =>
    <const>{ type: RundownActionType.DISABLE_MOS_REQUEST, rundownId },
  disableMosSuccess: (devicesNames: string[]) =>
    <const>{ type: RundownActionType.DISABLE_MOS_SUCCESS, devicesNames },
  disableMosFailure: (error: Error) =>
    <const>{ type: RundownActionType.DISABLE_MOS_FAILURE, error },

  //
  //  runMosAction()
  //

  runMosAction: (rundownId: number, deviceId: number | null, action: RundownMosAction) =>
    <const>{ type: RundownActionType.RUN_MOS_ACTION_REQUEST, rundownId, deviceId, action },
  runMosActionSuccess: (devicesNames: string[]) =>
    <const>{ type: RundownActionType.RUN_MOS_ACTION_SUCCESS, devicesNames },
  runMosActionFailure: (error: Error) =>
    <const>{ type: RundownActionType.RUN_MOS_ACTION_FAILURE, error },

  //
  //	rundown websocket updates
  //

  wsPatchRundown: (rundownId: number, changes: Partial<Rundown>) =>
    <const>{ type: RundownActionType.WS_PATCH, rundownId, changes },
  wsUpdateRundownMeta: (rundown: Rundown) =>
    <const>{ type: RundownActionType.WS_UPDATE_META, rundown },

  //
  //	block websocket updates
  //

  wsCreateBlock: (block: Block) => <const>{ type: RundownActionType.WS_CREATE_BLOCK, block },
  wsUpdateBlockMeta: (block: Block) =>
    <const>{ type: RundownActionType.WS_UPDATE_BLOCK_META, block },
  wsPatchBlock: (blockId: number, rundownId: number, changes: Partial<Block>) =>
    <const>{ type: RundownActionType.WS_PATCH_BLOCK, blockId, rundownId, changes },
  wsDeleteBlock: (blockId: number) => <const>{ type: RundownActionType.WS_DELETE_BLOCK, blockId },

  //
  //	story websocket updates
  //

  wsCreateStory: (stories: Story[], targetStoryId: number | undefined) =>
    <const>{ type: RundownActionType.WS_CREATE_STORY, stories, targetStoryId },
  wsMoveStories: (blockId: number, blockStoriesIds: number[], movedStories: Story[]) =>
    <const>{ type: RundownActionType.WS_MOVE_STORIES, blockId, blockStoriesIds, movedStories },

  //
  //  loadConfig()
  //

  loadConfig: () => <const>{ type: RundownActionType.LOAD_CONFIG_REQUEST },
  loadConfigSuccess: (config: RundownConfig) =>
    <const>{ type: RundownActionType.LOAD_CONFIG_SUCCESS, config },
  loadConfigFailure: (error: Error) =>
    <const>{ type: RundownActionType.LOAD_CONFIG_FAILURE, error },

  //
  //  createConfig()
  //

  createConfig: (config: RundownConfig) =>
    <const>{ type: RundownActionType.CREATE_CONFIG_REQUEST, config },
  createConfigSuccess: (config: RundownConfig) =>
    <const>{ type: RundownActionType.CREATE_CONFIG_SUCCESS, config },
  createConfigFailure: (error: Error) =>
    <const>{ type: RundownActionType.CREATE_CONFIG_FAILURE, error },

  //
  //  updateConfig()
  //

  updateConfig: (config: RundownConfig) =>
    <const>{ type: RundownActionType.UPDATE_CONFIG_REQUEST, config },
  updateConfigSuccess: (config: RundownConfig) =>
    <const>{ type: RundownActionType.UPDATE_CONFIG_SUCCESS, config },
  updateConfigFailure: (error: Error) =>
    <const>{ type: RundownActionType.UPDATE_CONFIG_FAILURE, error },
}
