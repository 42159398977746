import React, { useMemo, memo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from '@emotion/styled'
import { faBars } from '@fortawesome/pro-solid-svg-icons'

import Checkbox from 'antd/es/checkbox'
import Empty from 'antd/es/empty'

import theme from '../../../theme'

import DoubleIcon from '../DoubleIcon'

import Cells from './Cells'

import { ColumnProps, DataSource, DraggableIdProp, RowKey, SELECT_COLUMN_WIDTH } from './index'

const SelectionCount = styled.div`
  right: 14px;
  top: -14px;
  color: white;
  background: ${theme.colors.blue};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  margin: auto;
  font-size: 0.8rem;
`

interface TRowProps {
  dragging: boolean
}

const TRow = memo(
  styled.tr<TRowProps>`
    border: ${props => (props.dragging ? `1px dashed ${theme.border}` : 'none')};
  `,
)

const emptyFunction = (returnData: any) => () => returnData

interface TBodyRowsProps {
  columns: ColumnProps[]
  dataSource: DataSource
  dragCount?: number
  dragDisabled?: boolean | ((record: any) => boolean)
  draggableIdProp: DraggableIdProp
  firstColumn: 'checkbox' | 'bars' | 'none'
  rowKey: RowKey
  selectedRowKeys: number[]
  selectionDisabled?: boolean
  rowClassName?: (record: any, index: number) => string
  onRow?: (record: any, index: number) => object
  onSelectionChange: (key: number, selected: boolean) => void
}

const TBodyRows = (props: TBodyRowsProps) => {
  const {
    columns,
    dataSource,
    draggableIdProp,
    dragCount = 0,
    dragDisabled,
    firstColumn,
    onRow,
    onSelectionChange,
    rowKey,
    selectedRowKeys,
    selectionDisabled,
    rowClassName,
  } = props

  const defineClassName = useMemo(
    () => (typeof rowClassName === 'function' ? rowClassName : emptyFunction('')),
    [rowClassName],
  )
  const defineProps = useMemo(() => (typeof onRow === 'function' ? onRow : emptyFunction({})), [
    onRow,
  ])

  if (!dataSource.length) {
    return (
      <tr style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
        <td colSpan={100} style={{ textAlign: 'center' }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '8px 0' }} />
        </td>
      </tr>
    )
  }
  // teste

  return (
    <>
      {dataSource.map((record, index) => {
        const recordKey = record[rowKey]
        const rowProps = defineProps(record, index)

        return (
          <Draggable
            key={recordKey}
            draggableId={`${record[draggableIdProp]}`}
            isDragDisabled={
              typeof dragDisabled === 'function' ? dragDisabled(record) : dragDisabled
            }
            index={index}
          >
            {(provided, snapshot) => {
              const { style: rowPropsStyle, ...rowRestProps } = rowProps
              const { style: draggableStyle, ...draggableProps } = provided.draggableProps

              const style = { ...(rowPropsStyle || {}), ...(draggableStyle || {}) }

              return (
                // Devido às mutações de provided/snapshot, esse element sofrerá mudanças constantes
                // É importante que seus filhos usem PureComponent ou memo()
                <TRow
                  {...rowRestProps}
                  {...draggableProps}
                  {...provided.dragHandleProps}
                  style={style}
                  key={recordKey}
                  ref={provided.innerRef}
                  dragging={snapshot.isDragging}
                  className={defineClassName(record, index)}
                >
                  {!selectionDisabled && (
                    <td
                      style={{
                        width: SELECT_COLUMN_WIDTH,
                        position: 'relative',
                        overflow: 'visible',
                      }}
                    >
                      {firstColumn === 'checkbox' ? (
                        <Checkbox
                          checked={selectedRowKeys.includes(parseInt(recordKey, 10))}
                          onChange={e => onSelectionChange(recordKey, e.target.checked)}
                        />
                      ) : null}

                      {firstColumn === 'bars' ? (
                        <DoubleIcon style={{ width: '100%' }} icon={faBars} />
                      ) : null}

                      {/* Mostra contador de itens ao arrastar */}
                      {snapshot.isDragging && dragCount > 1 && (
                        <SelectionCount>{dragCount <= 99 ? dragCount : '99+'}</SelectionCount>
                      )}
                    </td>
                  )}
                  <Cells
                    rowKey={rowKey}
                    record={record}
                    columns={columns}
                    isSelected={selectedRowKeys.includes(recordKey)}
                    disabled={rowProps.disabled}
                  />
                </TRow>
              )
            }}
          </Draggable>
        )
      })}
    </>
  )
}

export default memo(TBodyRows)
