import { get, query } from './client';
var endpoint = '/api/maps';
var googleApi = {
    getLocation: function (apiKey) {
        return get(query(endpoint + "/location", { apiKey: apiKey }));
    },
    autocomplete: function (input, location) {
        return get(query(endpoint + "/autocomplete", { input: input, location: location }));
    },
    openOnMaps: function (address) {
        var touchDevice = 'ontouchstart' in document.documentElement;
        var link = query(endpoint + "/view", { q: address });
        window.open(link, touchDevice ? '_self' : '_blank');
    },
};
export default googleApi;
