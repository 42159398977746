// https://ant.design/docs/spec/colors
export var playingColorOptions = {
    '#ff4d4f': '#ffa39e',
    '#ffa940': '#ffd591',
    '#ffec3d': '#fffb8f',
    '#73d13d': '#b7eb8f',
    '#4096ff': '#91caff',
    '#9254de': '#d3adf7',
    '#f759ab': '#ffadd2', // Magenta
};
export var RundownCreateStatus;
(function (RundownCreateStatus) {
    RundownCreateStatus["EXIST"] = "EXIST";
    RundownCreateStatus["CAN_CREATE"] = "CAN_CREATE";
    RundownCreateStatus["CANT_CREATE"] = "CANT_CREATE";
})(RundownCreateStatus || (RundownCreateStatus = {}));
export var DisplayStatus;
(function (DisplayStatus) {
    DisplayStatus["PLAY"] = "PLAY";
    DisplayStatus["STOP"] = "STOP";
    DisplayStatus["BLANK"] = "BLANK";
})(DisplayStatus || (DisplayStatus = {}));
export var RundownLogCategory;
(function (RundownLogCategory) {
    RundownLogCategory["RUNDOWN"] = "RUNDOWN";
    RundownLogCategory["BLOCK"] = "BLOCK";
    RundownLogCategory["STORY"] = "STORY";
})(RundownLogCategory || (RundownLogCategory = {}));
export var RundownLogAction;
(function (RundownLogAction) {
    RundownLogAction["CREATED"] = "CREATED";
    RundownLogAction["UPDATED"] = "UPDATED";
    RundownLogAction["DELETED"] = "DELETED";
    RundownLogAction["MOVED"] = "MOVED";
    RundownLogAction["APPROVED"] = "APPROVED";
    RundownLogAction["DISAPPROVED"] = "DISAPPROVED";
})(RundownLogAction || (RundownLogAction = {}));
export var RUNDOWN_LOG_ACTIONS = [RundownLogAction.CREATED, RundownLogAction.UPDATED];
export var BLOCK_LOG_ACTIONS = [
    RundownLogAction.CREATED,
    RundownLogAction.UPDATED,
    RundownLogAction.DELETED,
];
export var STORY_LOG_ACTIONS = [
    RundownLogAction.CREATED,
    RundownLogAction.UPDATED,
    RundownLogAction.DELETED,
    RundownLogAction.MOVED,
    RundownLogAction.APPROVED,
    RundownLogAction.DISAPPROVED,
];
