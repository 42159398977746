import { RRule, Options, RRuleSet } from 'rrule'

import moment from 'moment'

import { CalendarEvent, EventRepetitionType, FrequencyType } from '@anews/types'

import { timestampToMoment } from '@anews/utils'

import i18n from '../i18n'

export function selectWeekDay(repetitions: EventRepetitionType[]) {
  const listWeekday: number[] = []

  const weekday = [
    { id: 0, value: 6 },
    { id: 1, value: 0 },
    { id: 2, value: 1 },
    { id: 3, value: 2 },
    { id: 4, value: 3 },
    { id: 5, value: 4 },
    { id: 6, value: 5 },
  ]

  repetitions.forEach(e => {
    // listWeekday.push(e.value)
    weekday.forEach(w => {
      if (w.id === e.value) {
        listWeekday.push(w.value)
      }
    })
  })

  return listWeekday
}

export const namesWeekDay = [
  { id: 0, name: 'sunday', sigla: 'su' },
  { id: 1, name: 'monday', sigla: 'mo' },
  { id: 2, name: 'tuesday', sigla: 'tu' },
  { id: 3, name: 'wednesday', sigla: 'we' },
  { id: 4, name: 'thursday', sigla: 'th' },
  { id: 5, name: 'friday', sigla: 'fr' },
  { id: 6, name: 'saturday', sigla: 'sa' },
]

export const namesOptionMonth = [
  { id: 1, name: 'first', sigla: 'fir' },
  { id: 2, name: 'second', sigla: 'sec' },
  { id: 3, name: 'third', sigla: 'thi' },
  { id: 4, name: 'fourth', sigla: 'fou' },
  { id: 5, name: 'fifth', sigla: 'fif' },
  { id: -1, name: 'last', sigla: 'las' },
]

export const namesMonth = [
  { id: 1, name: 'january', sigla: 'januaryInitials' },
  { id: 2, name: 'february', sigla: 'februaryInitials' },
  { id: 3, name: 'march', sigla: 'marchInitials' },
  { id: 4, name: 'april', sigla: 'aprilInitials' },
  { id: 5, name: 'may', sigla: 'mayInitials' },
  { id: 6, name: 'june', sigla: 'juneInitials' },
  { id: 7, name: 'july', sigla: 'julyInitials' },
  { id: 8, name: 'august', sigla: 'augustInitials' },
  { id: 9, name: 'september', sigla: 'septemberInitials' },
  { id: 10, name: 'october', sigla: 'octoberInitials' },
  { id: 11, name: 'november', sigla: 'novemberInitials' },
  { id: 12, name: 'december', sigla: 'decemberInitials' },
]

export function fnByWeekDay(dayOrder: number, value: number) {
  switch (value) {
    case 0:
      return [RRule.SU.nth(dayOrder)]
    case 1:
      return [RRule.MO.nth(dayOrder)]
    case 2:
      return [RRule.TU.nth(dayOrder)]
    case 3:
      return [RRule.WE.nth(dayOrder)]
    case 4:
      return [RRule.TH.nth(dayOrder)]
    case 5:
      return [RRule.FR.nth(dayOrder)]
    case 6:
      return [RRule.SA.nth(dayOrder)]
    default:
      return []
  }
}

// eslint-disable-next-line react-hooks/exhaustive-deps
export function repeatEvents(calEvent: CalendarEvent, currentDate: Date): CalendarEvent[] {
  const list: CalendarEvent[] = []
  try {
    const options = { tzid: 'America/Sao_Paulo' } as Options

    const freq: any = calEvent.frequency?.split('_')[1]
    const frequencyParse = RRule.FREQUENCIES.findIndex(f => f === freq)
    const { startDate, endDate } = calEvent

    const dur = moment.duration(timestampToMoment(endDate)!.diff(timestampToMoment(startDate)))

    if (frequencyParse < 0 || !moment(startDate).isValid()) {
      throw new Error('Frequência ou data inválida ')
    }

    if (calEvent.repetitions?.length) {
      // Mensal ou Anual
      if (frequencyParse === 1 || frequencyParse === 0) {
        const { dayOrder, value } = calEvent.repetitions[0]
        options.byweekday = fnByWeekDay(dayOrder, value)
      } else {
        // if(evento.repetitions)
        const listWeekday = selectWeekDay(calEvent.repetitions)

        if (listWeekday.length) {
          options.byweekday = listWeekday
        }
      }
    }

    let repeatUtil = moment(calEvent.repeatUtil).format('YYYY-MM-DDTHH:mm:ss')
    const lastDay = moment(currentDate).endOf('month')
    const isAfter = moment(repeatUtil).isAfter(lastDay)

    if (isAfter || !calEvent.repeatUtil) {
      repeatUtil = moment(lastDay).format('YYYY-MM-DDTHH:mm:ss')
    }

    const untilDate = moment(repeatUtil).isValid() ? new Date(repeatUtil) : undefined

    options.dtstart = new Date(startDate)
    options.freq = frequencyParse

    if (calEvent.interval) {
      options.interval = calEvent.interval
    }

    if (calEvent.repeatCount) {
      options.count = calEvent.repeatCount
    }

    if (!calEvent.repeatCount && untilDate) {
      options.until = untilDate
    }

    if (calEvent.repeatByMonthDay) {
      options.bymonthday = [calEvent.repeatByMonthDay]
    }

    if (calEvent.repeatByMonth) {
      options.bymonth = [calEvent.repeatByMonth]
    }

    const rule = new RRule(options)
    const ruleSet = new RRuleSet()
    ruleSet.rrule(rule)

    if (calEvent.exDates && calEvent.exDates.length) {
      calEvent.exDates.forEach(d => {
        ruleSet.exdate(new Date(d))
      })
    }

    ruleSet.all().forEach((e: Date) => {
      const newEvent = { ...calEvent }
      newEvent.startDate = moment(e).format('YYYY-MM-DDTHH:mm:ss')
      newEvent.endDate = moment(e).add(dur).format('YYYY-MM-DDTHH:mm:ss')
      list.push(newEvent)
    })

    return list
  } catch (error) {
    console.error({ message: error.message, event: calEvent.name })
    return list
  }
}

export function labelRepetitions(frequency: FrequencyType | undefined) {
  switch (frequency) {
    case FrequencyType.FREQ_DAILY:
      return ' Dia(s)'
    case FrequencyType.FREQ_WEEKLY:
      return ' Semana(s)'
    case FrequencyType.FREQ_MONTHLY:
      return ' Mês(es)'
    case FrequencyType.FREQ_YEARLY:
      return ' Ano(s)'
    default:
      return ' Dia(s)'
  }
}

export function labelReminderMinutes(minutes: number | undefined) {
  if (minutes === undefined) {
    return ''
  }
  let label = ''
  if (minutes <= 0) {
    label = i18n.t('calendar:now')
  } else if (minutes < 60) {
    // Menor que 1 hora
    label = i18n.t('calendar:intervalMinutes', { interval: minutes })
  } else if (minutes < 60 * 24) {
    // Menor que 1 dia
    const hours = Math.round(minutes / 60)
    label = i18n.t('calendar:intervalHours', { interval: hours })
  } else if (minutes < 60 * 24 * 7) {
    // Menor que 1 semana
    const days = Math.round(minutes / (60 * 24))
    label = i18n.t('calendar:intervalDays', { interval: days })
  } else {
    const weeks = Math.round(minutes / (60 * 24 * 7))
    label = i18n.t('calendar:intervalWeeks', { interval: weeks })
  }
  return label
}

export function decimalToHex(d: number): string {
  let hex = Number(d).toString(16)
  hex = '000000'.substr(0, 6 - hex.length) + hex
  return `#${hex}`
}
