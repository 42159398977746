import { del, get, query, post, put } from './client';
var endpoint = '/api/assignment_proposals';
var newsApi = {
    pageFilter: function (from, to, filter, page, size) {
        if (filter === void 0) { filter = ''; }
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", { from: from, to: to, filter: filter, page: page, size: size }));
    },
    load: function (id, lock) {
        return get(query(endpoint + "/" + id, { lock: lock }));
    },
    create: function (news) {
        return post(endpoint, news);
    },
    createFromRound: function (roundId) {
        return post(endpoint + "/round/" + roundId);
    },
    createFromFacebookPost: function (postId) {
        return post(endpoint + "/facebook/" + postId);
    },
    createFromRssItem: function (itemId) {
        return post(endpoint + "/rss/" + itemId);
    },
    createFromTweet: function (tweet) {
        return post(endpoint + "/twitter", tweet);
    },
    update: function (news) {
        return put(endpoint, news);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
};
export default newsApi;
