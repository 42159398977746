import { call, put, takeLatest } from 'redux-saga/effects'

import { roundApi as api } from '@anews/api'
import { Round } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  listRoundsSuccess,
  listRoundsFailure,
  CreateRoundAction,
  createRoundSuccess,
  createRoundFailure,
  UpdateRoundAction,
  updateRoundSuccess,
  updateRoundFailure,
  RemoveRoundsAction,
  removeRoundsSuccess,
  removeRoundsFailure,
  ListRoundsAction,
} from '../actions/round-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listRoundsSaga(action: ListRoundsAction): Generator {
  try {
    const rounds = yield call(api.dateList, action.date)
    yield put(listRoundsSuccess(rounds as Round[]))
  } catch (error) {
    yield put(listRoundsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createRoundSaga(action: CreateRoundAction): Generator {
  try {
    const round = yield call(api.create, action.round)
    yield put(createRoundSuccess(round as Round))
  } catch (error) {
    yield put(createRoundFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateRoundSaga(action: UpdateRoundAction): Generator {
  try {
    const round = yield call(api.update, action.round)
    yield put(updateRoundSuccess(round as Round))
  } catch (error) {
    yield put(updateRoundFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeRoundsSaga(action: RemoveRoundsAction): Generator {
  try {
    yield call(api.remove, action.ids)
    yield put(removeRoundsSuccess(action.ids))
  } catch (error) {
    yield put(removeRoundsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listRoundsSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createRoundSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateRoundSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeRoundsSaga)
  },
])
