import { TranslationDictionary } from '@anews/types'

export function getRevisionTranslate(term: string, isRadio?: boolean): TranslationDictionary {
  switch (term) {
    case 'date':
      return 'words:date'
    case 'schedule':
      return 'checklist:schedule'
    case 'departure':
      return 'checklist:departure'
    case 'arrival':
      return 'checklist:arrival'
    case 'local':
      return 'words:local'
    case 'team':
      return 'words:team'
    case 'tapes':
      return 'words:medias'
    case 'info':
      return 'words:info'
    case 'vehicles':
      return 'words:vehicles'
    case 'vehicle':
      return 'words:vehicle'
    case 'editors':
      return 'words:editors'
    case 'producer':
      return 'guideline:producer'
    case 'producers':
      return 'checklist:producers'
    case 'type':
      return 'words:type'
    case 'resources':
      return 'checklist:resources'
    case 'tasks':
      return 'words:tasks'
    case 'proposal':
      return 'guideline:proposal'
    case 'referral':
      return 'guideline:referral'
    case 'information':
      return 'words:info'
    case 'programs':
      return 'words:programs'
    case 'classification':
      return 'words:classification'
    case 'reporter':
      return 'words:reporter'
    case 'editor':
      return 'words:editor'
    case 'guides':
      return 'guideline:guides'
    case 'cameraman':
    case 'cameraMan':
      return 'words:cameraman'
    case 'content':
      return 'words:content'
    case 'category':
      return 'words:category'
    case 'event':
      return 'report:event'
    case 'sections':
      return 'reportage:sections'
    case 'tipOfFootnote':
      return 'reportage:footnote'
    case 'tipOfHead':
      return 'reportage:heading'
    case 'lead':
      return 'reportage:lead'
    case 'text':
      return 'reportage:text'
    case 'teaser':
      return 'reportage:teaser'
    case 'documentKind':
      return 'words:type'
    case 'headSection':
      return isRadio ? 'story:voiceover' : 'story:head'
    case 'linkSection':
      return isRadio ? 'story:vivo' : 'story:link'
    case 'ncSection':
      return 'story:nc'
    case 'vtSection':
      return isRadio ? 'story:voiced' : 'story:vt'
    case 'footerSection':
      return 'story:footnote'
    case 'offSection':
      return 'story:off'
    case 'editorials':
      return 'words:editorials'
    default:
      return 'words:error'
  }
}
