import { call, put, takeLatest } from 'redux-saga/effects'

import { classificationApi as api } from '@anews/api'
import { Classification } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  listClassificationsSuccess,
  listClassificationsFailure,
} from '../actions/classification-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listClassificationsSaga(): Generator {
  try {
    const classifications = yield call(api.list)
    yield put(listClassificationsSuccess(classifications as Classification[]))
  } catch (error) {
    yield put(listClassificationsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listClassificationsSaga)
  },
])
