import { User } from '@anews/types'

import { RootState } from '../reducers'

import { createMapSelector } from './helpers'

const allUsers = (state: RootState) => state.users.lists.all.data
const activeUsers = (state: RootState) => state.users.lists.active.data

export const allUsersMap = createMapSelector<User, string>(allUsers, 'id', 'nickname')

export const activeUsersMap = createMapSelector<User, string>(activeUsers, 'id', 'nickname')
