import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
// https://github.com/lodash/lodash/issues/2403#issuecomment-290760787
export function memoizeDebounce(func, wait, options) {
    if (wait === void 0) { wait = 0; }
    if (options === void 0) { options = {}; }
    var mem = memoize(function () { return debounce(func, wait, options); }, options.resolver);
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        mem.apply(this, args).apply(this, args);
    };
}
