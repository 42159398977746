import { IITemplate } from '@anews/types'

export enum ActionType {
  NEW = '@iitemplate/NEW',
  LIST_REQUEST = '@iitemplate/LIST_REQUEST',
  LIST_SUCCESS = '@iitemplate/LIST_SUCCESS',
  LIST_FAILURE = '@iitemplate/LIST_FAILURE',
  EDIT_REQUEST = '@iitemplate/EDIT_REQUEST',
  EDIT_SUCCESS = '@iitemplate/EDIT_SUCCESS',
  EDIT_FAILURE = '@iitemplate/EDIT_FAILURE',
  CREATE_REQUEST = '@iitemplate/CREATE_REQUEST',
  CREATE_SUCCESS = '@iitemplate/CREATE_SUCCESS',
  CREATE_FAILURE = '@iitemplate/CREATE_FAILURE',
  UPDATE_REQUEST = '@iitemplate/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@iitemplate/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@iitemplate/UPDATE_FAILURE',
  REMOVE_REQUEST = '@iitemplate/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@iitemplate/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@iitemplate/REMOVE_FAILURE',
}

export interface ListTemplatesAction {
  type: ActionType.LIST_REQUEST
  deviceId: number
}

export interface EditTemplateAction {
  type: ActionType.EDIT_REQUEST
  deviceId: number
  templateId: number
}

export interface CreateTemplateAction {
  type: ActionType.CREATE_REQUEST
  deviceId: number
  template: IITemplate
}

export interface UpdateTemplateAction {
  type: ActionType.UPDATE_REQUEST
  deviceId: number
  template: IITemplate
}

export interface RemoveTemplateAction {
  type: ActionType.REMOVE_REQUEST
  deviceId: number
  templateIds: number[]
}

export type IITemplateAction =
  /* newTemplate() */
  | { type: ActionType.NEW; template?: IITemplate }
  /* listTemplates() */
  | ListTemplatesAction
  | { type: ActionType.LIST_SUCCESS; templates: IITemplate[] }
  | { type: ActionType.LIST_FAILURE; error: Error }
  /* editTemplate() */
  | EditTemplateAction
  | { type: ActionType.EDIT_SUCCESS; template: IITemplate }
  | { type: ActionType.EDIT_FAILURE; error: Error }
  /* createTemplate() */
  | CreateTemplateAction
  | { type: ActionType.CREATE_SUCCESS; template: IITemplate }
  | { type: ActionType.CREATE_FAILURE; error: Error }
  /* updateTemplate() */
  | UpdateTemplateAction
  | { type: ActionType.UPDATE_SUCCESS; template: IITemplate }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* removeTemplate() */
  | RemoveTemplateAction
  | { type: ActionType.REMOVE_SUCCESS; templateIds: number[] }
  | { type: ActionType.REMOVE_FAILURE; error: Error }

//
//	newTemplate()
//

export const newTemplate = (template?: IITemplate): IITemplateAction => ({
  type: ActionType.NEW,
  template,
})

//
//	listTemplates()
//

export const listTemplates = (deviceId: number): IITemplateAction => ({
  type: ActionType.LIST_REQUEST,
  deviceId,
})

export const listTemplatesSuccess = (templates: IITemplate[]): IITemplateAction => ({
  type: ActionType.LIST_SUCCESS,
  templates,
})

export const listTemplatesFailure = (error: Error): IITemplateAction => ({
  type: ActionType.LIST_FAILURE,
  error,
})

//
//	editTemplate()
//

export const editTemplate = (deviceId: number, templateId: number): IITemplateAction => ({
  type: ActionType.EDIT_REQUEST,
  deviceId,
  templateId,
})

export const editTemplateSuccess = (template: IITemplate): IITemplateAction => ({
  type: ActionType.EDIT_SUCCESS,
  template,
})

export const editTemplateFailure = (error: Error): IITemplateAction => ({
  type: ActionType.EDIT_FAILURE,
  error,
})

//
//	createTemplate()
//

export const createTemplate = (deviceId: number, template: IITemplate): IITemplateAction => ({
  type: ActionType.CREATE_REQUEST,
  deviceId,
  template,
})

export const createTemplateSuccess = (template: IITemplate): IITemplateAction => ({
  type: ActionType.CREATE_SUCCESS,
  template,
})

export const createTemplateFailure = (error: Error): IITemplateAction => ({
  type: ActionType.CREATE_FAILURE,
  error,
})

//
//	updateTemplate()
//

export const updateTemplate = (deviceId: number, template: IITemplate): IITemplateAction => ({
  type: ActionType.UPDATE_REQUEST,
  deviceId,
  template,
})

export const updateTemplateSuccess = (template: IITemplate): IITemplateAction => ({
  type: ActionType.UPDATE_SUCCESS,
  template,
})

export const updateTemplateFailure = (error: Error): IITemplateAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//	removeTemplate()
//

export const removeTemplate = (deviceId: number, templateIds: number[]): IITemplateAction => ({
  type: ActionType.REMOVE_REQUEST,
  deviceId,
  templateIds,
})

export const removeTemplateSuccess = (templateIds: number[]): IITemplateAction => ({
  type: ActionType.REMOVE_SUCCESS,
  templateIds,
})

export const removeTemplateFailure = (error: Error): IITemplateAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})
