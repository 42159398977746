import { del, get, post, put, query } from './client';
var endpoint = '/api/programs';
var programApi = {
    cg: function (id) {
        return get(endpoint + "/" + id + "/cg");
    },
    playout: function (id) {
        return get(endpoint + "/" + id + "/playout");
    },
    editors: function (id) {
        return get(endpoint + "/" + id + "/editors");
    },
    imageEditors: function (id) {
        return get(endpoint + "/" + id + "/imageEditors");
    },
    presenters: function (id) {
        return get(endpoint + "/" + id + "/presenters");
    },
    list: function (all, withUsers) {
        return get(query(endpoint, { all: all, withUsers: withUsers }));
    },
    load: function (id) {
        return get(endpoint + "/" + id);
    },
    create: function (program) {
        return post(endpoint, program);
    },
    update: function (program) {
        return put("" + endpoint, program);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
};
export default programApi;
