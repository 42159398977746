import { call, put, takeLatest } from 'redux-saga/effects'

import { mamApi } from '@anews/api'
import { AssetField, Page, Media } from '@anews/types'

import i18n from '../../i18n'

import { NotificationActions } from '../actions'
import { MediaActionMap, MediaActions, MediaActionType } from '../actions/media-actions'

import { createRootSaga } from './helpers'

const {
  listSearchableFieldsSuccess,
  listSearchableFieldsFailure,
  filterMediasSuccess,
  filterMediasFailure,
} = MediaActions

const { notifyError } = NotificationActions

/* Watchers */

function* listSearchableFieldsSaga(
  action: MediaActionMap<MediaActionType.SEARCHABLE_FIELDS_REQUEST>,
): Generator {
  try {
    const fields = yield call(mamApi.searchableFields, action.mamId)
    yield put(listSearchableFieldsSuccess(fields as AssetField[], action.embedded))
  } catch (error) {
    yield put(listSearchableFieldsFailure(error, action.embedded))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* filterMediasSaga(
  action: MediaActionMap<MediaActionType.FILTER_MEDIAS_REQUEST>,
): Generator {
  try {
    const medias = yield call(
      mamApi.filterMedias,
      action.mamId,
      action.params,
      action.page,
      action.size,
    )
    yield put(filterMediasSuccess(medias as Page<Media>, action.embedded))
  } catch (error) {
    yield put(filterMediasFailure(error, action.embedded))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(MediaActionType.SEARCHABLE_FIELDS_REQUEST, listSearchableFieldsSaga)
  },
  function* () {
    yield takeLatest(MediaActionType.FILTER_MEDIAS_REQUEST, filterMediasSaga)
  },
])
