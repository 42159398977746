import { ChecklistType } from '@anews/types'

export enum ChecklistTypeActionType {
  LIST_REQUEST = '@checklisttype/LIST_REQUEST',
  LIST_SUCCESS = '@checklisttype/LIST_SUCCESS',
  LIST_FAILURE = '@checklisttype/LIST_FAILURE',
}

export type ChecklistTypeActionMap<T extends ChecklistTypeActionType> = {
  type: T
} & ChecklistTypeAction

export type ChecklistTypeAction = ReturnType<
  typeof ChecklistTypeActions[keyof typeof ChecklistTypeActions]
>

export const ChecklistTypeActions = {
  //
  //	listTypes()
  //

  listTypes: () => <const>{ type: ChecklistTypeActionType.LIST_REQUEST },

  listTypesSuccess: (types: ChecklistType[]) =>
    <const>{ type: ChecklistTypeActionType.LIST_SUCCESS, types },

  listTypesFailure: (error: Error) => <const>{ type: ChecklistTypeActionType.LIST_FAILURE, error },
}
