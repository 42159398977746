var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { CustomMetadataType } from '@anews/types';
import { dateToMoment, displayDate, displayTime, displayTimestamp, momentToDate, momentToTime, momentToTimestamp, timeToMoment, timestampToMoment, } from '.';
/*
 * Transforma o nome de um metadado em um nome de input válido.
 */
export var generateMetadataInputName = function (value) {
    return "__meta__" + value.replace(/[^a-z0-9]/gi, '_');
};
/**
 * Extrai os valores de metadados de um documento e os transforma em um objeto que pode ser usado
 * para preencher o formulário.
 *
 * @param docMeta Metadados do documento
 * @param metaList Lista de metadados possíveis
 * @returns Valores formatados para serem enviados para o formulário
 */
export function parseMetadataValues(docMeta, metaList) {
    if (docMeta === void 0) { docMeta = []; }
    if (metaList === void 0) { metaList = []; }
    var values = docMeta.reduce(function (acc, dc) {
        var _a;
        var _b;
        // Procura por metadata correspondente. Se não encontrar, não apresenta no formulário.
        var meta = metaList.find(function (m) { return m.id === dc.metadataId; });
        if (!meta) {
            return acc;
        }
        var parsedValue = null;
        // Converte o valor salvo conforme o tipo do metadado
        switch (meta.type) {
            case CustomMetadataType.DATE:
                parsedValue = dateToMoment(dc.value);
                break;
            case CustomMetadataType.TIME:
                parsedValue = timeToMoment(dc.value);
                break;
            case CustomMetadataType.TIMESTAMP:
                parsedValue = timestampToMoment(dc.value);
                break;
            case CustomMetadataType.SELECT:
                parsedValue = dc.selectedOptionsIds;
                break;
            case CustomMetadataType.NUMBER:
                parsedValue = /-?\d+/.test((_b = dc.value) !== null && _b !== void 0 ? _b : '') ? Number(dc.value) : undefined;
                break;
            default:
                parsedValue = dc.value;
                break;
        }
        return __assign(__assign({}, acc), (_a = {}, _a[generateMetadataInputName(meta.name)] = parsedValue, _a));
    }, {});
    return values;
}
/**
 * Extrai os valores do formulário e os transforma em um objeto que pode ser enviado para a API.
 *
 * @param values Valores retornados pelo Form do Antd
 * @param metadataList Lista de metadados possíveis
 * @param documentId ID do documento, se existir
 * @returns Valores formatados para serem enviados para a API
 */
export function formatMetadataValues(values, metadataList, documentId) {
    // [input-name]: CustomMetadata
    var map = metadataList.reduce(function (acc, m) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[generateMetadataInputName(m.name)] = m, _a)));
    }, {});
    var staticValues = {};
    var docMeta = [];
    Object.entries(values).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        var metadata = map[key];
        // Se não for um metadado, deixa o valor como está
        if (!metadata) {
            staticValues[key] = value;
            return;
        }
        var parsedValue;
        var optIds;
        switch (metadata.type) {
            case CustomMetadataType.DATE:
                parsedValue = momentToDate(value);
                break;
            case CustomMetadataType.TIME:
                parsedValue = momentToTime(value);
                break;
            case CustomMetadataType.TIMESTAMP:
                parsedValue = momentToTimestamp(value);
                break;
            case CustomMetadataType.SELECT: {
                if (metadata.multiple) {
                    optIds = value;
                }
                else {
                    optIds = typeof value === 'number' ? [value] : undefined;
                }
                break;
            }
            default:
                parsedValue =
                    typeof value === 'string' || typeof value === 'number' ? String(value) : undefined;
        }
        docMeta.push({
            documentId: documentId === -1 ? null : documentId,
            metadataId: metadata.id,
            value: parsedValue,
            selectedOptionsIds: optIds,
        });
    });
    return __assign(__assign({}, staticValues), { metadata: docMeta });
}
/**
 * Formata o valor de um metadado para ser exibido.
 *
 * @param docMeta Metadado preenchido do documento
 * @param metadata Metadado correspondente
 * @returns Valor formatado para ser exibido
 */
export function metadataDisplayValue(docMeta, metadata) {
    var value = docMeta.value, selectedOptionsIds = docMeta.selectedOptionsIds;
    switch (metadata.type) {
        case CustomMetadataType.DATE:
            return displayDate(value);
        case CustomMetadataType.TIME:
            return displayTime(value);
        case CustomMetadataType.TIMESTAMP:
            return displayTimestamp(value);
        case CustomMetadataType.SELECT:
            return selectedOptionsIds === null || selectedOptionsIds === void 0 ? void 0 : selectedOptionsIds.map(function (id) { var _a, _b; return (_b = (_a = metadata.options) === null || _a === void 0 ? void 0 : _a.find(function (o) { return o.id === id; })) === null || _b === void 0 ? void 0 : _b.label; }).join(', ');
        default:
            return value;
    }
}
export function displayMetadataValues(metadata, values, displayFn) {
    if (!values) {
        return null;
    }
    return values.map(function (mv) {
        var meta = metadata.find(function (m) { return m.id === mv.metadataId; });
        return !meta ? null : displayFn(mv.metadataId, meta.name, metadataDisplayValue(mv, meta));
    });
}
