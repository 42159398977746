import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
export function formatNational(value, country) {
    if (value === void 0) { value = ''; }
    var cleanValue = value.replace(/[^ \d)(+-]/, '');
    var phoneNumber = parsePhoneNumberFromString(cleanValue, country.toUpperCase());
    return phoneNumber ? phoneNumber.formatNational() : cleanValue;
}
export function formatInternational(value, country) {
    if (value === void 0) { value = ''; }
    var cleanValue = value.replace(/[^ \d)(+-]/, '');
    var phoneNumber = parsePhoneNumberFromString(cleanValue, country.toUpperCase());
    return phoneNumber ? phoneNumber.formatInternational() : cleanValue;
}
