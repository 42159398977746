import { Reducer } from 'redux'
import produce from 'immer'
import moment from 'moment'

import { Reportage, ReportageConfig } from '@anews/types'

import { ReportageParams } from '@anews/api'

import { ReportageActionType as ActionType, ReportageAction } from '../actions/reportage-actions'

import { REPORTAGES_LIST_TAB } from '../../components/main/reportages/ReportagesTabs'

import { ConfigState } from './types'

/*
 *  TODO: Refatorar esse reducer para deixar no estilo do checklist-reducer
 *
 *  Esse tá muito desorganizado e confuso
 */

export type ReportageFilterParams = Pick<
  ReportageParams,
  'from' | 'to' | 'programId' | 'allPrograms' | 'slug'
>

export interface ReportagesPageState {
  loading: boolean
  data: Reportage[]
  filter: ReportageFilterParams
  page: {
    number: number
    size: number
    total: number
  }
}

export interface ReportagesEditingState {
  reportages: Reportage[]
  activeTab: string
}

export interface ReportagesState {
  page: ReportagesPageState
  config: ConfigState<ReportageConfig>
  editing: ReportagesEditingState
  viewing?: Reportage
  saving: boolean
  disabling: boolean
  transientReportages: { [uuid: string]: Reportage }
}

export const defaultState: ReportagesState = {
  page: {
    loading: true,
    data: [],
    filter: {},
    page: {
      number: 0,
      size: 0,
      total: 0,
    },
  },
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
  editing: {
    reportages: [],
    activeTab: REPORTAGES_LIST_TAB,
  },
  viewing: undefined,
  saving: false,
  disabling: false,
  transientReportages: {},
}

function reportageMatchFilter(reportage: Reportage, from?: string, to?: string) {
  const date = moment(reportage.date)

  if (from && date.isBefore(moment(from))) {
    return false
  }
  if (to && date.isAfter(moment(to))) {
    return false
  }
  return true
}

function pageReducer(page: ReportagesPageState, action: ReportageAction): ReportagesPageState {
  switch (action.type) {
    case ActionType.COPY_REQUEST:
      return { ...page, loading: true }

    case ActionType.FILTER_REQUEST:
      return {
        ...page,
        filter: action.filter,
        loading: true,
      }

    case ActionType.COPY_FAILURE:
    case ActionType.FILTER_FAILURE:
      return { ...page, loading: false }

    case ActionType.FILTER_SUCCESS:
      return {
        ...page,
        data: action.result.data,
        page: {
          total: action.result.total,
          number: action.result.page,
          size: action.result.size,
        },
        loading: false,
      }

    case ActionType.COPY_SUCCESS:
      return produce(page, draft => {
        draft.data = [...action.reportages, ...page.data]

        // Quando a quantidade extrapolar o tamanho da página, tem que tirar o excesso
        // Isso estava causando um erro interno na paginação da tabela do antd
        const diff = draft.data.length - draft.page.size
        if (diff > 0) {
          draft.data.splice(draft.data.length - diff, diff)
        }
      })
    case ActionType.CREATE_SUCCESS:
    case ActionType.CREATE_FROM_GUIDELINE_SUCCESS:
    case ActionType.WS_CREATE: {
      const { reportage } = action

      // Se tiver um filtro de data, só adiciona se a data da reportagem estiver dentro do filtro
      if (!reportageMatchFilter(reportage, page.filter.from, page.filter.to)) {
        return page
      }

      return produce(page, draft => {
        // O filter serve para não duplicar (CREATE_SUCCESS x WS_CREATE)
        draft.data = [reportage, ...page.data.filter(r => r.id !== reportage.id)]

        // Quando a quantidade extrapolar o tamanho da página, tem que tirar o excesso
        // Isso estava causando um erro interno na paginação da tabela do antd
        const diff = draft.data.length - draft.page.size
        if (diff > 0) {
          draft.data.splice(draft.data.length - diff, diff)
        }
      })
    }

    case ActionType.UPDATE_SUCCESS:
    case ActionType.WS_UPDATE: {
      const { reportage } = action

      // Remove a reportagem da lista quando a data não bate com o filtro
      if (!reportageMatchFilter(reportage, page.filter.from, page.filter.to)) {
        return { ...page, data: page.data.filter(r => r.id !== reportage.id) }
      }

      return {
        ...page,
        data: page.data.map(r => (r.id === reportage.id ? reportage : r)),
      }
    }

    case ActionType.REMOVE_SUCCESS:
    case ActionType.WS_DELETE:
      return { ...page, data: page.data.filter(r => !action.ids.includes(r.id)) }

    case ActionType.PATCH_REQUEST: {
      const { reportageId, field, newValue } = action
      return {
        ...page,
        data: page.data.map(r => (r.id !== reportageId ? r : { ...r, [field]: newValue })),
      }
    }

    case ActionType.PATCH_FAILURE: {
      // Se o patch falhar, retorna o valor antigo
      const { reportageId, field, oldValue } = action
      return {
        ...page,
        data: page.data.map(r => (r.id !== reportageId ? r : { ...r, [field]: oldValue })),
      }
    }

    case ActionType.WS_PATCH: {
      const { reportageId, changes } = action
      return {
        ...page,
        data: page.data.map(r => (r.id !== reportageId ? r : { ...r, ...changes })),
      }
    }

    default:
      return page
  }
}

function configReducer(
  config: ConfigState<ReportageConfig>,
  action: ReportageAction,
): ConfigState<ReportageConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

function transientReducer(
  state: ReportagesState['transientReportages'],
  action: ReportageAction,
): ReportagesState['transientReportages'] {
  switch (action.type) {
    case ActionType.LOAD_SUCCESS:
      return action.edit ? { ...state, [action.reportage.uuid]: action.reportage } : state

    case ActionType.STORE_TRANSIENT:
    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
      return { ...state, [action.reportage.uuid]: action.reportage }

    default:
      return state
  }
}

function editingReducer(
  editing: ReportagesEditingState,
  action: ReportageAction,
): ReportagesEditingState {
  const { activeTab, reportages } = editing

  switch (action.type) {
    case ActionType.CHANGE_TAB:
      return { ...editing, activeTab: action.key }

    case ActionType.CLOSE_TAB:
      return {
        ...editing,
        activeTab: activeTab === action.key ? REPORTAGES_LIST_TAB : activeTab,
        reportages: reportages.filter(reportage => reportage.uuid !== action.key),
      }

    case ActionType.NEW: {
      // Se já tiver uma reportagem não salva aberta, ativa sua aba ao invés de criar uma nova
      const currentNew = reportages.find(reportage => reportage.id <= 0)
      if (currentNew) {
        return { ...editing, activeTab: currentNew.uuid }
      }
      return {
        ...editing,
        reportages: [...reportages, action.reportage],
        activeTab: action.reportage.uuid!,
      }
    }
    case ActionType.CREATE_FROM_GUIDELINE_SUCCESS: {
      return {
        ...editing,
        reportages: [...reportages, action.reportage],
        activeTab: action.reportage.uuid!,
      }
    }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.WS_CREATE:
    case ActionType.WS_UPDATE:
      return {
        ...editing,
        reportages: reportages.map(reportage =>
          reportage.uuid === action.reportage.uuid ? action.reportage : reportage,
        ),
      }

    case ActionType.LOAD_SUCCESS: {
      if (!action.edit) {
        return editing
      }
      const match = reportages.find(reportage => reportage.uuid === action.reportage.uuid)
      if (match) {
        return { ...editing, activeTab: action.reportage.uuid }
      }
      return {
        ...editing,
        reportages: [...reportages, action.reportage],
        activeTab: action.reportage.uuid,
      }
    }

    case ActionType.REMOVE_SUCCESS:
    case ActionType.WS_DELETE:
      return {
        ...editing,
        reportages: reportages.filter(r => !action.ids.includes(r.id)),
      }

    default:
      return editing
  }
}

const reportagesReducer: Reducer<ReportagesState, ReportageAction> = (
  state = defaultState,
  action,
): ReportagesState => {
  switch (action.type) {
    case ActionType.FILTER_REQUEST:
    case ActionType.FILTER_FAILURE:
    case ActionType.FILTER_SUCCESS: {
      const { viewing, page } = state

      const newPage = pageReducer(page, action)
      let newViewing

      if (viewing) {
        newViewing = newPage.data.find(reportage => reportage.id === viewing.id)
      }

      return { ...state, viewing: newViewing, page: newPage }
    }

    case ActionType.LOAD_CONFIG_REQUEST:
    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.UPDATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...state, config: configReducer(state.config, action) }

    // Reinicia o state. Chamado ao fechar a área de reportagens.
    case ActionType.CLEAR: {
      const { page, editing, viewing } = defaultState
      return { ...state, page, editing, viewing }
    }

    case ActionType.UNLOAD:
      return { ...state, viewing: undefined }

    case ActionType.CHANGE_TAB:
    case ActionType.CLOSE_TAB:
    case ActionType.NEW:
      return { ...state, editing: editingReducer(state.editing, action) }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: editingReducer(state.editing, action), saving: true }

    case ActionType.LOAD_SUCCESS:
      if (!action.edit) {
        return {
          ...state,
          viewing: action.reportage,
          transientReportages: transientReducer(state.transientReportages, action),
        }
      }
      return { ...state, viewing: undefined, editing: editingReducer(state.editing, action) }

    case ActionType.REMOVE_REQUEST:
      return { ...state, disabling: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, disabling: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
    case ActionType.CREATE_FROM_GUIDELINE_SUCCESS:
    case ActionType.WS_CREATE:
      return {
        ...state,
        viewing: undefined,
        disabling: false,
        saving: false,
        editing: editingReducer(state.editing, action),
        page: pageReducer(state.page, action),
        transientReportages: transientReducer(state.transientReportages, action),
      }

    case ActionType.STORE_TRANSIENT:
      return { ...state, transientReportages: transientReducer(state.transientReportages, action) }

    case ActionType.WS_UPDATE:
    case ActionType.WS_DELETE:
    case ActionType.PATCH_REQUEST:
    case ActionType.PATCH_SUCCESS:
    case ActionType.PATCH_FAILURE:
    case ActionType.WS_PATCH: {
      let { viewing } = state

      if (viewing) {
        if (action.type === ActionType.WS_UPDATE && viewing.id === action.reportage.id) {
          viewing = action.reportage
        }
        if (action.type === ActionType.WS_DELETE && action.ids.includes(viewing.id)) {
          viewing = undefined
        }
      }

      return {
        ...state,
        viewing,
        disabling: false,
        saving: false,
        editing: editingReducer(state.editing, action),
        page: pageReducer(state.page, action),
      }
    }

    default:
      return state
  }
}

export default reportagesReducer
