import { createStore, compose, applyMiddleware, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'

import notificationMiddleware from './middlewares/notification-middleware'

import reducers, { RootState } from './reducers'
import sagas from './sagas'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()
const store: Store<RootState> = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware, notificationMiddleware)),
)

sagaMiddleware.run(sagas)

export default store
