import { Reducer } from 'redux'

import { CustomMetadata } from '@anews/types'

import { CustomMetadataAction, CustomMetadataActionType as ActionType } from '../actions'

export type CustomMetadataState = {
  list: {
    data: CustomMetadata[]
    loading: boolean
  }
  saving: boolean
  removing: boolean
}

export const initialState: CustomMetadataState = {
  list: {
    data: [],
    loading: false,
  },
  saving: false,
  removing: false,
}

function listReducer(
  list: CustomMetadataState['list'],
  action: CustomMetadataAction,
): CustomMetadataState['list'] {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.data,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.data, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(customMetadata =>
          customMetadata.id === action.data.id ? action.data : customMetadata,
        ),
      }

    case ActionType.REMOVE_SUCCESS:
      return {
        ...list,
        data: list.data.filter(({ id }) => id !== action.id),
      }

    default:
      return list
  }
}

const customMetadataReducer: Reducer<CustomMetadataState, CustomMetadataAction> = (
  state = initialState,
  action,
): CustomMetadataState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_SUCCESS:
    case ActionType.LIST_FAILURE:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, saving: true }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        list: listReducer(state.list, action),
        saving: false,
        removing: false,
      }

    default:
      return state
  }
}

export default customMetadataReducer
