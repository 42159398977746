var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
// Retirado de https://usehooks.com/
export default function useDebounce(value, delay) {
    var _a = __read(useState(value), 2), debouncedValue = _a[0], setDebouncedValue = _a[1];
    useEffect(function () {
        // Atualiza debouncedValue após o delay
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        // Cancela o timeout se o valor mudar, se o delay mudar ou no unmount.
        // Isso é o que previne a atualização do valor dentro do delay.
        return function () {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}
