import { AuthenticationLog, Page } from '@anews/types'

import { PAGE_SIZE } from '../../consts/pagination'

export enum AuthenticationLogActionType {
  FILTER_REQUEST = 'FILTER_REQUEST',
  FILTER_SUCCESS = 'FILTER_SUCCESS',
  FILTER_FAILURE = 'FILTER_FAILURE',
}

export type AuthenticationLogActionMap<T extends AuthenticationLogActionType> = {
  type: T
} & AuthenticationLogAction

export type AuthenticationLogAction = ReturnType<
  typeof AuthenticationLogActions[keyof typeof AuthenticationLogActions]
>

export const AuthenticationLogActions = {
  //	filterLogs()
  //

  filterLogs: (
    filter: string,
    page: number = 0,
    size: number = PAGE_SIZE,
    success?: boolean,
    from?: string,
    to?: string,
  ) =>
    <const>{
      type: AuthenticationLogActionType.FILTER_REQUEST,
      filter,
      success,
      from,
      to,
      page,
      size,
    },

  filterLogsSuccess: (result: Page<AuthenticationLog>) =>
    <const>{ type: AuthenticationLogActionType.FILTER_SUCCESS, result },

  filterLogsFailure: (error: Error) =>
    <const>{ type: AuthenticationLogActionType.FILTER_FAILURE, error },
}
