var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { toQueryString } from '@anews/utils';
import { get, put } from './client';
var endpoint = '/api/print';
var printApi = {
    print: function (report, params) {
        var r = Math.round(Math.random() * 9999);
        var query = toQueryString(__assign({ r: r }, params));
        var encoded = encodeURIComponent(btoa(query));
        window.open("/print/" + encoded + "/" + report, '_blank');
    },
    //
    //  config
    //
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default printApi;
