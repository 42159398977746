import { WordpressConfig } from '@anews/types'

export enum ActionType {
  NEW_CONFIG = '@wordpress/NEW_CONFIG',
  LIST_CONFIGS_REQUEST = '@wordpress/LIST_CONFIGS_REQUEST',
  LIST_CONFIGS_SUCCESS = '@wordpress/LIST_CONFIGS_SUCCESS',
  LIST_CONFIGS_FAILURE = '@wordpress/LIST_CONFIGS_FAILURE',
  EDIT_CONFIG_REQUEST = '@wordpress/EDIT_CONFIG_REQUEST',
  EDIT_CONFIG_SUCCESS = '@wordpress/EDIT_CONFIG_SUCCESS',
  EDIT_CONFIG_FAILURE = '@wordpress/EDIT_CONFIG_FAILURE',
  CREATE_CONFIG_REQUEST = '@wordpress/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@wordpress/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@wordpress/CREATE_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@wordpress/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@wordpress/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@wordpress/UPDATE_CONFIG_FAILURE',
  REMOVE_CONFIG_REQUEST = '@wordpress/REMOVE_CONFIG_REQUEST',
  REMOVE_CONFIG_SUCCESS = '@wordpress/REMOVE_CONFIG_SUCCESS',
  REMOVE_CONFIG_FAILURE = '@wordpress/REMOVE_CONFIG_FAILURE',
}

export interface EditConfigAction {
  type: ActionType.EDIT_CONFIG_REQUEST
  id: number
}

export interface CreateWordpressConfigAction {
  type: ActionType.CREATE_CONFIG_REQUEST
  config: WordpressConfig
}

export interface UpdateWordpressConfigAction {
  type: ActionType.UPDATE_CONFIG_REQUEST
  config: WordpressConfig
}

export interface RemoveConfigAction {
  type: ActionType.REMOVE_CONFIG_REQUEST
  ids: number[]
}

export type WordpressAction =
  /* newConfig() */
  | { type: ActionType.NEW_CONFIG; config?: WordpressConfig }
  /* listConfigs() */
  | { type: ActionType.LIST_CONFIGS_REQUEST }
  | { type: ActionType.LIST_CONFIGS_SUCCESS; configs: WordpressConfig[] }
  | { type: ActionType.LIST_CONFIGS_FAILURE; error: Error }
  /* editConfig() */
  | EditConfigAction
  | { type: ActionType.EDIT_CONFIG_SUCCESS; config: WordpressConfig }
  | { type: ActionType.EDIT_CONFIG_FAILURE; error: Error }
  /* createConfig() */
  | CreateWordpressConfigAction
  | { type: ActionType.CREATE_CONFIG_SUCCESS; config: WordpressConfig }
  | { type: ActionType.CREATE_CONFIG_FAILURE; error: Error }
  /* updateConfig() */
  | UpdateWordpressConfigAction
  | { type: ActionType.UPDATE_CONFIG_SUCCESS; config: WordpressConfig }
  | { type: ActionType.UPDATE_CONFIG_FAILURE; error: Error }
  /* removeConfig() */
  | RemoveConfigAction
  | { type: ActionType.REMOVE_CONFIG_SUCCESS; ids: number[] }
  | { type: ActionType.REMOVE_CONFIG_FAILURE; error: Error }

//
//	newConfig()
//

export const newConfig = (config?: WordpressConfig): WordpressAction => ({
  type: ActionType.NEW_CONFIG,
  config,
})

//
//  listConfigs()
//

export const listConfigs = (): WordpressAction => ({ type: ActionType.LIST_CONFIGS_REQUEST })

export const listConfigsSuccess = (configs: WordpressConfig[]): WordpressAction => ({
  type: ActionType.LIST_CONFIGS_SUCCESS,
  configs,
})

export const listConfigsFailure = (error: Error): WordpressAction => ({
  type: ActionType.LIST_CONFIGS_FAILURE,
  error,
})

//
//	editConfig()
//

export const editConfig = (id: number): WordpressAction => ({
  type: ActionType.EDIT_CONFIG_REQUEST,
  id,
})

export const editConfigSuccess = (config: WordpressConfig): WordpressAction => ({
  type: ActionType.EDIT_CONFIG_SUCCESS,
  config,
})

export const editConfigFailure = (error: Error): WordpressAction => ({
  type: ActionType.EDIT_CONFIG_FAILURE,
  error,
})

//
//  createConfig()
//

export const createConfig = (config: WordpressConfig): WordpressAction => ({
  type: ActionType.CREATE_CONFIG_REQUEST,
  config,
})

export const createConfigSuccess = (config: WordpressConfig): WordpressAction => ({
  type: ActionType.CREATE_CONFIG_SUCCESS,
  config,
})

export const createConfigFailure = (error: Error): WordpressAction => ({
  type: ActionType.CREATE_CONFIG_FAILURE,
  error,
})

//
//  updateConfig()
//

export const updateConfig = (config: WordpressConfig): WordpressAction => ({
  type: ActionType.UPDATE_CONFIG_REQUEST,
  config,
})

export const updateConfigSuccess = (config: WordpressConfig): WordpressAction => ({
  type: ActionType.UPDATE_CONFIG_SUCCESS,
  config,
})

export const updateConfigFailure = (error: Error): WordpressAction => ({
  type: ActionType.UPDATE_CONFIG_FAILURE,
  error,
})

//
//	removeConfig()
//

export const removeConfig = (ids: number[]): WordpressAction => ({
  type: ActionType.REMOVE_CONFIG_REQUEST,
  ids,
})

export const removeConfigSuccess = (ids: number[]): WordpressAction => ({
  type: ActionType.REMOVE_CONFIG_SUCCESS,
  ids,
})

export const removeConfigFailure = (error: Error): WordpressAction => ({
  type: ActionType.REMOVE_CONFIG_FAILURE,
  error,
})
