import { Reducer } from 'redux'

import { StorageFile } from '@anews/types'

import { StorageFileActionType as ActionType, StorageFileAction } from '../actions'

export interface StorageFileListState {
  data: StorageFile[]
  loading: boolean
}

export interface StorageFileState {
  list: StorageFileListState
}

export const initialState: StorageFileState = {
  list: {
    data: [],
    loading: true,
  },
}

function listReducer(list: StorageFileListState, action: StorageFileAction): StorageFileListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.storageFile,
        loading: false,
      }

    default:
      return list
  }
}

const storageFileReducer: Reducer<StorageFileState, StorageFileAction> = (
  state = initialState,
  action,
): StorageFileState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    default:
      return state
  }
}

export default storageFileReducer
