import React, { ReactNode, CSSProperties } from 'react'
import styled from '@emotion/styled'

import { escapeTags } from '@anews/utils'

import { selectPalleteScale, ThemeProps } from '../../theme'

const Row = styled.div<ThemeProps>`
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 8px;

  span.highlight {
    color: ${selectPalleteScale('blue', 7)};
  }
`

const Label = styled.p`
  color: #000000;
  font-weight: 500;
  display: inline-block;
  margin-right: 8px;
`

const Header = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 24px;
  margin-bottom: 8px;
`

export interface ViewerItemProps {
  label?: string
  children: ReactNode
  highlightComments?: boolean
  style?: CSSProperties
}

function ViewerItem({ label, children, highlightComments, style = {} }: ViewerItemProps) {
  if (!children) {
    return null
  }

  let html = null

  if (highlightComments && typeof children === 'string') {
    html = escapeTags(children).replace(/(\[+[^\]]*\])/g, `<span class="highlight">$1</span>`)
  }

  return (
    <Row style={{ whiteSpace: 'pre-wrap', ...style }} className="viewer-row">
      {label && <Label className="viewer-label">{label}:</Label>}
      {/* eslint-disable react/no-danger */}
      {html ? (
        <span dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <span style={{ whiteSpace: 'pre-wrap' }}>{children}</span>
      )}
    </Row>
  )
}

ViewerItem.Row = Row
ViewerItem.Label = Label
ViewerItem.Header = Header

export default ViewerItem
