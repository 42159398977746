import { call, put, takeLatest } from 'redux-saga/effects'

import { groupApi as api } from '@anews/api'
import { Group } from '@anews/types'
import { createCopy } from '@anews/utils'

import { NotificationActions } from '../actions'
import {
  ActionType,
  listGroupsSuccess,
  listGroupsFailure,
  EditGroupAction,
  editGroupSuccess,
  editGroupFailure,
  CreateGroupAction,
  createGroupSuccess,
  createGroupFailure,
  UpdateGroupAction,
  updateGroupSuccess,
  updateGroupFailure,
  RemoveGroupAction,
  removeGroupSuccess,
  removeGroupFailure,
} from '../actions/group-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* listGroupsSaga(): Generator {
  try {
    const groups = yield call(api.list)
    yield put(listGroupsSuccess(groups as Group[]))
  } catch (error) {
    yield put(listGroupsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* editGroupSaga(action: EditGroupAction): Generator {
  try {
    const group = yield call(api.load, action.id)
    yield put(editGroupSuccess(action.copy ? createCopy(group) : group))
  } catch (error) {
    yield put(editGroupFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createGroupSaga(action: CreateGroupAction): Generator {
  try {
    const group = yield call(api.create, action.group)
    yield put(createGroupSuccess(group as Group))
  } catch (error) {
    yield put(createGroupFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateGroupSaga(action: UpdateGroupAction): Generator {
  try {
    const group = yield call(api.update, action.group)
    yield put(updateGroupSuccess(group as Group))
  } catch (error) {
    yield put(updateGroupFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeGroupSaga(action: RemoveGroupAction): Generator {
  try {
    yield call(api.remove, action.ids)
    yield put(removeGroupSuccess(action.ids))
  } catch (error) {
    yield put(removeGroupFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listGroupsSaga)
  },
  function* () {
    yield takeLatest(ActionType.EDIT_REQUEST, editGroupSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createGroupSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateGroupSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeGroupSaga)
  },
])
