import { Reducer } from 'redux'
import produce from 'immer'

import { ReportFilterParams } from '@anews/api'
import { Page, Report, ReportConfig, ReportRevision } from '@anews/types'

import { isNew } from '@anews/utils'

import { ReportAction, ReportActionType as ActionType } from '../actions'

import { ConfigState } from './types'

export interface ReportsState {
  dictionary: { [id: number]: Report }
  list: Page<number> & {
    params: ReportFilterParams
    loading?: boolean
  }
  editing: {
    data: Report[]
    activeKey?: string
    saving?: boolean
  }
  viewing?: number
  revisions?: ReportRevision[]
  config: ConfigState<ReportConfig>
}

export const initialState: ReportsState = {
  dictionary: {},
  list: { params: {}, data: [], total: 0, page: 0, size: 0 },
  editing: { data: [] },
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
}

function configReducer(
  config: ConfigState<ReportConfig>,
  action: ReportAction,
): ConfigState<ReportConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

function dictionaryReducer(
  dictionary: ReportsState['dictionary'],
  action: ReportAction,
): ReportsState['dictionary'] {
  switch (action.type) {
    case ActionType.FILTER_SUCCESS:
      return produce(dictionary, draft => {
        action.result.data.forEach(report => {
          draft[report.id] = report
        })
      })

    case ActionType.LOAD_SUCCESS:
    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.WS_CREATE:
    case ActionType.WS_UPDATE:
      return produce(dictionary, draft => {
        draft[action.report.id] = action.report
      })

    case ActionType.WS_PATCH:
      return produce(dictionary, draft => {
        draft[action.id] = { ...(draft[action.id] || {}), ...action.changes }
      })

    default:
      return dictionary
  }
}

function listReducer(list: ReportsState['list'], action: ReportAction): ReportsState['list'] {
  switch (action.type) {
    case ActionType.FILTER_REQUEST:
      return { ...list, loading: true, params: action.params }

    case ActionType.FILTER_FAILURE:
      return { ...list, loading: false }

    case ActionType.FILTER_SUCCESS: {
      const { data, total, page, size } = action.result
      return { ...list, loading: false, data: data.map(r => r.id), total, page, size }
    }

    default:
      return list
  }
}

function viewingReducer(
  viewing: ReportsState['viewing'],
  action: ReportAction,
): ReportsState['viewing'] {
  switch (action.type) {
    case ActionType.UNLOAD:
      return undefined

    case ActionType.FILTER_SUCCESS:
      // Se o ID que está sendo visualizado não estiver nos resultados, limpa
      return action.result.data.find(r => r.id === viewing)?.id

    case ActionType.LOAD_SUCCESS:
      return action.edit ? undefined : action.report.id

    case ActionType.REMOVE_SUCCESS:
      return action.ids.find(id => id === viewing) ? undefined : viewing

    case ActionType.WS_DELETE:
      return viewing && action.ids.includes(viewing) ? undefined : viewing

    default:
      return viewing
  }
}

function revisionsReducer(
  revisions: ReportsState['revisions'],
  action: ReportAction,
): ReportsState['revisions'] {
  switch (action.type) {
    case ActionType.UNLOAD_REVISIONS:
      return initialState.revisions
    case ActionType.LOAD_REVISIONS_SUCCESS:
      return action.revisions
    default:
      return revisions
  }
}

function editingReducer(
  editing: ReportsState['editing'],
  dictionary: ReportsState['dictionary'],
  action: ReportAction,
): ReportsState['editing'] {
  switch (action.type) {
    case ActionType.NEW: {
      const { report } = action
      const newData = editing.data.find(r => isNew(r))

      // Já está criando um novo relatório? Se sim, troca para a aba dele
      if (newData) {
        return { ...editing, activeKey: newData.uuid }
      }
      return { ...editing, activeKey: report.uuid, data: [...editing.data, report] }
    }

    case ActionType.LOAD_SUCCESS: {
      const { edit, report } = action
      if (!edit) {
        return editing
      }
      const match = editing.data.find(r => r.id === report.id)
      return match
        ? { ...editing, activeKey: report.uuid }
        : { ...editing, data: [...editing.data, report], activeKey: report.uuid }
    }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...editing, saving: true }

    case ActionType.UPDATE_FAILURE:
    case ActionType.CREATE_FAILURE:
      return { ...editing, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS: {
      const { report } = action
      return {
        ...editing,
        saving: false,
        data: editing.data.map(r => (r.uuid === report.uuid ? report : r)),
      }
    }

    case ActionType.CHANGE_TAB:
      return { ...editing, activeKey: action.key }

    case ActionType.CLOSE_TAB: {
      const { key } = action
      const { activeKey } = editing
      return {
        ...editing,
        activeKey: activeKey === key ? undefined : activeKey,
        data: editing.data.filter(r => r.uuid !== key),
      }
    }

    default:
      return editing
  }
}

const reportsReducer: Reducer<ReportsState, ReportAction> = (
  state = initialState,
  action,
): ReportsState => {
  if (action.type === ActionType.CLEAR) {
    return initialState
  }
  if (!Object.values(ActionType).includes(action.type)) {
    return state
  }
  return {
    config: configReducer(state.config, action),
    dictionary: dictionaryReducer(state.dictionary, action),
    list: listReducer(state.list, action),
    viewing: viewingReducer(state.viewing, action),
    editing: editingReducer(state.editing, state.dictionary, action),
    revisions: revisionsReducer(state.revisions, action),
  }
}

export default reportsReducer
