import React, { memo, useCallback, ReactNode, DetailedHTMLProps, HTMLAttributes } from 'react'

import { Droppable } from 'react-beautiful-dnd'
import styled from '@emotion/styled'

import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'
import Pagination from 'antd/es/pagination'
import Spin from 'antd/es/spin'

import { PAGE_SIZE } from '../../../consts/pagination'

import theme from '../../../theme'

import { defaultPaginationProps } from '../../../utils/pagination-utils'

import TBodyRows from './TBodyRows'

export type RowKey = string

export interface ColumnProps<T = unknown> {
  title: ReactNode
  key: string
  dataIndex?: keyof T
  extractor?: (recordValue: any, recordKey: number) => any
  align?: 'left' | 'center' | 'right'
  editor?: {
    type: string
    disabled?: boolean
    durationFormat?: 'HH:mm' | 'HH:mm:ss' | 'mm:ss'
    maxLength?: number
    onChange: (recordKey: number, dataIndex: any, value: any, initial: any) => any
    transformer?: Function
    validator?: Function
    values?: any[]
    valueKey?: string
    valueLabel?: string
    className?: string
    minWidth?: number // para o editor do tipo input (geralmente slug)
  }
  onCell?: (recordValue: any, recordKey: number, index: number) => any
  render?: (recordValue: any, recordKey: number, index: number) => ReactNode
  width?: number | string
}

export type DataSource = any[]

export type DraggableIdProp = string

export const SELECT_COLUMN_WIDTH = 26

const TableWrapper = styled.div`
  color: rgba(0, 0, 0, 1);
  line-height: 1.5;
  position: relative;
  user-select: none;

  & table {
    border: 0;
    border-collapse: separate;
    border-radius: 0;
    border-spacing: 0;
    width: 100%;
  }
  & table.ant-spin-blur {
    clear: both;
    overflow: hidden;
    opacity: 0.25;
    user-select: none;
    pointer-events: none;
  }
  div > .ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;

    & .ant-spin-dot {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -10px;
    }
  }
`

const borderColor = theme.palletes.grey[2]

const THead = styled.thead<
  DetailedHTMLProps<HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement> & {
    bordered: boolean
  }
>`
  & tr {
    border-bottom: 1px solid ${borderColor};
    transition: all 0.3s, height 0s;
  }
  & tr th {
    background: ${theme.backgrounds.light};
    border-bottom: 1px solid ${borderColor};
    border-right: ${({ bordered }) => (bordered ? `1px solid ${borderColor}` : 0)};
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    overflow: hidden;
    padding: 2px 4px;
    text-align: left;
    text-overflow: ellipsis;
    transition: background 0.3s ease;
    white-space: nowrap;
  }
  & tr th:last-child {
    border-right: 0;
  }
`

const TBody = styled.tbody<
  DetailedHTMLProps<HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement> & {
    bordered: boolean
    ellipsis: boolean
  }
>`
  & tr {
    background-color: ${theme.backgrounds.body};
    width: 100%;
  }
  & tr td {
    border-bottom: 1px solid ${borderColor};
    border-right: ${({ bordered }) => (bordered ? `1px solid ${borderColor}` : 0)};
    color: rgba(0, 0, 0, 1);
    padding: 2px 4px;
    transition: all 0.3s, border 0s;
    overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'inherit')};
    text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
    white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'inherit')};
  }
  & tr td:last-child {
    border-right: 0;
  }
`

export interface DraggableTableProps {
  bordered: boolean
  columns: ColumnProps<any>[]
  dataSource: DataSource
  dragDisabled?: boolean | ((record: any) => boolean)
  dropDisabled?: boolean
  droppableId: string
  draggableIdProp: DraggableIdProp
  ellipsis: boolean
  firstColumn: 'checkbox' | 'bars' | 'none'
  loading?: boolean
  rowKey: string
  disableCheckbox?: boolean
  dragCount?: number
  pagination?: { total: number; page: number; onPageChange: (newPage: number) => void }
  rowSelection: {
    selectedRowKeys: number[]
    onChange?: (selectedKeys: number[]) => void
  }
  onRow?: (record: any, index: number) => any
  rowClassName?: (record: any, index: number) => any
}

const DraggableTable = (props: DraggableTableProps) => {
  const {
    bordered,
    columns,
    dataSource,
    dragDisabled,
    dropDisabled,
    droppableId,
    draggableIdProp,
    ellipsis,
    firstColumn,
    loading,
    rowKey,
    rowSelection,
    pagination,
    disableCheckbox,
    dragCount,
    rowClassName,
    onRow,
  } = props

  const selectionDisabled = Object.keys(rowSelection).length === 0 || disableCheckbox !== undefined
  const { selectedRowKeys, onChange: onSelectionChange } = rowSelection

  const empty = dataSource.length === 0
  const checked = !selectionDisabled && !empty && dataSource.length === selectedRowKeys!.length
  const indeterminate = !selectionDisabled && !checked && selectedRowKeys!.length > 0

  const toggleSelectAll = useCallback(
    (event: CheckboxChangeEvent) => {
      if (onSelectionChange) {
        if (event.target.checked) {
          onSelectionChange(dataSource.map(record => record[rowKey]))
        } else {
          onSelectionChange([])
        }
      }
    },
    [dataSource, onSelectionChange, rowKey],
  )

  const toggleSelect = useCallback(
    (key: number, selected: boolean) => {
      if (onSelectionChange) {
        if (selected) {
          onSelectionChange([...selectedRowKeys, key])
        } else {
          onSelectionChange(selectedRowKeys.filter(curr => curr !== key))
        }
      }
    },
    [selectedRowKeys, onSelectionChange],
  )

  return (
    <TableWrapper>
      {loading && (
        <div>
          <Spin />
        </div>
      )}
      <table className={loading ? 'ant-spin-blur' : undefined}>
        <THead bordered={bordered}>
          <tr>
            {!selectionDisabled && (
              <th style={{ width: SELECT_COLUMN_WIDTH }}>
                <Checkbox
                  disabled={empty}
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={toggleSelectAll}
                />
              </th>
            )}
            {columns.map(column => (
              <th
                key={column.key}
                style={column.width ? { width: column.width, maxWidth: column.width } : {}}
                title={typeof column.title === 'string' ? column.title : undefined}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </THead>
        <Droppable droppableId={droppableId} isDropDisabled={!!dropDisabled}>
          {provided => (
            // Devido às mutações de provided/snapshot, esse element sofrerá mudanças constantes
            // É importante que seus filhos usem PureComponent ou memo()
            <TBody
              {...provided.droppableProps}
              ref={provided.innerRef}
              bordered={bordered}
              ellipsis={ellipsis}
            >
              <TBodyRows
                columns={columns}
                dataSource={dataSource}
                dragCount={dragCount}
                dragDisabled={dragDisabled}
                draggableIdProp={draggableIdProp}
                firstColumn={firstColumn}
                rowClassName={rowClassName}
                rowKey={rowKey}
                selectedRowKeys={selectedRowKeys}
                selectionDisabled={selectionDisabled}
                onRow={onRow}
                onSelectionChange={toggleSelect}
              />
              {provided.placeholder}
            </TBody>
          )}
        </Droppable>
      </table>

      {pagination && (
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination
            current={pagination.page + 1}
            pageSize={PAGE_SIZE}
            showQuickJumper={defaultPaginationProps.showQuickJumper}
            showSizeChanger={defaultPaginationProps.showSizeChanger}
            showTotal={defaultPaginationProps.showTotal}
            size={defaultPaginationProps.size}
            total={pagination.total}
            onChange={(page: number) => pagination.onPageChange(page - 1)}
          />
        </div>
      )}
    </TableWrapper>
  )
}

export default memo(DraggableTable)
