import { get, put } from './client';
var endpoint = '/api/cleaning';
var cleaningApi = {
    //
    //  config
    //
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default cleaningApi;
