import { query, upload, get } from './client';
var endpoint = '/api/files';
var storageFileApi = {
    uploadFile: function (file, noProxy, onUploadProgress, cancelUpload) {
        var form = new FormData();
        form.append('file', file);
        return upload(query(endpoint + "/single", { noProxy: noProxy }), form, onUploadProgress, cancelUpload);
    },
    loadFiles: function (storageFilesIds) {
        return get(query(endpoint + "/" + storageFilesIds, { storageFilesIds: storageFilesIds }));
    },
    uploadFiles: function (files, noProxy, onUploadProgress, cancelUpload) {
        var form = new FormData();
        for (var idx = 0; idx < files.length; idx += 1) {
            var file = files[idx];
            form.append(file.name, file);
        }
        return upload(query(endpoint + "/multiple", { noProxy: noProxy }), form, onUploadProgress, cancelUpload);
    },
    getThumbSrc: function (id) {
        return endpoint + "/" + id + "/thumbnail";
    },
    getFileSrc: function (id) {
        return endpoint + "/" + id + "/file";
    },
    previewFile: function (id) {
        return endpoint + "/" + id + "/preview";
    },
};
export default storageFileApi;
