import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'
import moment from 'moment'

import { useLocalStorage } from '@anews/hooks'

import { AllowedType, Section, UnsavedDataCache } from './types'

const STORAGE_KEY = 'unsaved_data_cache'

export interface UnsavedContextValue {
  actives: string[]
  unsavedData: UnsavedDataCache
  clearUnsavedData: () => void
  clearUnsavedSection: (sectionKey: Section) => void
  clearUnsavedEntry: (sectionKey: Section, uuid: string) => void
  updateUnsavedEntry: <T extends AllowedType>(sectionKey: Section, uuid: string, newData: T) => void
}

const initialData = {
  [Section.CHECKLISTS]: {},
  [Section.GUIDELINES]: {},
  [Section.REPORTS]: {},
  [Section.REPORTAGES]: {},
  [Section.STORIES]: {},
}

export const UnsavedContext = createContext<UnsavedContextValue>({
  actives: [],
  unsavedData: initialData,
  clearUnsavedData: () => {},
  clearUnsavedSection: (sectionKey: Section) => {},
  clearUnsavedEntry: (sectionKey: Section, uuid: string) => {},
  updateUnsavedEntry: <T extends AllowedType>(sectionKey: Section, uuid: string, newData: T) => {},
})

export default function UnsavedDataProvider({ children }: { children?: ReactNode }) {
  const [unsavedData, setUnsavedData] = useLocalStorage<UnsavedDataCache>(STORAGE_KEY, initialData)
  const [actives, setActives] = useState<string[]>([])

  const clearUnsavedData = useCallback(() => {
    try {
      setUnsavedData(initialData)
    } catch (error) {
      console.error('UnsavedDataProvider error', error)
    }
  }, [setUnsavedData])

  const clearUnsavedSection = useCallback(
    (sectionKey: Section) => {
      try {
        setUnsavedData(currCache => ({ ...currCache, [sectionKey]: {} }))
      } catch (error) {
        console.error('UnsavedDataProvider error', error)
      }
    },
    [setUnsavedData],
  )

  const clearUnsavedEntry = useCallback(
    (sectionKey: Section, uuid: string) => {
      if (!uuid) {
        return
      }
      try {
        setUnsavedData(currCache => {
          const { [uuid]: toRemove, ...rest } = currCache[sectionKey] || {}
          return { ...currCache, [sectionKey]: rest }
        })
        setActives(currUuids => currUuids.filter(curr => curr !== uuid))
      } catch (error) {
        console.error('UnsavedDataProvider error', error)
      }
    },
    [setUnsavedData],
  )

  const updateUnsavedEntry = useCallback(
    (sectionKey: Section, uuid: string, newData: AllowedType) => {
      if (!uuid) {
        return
      }
      try {
        setUnsavedData(currCache => {
          const currSection = currCache[sectionKey] || {}
          const entry = currSection[uuid]

          if (isEqual(entry?.document, newData)) {
            // Um documento alterado estava sendo removido na segunda verificação com isso aqui
            // setActives(currUuids => currUuids.filter(curr => curr !== uuid))
            return currCache
          }

          setActives(currUuids => (currUuids.includes(uuid) ? currUuids : [...currUuids, uuid]))

          return {
            ...currCache,
            [sectionKey]: {
              ...currSection,
              [uuid]: { document: newData, date: moment().toISOString() },
            },
          }
        })
      } catch (error) {
        console.error('UnsavedDataProvider error', error)
      }
    },
    [setUnsavedData],
  )

  const value = useMemo(
    () => ({
      actives,
      unsavedData,
      clearUnsavedData,
      clearUnsavedSection,
      clearUnsavedEntry,
      updateUnsavedEntry,
    }),
    [
      actives,
      unsavedData,
      clearUnsavedData,
      clearUnsavedSection,
      clearUnsavedEntry,
      updateUnsavedEntry,
    ],
  )

  return <UnsavedContext.Provider value={value}>{children}</UnsavedContext.Provider>
}
