import { Reducer } from 'redux'

import { Round } from '@anews/types'

import { ActionType, RoundAction } from '../actions/round-actions'

export interface RoundsListState {
  data: Round[]
  loading: boolean
}

export interface RoundsState {
  list: RoundsListState
  saving: boolean
  removing: boolean
}

export const initialState: RoundsState = {
  list: {
    data: [],
    loading: true,
  },
  saving: false,
  removing: false,
}

function sortRounds(rounds: Round[]) {
  // !! Modifica o array original !!
  rounds.sort((a: Round, b: Round) => a.time.localeCompare(b.time))
  return rounds
}

function listReducer(list: RoundsListState, action: RoundAction): RoundsListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.rounds,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
    case ActionType.WS_CREATE:
      // O filter serve para não duplicar (CREATE_SUCCESS x WS_CREATE)
      return {
        ...list,
        data: sortRounds([action.round, ...list.data.filter(r => r.id !== action.round.id)]),
      }

    case ActionType.UPDATE_SUCCESS:
    case ActionType.WS_UPDATE:
      return {
        ...list,
        data: sortRounds(
          list.data.map(round => (round.id === action.round.id ? action.round : round)),
        ),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(round => !action.ids.includes(round.id)) }

    case ActionType.WS_DELETE:
      return { ...list, data: list.data.filter(round => round.id !== action.id) }

    default:
      return list
  }
}

const roundsReducer: Reducer<RoundsState, RoundAction> = (
  state = initialState,
  action,
): RoundsState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
    case ActionType.WS_CREATE:
    case ActionType.WS_DELETE:
    case ActionType.WS_UPDATE:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default roundsReducer
