import { all, call, spawn } from 'redux-saga/effects'

/*
 * Cria um generator para ser o root de um conjunto de sagas de uma forma em que,
 * caso uma das sagas gere uma exceção, não "crash" todo o fluxo de sagas.
 *
 * Essa pattern foi retirado da documentação oficial, que por sua vez se baseou
 * em um coméntario em uma issue no Github. (links abaixo)
 *
 * https://redux-saga.js.org/docs/advanced/RootSaga.html
 * https://github.com/redux-saga/redux-saga/issues/570#issuecomment-258038482
 */
export function createRootSaga(sagas: any[]) {
  return function* root() {
    yield all(
      sagas.map(saga =>
        spawn(function* () {
          while (true) {
            try {
              yield call(saga)
              break
            } catch (error) {
              console.error(error)
            }
          }
        }),
      ),
    )
  }
}
