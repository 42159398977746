import { Reducer } from 'redux'

import { FacebookPage } from '@anews/types'

import {
  FacebookPageActionType as ActionType,
  FacebookPageAction,
} from '../actions/facebookpage-actions'

export interface FacebookPagesListState {
  data: FacebookPage[]
  loading: boolean
}

export interface FacebookPagesState {
  list: FacebookPagesListState
  editing?: FacebookPage
  saving: boolean
  removing: boolean
}

export const initialState: FacebookPagesState = {
  list: {
    data: [],
    loading: true,
  },
  editing: undefined,
  saving: false,
  removing: false,
}

function listReducer(
  list: FacebookPagesListState,
  action: FacebookPageAction,
): FacebookPagesListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.pages,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [action.page, ...list.data] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(page => (page.id === action.page.id ? action.page : page)),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(page => page.id !== action.id) }

    default:
      return list
  }
}

const pagesReducer: Reducer<FacebookPagesState, FacebookPageAction> = (
  state = initialState,
  action,
): FacebookPagesState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT:
      return { ...state, editing: action.page }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.page, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        list: listReducer(state.list, action),
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default pagesReducer
