export var NEWSPAPER = 'NEWSPAPER';
export var RADIO = 'RADIO';
export var TV = 'TV';
export var WEB = 'WEB';
export var VehicleType;
(function (VehicleType) {
    VehicleType["NEWSPAPER"] = "NEWSPAPER";
    VehicleType["RADIO"] = "RADIO";
    VehicleType["TV"] = "TV";
    VehicleType["WEB"] = "WEB";
})(VehicleType || (VehicleType = {}));
