import { call, put, takeLatest } from 'redux-saga/effects'

import { indexApi as api } from '@anews/api'
import { IndexesState } from '@anews/types'

import { NotificationActions } from '../actions/notification-actions'
import { IndexActionType, IndexActions, IndexActionMap } from '../actions/index-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions
const {
  loadStateSuccess,
  loadStateFailure,
  rebuildIndexSuccess,
  rebuildIndexFailure,
} = IndexActions
/* Watchers */

function* loadStateSaga(): Generator {
  try {
    const state = yield call(api.loadState)
    yield put(loadStateSuccess(state as IndexesState))
  } catch (error) {
    yield put(loadStateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* rebuildIndexSaga(action: IndexActionMap<IndexActionType.REBUILD_REQUEST>): Generator {
  try {
    yield call(api.rebuildIndex, action.targets)
    yield put(rebuildIndexSuccess())
  } catch (error) {
    yield put(rebuildIndexFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(IndexActionType.LOAD_STATE_REQUEST, loadStateSaga)
  },
  function* () {
    yield takeLatest(IndexActionType.REBUILD_REQUEST, rebuildIndexSaga)
  },
])
