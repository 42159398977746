var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { del, get, query, patch, post, put } from './client';
var endpoint = '/api/reports';
var reportApi = {
    pageFilter: function (params, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", __assign(__assign({}, params), { page: page, size: size })));
    },
    listDocumentReports: function (ids) {
        var documentIds = Array.isArray(ids) ? ids : [ids];
        return get(query(endpoint, { documentIds: documentIds }));
    },
    listExcluded: function (filter, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/excluded", __assign(__assign({}, filter), { page: page, size: size })));
    },
    load: function (id, lock) {
        return get(query(endpoint + "/" + id, { lock: lock }));
    },
    create: function (report, lock) {
        return post(query(endpoint, { lock: lock }), report);
    },
    update: function (report) {
        return put(endpoint, report);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
    restore: function (ids, date) {
        return put(query(endpoint + "/restore", { ids: ids, date: date }));
    },
    loadChangesHistory: function (id) {
        return get(query(endpoint + "/" + id + "/revisions"));
    },
    loadRevision: function (id, revisionNumber, showDiff) {
        return get(query(endpoint + "/" + id + "/revisions/" + revisionNumber, { showDiff: showDiff }));
    },
    togglePrivate: function (id) {
        return patch(endpoint + "/" + id + "/private");
    },
    updateShare: function (id, usersIds) {
        return put(endpoint + "/" + id + "/allowedUsers", usersIds);
    },
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default reportApi;
