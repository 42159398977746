import styled from '@emotion/styled'

import theme from '../../theme'

const DropArea = styled.div<{ over?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  align-items: center;
  display: flex;
  justify-content: center;

  border: 2px dashed ${props => (props.over ? theme.colors.blue : theme.colors.grey)};
  border-radius: 2px;
  background-color: #fff;

  color: ${props => (props.over ? theme.colors.blue : theme.colors.grey)};
  font-size: 14px;
`

export default DropArea
