import { call, put, takeLatest } from 'redux-saga/effects'

import { reportEventApi as api } from '@anews/api'
import { ReportEvent } from '@anews/types'

import {
  ReportEventActions,
  ReportEventActionType as ActionType,
  ReportEventActionMap as ActionMap,
  NotificationActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  listReportEventsSuccess,
  listReportEventsFailure,
  createReportEventSuccess,
  createReportEventFailure,
  updateReportEventSuccess,
  updateReportEventFailure,
  removeReportEventSuccess,
  removeReportEventFailure,
} = ReportEventActions

const { notifyError } = NotificationActions

/* Watchers */

function* listReportEventsSaga(): Generator {
  try {
    const events = yield call(api.list)
    yield put(listReportEventsSuccess(events as ReportEvent[]))
  } catch (error) {
    yield put(listReportEventsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createReportEventSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const event = yield call(api.create, action.event)
    yield put(createReportEventSuccess(event as ReportEvent))
  } catch (error) {
    yield put(createReportEventFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateReportEventSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const event = yield call(api.update, action.event)
    yield put(updateReportEventSuccess(event as ReportEvent))
  } catch (error) {
    yield put(updateReportEventFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeReportEventSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeReportEventSuccess(action.id))
  } catch (error) {
    yield put(removeReportEventFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listReportEventsSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createReportEventSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateReportEventSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeReportEventSaga)
  },
])
