import { del, get, post, put } from './client';
var endpoint = '/api/twitter/lists';
var twitterListApi = {
    listAll: function () {
        return get(endpoint);
    },
    listUserOwnedLists: function (username) {
        return get(endpoint + "/user/" + username);
    },
    create: function (list) {
        return post(endpoint, list);
    },
    update: function (list) {
        return put("" + endpoint, list);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default twitterListApi;
