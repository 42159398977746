import React, { ReactElement, useCallback } from 'react'
import Select, { SelectProps, SelectValue } from 'antd/es/select'

const normalizeString = (str: string) =>
  str
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replaceAll('°', '')
    .replaceAll('º', '')
    .replaceAll('ª', '')
    .toLowerCase()

const noSpaces = (str: string) => str.trim().replaceAll(' ', '').toLowerCase()

// Para casos onde o select.option contenha componentes
const getTextWithClassName = (className: string, element: ReactElement): string | undefined => {
  let elem = element
  if (typeof elem === 'string' || typeof elem === 'number') {
    return `${elem}`
  }

  if (typeof elem === 'object' && elem.props) {
    if (elem.props.className === className) {
      return getTextWithClassName(className, elem.props.children)
    }
    elem = elem.props.children
  }

  if (!Array.isArray(elem)) {
    if (elem.props.className === className) {
      return getTextWithClassName(className, elem.props.children)
    }
  } else {
    const children: ReactElement[] = elem || []
    for (let idx = 0; idx < children.length; idx += 1) {
      const child = children[idx]
      const text = getTextWithClassName(className, child)
      if (text) {
        return text
      }
    }
  }
}

export default function SelectWithSearch<T extends SelectValue>(props: SelectProps<T>) {
  const { className, optionFilterProp } = props

  const filterFn = useCallback(
    (input, option) => {
      try {
        const normInput = normalizeString(input)

        // Caso esteja dentro de um grupo select
        if (Array.isArray(option?.options)) {
          const options = option?.options || []
          return options.includes(
            (child: any) =>
              normalizeString(child.children).includes(normInput) ||
              noSpaces(child.children).includes(noSpaces(normInput)),
          )
        }

        let opt = option && option[optionFilterProp || 'children']
        if (opt && className && typeof opt === 'object') {
          opt = getTextWithClassName(className, opt as ReactElement)
        }

        if (opt && (typeof opt === 'string' || typeof opt === 'number')) {
          opt = normalizeString(`${opt}`)
          return opt.includes(normInput) || noSpaces(opt).includes(noSpaces(normInput))
        }
      } catch (error) {
        console.error(error)
      }
      return true
    },
    [className, optionFilterProp],
  )

  return <Select<T> showSearch filterOption={filterFn} {...props} />
}
