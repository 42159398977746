import { del, get, query, patch, post } from './client';
var endpoint = '/api/rundowns/templates';
var rundownTemplateApi = {
    listTemplates: function (programId) {
        if (programId === undefined) {
            return Promise.resolve([]);
        }
        return get(query(endpoint, { programId: programId }));
    },
    listAllTemplates: function () {
        return get(query(endpoint + "/all"));
    },
    loadTemplate: function (id) {
        return get(endpoint + "/" + id);
    },
    create: function (name, programId) {
        return post(query(endpoint, { name: name, programId: programId }));
    },
    createCopy: function (templateId, name, programId) {
        return post(query(endpoint + "/" + templateId, { name: name, programId: programId }));
    },
    removeTemplate: function (templateId) {
        return del(endpoint + "/" + templateId);
    },
    createBlock: function (rundownId) {
        return post(query(endpoint + "/" + rundownId + "/blocks"));
    },
    createStory: function (blockId) {
        return post(query(endpoint + "/blocks/" + blockId + "/stories"));
    },
    copyStoriesToTemplate: function (id, source, storiesIds) {
        return patch(query(endpoint + "/" + id + "/paste", { source: source, storiesIds: storiesIds }));
    },
    patchTemplate: function (id, field, value) {
        var _a;
        // Enviar undefined não funciona para limpar o valor
        return patch(endpoint + "/" + id, (_a = {}, _a[field] = value === undefined ? null : value, _a));
    },
    patchBlock: function (blockId, field, value) {
        var _a;
        // Enviar undefined não funciona para limpar o valor
        return patch(endpoint + "/blocks/" + blockId, (_a = {}, _a[field] = value === undefined ? null : value, _a));
    },
    removeBlock: function (blockId) {
        return del(endpoint + "/blocks/" + blockId);
    },
    moveStory: function (blockId, storyId, targetStoryId) {
        return patch(query(endpoint + "/blocks/" + blockId + "/stories/move", { storyId: storyId, targetStoryId: targetStoryId }));
    },
    repaginateStories: function (templateId) {
        return patch(endpoint + "/" + templateId + "/repaginate");
    },
};
export default rundownTemplateApi;
