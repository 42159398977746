import React, { memo } from 'react'

import Button from 'antd/es/button'

import { mapsApi } from '@anews/api'
import { Contact, AgendaContact, TranslationDictionary } from '@anews/types'
import { isNotBlank } from '@anews/utils'

import { ViewerItem } from '../../global'
import { useTranslation } from '../../../i18n'

interface Props {
  contact: Contact
}

function ContactCompactViewer({ contact }: Props) {
  const { t } = useTranslation()
  const { address } = contact as AgendaContact

  return (
    <>
      <ViewerItem label={t('words:contact')}>{contact.name}</ViewerItem>
      <ViewerItem label={t('contact:profession')}>{contact.profession}</ViewerItem>
      {contact.numbers && contact.numbers.length > 0 && (
        <ViewerItem label={t('contact:numbers')}>
          <ul>
            {contact.numbers.map(number => (
              <li key={number.id}>
                {number.value} (
                {t(`contact:numberType_${number.label.toLowerCase()}` as TranslationDictionary)})
              </li>
            ))}
          </ul>
        </ViewerItem>
      )}

      {contact.emails && contact.emails.length > 0 && (
        <ViewerItem label={t('contact:emails')}>
          <ul>
            {contact.emails.map(email => (
              <li key={email}>{email}</li>
            ))}
          </ul>
        </ViewerItem>
      )}

      {address && (
        <ViewerItem label={t('words:address')}>
          <Button
            type="link"
            style={{ maxWidth: '576px', overflowX: 'scroll', overflowY: 'hidden', height: 'auto' }}
            onClick={() => address && mapsApi.openOnMaps(address)}
          >
            {address}
          </Button>
        </ViewerItem>
      )}

      {isNotBlank(contact.info) && <ViewerItem label={t('words:info')}>{contact.info}</ViewerItem>}
    </>
  )
}

export default memo(ContactCompactViewer)
