import { del, get, post as httpPost, put, query } from './client';
var endpoint = '/api/wordpress';
var wordpressApi = {
    listConfigs: function () {
        return get("" + endpoint);
    },
    loadConfig: function (id) {
        return get(endpoint + "/" + id);
    },
    createConfig: function (config) {
        return httpPost("" + endpoint, config);
    },
    updateConfig: function (config) {
        return put("" + endpoint, config);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
    listAuthors: function (id) {
        if (!id) {
            return Promise.resolve([]);
        }
        return get(endpoint + "/" + id + "/authors");
    },
    listCategories: function (id) {
        if (!id) {
            return Promise.resolve([]);
        }
        return get(endpoint + "/" + id + "/categories");
    },
};
export default wordpressApi;
