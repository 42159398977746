import { call, put, takeLatest } from 'redux-saga/effects'

import { licenseApi as api } from '@anews/api'

import { License } from '@anews/types'

import { NotificationActions } from '../actions'

import {
  ActionType,
  createSetServerUrlSuccess,
  createSetServerUrlFailure,
  SetServerUrlLicenseAction,
  createLicenseInfoSuccess,
  createLicenseInfoFailure,
  LicenseRegisterAction,
  createLicenseRegisterSuccess,
  createLicenseRegisterFailure,
} from '../actions/license-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError, notifyWarning } = NotificationActions

/* Watchers */

function* licenseInfoSaga(): Generator {
  try {
    const result = yield call(api.getInfo)
    yield put(createLicenseInfoSuccess(result as License))
  } catch (error) {
    yield put(createLicenseInfoFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

// Obter dias de expiração e notificar
function* daysToExpireSaga(): Generator {
  try {
    const daysToExpire = (yield call(api.getDaysToExpire)) as number

    if (i18n.isInitialized) {
      if (daysToExpire >= 0) {
        if (daysToExpire < 30) {
          yield put(
            notifyWarning({
              message: i18n.t('license:expirationNearFront', { daysToExpire }),
              description: i18n.t('license:contact'),
            }),
          )
        }
      } else {
        // Expired
        yield put(
          notifyWarning({
            message: i18n.t('license:expiredMsg'),
            description: i18n.t('license:contact'),
          }),
        )
      }
    }
  } catch (error) {
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* setServerUrlLicenseSaga(actions: SetServerUrlLicenseAction): Generator {
  try {
    const result = yield call(api.setServerUrl, actions.url)
    const lic = result as License
    yield put(createSetServerUrlSuccess(lic))
  } catch (error) {
    yield put(createSetServerUrlFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* licenseRegisterseSaga(actions: LicenseRegisterAction): Generator {
  try {
    const result = yield call(api.register, actions.license)
    yield put(createLicenseRegisterSuccess(result as License))
  } catch (error) {
    yield put(createLicenseRegisterFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}
/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.INFO_LICENSE_REQUEST, licenseInfoSaga)
  },
  function* () {
    yield takeLatest(ActionType.DAYS_LICENSE_REQUEST, daysToExpireSaga)
  },
  function* () {
    yield takeLatest(ActionType.SET_SERVER_URL_REQUEST, setServerUrlLicenseSaga)
  },
  function* () {
    yield takeLatest(ActionType.REGISTER_REQUEST, licenseRegisterseSaga)
  },
])
