import { memo } from 'react'
import styled from '@emotion/styled'
import Form from 'antd/es/form'

const LabelessFormItem = styled(Form.Item)`
  &&& {
    margin-bottom: 0;

    .ant-form-item-control {
      line-height: 0%;
    }
    .ant-form-item-control-wrapper {
      display: block;
    }
  }
`

export default memo(LabelessFormItem)
