import { Vehicle } from '@anews/types'

export enum VehicleActionType {
  LIST_REQUEST = '@vehicle/LIST_REQUEST',
  LIST_SUCCESS = '@vehicle/LIST_SUCCESS',
  LIST_FAILURE = '@vehicle/LIST_FAILURE',
}

export type VehicleActionMap<T extends VehicleActionType> = { type: T } & VehicleAction

export type VehicleAction = ReturnType<typeof VehicleActions[keyof typeof VehicleActions]>

export const VehicleActions = {
  //
  //	listVehicles()
  //

  listVehicles: () => <const>{ type: VehicleActionType.LIST_REQUEST },

  listVehiclesSuccess: (vehicles: Vehicle[]) =>
    <const>{ type: VehicleActionType.LIST_SUCCESS, vehicles },

  listVehiclesFailure: (error: Error) => <const>{ type: VehicleActionType.LIST_FAILURE, error },
}
