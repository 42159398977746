import { call, put, takeLatest } from 'redux-saga/effects'
import { FORBIDDEN } from 'http-status-codes'

import { entityLockApi as api } from '@anews/api'
import { EntityLock } from '@anews/types'

import {
  EntityLockActionType,
  EntityLockActionMap,
  EntityLockActions,
  NotificationActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  listActiveLocksFailure,
  listActiveLocksSuccess,
  renewLockFailure,
  renewLockSuccess,
  unlockFailure,
  unlockSuccess,
  takeOverLockSuccess,
  takeOverLockFailure,
} = EntityLockActions

const { notifyError } = NotificationActions

/* Watchers */

function* listActiveLocksSaga(): Generator {
  try {
    const locks = yield call(api.activeLocks)
    yield put(listActiveLocksSuccess(locks as EntityLock[]))
  } catch (error) {
    yield put(listActiveLocksFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* renewLockSaga(
  action: EntityLockActionMap<EntityLockActionType.RENEW_REQUEST>,
): Generator {
  try {
    const lock = yield call(api.renewLock, action.uuid)
    yield put(renewLockSuccess(lock as EntityLock))
  } catch (error) {
    yield put(renewLockFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* takeOverLockSaga(
  action: EntityLockActionMap<EntityLockActionType.TAKE_OVER_REQUEST>,
): Generator {
  try {
    const lock = yield call(api.takeOver, action.uuid)
    yield put(takeOverLockSuccess(lock as EntityLock))
  } catch (error) {
    yield put(takeOverLockFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* unlockSaga(action: EntityLockActionMap<EntityLockActionType.UNLOCK_REQUEST>): Generator {
  try {
    yield call(api.unlock, action.uuid)
    yield put(unlockSuccess(action.uuid))
  } catch (error) {
    yield put(unlockFailure(error))

    const status = error.response?.status

    if (status !== FORBIDDEN) {
      yield put(
        notifyError({
          message: i18n.t('error:operation'),
          description: i18n.t('error:deleteFailed'),
          error,
        }),
      )
    }
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(EntityLockActionType.LIST_ACTIVE_REQUEST, listActiveLocksSaga)
  },
  function* () {
    yield takeLatest(EntityLockActionType.RENEW_REQUEST, renewLockSaga)
  },
  function* () {
    yield takeLatest(EntityLockActionType.TAKE_OVER_REQUEST, takeOverLockSaga)
  },
  function* () {
    yield takeLatest(EntityLockActionType.UNLOCK_REQUEST, unlockSaga)
  },
])
