import { ThemeColor } from '@anews/types'

const theme = {
  fonts: ['Bitsumishi, sans-serif'],
  space: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256, 512],
  // https://ant.design/docs/spec/colors
  colors: {
    black: '#000000',
    blue: '#1890ff',
    cyan: '#13c2c2',
    geek: '#2f54eb',
    gold: '#faad14',
    grey: '#bfbfbf',
    green: '#52c41a',
    lime: '#a0d911',
    magenta: '#eb2f96',
    orange: '#fa8c16',
    purple: '#722ed1',
    red: '#f5222d',
    volcano: '#fa541c',
    white: '#ffffff',
    yellow: '#fadb14',
  },
  // baseado nos valores de antd-customize.less
  backgrounds: {
    component: '#fcfcfc', // component only
    body: '#fafafa',
    light: '#f5f5f5',
    base: '#f0f0f0', // não sei pq "base"... o padrão geral é o "body" acima
  },
  border: '#d9d9d9',
  palletes: {
    blue: [
      '#e6f7ff', // 0
      '#bae7ff', // 1
      '#91d5ff', // 2
      '#69c0ff', // 3
      '#40a9ff', // 4
      '#1890ff', // 5
      '#096dd9', // 6
      '#0050b3', // 7
      '#003a8c', // 8
      '#002766', // 9
    ],
    cyan: [
      '#e6fffb', // 0
      '#b5f5ec', // 1
      '#87e8de', // 2
      '#5cdbd3', // 3
      '#36cfc9', // 4
      '#13c2c2', // 5
      '#08979c', // 6
      '#006d75', // 7
      '#00474f', // 8
      '#002329', // 9
    ],
    geek: [
      '#f0f5ff', // 0
      '#d6e4ff', // 1
      '#adc6ff', // 2
      '#85a5ff', // 3
      '#597ef7', // 4
      '#2f54eb', // 5
      '#1d39c4', // 6
      '#10239e', // 7
      '#061178', // 8
      '#030852', // 9
    ],
    gold: [
      '#fffbe6', // 0
      '#fff1b8', // 1
      '#ffe58f', // 2
      '#ffd666', // 3
      '#ffc53d', // 4
      '#faad14', // 5
      '#d48806', // 6
      '#ad6800', // 7
      '#874d00', // 8
      '#613400', // 9
    ],
    grey: [
      '#fafafa', // 0
      '#f5f5f5', // 1
      '#f0f0f0', // 2
      '#d9d9d9', // 3
      '#bfbfbf', // 4
      '#8c8c8c', // 5
      '#595959', // 6
      '#434343', // 7
      '#262626', // 8
      '#1f1f1f', // 9
    ],
    green: [
      '#f6ffed', // 0
      '#d9f7be', // 1
      '#b7eb8f', // 2
      '#95de64', // 3
      '#73d13d', // 4
      '#52c41a', // 5
      '#389e0d', // 6
      '#237804', // 7
      '#135200', // 8
      '#092b00', // 9
    ],
    lime: [
      '#fcffe6', // 0
      '#f4ffb8', // 1
      '#eaff8f', // 2
      '#d3f261', // 3
      '#bae637', // 4
      '#a0d911', // 5
      '#7cb305', // 6
      '#5b8c00', // 7
      '#3f6600', // 8
      '#254000', // 9
    ],
    magenta: [
      '#fff0f6', // 0
      '#ffd6e7', // 1
      '#ffadd2', // 2
      '#ff85c0', // 3
      '#f759ab', // 4
      '#eb2f96', // 5
      '#c41d7f', // 6
      '#9e1068', // 7
      '#780650', // 8
      '#520339', // 9
    ],
    orange: [
      '#fff7e6', // 0
      '#ffe7ba', // 1
      '#ffd591', // 2
      '#ffc069', // 3
      '#ffa940', // 4
      '#fa8c16', // 5
      '#d46b08', // 6
      '#ad4e00', // 7
      '#873800', // 8
      '#612500', // 9
    ],
    purple: [
      '#f9f0ff', // 0
      '#efdbff', // 1
      '#d3adf7', // 2
      '#b37feb', // 3
      '#9254de', // 4
      '#722ed1', // 5
      '#531dab', // 6
      '#391085', // 7
      '#22075e', // 8
      '#120338', // 9
    ],
    red: [
      '#fff1f0', // 0
      '#ffccc7', // 1
      '#ffa39e', // 2
      '#ff7875', // 3
      '#ff4d4f', // 4
      '#f5222d', // 5
      '#cf1322', // 6
      '#a8071a', // 7
      '#820014', // 8
      '#5c0011', // 9
    ],
    volcano: [
      '#fff2e8', // 0
      '#ffd8bf', // 1
      '#ffbb96', // 2
      '#ff9c6e', // 3
      '#ff7a45', // 4
      '#fa541c', // 5
      '#d4380d', // 6
      '#ad2102', // 7
      '#871400', // 8
      '#610b00', // 9
    ],
    yellow: [
      '#feffe6', // 0
      '#ffffb8', // 1
      '#fffb8f', // 2
      '#fff566', // 3
      '#ffec3d', // 4
      '#fadb14', // 5
      '#d4b106', // 6
      '#ad8b00', // 7
      '#876800', // 8
      '#614700', // 9
    ],
  },
}

export type Theme = typeof theme

export interface ThemeProps {
  theme?: Theme
}

export default theme

// export type ThemeColor = keyof typeof theme.palletes

export function selectPalleteScale(color: ThemeColor | undefined, scale: number) {
  return color ? theme.palletes[color][scale] : undefined
}

// Usado para evitar valores inválidos
// Se uma cor for renomeada, por exemplo, e ela estava salva no banco, vai substituir ela pelo tema padrão (undefined)
export function validateThemeColor(color?: string) {
  if (color && Object.keys(theme.palletes).includes(color)) {
    return color as ThemeColor
  }
  return '' as ThemeColor // tema padrão
}
