import React, { PureComponent, memo, useCallback, useState } from 'react'

import styled from '@emotion/styled'

import Button from 'antd/es/button'
import Tooltip from 'antd/es/tooltip'

import {
  faChevronDown,
  faChevronUp,
  faFontCase,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import { createEditor, Descendant } from 'slate'
import { ReactEditor, withReact } from 'slate-react'
import { withHistory } from 'slate-history'

import { formatIsoDuration, richHasContent } from '@anews/utils'
import { RichTextBlock } from '@anews/types'

import { DoubleIcon, HGroup, TinySwitch, DurationInput, Popconfirm } from '../../global'
import i18n from '../../../i18n'

import RichTextEditor from '../../global/RichText/RichTextEditor'
import { withComment } from '../../global/RichText/toggleRichComment'
import { toggleRichCase } from '../../global/RichText/toggleRichCase'

const StyledHeader = styled.div`
  color: black;
  flex-grow: 1;
  font-size: 16px;
  margin: 0;
  /* padding: 0 8px; */
  padding: 0;
  text-transform: uppercase;
`
const StyledButton = styled(Button)`
  border: 0;
  font-size: 12px;
  height: 24px;
  padding: 0 !important;
`
const StyledDurationInput = styled(DurationInput)`
  border: 0;
  border-radius: 0;
  padding: 0;
  height: 21px;
  width: 40px;
`

interface Props {
  header: string
  duration?: string
  manual?: boolean
  onRemove?: () => void
  onDurationChange?: (manual: boolean, duration: string) => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  onChange?: (content: Descendant[]) => void
  value?: Descendant[]
}
/*
 * O controle de formulário do antd adiciona algumas propriedades à mais no componente, e essas
 * propriedades mudam a cada letra digitada, disparando um render de cada componente de texto.
 *
 * Por isso criei esse componente interno que não recebe as propriedades do antd. Assim ele só faz
 * seu render quando realmente precisa.
 *
 * Isso foi antes da atualização para a versão 4.*
 */
function InternalText({
  header,
  duration,
  manual,
  onDurationChange,
  onRemove,
  onChange,
  onMoveUp,
  onMoveDown,
  value,
}: Props) {
  const [controlsVisible, setControlsVisible] = useState(false)
  const [over, setOver] = useState(false)
  const [focus, setFocus] = useState(false)
  const [visibleConfirm, setVisibleConfirm] = useState(false)

  const formattedDuration = formatIsoDuration(duration || 'PT0S', 'mm:ss')

  const [editor] = useState(() =>
    withComment(withReact(withHistory(createEditor() as ReactEditor))),
  )

  const displayControls = useCallback(() => {
    setControlsVisible(true)
  }, [])

  const hideControls = useCallback(() => {
    setControlsVisible(false)
  }, [])

  const handleFocus = useCallback(() => {
    setFocus(true)
    displayControls()
  }, [displayControls])

  const handleBlur = useCallback(() => {
    setFocus(false)

    if (!over) {
      hideControls()
    }
  }, [hideControls, over])

  const handleMouseEnter = useCallback(() => {
    setOver(true)
    displayControls()
  }, [displayControls])

  const handleMouseLeave = useCallback(() => {
    setOver(false)

    if (!focus) {
      hideControls()
    }
  }, [focus, hideControls])

  const handleManualChange = useCallback(
    checked => {
      if (onDurationChange) {
        onDurationChange(!checked, duration || 'PT0S')
      }
    },
    [duration, onDurationChange],
  )

  const handleDurationChange = useCallback(
    seconds => {
      if (onDurationChange) {
        onDurationChange(manual || false, seconds || 0)
      }
    },
    [manual, onDurationChange],
  )

  let durationElement

  if (duration !== undefined) {
    durationElement = manual ? (
      <StyledDurationInput value={duration} onChange={handleDurationChange} format="mm:ss" />
    ) : (
      <span style={{ width: 40 }}>{formattedDuration}</span>
    )
  }

  const handleVisibleConfirm = useCallback(
    (visible: boolean) => {
      if (!visible) {
        setVisibleConfirm(visible)
        return
      }
      if (!richHasContent(value as RichTextBlock[] | undefined)) {
        onRemove && onRemove()
      } else {
        setVisibleConfirm(visible)
      }
    },
    [onRemove, value],
  )

  const cancelDelete = useCallback(() => {
    setVisibleConfirm(false)
  }, [])

  const onToggleCase = useCallback(() => {
    toggleRichCase(editor)
  }, [editor])

  // Os eventos focus e blur foram recomendados para deficientes visuais
  return (
    <div
      onFocusCapture={handleFocus}
      onBlurCapture={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <HGroup>
        <StyledHeader className="upperCase configFontSize">[{header}]</StyledHeader>
        {controlsVisible && (
          <>
            {onMoveUp && (
              <Tooltip key="up" title={i18n.t('phrases:moveUp')}>
                <StyledButton onClick={onMoveUp}>
                  <DoubleIcon icon={faChevronUp} />
                </StyledButton>
              </Tooltip>
            )}
            {onMoveDown && (
              <Tooltip key="down" title={i18n.t('phrases:moveDown')}>
                <StyledButton onClick={onMoveDown}>
                  <DoubleIcon icon={faChevronDown} />
                </StyledButton>
              </Tooltip>
            )}
            {onToggleCase && (
              <Tooltip title={i18n.t('phrases:toggleCase')}>
                <StyledButton onClick={onToggleCase}>
                  <DoubleIcon icon={faFontCase} />
                </StyledButton>
              </Tooltip>
            )}
            {onRemove && (
              <Tooltip title={i18n.t('words:delete')}>
                <Popconfirm
                  title={i18n.t('phrases:sure')}
                  trigger="click"
                  visible={visibleConfirm}
                  onVisibleChange={handleVisibleConfirm}
                  onConfirm={onRemove}
                  onCancel={cancelDelete}
                  placement="leftBottom"
                  cancelText={i18n.t('words:no')}
                  okText={i18n.t('words:yes')}
                >
                  <StyledButton>
                    <DoubleIcon icon={faTrashAlt} />
                  </StyledButton>
                </Popconfirm>
              </Tooltip>
            )}
            {duration !== undefined && (
              <Tooltip title={i18n.t('reportage:autoCount')}>
                <TinySwitch
                  checked={!manual}
                  onChange={handleManualChange}
                  style={{ marginTop: '2px' }}
                />
              </Tooltip>
            )}
          </>
        )}
        {durationElement}
      </HGroup>
      <RichTextEditor editor={editor} value={value} onChange={onChange} />
    </div>
  )
}

const MemoizedText = memo(InternalText)

// Tinha que ser classe por causa do getFieldDecorator() (pré antd 4.*)
class ReportageText extends PureComponent<Props> {
  render() {
    const {
      header,
      duration,
      manual,
      onRemove,
      onDurationChange,
      onChange,
      onMoveUp,
      onMoveDown,
      value,
    } = this.props

    return (
      <MemoizedText
        value={value}
        header={header}
        duration={duration}
        manual={manual}
        onRemove={onRemove}
        onDurationChange={onDurationChange}
        onChange={onChange}
        onMoveUp={onMoveUp}
        onMoveDown={onMoveDown}
      />
    )
  }
}

export default ReportageText
