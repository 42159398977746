import { createSelector } from 'reselect'

import { Roles } from '@anews/types'

import { RootState } from '../reducers'

const isSupport = (state: RootState) =>
  state.auth.user && state.auth.user.email === 'suporte@snews.tv'

const getRoles = (state: RootState) => (state.auth.user && state.auth.user.roles) || []

export type RolesChecker = (...roles: Roles[]) => boolean

export const createRolesChecker = createSelector(
  [isSupport, getRoles],
  (support, userRoles): RolesChecker => (...roles) => {
    if (support) {
      return true
    }
    if (Array.isArray(roles)) {
      return roles.every(role => userRoles.includes(role))
    }
    return userRoles.includes(roles)
  },
)
