import { del, get, post, put } from './client';
var endpoint = '/api/productions/resources';
var checklistResourceApi = {
    list: function () {
        return get(endpoint);
    },
    load: function (id) {
        return get(endpoint + "/" + id);
    },
    usageCount: function (id) {
        return get(endpoint + "/" + id + "/usage_count");
    },
    create: function (resource) {
        return post(endpoint, resource);
    },
    update: function (resource) {
        return put("" + endpoint, resource);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default checklistResourceApi;
