import { call, put, takeLatest } from 'redux-saga/effects'

import { postApi as api } from '@anews/api'
import { Post, Page } from '@anews/types'

import {
  ActionType,
  FilterPostsAction,
  filterPostsSuccess,
  filterPostsFailure,
  LoadPostAction,
  loadPostSuccess,
  loadPostFailure,
  CreatePostAction,
  createPostSuccess,
  createPostFailure,
  UpdatePostAction,
  updatePostSuccess,
  updatePostFailure,
  RemovePostsAction,
  removePostsSuccess,
  removePostsFailure,
  CreatePostCopyAction,
  createPostCopySuccess,
  createPostCopyFailure,
  PublishPostAction,
  publishPostSuccess,
  publishPostFailure,
  UnpublishPostAction,
  unpublishPostSuccess,
  unpublishPostFailure,
} from '../actions/post-actions'

import { NotificationActions } from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* filterPostSaga(action: FilterPostsAction): Generator {
  try {
    const result = yield call(api.pageFilter, action.from, action.to, action.filter, action.page)
    yield put(filterPostsSuccess(result as Page<Post>))
  } catch (error) {
    yield put(filterPostsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadPostSaga(action: LoadPostAction): Generator {
  try {
    const post = yield call(api.load, action.id, action.edit)
    yield put(loadPostSuccess(post as Post, action.edit))
  } catch (error) {
    yield put(loadPostFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createPostCopySaga(action: CreatePostCopyAction): Generator {
  try {
    const post = yield call(api.createCopy, action.id)
    yield put(createPostCopySuccess(post as Post))
  } catch (error) {
    yield put(createPostCopyFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* createPostSaga(action: CreatePostAction): Generator {
  try {
    const post = yield call(api.create, action.post)
    yield put(createPostSuccess(post as Post))
  } catch (error) {
    yield put(createPostFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updatePostSaga(action: UpdatePostAction): Generator {
  try {
    const post = yield call(api.update, action.post)
    yield put(updatePostSuccess(post as Post))
  } catch (error) {
    yield put(updatePostFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removePostSaga(action: RemovePostsAction): Generator {
  try {
    const ids = yield call(api.remove, action.ids)
    yield put(removePostsSuccess(ids as number[]))
  } catch (error) {
    yield put(removePostsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

function* publishPostSaga(action: PublishPostAction): Generator {
  try {
    const post = yield call(
      api.publish,
      action.wordpressId,
      action.id,
      action.authorId,
      action.categoryId,
    )
    yield put(publishPostSuccess(post as Post))
  } catch (error) {
    yield put(publishPostFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('post:publishFailed'),
        error,
      }),
    )
  }
}

function* unpublishPostSaga(action: UnpublishPostAction): Generator {
  try {
    yield call(api.unpublish, action.id)
    yield put(unpublishPostSuccess(action.id))
  } catch (error) {
    yield put(unpublishPostFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('post:unpublishFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.FILTER_REQUEST, filterPostSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_REQUEST, loadPostSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_COPY_REQUEST, createPostCopySaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createPostSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updatePostSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removePostSaga)
  },
  function* () {
    yield takeLatest(ActionType.PUBLISH_POST_REQUEST, publishPostSaga)
  },
  function* () {
    yield takeLatest(ActionType.UNPUBLISH_POST_REQUEST, unpublishPostSaga)
  },
])
