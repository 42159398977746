import { Reducer } from 'redux'

import { Classification } from '@anews/types'

import { ActionType, ClassificationAction } from '../actions/classification-actions'

export interface ClassificationsListState {
  data: Classification[]
  loading: boolean
}

export interface ClassificationsState {
  list: ClassificationsListState
}

export const initialState: ClassificationsState = {
  list: {
    data: [],
    loading: true,
  },
}

function listReducer(
  list: ClassificationsListState,
  action: ClassificationAction,
): ClassificationsListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.classifications,
        loading: false,
      }

    default:
      return list
  }
}

const classificationsReducer: Reducer<ClassificationsState, ClassificationAction> = (
  state = initialState,
  action,
): ClassificationsState => {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    default:
      return state
  }
}

export default classificationsReducer
