import { Segment } from '@anews/types'

export enum ActionType {
  NEW = '@round_segment/NEW',
  EDIT = '@round_segment/EDIT',
  LIST_REQUEST = '@round_segment/LIST_REQUEST',
  LIST_SUCCESS = '@round_segment/LIST_SUCCESS',
  LIST_FAILURE = '@round_segment/LIST_FAILURE',
  CREATE_REQUEST = '@round_segment/CREATE_REQUEST',
  CREATE_SUCCESS = '@round_segment/CREATE_SUCCESS',
  CREATE_FAILURE = '@round_segment/CREATE_FAILURE',
  UPDATE_REQUEST = '@round_segment/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@round_segment/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@round_segment/UPDATE_FAILURE',
  REMOVE_REQUEST = '@round_segment/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@round_segment/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@round_segment/REMOVE_FAILURE',
}

export interface ListSegmentsAction {
  type: ActionType.LIST_REQUEST
  institutions: boolean
}

export interface CreateSegmentAction {
  type: ActionType.CREATE_REQUEST
  segment: Segment
}

export interface UpdateSegmentAction {
  type: ActionType.UPDATE_REQUEST
  segment: Segment
}

export interface RemoveSegmentAction {
  type: ActionType.REMOVE_REQUEST
  id: number
}

export type SegmentAction =
  /* newSegment() */
  | { type: ActionType.NEW; segment?: Segment }
  /* listSegments() */
  | ListSegmentsAction
  | { type: ActionType.LIST_SUCCESS; segments: Segment[] }
  | { type: ActionType.LIST_FAILURE; error: Error }
  /* editSegment() */
  | { type: ActionType.EDIT; segment: Segment }
  /* createSegment() */
  | CreateSegmentAction
  | { type: ActionType.CREATE_SUCCESS; segment: Segment }
  | { type: ActionType.CREATE_FAILURE; error: Error }
  /* updateSegment() */
  | UpdateSegmentAction
  | { type: ActionType.UPDATE_SUCCESS; segment: Segment }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* removeSegment() */
  | RemoveSegmentAction
  | { type: ActionType.REMOVE_SUCCESS; id: number }
  | { type: ActionType.REMOVE_FAILURE; error: Error }

//
//	newSegment()
//

export const newSegment = (segment?: Segment): SegmentAction => ({
  type: ActionType.NEW,
  segment,
})

//
//	listSegments()
//

export const listSegments = (institutions: boolean): SegmentAction => ({
  type: ActionType.LIST_REQUEST,
  institutions,
})

export const listSegmentsSuccess = (segments: Segment[]): SegmentAction => ({
  type: ActionType.LIST_SUCCESS,
  segments,
})

export const listSegmentsFailure = (error: Error): SegmentAction => ({
  type: ActionType.LIST_FAILURE,
  error,
})

//
//	editSegment()
//

export const editSegment = (segment: Segment): SegmentAction => ({
  type: ActionType.EDIT,
  segment,
})

//
//	createSegment()
//

export const createSegment = (segment: Segment): SegmentAction => ({
  type: ActionType.CREATE_REQUEST,
  segment,
})

export const createSegmentSuccess = (segment: Segment): SegmentAction => ({
  type: ActionType.CREATE_SUCCESS,
  segment,
})

export const createSegmentFailure = (error: Error): SegmentAction => ({
  type: ActionType.CREATE_FAILURE,
  error,
})

//
//	updateSegment()
//

export const updateSegment = (segment: Segment): SegmentAction => ({
  type: ActionType.UPDATE_REQUEST,
  segment,
})

export const updateSegmentSuccess = (segment: Segment): SegmentAction => ({
  type: ActionType.UPDATE_SUCCESS,
  segment,
})

export const updateSegmentFailure = (error: Error): SegmentAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//	removeSegment()
//

export const removeSegment = (id: number): SegmentAction => ({
  type: ActionType.REMOVE_REQUEST,
  id,
})

export const removeSegmentSuccess = (id: number): SegmentAction => ({
  type: ActionType.REMOVE_SUCCESS,
  id,
})

export const removeSegmentFailure = (error: Error): SegmentAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})
