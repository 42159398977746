import { call, put, takeLatest } from 'redux-saga/effects'

import { ldapApi as api } from '@anews/api'
import { LdapConfig } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ActionType,
  loadConfigSuccess,
  loadConfigFailure,
  UpdateLdapConfigAction,
  updateConfigSuccess,
  updateConfigFailure,
  CreateLdapConfigAction,
  createConfigSuccess,
  createConfigFailure,
  syncStateSuccess,
  syncStateFailure,
} from '../actions/ldap-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions

/* Watchers */

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as LdapConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: CreateLdapConfigAction): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as LdapConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: UpdateLdapConfigAction): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as LdapConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* synchronizeUsers(): Generator {
  try {
    yield call(api.synchronizeUsers)
  } catch (error) {
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* syncState(): Generator {
  try {
    const state = yield call(api.syncState)
    yield put(syncStateSuccess(state as boolean))
  } catch (error) {
    yield put(syncStateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.SYNC_USERS_REQUEST, synchronizeUsers)
  },
  function* () {
    yield takeLatest(ActionType.SYNC_STATE_REQUEST, syncState)
  },
])
