import { Post, Page } from '@anews/types'

import { PAGE_SIZE } from '../../consts/pagination'

export enum ActionType {
  NEW = '@post/NEW',
  UNLOAD = '@post/UNLOAD',
  // filterPosts()
  FILTER_REQUEST = '@post/FILTER_REQUEST',
  FILTER_SUCCESS = '@post/FILTER_SUCCESS',
  FILTER_FAILURE = '@post/FILTER_FAILURE',
  // loadPost()
  LOAD_REQUEST = '@post/LOAD_REQUEST',
  LOAD_SUCCESS = '@post/LOAD_SUCCESS',
  LOAD_FAILURE = '@post/LOAD_FAILURE',
  // createPostCopy()
  CREATE_COPY_REQUEST = '@post/CREATE_COPY_REQUEST',
  CREATE_COPY_SUCCESS = '@post/CREATE_COPY_SUCCESS',
  CREATE_COPY_FAILURE = '@post/CREATE_COPY_FAILURE',
  // createPost()
  CREATE_REQUEST = '@post/CREATE_REQUEST',
  CREATE_SUCCESS = '@post/CREATE_SUCCESS',
  CREATE_FAILURE = '@post/CREATE_FAILURE',
  // updatePost()
  UPDATE_REQUEST = '@post/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@post/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@post/UPDATE_FAILURE',
  // removePosts()
  REMOVE_REQUEST = '@post/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@post/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@post/REMOVE_FAILURE',
  // publishPost()
  PUBLISH_POST_REQUEST = '@post/PUBLISH_POST_REQUEST',
  PUBLISH_POST_SUCCESS = '@post/PUBLISH_POST_SUCCESS',
  PUBLISH_POST_FAILURE = '@post/PUBLISH_POST_FAILURE',
  // unpublishPost()
  UNPUBLISH_POST_REQUEST = '@post/UNPUBLISH_POST_REQUEST',
  UNPUBLISH_POST_SUCCESS = '@post/UNPUBLISH_POST_SUCCESS',
  UNPUBLISH_POST_FAILURE = '@post/UNPUBLISH_POST_FAILURE',
  // websocket
  WS_CREATE = '@post/WS_CREATE',
  WS_DELETE = '@post/WS_DELETE',
  WS_UPDATE = '@post/WS_UPDATE',
}

export interface FilterPostsAction {
  type: ActionType.FILTER_REQUEST
  from?: string
  to?: string
  filter?: string
  page: number
  size: number
}

export interface LoadPostAction {
  type: ActionType.LOAD_REQUEST
  id: number
  edit: boolean
}

export interface CreatePostCopyAction {
  type: ActionType.CREATE_COPY_REQUEST
  id: number
}

export interface CreatePostAction {
  type: ActionType.CREATE_REQUEST
  post: Post
}

export interface UpdatePostAction {
  type: ActionType.UPDATE_REQUEST
  post: Post
}

export interface RemovePostsAction {
  type: ActionType.REMOVE_REQUEST
  ids: number[]
}

export interface PublishPostAction {
  type: ActionType.PUBLISH_POST_REQUEST
  wordpressId: number
  id: number
  authorId: string
  categoryId: string
}

export interface UnpublishPostAction {
  type: ActionType.UNPUBLISH_POST_REQUEST
  id: number
}

export type PostAction =
  /* filterPostss() */
  | FilterPostsAction
  | { type: ActionType.FILTER_SUCCESS; result: Page<Post> }
  | { type: ActionType.FILTER_FAILURE; error: Error }
  /* loadPost() */
  | LoadPostAction
  | { type: ActionType.LOAD_SUCCESS; post: Post; edit: boolean }
  | { type: ActionType.LOAD_FAILURE; error: Error }
  /* createPostCopy() */
  | CreatePostCopyAction
  | { type: ActionType.CREATE_COPY_SUCCESS; post: Post }
  | { type: ActionType.CREATE_COPY_FAILURE; error: Error }
  /* createPost() */
  | CreatePostAction
  | { type: ActionType.CREATE_SUCCESS; post: Post }
  | { type: ActionType.CREATE_FAILURE; error: Error }
  /* updatePost() */
  | UpdatePostAction
  | { type: ActionType.UPDATE_SUCCESS; post: Post }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* removePosts() */
  | RemovePostsAction
  | { type: ActionType.REMOVE_SUCCESS; ids: number[] }
  | { type: ActionType.REMOVE_FAILURE; error: Error }
  /* publishPost() */
  | PublishPostAction
  | { type: ActionType.PUBLISH_POST_SUCCESS; post: Post }
  | { type: ActionType.PUBLISH_POST_FAILURE; error: Error }
  /* unpublishPost() */
  | UnpublishPostAction
  | { type: ActionType.UNPUBLISH_POST_SUCCESS; id: number }
  | { type: ActionType.UNPUBLISH_POST_FAILURE; error: Error }
  /* newPost() */
  | { type: ActionType.NEW; post?: Post }
  /* unloadPost() */
  | { type: ActionType.UNLOAD }
  /* wsCreatePost() */
  | { type: ActionType.WS_CREATE; post: Post }
  /* wsUpdatePost() */
  | { type: ActionType.WS_UPDATE; post: Post }
  /* wsDeletePost() */
  | { type: ActionType.WS_DELETE; id: number }

//
//	filterPosts()
//

export const filterPosts = (
  from?: string,
  to?: string,
  filter?: string,
  page: number = 0,
  size: number = PAGE_SIZE,
): PostAction => ({ type: ActionType.FILTER_REQUEST, from, to, filter, page, size })

export const filterPostsSuccess = (result: Page<Post>): PostAction => ({
  type: ActionType.FILTER_SUCCESS,
  result,
})

export const filterPostsFailure = (error: Error): PostAction => ({
  type: ActionType.FILTER_FAILURE,
  error,
})

//
//	loadPost()
//

export const loadPost = (id: number, edit: boolean): PostAction => ({
  type: ActionType.LOAD_REQUEST,
  id,
  edit,
})

export const loadPostSuccess = (post: Post, edit: boolean): PostAction => ({
  type: ActionType.LOAD_SUCCESS,
  post,
  edit,
})

export const loadPostFailure = (error: Error): PostAction => ({
  type: ActionType.LOAD_FAILURE,
  error,
})

//
//	createPostCopy()
//

export const createPostCopy = (id: number): PostAction => ({
  type: ActionType.CREATE_COPY_REQUEST,
  id,
})

export const createPostCopySuccess = (post: Post): PostAction => ({
  type: ActionType.CREATE_COPY_SUCCESS,
  post,
})

export const createPostCopyFailure = (error: Error): PostAction => ({
  type: ActionType.CREATE_COPY_FAILURE,
  error,
})

//
//	createPost()
//

export const createPost = (post: Post): PostAction => ({
  type: ActionType.CREATE_REQUEST,
  post,
})

export const createPostSuccess = (post: Post): PostAction => ({
  type: ActionType.CREATE_SUCCESS,
  post,
})

export const createPostFailure = (error: Error): PostAction => ({
  type: ActionType.CREATE_FAILURE,
  error,
})

//
//	updatePost()
//

export const updatePost = (post: Post): PostAction => ({
  type: ActionType.UPDATE_REQUEST,
  post,
})

export const updatePostSuccess = (post: Post): PostAction => ({
  type: ActionType.UPDATE_SUCCESS,
  post,
})

export const updatePostFailure = (error: Error): PostAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//	removePosts()
//

export const removePosts = (ids: number[]): PostAction => ({
  type: ActionType.REMOVE_REQUEST,
  ids,
})

export const removePostsSuccess = (ids: number[]): PostAction => ({
  type: ActionType.REMOVE_SUCCESS,
  ids,
})

export const removePostsFailure = (error: Error): PostAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})

//
//  publishPost()
//

export const publishPost = (
  wordpressId: number,
  id: number,
  authorId: string,
  categoryId: string,
): PostAction => ({
  type: ActionType.PUBLISH_POST_REQUEST,
  wordpressId,
  id,
  authorId,
  categoryId,
})

export const publishPostSuccess = (post: Post): PostAction => ({
  type: ActionType.PUBLISH_POST_SUCCESS,
  post,
})

export const publishPostFailure = (error: Error): PostAction => ({
  type: ActionType.PUBLISH_POST_FAILURE,
  error,
})

//
//  unpublishPost()
//

export const unpublishPost = (id: number): PostAction => ({
  type: ActionType.UNPUBLISH_POST_REQUEST,
  id,
})

export const unpublishPostSuccess = (id: number): PostAction => ({
  type: ActionType.UNPUBLISH_POST_SUCCESS,
  id,
})

export const unpublishPostFailure = (error: Error): PostAction => ({
  type: ActionType.UNPUBLISH_POST_FAILURE,
  error,
})

//
//	newPost()
//

export const newPost = (post?: Post): PostAction => ({
  type: ActionType.NEW,
  post,
})

//
//	unloadPost()
//

export const unloadPost = (): PostAction => ({ type: ActionType.UNLOAD })

//
//  wsCreatePost()
//

export const wsCreatePost = (post: Post): PostAction => ({ type: ActionType.WS_CREATE, post })

//
//  wsUpdatePost()
//

export const wsUpdatePost = (post: Post): PostAction => ({ type: ActionType.WS_UPDATE, post })

//
//  wsDeletePost()
//

export const wsDeletePost = (id: number): PostAction => ({ type: ActionType.WS_DELETE, id })
