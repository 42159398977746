import { FacebookPage } from '@anews/types'

export enum FacebookPageActionType {
  NEW = '@facebook_page/NEW',
  EDIT = '@facebook_page/EDIT',
  LIST_REQUEST = '@facebook_page/LIST_REQUEST',
  LIST_SUCCESS = '@facebook_page/LIST_SUCCESS',
  LIST_FAILURE = '@facebook_page/LIST_FAILURE',
  CREATE_REQUEST = '@facebook_page/CREATE_REQUEST',
  CREATE_SUCCESS = '@facebook_page/CREATE_SUCCESS',
  CREATE_FAILURE = '@facebook_page/CREATE_FAILURE',
  UPDATE_REQUEST = '@facebook_page/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@facebook_page/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@facebook_page/UPDATE_FAILURE',
  REMOVE_REQUEST = '@facebook_page/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@facebook_page/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@facebook_page/REMOVE_FAILURE',
}

export type FacebookPageActionMap<T extends FacebookPageActionType> = {
  type: T
} & FacebookPageAction

export type FacebookPageAction = ReturnType<
  typeof FacebookPageActions[keyof typeof FacebookPageActions]
>

export const FacebookPageActions = {
  //
  //	newFacebookPage()
  //

  newFacebookPage: (page?: FacebookPage) => <const>{ type: FacebookPageActionType.NEW, page },

  //
  //	listFacebookPages()
  //

  listFacebookPages: () => <const>{ type: FacebookPageActionType.LIST_REQUEST },

  listFacebookPagesSuccess: (pages: FacebookPage[]) =>
    <const>{ type: FacebookPageActionType.LIST_SUCCESS, pages },

  listFacebookPagesFailure: (error: Error) =>
    <const>{ type: FacebookPageActionType.LIST_FAILURE, error },

  //
  //	editFacebookPage()
  //

  editFacebookPage: (page: FacebookPage) => <const>{ type: FacebookPageActionType.EDIT, page },

  //
  //	createFacebookPage()
  //

  createFacebookPage: (page: FacebookPage) =>
    <const>{ type: FacebookPageActionType.CREATE_REQUEST, page },

  createFacebookPageSuccess: (page: FacebookPage) =>
    <const>{ type: FacebookPageActionType.CREATE_SUCCESS, page },

  createFacebookPageFailure: (error: Error) =>
    <const>{ type: FacebookPageActionType.CREATE_FAILURE, error },

  //
  //	updateFacebookPage()
  //

  updateFacebookPage: (page: FacebookPage) =>
    <const>{ type: FacebookPageActionType.UPDATE_REQUEST, page },

  updateFacebookPageSuccess: (page: FacebookPage) =>
    <const>{ type: FacebookPageActionType.UPDATE_SUCCESS, page },

  updateFacebookPageFailure: (error: Error) =>
    <const>{ type: FacebookPageActionType.UPDATE_FAILURE, error },

  //
  //	removeFacebookPage()
  //

  removeFacebookPage: (id: number) => <const>{ type: FacebookPageActionType.REMOVE_REQUEST, id },

  removeFacebookPageSuccess: (id: number) =>
    <const>{ type: FacebookPageActionType.REMOVE_SUCCESS, id },

  removeFacebookPageFailure: (error: Error) =>
    <const>{ type: FacebookPageActionType.REMOVE_FAILURE, error },
}
