import { Story } from '@anews/types'

import { StoryChange } from './story-actions'

export enum ActionType {
  // updateStory()
  UPDATE_REQUEST = '@storytemplate/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@storytemplate/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@storytemplate/UPDATE_FAILURE',
  // patchStory()
  PATCH_REQUEST = '@storytemplate/PATCH_REQUEST',
  PATCH_SUCCESS = '@storytemplate/PATCH_SUCCESS',
  PATCH_FAILURE = '@storytemplate/PATCH_FAILURE',
  // removeStories()
  REMOVE_REQUEST = '@storytemplate/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@storytemplate/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@storytemplate/REMOVE_FAILURE',
  // websocket
  WS_DELETE = '@storytemplate/WS_DELETE',
  WS_PATCH = '@storytemplate/WS_PATCH',
  WS_UPDATE = '@storytemplate/WS_UPDATE',
}

export interface UpdateStoryAction {
  type: ActionType.UPDATE_REQUEST
  story: Story
}

export interface PatchStoryAction {
  type: ActionType.PATCH_REQUEST
  storyId: number
  field: keyof Story
  newValue: any
  oldValue: any
}

export interface RemoveStoriesAction {
  type: ActionType.REMOVE_REQUEST
  ids: number[]
}

export type StoryTemplateAction =
  /* updateStory() */
  | UpdateStoryAction
  | { type: ActionType.UPDATE_SUCCESS; story: Story }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* patchStory() */
  | PatchStoryAction
  | { type: ActionType.PATCH_SUCCESS }
  | {
      type: ActionType.PATCH_FAILURE
      error: Error
      storyId: number
      field: keyof Story
      newValue: any
      oldValue: any
    }
  /* removeStories() */
  | RemoveStoriesAction
  | { type: ActionType.REMOVE_SUCCESS; ids: number[] }
  | { type: ActionType.REMOVE_FAILURE; error: Error }
  /* websocket */
  | { type: ActionType.WS_DELETE; ids: number[] }
  | { type: ActionType.WS_PATCH; changes: StoryChange[] }
  | { type: ActionType.WS_UPDATE; stories: Story[] }

//
//	updateStory()
//

export const updateStory = (story: Story): StoryTemplateAction => ({
  type: ActionType.UPDATE_REQUEST,
  story,
})

export const updateStorySuccess = (story: Story): StoryTemplateAction => ({
  type: ActionType.UPDATE_SUCCESS,
  story,
})

export const updateStoryFailure = (error: Error): StoryTemplateAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//  patchStory()
//

export const patchStory = (
  storyId: number,
  field: keyof Story,
  newValue: any,
  oldValue: any,
): StoryTemplateAction => ({ type: ActionType.PATCH_REQUEST, storyId, field, newValue, oldValue })

export const patchStorySuccess = (): StoryTemplateAction => ({ type: ActionType.PATCH_SUCCESS })

export const patchStoryFailure = (
  error: Error,
  storyId: number,
  field: keyof Story,
  newValue: any,
  oldValue: any,
): StoryTemplateAction => ({
  type: ActionType.PATCH_FAILURE,
  error,
  storyId,
  field,
  newValue,
  oldValue,
})

//
//  removeStories()
//

export const removeStories = (ids: number[]): StoryTemplateAction => ({
  type: ActionType.REMOVE_REQUEST,
  ids,
})

export const removeStoriesSuccess = (ids: number[]): StoryTemplateAction => ({
  type: ActionType.REMOVE_SUCCESS,
  ids,
})

export const removeStoriesFailure = (error: Error): StoryTemplateAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})

//
//  websocket
//

export const wsDeleteStories = (ids: number[]): StoryTemplateAction => ({
  type: ActionType.WS_DELETE,
  ids,
})

export const wsPatchStory = (changes: StoryChange[]): StoryTemplateAction => ({
  type: ActionType.WS_PATCH,
  changes,
})

export const wsUpdateStories = (stories: Story[]): StoryTemplateAction => ({
  type: ActionType.WS_UPDATE,
  stories,
})
