import { ReportCategory } from '@anews/types'

export enum ReportCategoryActionType {
  NEW = '@report_category/NEW',
  EDIT = '@report_category/EDIT',
  LIST_REQUEST = '@report_category/LIST_REQUEST',
  LIST_SUCCESS = '@report_category/LIST_SUCCESS',
  LIST_FAILURE = '@report_category/LIST_FAILURE',
  CREATE_REQUEST = '@report_category/CREATE_REQUEST',
  CREATE_SUCCESS = '@report_category/CREATE_SUCCESS',
  CREATE_FAILURE = '@report_category/CREATE_FAILURE',
  UPDATE_REQUEST = '@report_category/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@report_category/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@report_category/UPDATE_FAILURE',
  REMOVE_REQUEST = '@report_category/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@report_category/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@report_category/REMOVE_FAILURE',
}

export type ReportCategoryActionMap<T extends ReportCategoryActionType> = {
  type: T
} & ReportCategoryAction

export type ReportCategoryAction = ReturnType<
  typeof ReportCategoryActions[keyof typeof ReportCategoryActions]
>

export const ReportCategoryActions = {
  //	newReportCategory()

  newReportCategory: (category?: ReportCategory) =>
    <const>{ type: ReportCategoryActionType.NEW, category },

  //	listReportCategories()

  listReportCategories: (events: boolean) =>
    <const>{ type: ReportCategoryActionType.LIST_REQUEST, events },

  listReportCategoriesSuccess: (categories: ReportCategory[]) =>
    <const>{ type: ReportCategoryActionType.LIST_SUCCESS, categories },

  listReportCategoriesFailure: (error: Error) =>
    <const>{ type: ReportCategoryActionType.LIST_FAILURE, error },

  //	editReportCategory()

  editReportCategory: (category: ReportCategory) =>
    <const>{ type: ReportCategoryActionType.EDIT, category },

  //	createReportCategory()

  createReportCategory: (category: ReportCategory) =>
    <const>{ type: ReportCategoryActionType.CREATE_REQUEST, category },

  createReportCategorySuccess: (category: ReportCategory) =>
    <const>{ type: ReportCategoryActionType.CREATE_SUCCESS, category },

  createReportCategoryFailure: (error: Error) =>
    <const>{ type: ReportCategoryActionType.CREATE_FAILURE, error },

  //	updateReportCategory()

  updateReportCategory: (category: ReportCategory) =>
    <const>{ type: ReportCategoryActionType.UPDATE_REQUEST, category },

  updateReportCategorySuccess: (category: ReportCategory) =>
    <const>{ type: ReportCategoryActionType.UPDATE_SUCCESS, category },

  updateReportCategoryFailure: (error: Error) =>
    <const>{ type: ReportCategoryActionType.UPDATE_FAILURE, error },

  //	removeReportCategory()

  removeReportCategory: (id: number) =>
    <const>{ type: ReportCategoryActionType.REMOVE_REQUEST, id },

  removeReportCategorySuccess: (id: number) =>
    <const>{ type: ReportCategoryActionType.REMOVE_SUCCESS, id },

  removeReportCategoryFailure: (error: Error) =>
    <const>{ type: ReportCategoryActionType.REMOVE_FAILURE, error },
}
